import { JerseySection, ModelStyle } from './types';
import type { ColorTabInterface } from './types';
import { useEffect, useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ColorResponseItem } from './responses';

import {
  faAngleRight,
  faAngleLeft
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import IconCross from './icons/IconCross';
import IconPlus from './icons/IconPlus';
import IconMinus from './icons/IconMinus';

enum SelectColorTabMobile {
  Frontside,
  Backside,
  Sleeve,
  Collar,
  Short,
  ShortElastic,
  ShortStripe
}

const PER_ROW_ITEM = 6;

export default function JakoColorTab({
  modelStyle,
  colorList,
  possibleDesignAreaId,
  selectedJerseySectionColor,
  onSelectColor,
  setSelectTabBack,
  setSelectTabNext,
  isMobile,
  onCloseMobile,
}: ColorTabInterface) {
  const { t } = useTranslation('translations');
  const [showMoreColorSection, setShowMoreColorSection] = useState({
    [JerseySection.Front]: false,
    [JerseySection.Back]: false,
    [JerseySection.Left]: false,
    [JerseySection.Right]: false,
    [JerseySection.CuffLeft]: false,
    [JerseySection.CuffRight]: false,
    [JerseySection.ShortStripe]: false,
  } as any);

  const scrollBarRef = useRef(null as any);
  const [hasMoreScrollContainer, setHasMoreScrollContainer] = useState(false);
  const [selectTabMobile, setSelectTabMobile] = useState<SelectColorTabMobile>(SelectColorTabMobile.Frontside);

  const hasShowingMoreColorSection = useMemo(() => Object.values(showMoreColorSection).some((val) => val) , [showMoreColorSection]);
  const onlyMainSectionKey = useMemo(
    () => Object.keys(selectedJerseySectionColor).slice()
      .filter((itemKey) => !itemKey.includes('neck') && !itemKey.includes('bg-') && !itemKey.includes('copy'))
  , [selectedJerseySectionColor]);
  const colorRowCount = useMemo(() => Math.ceil(colorList.length / PER_ROW_ITEM), [colorList]);

  const uniqueColorList = useMemo(() => {
    // arr.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
    return colorList.filter((v,i,a)=>a.findIndex(v2=>(v2.colorHexCode===v.colorHexCode))===i);
  }, [colorList]);

  const hasShortElastic = useMemo(() => selectedJerseySectionColor && Object.keys(selectedJerseySectionColor).some((key) => key.includes('elastic')), [selectedJerseySectionColor]);
  const isShortElasticLeftRight = useMemo(() => selectedJerseySectionColor && selectedJerseySectionColor['elastic_left_in'] && selectedJerseySectionColor['elastic_right_in'], [selectedJerseySectionColor]);

  const sectionColorOptions: {
    [key: string]: ColorResponseItem[][]
  } = useMemo(
    () => {
      let results = {};
      onlyMainSectionKey
        .forEach((itemKey: string) => {
          let reArrangeColorListResults = [...colorList];
          const foundedIndex = colorList.findIndex((colorItem) => '#' + colorItem.colorHexCode === selectedJerseySectionColor[itemKey]);
          // array.splice(to, 0, array.splice(from, 1)[0]);
          reArrangeColorListResults.splice(0, 0, reArrangeColorListResults.splice(foundedIndex, 1)[0]);
          let colorItemsPerRow: any = [];
          for (let row = 1; row <= colorRowCount; row++) {
            const perRowItems = reArrangeColorListResults.slice((row - 1) * PER_ROW_ITEM, row * PER_ROW_ITEM);
            colorItemsPerRow.push(perRowItems);
          }
          // colorItemsPerRow;
          results = {
            ...results,
            [itemKey]: colorItemsPerRow
          };
        });
      return results;
    }
  , [colorRowCount, colorList, onlyMainSectionKey, selectedJerseySectionColor]);

  const colorListDataByHex = useMemo<{
    [key: string]: ColorResponseItem
  }>(() => {
    let tmpColorList = {};
    uniqueColorList.forEach((item) => {
      tmpColorList = {
        ...tmpColorList,
        [item.colorHexCode]: item
      };
    });
    return tmpColorList;
  }, [uniqueColorList]);

  const onlyHexCodeList = useMemo(() => uniqueColorList.slice().map((item) => item.colorHexCode), [uniqueColorList]);
  const [orderedDesignAreaKeys, setOrderedDesignAreaKeys] = useState<any>([]);
  useEffect(() => {
    if (!scrollBarRef.current) {
      return;
    }

    onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
    scrollBarRef.current.getScrollElement()
      .addEventListener("scroll", function(e: any) { onScrollContainerUpdate(e.target); });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let onlyPossibleDesignAreas: any = {};
    console.log('possibleDesignAreaId', possibleDesignAreaId);
    if (possibleDesignAreaId.length) {
      const onlyNonCopyItems = possibleDesignAreaId.slice().filter((area) => !area.includes('collar_under') && !area.includes('placket') && !area.includes('neck') && !area.includes('bg-') && !area.includes('copy'));
      onlyNonCopyItems.forEach((item) => {
        const getWordDesignPart = item.split('_')[0]; // design-1, design-2, design-3
        const getWordDesignJerseySectionPart = item.split('_')[1] ? item.split('_')[1] : ''; // front, back, left, right
        const getDesignWord = getWordDesignPart.split('-')[0] + (getWordDesignPart.split('-')[1] ? ' ' + getWordDesignPart.split('-')[1] : '');
        const getAreaName = getDesignWord + ' ' + getWordDesignJerseySectionPart;
        onlyPossibleDesignAreas = {
          ...onlyPossibleDesignAreas,
          [item]: getAreaName
        };
      });
      const tmpOrderedDesignAreaKeys = Object.keys(onlyPossibleDesignAreas).sort((aKey: string, bKey: string) => {
        const getWordDesignPartA = aKey.split('_')[0]; // design-1, design-2, design-3
        const getWordDesignPartB = bKey.split('_')[0]; // design-1, design-2, design-3
        const getNumberA = parseInt(getWordDesignPartA.split('-')[1]);
        const getNumberB = parseInt(getWordDesignPartB.split('-')[1]);
        const getJerseySectionA = aKey.split('_')[1];
        const getJerseySectionB = bKey.split('_')[1];
        if (getJerseySectionA === 'front' && getJerseySectionB === 'front') {
          return getNumberA - getNumberB;
        } else if (getJerseySectionA === 'front') {
          return -1;
        } else if (getJerseySectionB === 'front') {
          return 1;
        } else if (getJerseySectionA === 'back' && getJerseySectionB === 'back') {
          return getNumberA - getNumberB;
        } else if (getJerseySectionA === 'back') {
          return -1;
        } else if (getJerseySectionB === 'back') {
          return 1;
        } else if (getJerseySectionA === 'left' && getJerseySectionB === 'left') {
          return getNumberA - getNumberB;
        } else if (getJerseySectionA === 'left') {
          return -1;
        } else if (getJerseySectionB === 'left') {
          return 1;
        } else if (getJerseySectionA === 'right' && getJerseySectionB === 'right') {
          return getNumberA - getNumberB;
        } else if (getJerseySectionA === 'right') {
          return -1;
        } else if (getJerseySectionB === 'right') {
          return 1;
        } else {
          return  getNumberA - getNumberB;
        }
      });
      setOrderedDesignAreaKeys(tmpOrderedDesignAreaKeys);
    }
  }, [possibleDesignAreaId]);

  const frontDesignAreaKeys = useMemo(() => orderedDesignAreaKeys.slice().filter((key: string) => key.includes('_front')), [orderedDesignAreaKeys]);
  const backDesignAreaKeys = useMemo(() => orderedDesignAreaKeys.slice().filter((key: string) => key.includes('_back')), [orderedDesignAreaKeys]);

  const leftDesignAreaKeys = useMemo(() => orderedDesignAreaKeys.slice().filter((key: string) => key.includes('_left')), [orderedDesignAreaKeys]);
  const rightDesignAreaKeys = useMemo(() => orderedDesignAreaKeys.slice().filter((key: string) => key.includes('_right')), [orderedDesignAreaKeys]);

  console.log('leftDesignAreaKeys', leftDesignAreaKeys);
  console.log('rightDesignAreaKeys', rightDesignAreaKeys);
  useEffect(() => {
    if (modelStyle === ModelStyle.Short) {
      setSelectTabMobile(SelectColorTabMobile.Short);
    } else {
      setSelectTabMobile(SelectColorTabMobile.Frontside);
    }
  }, [modelStyle]);
  useEffect(() => {
    if (orderedDesignAreaKeys.length) {
      let results = {};
      orderedDesignAreaKeys.forEach((itemKey: string) => {
        results = {
          ...results,
          [itemKey]: false
        };
      });
      setShowMoreColorSection({
        ...showMoreColorSection,
        ...results,
      });
    }
    // eslint-disable-next-line
  }, [colorList]);

  function handleOnSelectOption(section: string) {
    let results = {};
    Object.keys(orderedDesignAreaKeys).forEach((itemKey) => {
      results = {
        ...results,
        [itemKey]: false
      };
    });
    results = {
      [JerseySection.Front]: false,
      [JerseySection.Back]: false,
      [JerseySection.Left]: false,
      [JerseySection.Right]: false,
      [JerseySection.CuffLeft]: false,
      [JerseySection.CuffRight]: false,
      [JerseySection.ShortStripe]: false,
      ...results,
      [section]: !showMoreColorSection[section],
    };

    setShowMoreColorSection(results);
  }

  function getSelectedColorOptionItemFromHex(hex: string) {
    const foundedColor = colorList.find((item) => ('#'+item.colorHexCode) === hex);
    if (foundedColor) {
      return foundedColor;
    }
    return colorList[0];
  }

  function onScrollContainerUpdate(el: any) {
    if (el.clientHeight + el.scrollTop >= el.scrollHeight) {
      setHasMoreScrollContainer(false);
    } else {
      setHasMoreScrollContainer(true);
    }
  }

  function handleOnClickCloseMobile() {
    if (scrollBarRef.current) {
      onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
    }
    onCloseMobile();
  }

  function hideAllColorSection() {
    let results = {};
    Object.keys(orderedDesignAreaKeys).forEach((itemKey) => {
      results = {
        ...results,
        [itemKey]: false
      };
    });
    results = {
      [JerseySection.Front]: false,
      [JerseySection.Back]: false,
      [JerseySection.Left]: false,
      [JerseySection.Right]: false,
      [JerseySection.CuffLeft]: false,
      [JerseySection.CuffRight]: false,
      [JerseySection.ShortStripe]: false,
      ...results,
    };
    setShowMoreColorSection(results);
  }

  function onSelectTabMobile(selectTab: SelectColorTabMobile) {
    hideAllColorSection();
    setSelectTabMobile(selectTab);
  }

  function MobileColorSelectMain({ designKey, label }: { designKey: string, label: string }) {
    return <div
      className={
        "relative itemColor !pb-8 !h-auto "
        + (showMoreColorSection[designKey] ? 'active' : '')
      }
      onClick={() => handleOnSelectOption(designKey)}
    >
      <div
        className={
          "itemColor--bgColor tooltip"
          + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).code === '01_SU_WEISS' ? ' border' : '')
        }
        style={{
          backgroundColor: selectedJerseySectionColor[designKey]
        }}
        data-tooltip={getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).description}
      >
        <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).description }</label>
      </div>
      <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ label }</p>
    </div>;
  }

  function MobileColorOptionMore({ designKey }: { designKey: string }) {
    return <div
      className={
        "colorWrapper colorWrapper--responsive mt-4 pt-4 border-t-2 border-[#F0F0F0]"
        + (showMoreColorSection[designKey] ? ' grid' : ' hidden')
      }
    >
      {
        uniqueColorList.map((item, index) =>
          <div
            key={'frontColor' + index + '#' + item.colorHexCode + item.description}
            className={
              "itemColor "
              + ('#' + item.colorHexCode === selectedJerseySectionColor[designKey] ? 'active' : '')
            }
            onClick={() => onSelectColor(designKey, '#' + item.colorHexCode)}
          >
            <div
              className={
                "itemColor--bgColor tooltip"
                + (item.code === '01_SU_WEISS' ? ' border' : '')
              }
              style={{
                backgroundColor: '#' + item.colorHexCode
              }}
              data-tooltip={item.description}
            >
              <label className="tooltipBubble">{ item.description }</label>
            </div>
          </div>
        )
      }
    </div>;
  }

  function DesktopColorPreviewSection({ designKey }: { designKey: string }) {
    return <div
      className={
        "colorWrapper colorWrapper--7cols"
        + (showMoreColorSection[designKey] ? ' mb-10' : '')
      }
    >
      <div className="relative itemColor active !border-b-0">
        <div
          className={
            "itemColor--bgColor tooltip"
            + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).code === '01_SU_WEISS' ? ' border' : '')
          }
          style={{
            backgroundColor: selectedJerseySectionColor[designKey]
          }}
          data-tooltip={getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).description}
        >
          <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).description }</label>
        </div>
      </div>
      {
        onlyHexCodeList.slice(1, 6).map((colorHexCode, index) =>
          <div
            key={'frontColorPreview' + index + '#' + colorHexCode + colorListDataByHex[colorHexCode].description}
            className="relative itemColor"
            onClick={() => onSelectColor(designKey, '#' + colorHexCode)}
          >
            <div
              className={
                "itemColor--bgColor tooltip"
                + (getSelectedColorOptionItemFromHex('#'+colorHexCode).code === '01_SU_WEISS' ? ' border' : '')
              }
              style={{
                backgroundColor: '#'+colorHexCode
              }}
              data-tooltip={getSelectedColorOptionItemFromHex('#'+colorHexCode).description}
            >
              <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex('#'+colorHexCode).description }</label>
            </div>
          </div>
        )
      }
      <div
        className="relative flex items-center justify-end max-w-[60px] cursor-pointer"
        onClick={() => handleOnSelectOption(designKey)}
      >
        {
        showMoreColorSection[designKey]
          ? <div className="flex flex-col w-full h-full items-center justify-center text-[#141414]">
            <IconMinus className="w-auto h-[14px]" />
            <span className="font-semibold text-[14px]">{ t('configurator_3d_less') }</span>
          </div>
          : <div className="flex flex-col w-full h-full items-center justify-center text-[#141414]">
            <IconPlus className="w-auto h-[14px]" />
            <span className="font-semibold text-[14px]">{ t('configurator_3d_more') }</span>
          </div>
      }
      </div>
    </div>;
  }

  function DesktopColorMoreSection({ designKey }: { designKey: string }) {
    return <div
      className={
        "colorWrapper colorWrapper--7cols mt-4"
        + (showMoreColorSection[designKey] ? ' grid' : ' hidden')
      }
    >
      {
        onlyHexCodeList.map((colorHexCode, index) =>
          <div
            key={'frontColor' + index + '#' + colorHexCode + colorListDataByHex[colorHexCode].description}
            className={
              "itemColor "
              + ('#' + colorHexCode === selectedJerseySectionColor[designKey] ? 'active' : '')
            }
            onClick={() => onSelectColor(designKey, '#' + colorHexCode)}
          >
            <div
              className={
                "itemColor--bgColor tooltip"
                + (colorListDataByHex[colorHexCode].code === '01_SU_WEISS' ? ' border' : '')
              }
              style={{
                backgroundColor: '#' + colorHexCode
              }}
              data-tooltip={colorListDataByHex[colorHexCode].description}
            >
              <label className="tooltipBubble">{ colorListDataByHex[colorHexCode].description }</label>
            </div>
          </div>
        )
      }
    </div>;
  }

  if (Object.keys(sectionColorOptions).length === 0) {
    return (
      <>
        <div className="h1 text-center">{ t('configurator_3d_choose_colors') }</div>
        <div className="text-center marginBottom--small">{ t('configurator_3d_choose_colors_text') }</div>
      </>
    );
  }

  return modelStyle === ModelStyle.Shirt || modelStyle === ModelStyle.ShirtNoSleeve
    ? isMobile
      ? <div
        className="relative w-full px-6 pt-10 pb-6"
        style={{
          height: hasShowingMoreColorSection ? '325px' : '150px'
        }}
      >
        <button
          className="absolute top-[18px] right-[12px] z-10"
          onClick={handleOnClickCloseMobile}
        >
          <IconCross className="h-[20px]" />
        </button>
        <div className="relative w-full pb-3 flex items-center gap-5 items-center mb-4 before:absolute before:w-full before:h-[2px] before:bottom-[1px] before:left-0 before:bg-[#F0F0F0]">
          <button
            className={
              "relative font-semibold text-[14px] before:absolute before:w-full before:h-[2px] before:bg-[#141414] before:left-0 before:bottom-[-6px] "
              + (selectTabMobile === SelectColorTabMobile.Frontside ? 'text-[#141414] before:opacity-100' : 'text-[#C3C3C3] before:opacity-0')
            }
            onClick={() => onSelectTabMobile(SelectColorTabMobile.Frontside)}
          >
            { t('configurator_3d_front_side') }
          </button>
          <button
            className={
              "relative font-semibold text-[14px] before:absolute before:w-full before:h-[2px] before:bg-[#141414] before:left-0 before:bottom-[-6px]  "
              + (selectTabMobile === SelectColorTabMobile.Backside ? 'text-[#141414] before:opacity-100' : 'text-[#C3C3C3] before:opacity-0')
            }
            onClick={() => onSelectTabMobile(SelectColorTabMobile.Backside)}
          >
            { t('configurator_3d_backside') }
          </button>
          {
            modelStyle === ModelStyle.ShirtNoSleeve
              ? <></>
              : <>
                <button
                  className={
                    "relative font-semibold text-[14px] before:absolute before:w-full before:h-[2px] before:bg-[#141414] before:left-0 before:bottom-[-6px] "
                    + (selectTabMobile === SelectColorTabMobile.Collar ? 'text-[#141414] before:opacity-100' : 'text-[#C3C3C3] before:opacity-0')
                  }
                  onClick={() => onSelectTabMobile(SelectColorTabMobile.Collar)}
                >
                  { t('configurator_3d_collar') }
                </button>
                <button
                  className={
                    "relative font-semibold text-[14px] before:absolute before:w-full before:h-[2px] before:bg-[#141414] before:left-0 before:bottom-[-6px] "
                    + (selectTabMobile === SelectColorTabMobile.Sleeve ? 'text-[#141414] before:opacity-100' : 'text-[#C3C3C3] before:opacity-0')
                  }
                  onClick={() => onSelectTabMobile(SelectColorTabMobile.Sleeve)}
                >
                  { t('configurator_3d_sleeve') }
                </button>
              </>
          }
        </div>
        <SimpleBar
          ref={scrollBarRef}
          style={{
            width: '100%',
            height: hasShowingMoreColorSection ? '262px' : '',
          }}
          autoHide={false}
          className="color-picker-simplebar"
          onScroll={onScrollContainerUpdate}
        >
          {
            selectTabMobile === SelectColorTabMobile.Frontside
              ? <div className="flex flex-col min-h-full mb-[10px]">
                <div className="flex justify-between">
                  <p className="text-[14px] pt-3">{ t('configurator_3d_choose_colors') }</p>
                  <div className="colorWrapper colorWrapper--flex-responsive">
                    <MobileColorSelectMain designKey={JerseySection.Front} label={t('configurator_3d_front_side')} />
                    {
                      frontDesignAreaKeys.length
                        ? frontDesignAreaKeys.map((designKey: any, index: number) =>
                          <MobileColorSelectMain
                            key={'frontDesign' + index + designKey}
                            designKey={designKey}
                            label={t('configurator_3d_shirt_design_no') + ' ' + (index + 1)}
                          />
                        )
                        : <></>
                    }
                  </div>
                </div>
                <MobileColorOptionMore designKey={JerseySection.Front} />
                {
                  frontDesignAreaKeys.length
                    ? frontDesignAreaKeys.map((designKey: any, orderIndex: number) =>
                      <MobileColorOptionMore key={'designKeyslideContent__show' + designKey + orderIndex} designKey={designKey} />
                    )
                    : <></>
                }
              </div>
              : <></>
          }
          {
            selectTabMobile === SelectColorTabMobile.Backside
              ? <div className="flex flex-col min-h-full mb-[10px]">
                <div className="flex justify-between">
                  <p className="text-[14px] pt-3">{ t('configurator_3d_choose_colors') }</p>
                  <div className="colorWrapper colorWrapper--flex-responsive">
                    <MobileColorSelectMain designKey={JerseySection.Back} label={t('configurator_3d_backside')} />
                    {
                      backDesignAreaKeys.length
                        ? backDesignAreaKeys.map((designKey: any, index: number) =>
                          <MobileColorSelectMain
                            key={'backDesign' + index + designKey}
                            designKey={designKey}
                            label={t('configurator_3d_shirt_design_no') + ' ' + (index + 1)}
                          />
                        )
                        : <></>
                    }
                  </div>
                </div>
                <MobileColorOptionMore designKey={JerseySection.Back} />
                {
                  backDesignAreaKeys.length
                    ? backDesignAreaKeys.map((designKey: any, orderIndex: number) =>
                      <MobileColorOptionMore
                        key={'backDesigndesignKeyslideContent__show' + designKey + orderIndex}
                        designKey={JerseySection.Back}
                      />
                    )
                    : <></>
                }
              </div>
              : <></>
          }
          {
            selectTabMobile === SelectColorTabMobile.Collar
              && sectionColorOptions
              && sectionColorOptions[JerseySection.Collar]
              ? <div className="flex flex-col min-h-full mb-[10px]">
                <div className="flex justify-between">
                  <p className="text-[14px] pt-3">{ t('configurator_3d_choose_colors') }</p>
                  <div className="colorWrapper colorWrapper--flex-responsive">
                    <MobileColorSelectMain designKey={JerseySection.Collar} label={t('configurator_3d_collar')} />
                  </div>
                </div>
                <MobileColorOptionMore designKey={JerseySection.Collar} />
              </div>
              : <></>
          }
          {
            modelStyle === ModelStyle.ShirtNoSleeve
              ? selectTabMobile === SelectColorTabMobile.Sleeve
                && sectionColorOptions
                && sectionColorOptions[JerseySection.Left]
                && sectionColorOptions[JerseySection.Right]
                ? <div className="flex flex-col min-h-full mb-[10px]">
                  <div className="flex justify-between">
                    <p className="text-[14px] pt-3">{ t('configurator_3d_choose_colors') }</p>
                    <div className="colorWrapper colorWrapper--flex-responsive">
                      <MobileColorSelectMain designKey={JerseySection.Right} label={t('configurator_3d_sleeve_right')} />
                      <MobileColorSelectMain designKey={JerseySection.Left} label={t('configurator_3d_sleeve_left')} />
                    </div>
                  </div>
                  <MobileColorOptionMore designKey={JerseySection.Right} />
                  <MobileColorOptionMore designKey={JerseySection.Left} />
                </div>
                : <></>
              : selectTabMobile === SelectColorTabMobile.Sleeve
                ? <div className="flex flex-col min-h-full mb-[10px]">
                  <div className="flex justify-between">
                    <p className="text-[14px] pt-3">{ t('configurator_3d_choose_colors') }</p>
                    <div className="colorWrapper colorWrapper--flex-responsive">
                      <MobileColorSelectMain designKey={JerseySection.Right} label={t('configurator_3d_sleeve_right')} />
                      <MobileColorSelectMain designKey={JerseySection.Left} label={t('configurator_3d_sleeve_left')} />
                      {
                        sectionColorOptions && sectionColorOptions.stripe_left
                          ? <MobileColorSelectMain designKey={'stripe_left'} label={t('configurator_3d_stripe_left')} />
                          : <></>
                      }
                      {
                        sectionColorOptions && sectionColorOptions.stripe_right
                          ? <MobileColorSelectMain designKey={'stripe_right'} label={t('configurator_3d_stripe_right')} />
                          : <></>
                      }
                      {
                        sectionColorOptions && sectionColorOptions.cuff_left
                          ? <MobileColorSelectMain designKey={JerseySection.CuffLeft} label={t('configurator_3d_cuff_left')} />
                          : <></>
                      }
                      {
                        sectionColorOptions && sectionColorOptions.cuff_right
                          ? <MobileColorSelectMain designKey={JerseySection.CuffRight} label={t('configurator_3d_cuff_right')} />
                          : <></>
                      }
                      {
                        sectionColorOptions && sectionColorOptions.elbow_left
                          ? <MobileColorSelectMain designKey={'elbow_left'} label={t('configurator_3d_elbow_left')} />
                          : <></>
                      }
                      {
                        sectionColorOptions && sectionColorOptions.elbow_right
                          ? <MobileColorSelectMain designKey={'elbow_right'} label={t('configurator_3d_elbow_right')} />
                          : <></>
                      }
                    </div>
                  </div>
                  <MobileColorOptionMore designKey={JerseySection.Right} />
                  <MobileColorOptionMore designKey={JerseySection.Left} />
                  {
                    sectionColorOptions && sectionColorOptions.stripe_left
                      ? <MobileColorOptionMore designKey={'stripe_left'} />
                      : <></>
                  }
                  {
                    sectionColorOptions && sectionColorOptions.stripe_right
                      ? <MobileColorOptionMore designKey={'stripe_right'} />
                      : <></>
                  }
                  {
                    sectionColorOptions && sectionColorOptions.cuff_left
                      ? <MobileColorOptionMore designKey={JerseySection.CuffLeft} />
                      : <></>
                  }
                  {
                    sectionColorOptions && sectionColorOptions.cuff_right
                      ? <MobileColorOptionMore designKey={JerseySection.CuffRight} />
                      : <></>
                  }
                  {
                    sectionColorOptions && sectionColorOptions.elbow_left
                      ? <MobileColorOptionMore designKey={'elbow_left'} />
                      : <></>
                  }
                  {
                    sectionColorOptions && sectionColorOptions.elbow_right
                      ? <MobileColorOptionMore designKey={'elbow_right'} />
                      : <></>
                  }
                </div>
                : <></>
          }
        </SimpleBar>
        {
          hasMoreScrollContainer
            ? <div
              className={
                "absolute w-full h-[40px] bottom-[-1px] left-0 bg-gradient-to-b from-transparent to-white transition-all duration-200"
                + (hasMoreScrollContainer ? ' opacity-100' : ' opacity-0')
              }
            />
            : <></>
        }
      </div>
      : <>
        <div className="h1 text-center">{ t('configurator_3d_choose_colors') }</div>
        <div className="text-center marginBottom--small">{ t('configurator_3d_choose_colors_text') }</div>
        <div className="color-tab slideContent">
          <div className="py-4">
            <div className="slideContainer pb-4">
              <p className="slideContainer__headline marginBottom--xsmall pb-2 mb-5 border-b border-[#f0f0f0]">
                { t('configurator_3d_front_side') }
              </p>
              <ul className="!p-0 list-none">
                <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                  <p className="text-[14px] text-[#141414] mb-4">
                    <span className="font-semibold">
                    { t('configurator_3d_front_side') }
                    </span> / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Front]).description }
                  </p>
                  <DesktopColorPreviewSection designKey={JerseySection.Front} />
                  <DesktopColorMoreSection designKey={JerseySection.Front} />
                </li>

                {
                  frontDesignAreaKeys.length
                    ? frontDesignAreaKeys.map((designKey: any, index: number) =>
                      <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                        <p className="text-[14px] text-[#141414] mb-4">
                          <span className="font-semibold">
                          { t('configurator_3d_shirt_design_no') + ' ' + (index + 1) }
                          </span> / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).description }
                        </p>
                        <DesktopColorPreviewSection designKey={designKey} />
                        <DesktopColorMoreSection designKey={designKey} />
                      </li>
                    )
                    : <></>
                }
              </ul>
            </div>

            <div className="slideContainer pb-4 mt-10">
              <p className="slideContainer__headline marginBottom--xsmall pb-2 mb-5 border-b border-[#f0f0f0]">
                { t('configurator_3d_backside') }
              </p>
              <ul className="!p-0 list-none">
                <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                  <p className="text-[14px] text-[#141414] mb-4">
                    <span className="font-semibold">
                    { t('configurator_3d_backside') }
                    </span> / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Back]).description }
                  </p>
                  <DesktopColorPreviewSection designKey={JerseySection.Back} />
                  <DesktopColorMoreSection designKey={JerseySection.Back} />
                </li>

                {
                  backDesignAreaKeys.length
                    ? backDesignAreaKeys.map((designKey: any, index: number) =>
                      <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                        <p className="text-[14px] text-[#141414] mb-4">
                          <span className="font-semibold">
                          { t('configurator_3d_shirt_design_no') + ' ' + (index + 1) }
                          </span> / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).description }
                        </p>
                        <DesktopColorPreviewSection designKey={designKey} />
                        <DesktopColorMoreSection designKey={designKey} />
                      </li>
                    )
                    : <></>
                }
              </ul>
            </div>
            {
              modelStyle === ModelStyle.ShirtNoSleeve
                ? <>
                  {
                    sectionColorOptions && sectionColorOptions[JerseySection.Collar]
                      ? <div className="slideContainer pb-4 mt-10">
                        <p className="slideContainer__headline marginBottom--xsmall pb-2 mb-5 border-b border-[#f0f0f0]">
                          { t('configurator_3d_collar') }
                        </p>
                        <ul className="!p-0 list-none">
                          <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                            <p className="text-[14px] text-[#141414] mb-4">
                              <span className="font-semibold">
                              { t('configurator_3d_collar') }
                              </span> / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Collar]).description }
                            </p>
                            <DesktopColorPreviewSection designKey={JerseySection.Collar} />
                            <DesktopColorMoreSection designKey={JerseySection.Collar} />
                          </li>
                        </ul>
                      </div>
                      : <></>
                  }
                  {
                    sectionColorOptions && sectionColorOptions[JerseySection.Left] && sectionColorOptions[JerseySection.Right]
                      ? <div className="slideContainer pb-4 mt-10">
                        <p className="slideContainer__headline marginBottom--xsmall pb-2 mb-5 border-b border-[#f0f0f0]">
                          { t('configurator_3d_sleeve') }
                        </p>
                        <ul className="!p-0 list-none">
                          <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                            <p className="text-[14px] text-[#141414] mb-4">
                              <span className="font-semibold">
                              { t('configurator_3d_sleeve_left') }
                              </span> / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Left]).description }
                            </p>
                            <DesktopColorPreviewSection designKey={JerseySection.Left} />
                            <DesktopColorMoreSection designKey={JerseySection.Left} />
                          </li>
                          <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                            <p className="text-[14px] text-[#141414] mb-4">
                              <span className="font-semibold">
                              { t('configurator_3d_sleeve_right') }
                              </span> / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Right]).description }
                            </p>
                            <DesktopColorPreviewSection designKey={JerseySection.Right} />
                            <DesktopColorMoreSection designKey={JerseySection.Right} />
                          </li>
                        </ul>
                      </div>
                      : <></>
                  }
                </>
                : <>
                  <div className="slideContainer pb-4 mt-10">
                    <p className="slideContainer__headline marginBottom--xsmall pb-2 mb-5 border-b border-[#f0f0f0]">
                      { t('configurator_3d_collar') }
                    </p>
                    <ul className="!p-0 list-none">
                      <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                        <p className="text-[14px] text-[#141414] mb-4">
                          <span className="font-semibold">
                          { t('configurator_3d_collar') }
                          </span> / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Collar]).description }
                        </p>
                        <DesktopColorPreviewSection designKey={JerseySection.Collar} />
                        <DesktopColorMoreSection designKey={JerseySection.Collar} />
                      </li>
                    </ul>
                  </div>
                  <div className="slideContainer pb-4 mt-10">
                    <p className="slideContainer__headline marginBottom--xsmall pb-2 mb-5 border-b border-[#f0f0f0]">
                      { t('configurator_3d_sleeve') }
                    </p>
                    <ul className="!p-0 list-none">
                      <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                        <p className="text-[14px] text-[#141414] mb-4">
                          <span className="font-semibold">
                          { t('configurator_3d_sleeve_left') }
                          </span> / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Left]).description }
                        </p>
                        <DesktopColorPreviewSection designKey={JerseySection.Left} />
                        <DesktopColorMoreSection designKey={JerseySection.Left} />
                      </li>
                      <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                        <p className="text-[14px] text-[#141414] mb-4">
                          <span className="font-semibold">
                          { t('configurator_3d_sleeve_right') }
                          </span> / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Right]).description }
                        </p>
                        <DesktopColorPreviewSection designKey={JerseySection.Right} />
                        <DesktopColorMoreSection designKey={JerseySection.Right} />
                      </li>
                      {
                        sectionColorOptions && sectionColorOptions.stripe_left && sectionColorOptions.stripe_right
                        ? <>
                          <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                            <p className="text-[14px] text-[#141414] mb-4">
                              <span className="font-semibold">
                              { t('configurator_3d_stripe_left') }
                              </span> / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor['stripe_left']).description }
                            </p>
                            <DesktopColorPreviewSection designKey={'stripe_left'} />
                            <DesktopColorMoreSection designKey={'stripe_left'} />
                          </li>
                          <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                            <p className="text-[14px] text-[#141414] mb-4">
                              <span className="font-semibold">
                              { t('configurator_3d_stripe_right') }
                              </span> / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor['stripe_right']).description }
                            </p>
                            <DesktopColorPreviewSection designKey={'stripe_right'} />
                            <DesktopColorMoreSection designKey={'stripe_right'} />
                          </li>
                        </>
                        : <></>
                      }
                      {
                        sectionColorOptions && sectionColorOptions.cuff_left && sectionColorOptions.cuff_right
                          ? <>
                            <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                              <p className="text-[14px] text-[#141414] mb-4">
                                <span className="font-semibold">
                                { t('configurator_3d_cuff_left') }
                                </span> / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.CuffLeft]).description }
                              </p>
                              <DesktopColorPreviewSection designKey={JerseySection.CuffLeft} />
                              <DesktopColorMoreSection designKey={JerseySection.CuffLeft} />
                            </li>
                            <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                              <p className="text-[14px] text-[#141414] mb-4">
                                <span className="font-semibold">
                                { t('configurator_3d_cuff_right') }
                                </span> / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.CuffRight]).description }
                              </p>
                              <DesktopColorPreviewSection designKey={JerseySection.CuffRight} />
                              <DesktopColorMoreSection designKey={JerseySection.CuffRight} />
                            </li>
                          </>
                          : <></>
                      }
                      {
                        sectionColorOptions && sectionColorOptions.elbow_left && sectionColorOptions.elbow_right
                          ? <>
                            <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                              <p className="text-[14px] text-[#141414] mb-4">
                                <span className="font-semibold">
                                { t('configurator_3d_elbow_left') }
                                </span> / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elbow_left']).description }
                              </p>
                              <DesktopColorPreviewSection designKey={'elbow_left'} />
                              <DesktopColorMoreSection designKey={'elbow_left'} />
                            </li>
                            <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                              <p className="text-[14px] text-[#141414] mb-4">
                                <span className="font-semibold">
                                { t('configurator_3d_elbow_right') }
                                </span> / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elbow_right']).description }
                              </p>
                              <DesktopColorPreviewSection designKey={'elbow_right'} />
                              <DesktopColorMoreSection designKey={'elbow_right'} />
                            </li>
                          </>
                          : <></>
                      }

                    </ul>
                  </div>
                </>
            }
          </div>
        </div>

        <div className="flex justify-between mt-20">
          <button
            className="px-[27px] py-[12px] bg-[#FAFAFA] text-[#5B5B5B]"
            onClick={setSelectTabBack}
          >
            <div className="flex items-center">
              <FontAwesomeIcon
                className="h-[14px] mr-2"
                icon={faAngleLeft}
              />
              <span className='relative top-[-1px]'>{ t('configurator_3d_back') }</span>
            </div>
          </button>
          <button
            className="px-[27px] py-[12px] bg-[#008AC9] text-[#FFFFFF]"
            onClick={setSelectTabNext}
          >
            <div className="flex items-center">
              <span className='relative top-[-1px]'>{ t('configurator_3d_basket_next') }</span>
              <FontAwesomeIcon
                className="h-[14px] ml-2"
                icon={faAngleRight}
              />
            </div>
          </button>
        </div>
      </>
    : isMobile
      ? <div
        className="relative w-full px-6 pt-10 pb-6"
        style={{
          height: hasShowingMoreColorSection ? '325px' : '150px'
        }}
      >
        <button
          className="absolute top-[18px] right-[12px] z-10"
          onClick={handleOnClickCloseMobile}
        >
          <IconCross className="h-[20px]" />
        </button>
        <div className="relative w-full pb-3 flex items-center gap-5 items-center mb-4 before:absolute before:w-full before:h-[2px] before:bottom-[1px] before:left-0 before:bg-[#F0F0F0]">
          <button
            className={
              "relative font-semibold text-[14px] before:absolute before:w-full before:h-[2px] before:bg-[#141414] before:left-0 before:bottom-[-6px] "
              + (selectTabMobile === SelectColorTabMobile.Short ? 'text-[#141414] before:opacity-100' : 'text-[#C3C3C3] before:opacity-0')
            }
            onClick={() => onSelectTabMobile(SelectColorTabMobile.Short)}
          >
            { t('configurator_3d_short') }
          </button>
          <button
            className={
              "relative font-semibold text-[14px] before:absolute before:w-full before:h-[2px] before:bg-[#141414] before:left-0 before:bottom-[-6px] "
              + (selectTabMobile === SelectColorTabMobile.ShortElastic ? 'text-[#141414] before:opacity-100' : 'text-[#C3C3C3] before:opacity-0')
            }
            onClick={() => onSelectTabMobile(SelectColorTabMobile.ShortElastic)}
          >
            { t('configurator_3d_waistband') }
          </button>
          {
            selectedJerseySectionColor && selectedJerseySectionColor['stripe_left']
              ? <button
                className={
                  "relative font-semibold text-[14px] before:absolute before:w-full before:h-[2px] before:bg-[#141414] before:left-0 before:bottom-[-6px] "
                  + (selectTabMobile === SelectColorTabMobile.ShortStripe ? 'text-[#141414] before:opacity-100' : 'text-[#C3C3C3] before:opacity-0')
                }
                onClick={() => onSelectTabMobile(SelectColorTabMobile.ShortStripe)}
              >
                { t('configurator_3d_short_stripe') }
              </button>
              : <></>
          }
        </div>
        <SimpleBar
          ref={scrollBarRef}
          style={{
            width: '100%',
            height: hasShowingMoreColorSection ? '262px' : '130px'
          }}
          autoHide={false}
          onScroll={onScrollContainerUpdate}
        >
          {
            selectTabMobile === SelectColorTabMobile.Short
              ? <div className="flex flex-col min-h-full mb-[10px]">
                <div className="flex justify-between">
                  <p className="text-[14px] pt-3">{ t('configurator_3d_choose_colors') }</p>
                  <div className="colorWrapper colorWrapper--flex-responsive">
                    <MobileColorSelectMain designKey={JerseySection.Right} label={t('configurator_3d_sleeve_right')} />
                    {
                      rightDesignAreaKeys.length
                        ? rightDesignAreaKeys.map((designKey: any, index: number) =>
                          <MobileColorSelectMain
                            key={'ShortRightDesignAreaKeys' + designKey + index}
                            designKey={designKey}
                            label={t('configurator_3d_sleeve_right') + ': ' + t('configurator_3d_shirt_design_no') + ' ' + (index + 1)}
                          />
                        )
                        : <></>
                    }
                    <MobileColorSelectMain designKey={JerseySection.Left} label={t('configurator_3d_sleeve_left')} />
                    {
                      leftDesignAreaKeys.length
                        ? leftDesignAreaKeys.map((designKey: any, index: number) =>
                          <MobileColorSelectMain
                            key={'ShortLeftDesignAreaKeys' + designKey + index}
                            designKey={designKey}
                            label={t('configurator_3d_sleeve_left') + ': ' + t('configurator_3d_shirt_design_no') + ' ' + (index + 1)}
                          />
                        )
                        : <></>
                    }
                  </div>
                </div>
                <MobileColorOptionMore designKey={JerseySection.Right} />
                <MobileColorOptionMore designKey={JerseySection.Left} />
                {
                  rightDesignAreaKeys.length
                    ? rightDesignAreaKeys.map((designKey: any, index: number) =>
                      <MobileColorOptionMore key={'ShortRightDesignAreaKeysMore' + designKey + index} designKey={designKey} />
                    )
                    : <></>
                }
                {
                  leftDesignAreaKeys.length
                    ? leftDesignAreaKeys.map((designKey: any, index: number) =>
                      <MobileColorOptionMore key={'ShortLeftDesignAreaKeysMore' + designKey + index} designKey={designKey} />
                    )
                    : <></>
                }
              </div>
              : <></>
          }
          {
            hasShortElastic && selectTabMobile === SelectColorTabMobile.ShortElastic
              ? <div className="flex flex-col min-h-full mb-[10px]">
                <div className="flex justify-between">
                  <p className="text-[14px] pt-3">{ t('configurator_3d_choose_colors') }</p>
                  <div className="colorWrapper colorWrapper--flex-responsive">
                    {
                     isShortElasticLeftRight
                        ? <></>
                        : <MobileColorSelectMain designKey={'elastic_main'} label={t('configurator_3d_waistband')} />
                    }
                  </div>
                </div>
                {
                  isShortElasticLeftRight
                    ? <></>
                    : <MobileColorOptionMore designKey={'elastic_main'} />
                }
              </div>
              : <></>
          }
          {
            selectTabMobile === SelectColorTabMobile.ShortStripe && selectedJerseySectionColor['stripe_left']
              ? <div className="flex flex-col min-h-full mb-[10px]">
                <div className="flex justify-between">
                  <p className="text-[14px] pt-3">{ t('configurator_3d_choose_colors') }</p>
                  <div className="colorWrapper colorWrapper--flex-responsive">
                    <MobileColorSelectMain designKey={'stripe_left'} label={t('configurator_3d_short_stripe')} />
                  </div>
                </div>
                <MobileColorOptionMore designKey={'stripe_left'} />
              </div>
              : <></>
          }
        </SimpleBar>
        {
          hasMoreScrollContainer
            ? <div
              className={
                "absolute w-full h-[40px] bottom-[-1px] left-0 bg-gradient-to-b from-transparent to-white transition-all duration-200"
                + (hasMoreScrollContainer ? ' opacity-100' : ' opacity-0')
              }
            />
            : <></>
        }
      </div>
      : <>
        <div className="h1 text-center">{ t('configurator_3d_choose_colors') }</div>
        <div className="text-center marginBottom--small">{ t('configurator_3d_choose_colors_text') }</div>
        <div className="color-tab slideContent">
          <div className="py-4">
            {
              leftDesignAreaKeys.length && rightDesignAreaKeys.length
                ? <>
                  <div className="slideContainer pb-4">
                    <p className="slideContainer__headline marginBottom--xsmall pb-2 mb-5 border-b border-[#f0f0f0]">
                      { t('configurator_3d_sleeve_left') }
                    </p>
                    <ul className="!p-0 list-none">
                      <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                        <p className="text-[14px] text-[#141414] mb-4">
                          <span className="font-semibold">
                          { t('configurator_3d_sleeve_left') }
                          </span> / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Left]).description }
                        </p>
                        <DesktopColorPreviewSection designKey={JerseySection.Left} />
                        <DesktopColorMoreSection designKey={JerseySection.Left} />
                      </li>
                      {
                        leftDesignAreaKeys.length
                          ? leftDesignAreaKeys.map((designKey: any, index: number) =>
                            <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                              <p className="text-[14px] text-[#141414] mb-4">
                                <span className="font-semibold">
                                { t('configurator_3d_shirt_design_no') + ' ' + (index + 1) }
                                </span> / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).description }
                              </p>
                              <DesktopColorPreviewSection designKey={designKey} />
                              <DesktopColorMoreSection designKey={designKey} />
                            </li>
                          )
                          : <></>
                      }
                    </ul>
                  </div>
                  <div className="slideContainer pb-4">
                    <p className="slideContainer__headline marginBottom--xsmall pb-2 mb-5 border-b border-[#f0f0f0]">
                      { t('configurator_3d_sleeve_right') }
                    </p>
                    <ul className="!p-0 list-none">
                      <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                        <p className="text-[14px] text-[#141414] mb-4">
                          <span className="font-semibold">
                          { t('configurator_3d_sleeve_right') }
                          </span> / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Right]).description }
                        </p>
                        <DesktopColorPreviewSection designKey={JerseySection.Right} />
                        <DesktopColorMoreSection designKey={JerseySection.Right} />
                      </li>
                      {
                        rightDesignAreaKeys.length
                          ? rightDesignAreaKeys.map((designKey: any, index: number) =>
                            <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                              <p className="text-[14px] text-[#141414] mb-4">
                                <span className="font-semibold">
                                { t('configurator_3d_shirt_design_no') + ' ' + (index + 1) }
                                </span> / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).description }
                              </p>
                              <DesktopColorPreviewSection designKey={designKey} />
                              <DesktopColorMoreSection designKey={designKey} />
                            </li>
                          )
                          : <></>
                      }
                    </ul>
                  </div>
                </>
                : <div className="slideContainer pb-4">
                  <p className="slideContainer__headline marginBottom--xsmall pb-2 mb-5 border-b border-[#f0f0f0]">
                    { t('configurator_3d_short') }
                  </p>
                  <ul className="!p-0 list-none">
                    <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                      <p className="text-[14px] text-[#141414] mb-4">
                        <span className="font-semibold">
                        { t('configurator_3d_sleeve_left') }
                        </span> / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Left]).description }
                      </p>
                      <DesktopColorPreviewSection designKey={JerseySection.Left} />
                      <DesktopColorMoreSection designKey={JerseySection.Left} />
                    </li>
                    <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                      <p className="text-[14px] text-[#141414] mb-4">
                        <span className="font-semibold">
                        { t('configurator_3d_sleeve_right') }
                        </span> / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Right]).description }
                      </p>
                      <DesktopColorPreviewSection designKey={JerseySection.Right} />
                      <DesktopColorMoreSection designKey={JerseySection.Right} />
                    </li>
                    {
                      frontDesignAreaKeys.length
                        ? frontDesignAreaKeys.map((designKey: any, index: number) =>
                          <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                            <p className="text-[14px] text-[#141414] mb-4">
                              <span className="font-semibold">
                              { t('configurator_3d_shirt_design_no') + ' ' + (index + 1) }
                              </span> / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).description }
                            </p>
                            <DesktopColorPreviewSection designKey={designKey} />
                            <DesktopColorMoreSection designKey={designKey} />
                          </li>
                        )
                        : <></>
                    }
                  </ul>
                </div>
            }
            {
              hasShortElastic && !isShortElasticLeftRight
                ? <div className="slideContainer pb-4 mt-10">
                    <p className="slideContainer__headline marginBottom--xsmall pb-2 mb-5 border-b border-[#f0f0f0]">
                      { t('configurator_3d_waistband') }
                    </p>
                    <ul className="!p-0 list-none">
                      <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                        <p className="text-[14px] text-[#141414] mb-4">
                          <span className="font-semibold">
                          { t('configurator_3d_waistband') }
                          </span> / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elastic_main']).description }
                        </p>
                        <DesktopColorPreviewSection designKey={'elastic_main'} />
                        <DesktopColorMoreSection designKey={'elastic_main'} />
                      </li>
                    </ul>
                  </div>
                : <></>
            }
            {
              sectionColorOptions && sectionColorOptions.stripe_left
                ? <div className="slideContainer pb-4 mt-10">
                  <p className="slideContainer__headline marginBottom--xsmall pb-2 mb-5 border-b border-[#f0f0f0]">
                    { t('configurator_3d_short_stripe') }
                  </p>
                  <ul className="!p-0 list-none">
                    <li className="pb-4 mb-4 last:mb-0 border-b-2 border-[#f0f0f0]">
                      <p className="text-[14px] text-[#141414] mb-4">
                        <span className="font-semibold">
                        { t('configurator_3d_short_stripe') }
                        </span> / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor['stripe_left']).description }
                      </p>
                      <DesktopColorPreviewSection designKey={'stripe_left'} />
                      <DesktopColorMoreSection designKey={'stripe_left'} />
                    </li>
                  </ul>
                </div>
                : <></>
            }
            {/* {
              orderedDesignAreaKeys.length
                ? <div className="slideContainer">
                  <p className="slideContainer__headline marginBottom--xsmall">
                    {
                      orderedDesignAreaKeys.length
                        ? orderedDesignAreaKeys.map((designKey: string) => '/ ' + getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).description)
                        : ''
                    }
                  </p>
                  <div className="colorWrapper colorWrapper--7cols">
                    {
                      orderedDesignAreaKeys.length
                        ? orderedDesignAreaKeys.map((designKey: any, index: number) =>
                          <div
                            key={'frontDesign' + index + designKey}
                            className={
                              "relative itemColor !pb-8 !h-auto "
                              + (showMoreColorSection[designKey] ? 'active' : '')
                            }
                            onClick={() => handleOnSelectOption(designKey)}
                          >
                            <div
                              className={
                                "itemColor--bgColor tooltip"
                                + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).code === '01_SU_WEISS' ? ' border' : '')
                              }
                              style={{
                                backgroundColor: selectedJerseySectionColor[designKey]
                              }}
                              data-tooltip={getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).description}
                            >
                              <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).description }</label>
                            </div>
                            <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_shirt_design_no') + ' ' + (index + 1) }</p>
                          </div>
                        )
                        : <></>
                    }
                  </div>

                  {
                    orderedDesignAreaKeys.length
                      ? orderedDesignAreaKeys.map((designKey: any, orderIndex: number) =>
                          <div
                            key={'designKeyslideContent__show' + designKey + orderIndex}
                            className={'colorWrapper colorWrapper--7cols mt-4' + (showMoreColorSection[designKey] ? ' grid' : ' hidden')}
                          >
                            {
                              uniqueColorList.map((item, index) =>
                                <div
                                  key={designKey + index + '#' + item.colorHexCode + item.description}
                                  className={
                                    "itemColor "
                                    + ('#' + item.colorHexCode === selectedJerseySectionColor[designKey] ? 'active' : '')
                                  }
                                  onClick={() => onSelectColor(designKey, '#' + item.colorHexCode)}
                                >
                                  <div
                                    className={
                                      "itemColor--bgColor tooltip"
                                      + (item.code === '01_SU_WEISS' ? ' border' : '')
                                    }
                                    style={{
                                      backgroundColor: '#' + item.colorHexCode
                                    }}
                                    data-tooltip={item.description}
                                  >
                                    <label className="tooltipBubble">{ item.description }</label>
                                  </div>
                                </div>
                              )
                            }
                          </div>
                        )
                      : <></>
                  }
                </div>
                : <></>
            } */}
          </div>
        </div>

        <div className="flex justify-between mt-20">
          <button
            className="px-[27px] py-[12px] bg-[#FAFAFA] text-[#5B5B5B]"
            onClick={setSelectTabBack}
          >
            <div className="flex items-center">
              <FontAwesomeIcon
                className="h-[14px] mr-2"
                icon={faAngleLeft}
              />
              <span className='relative top-[-1px]'>{ t('configurator_3d_back') }</span>
            </div>
          </button>
          <button
            className="px-[27px] py-[12px] bg-[#008AC9] text-[#FFFFFF]"
            onClick={setSelectTabNext}
          >
            <div className="flex items-center">
              <span className='relative top-[-1px]'>{ t('configurator_3d_basket_next') }</span>
              <FontAwesomeIcon
                className="h-[14px] ml-2"
                icon={faAngleRight}
              />
            </div>
          </button>
        </div>
      </>;
}
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import type { CuttingOptionData, CuttingTabInterface} from './types';
import { CutMaterial} from './types';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {
  faAngleRight,
  faAngleLeft,
  faCheckCircle,
  faPenCircle
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SimpleBar from 'simplebar-react';

import IconCross from './icons/IconCross';

export default function JakoCuttingTab({
  isEdited,
  cuttingOptions,
  cuttingFormLinksResponse,
  cuttingFormCode,
  onSelectCut,
  onSelectMaterial,
  setSelectTabBack,
  setSelectTabNext,
  isMobile,
  onCloseMobile,
}: CuttingTabInterface) {
  const { t } = useTranslation('translations');

  const [material, setMaterial] = useState<CutMaterial>(CutMaterial.Be);
  const [selectedCutting, setSelectedCutting] = useState<{
    [key: string]: {
      code: string,
      codeDescription: string,
      codeSorting: number,
      cuttingFormCode: string,
      sorting: string,
      value: string,
      valueDescription: string,
    }}>({});
  const [showMoreCuttingOption, setShowMoreCuttingOption] = useState<any>({});
  const [showMoreCuttingOptionMobile, setShowMoreCuttingOptionMobile] = useState<any>(false);
  const [showingCuttingOptionMobile, setShowingCuttingOptionMobile] = useState<CuttingOptionData | null>(null);
  const [hasMoreScrollContainer, setHasMoreScrollContainer] = useState(false);
  const scrollBarRef = useRef(null as any);

  const customCuttingOptions = useMemo(() => {
    const filteredOptions = cuttingOptions.slice().filter((item) => !item.code.includes('SPORTART') && !item.code.includes('MATERIAL')).sort((a, b) => parseInt(a.sorting) - parseInt(b.sorting));
    const sortedOptions = filteredOptions.sort((a, b) => a.codeSorting - b.codeSorting);
    return sortedOptions;
  }, [cuttingOptions]);

  const materialCuttingOption = useMemo(() => {
    if (!cuttingOptions.length) {
      return cuttingFormLinksResponse.find((item) => item.code === '3D_MATERIAL');
    }
    if (!cuttingOptions[0].mappedItems[0]) {
      return cuttingFormLinksResponse.find((item) => item.code === '3D_MATERIAL');
    }
    return cuttingFormLinksResponse.find((item) => item.code === '3D_MATERIAL' && item.cuttingFormCode.slice(5, 7) === cuttingOptions[0].mappedItems[0].cuttingFormCode.slice(5 , 7));
  }, [cuttingFormLinksResponse, cuttingOptions]);

  const materialCuttingOptionBE = useMemo(() => {
    if (!cuttingOptions.length) {
      return cuttingFormLinksResponse.find((item) => item.code === '3D_MATERIAL');
    }
    if (!cuttingOptions[0].mappedItems[0]) {
      return cuttingFormLinksResponse.find((item) => item.code === '3D_MATERIAL');
    }
    const selectedSport = cuttingOptions.find((item) => item.code.includes('SPORTART'));
    if (selectedSport) {
      const onlySelectedSports = cuttingFormLinksResponse.slice().filter((item) => item.code.includes('SPORTART') && item.value === selectedSport.value);
      const isSelectedSportHasBeMaterial = onlySelectedSports.find((item) => item.cuttingFormCode.slice(5).includes('BE'));
      if (isSelectedSportHasBeMaterial) {
        return cuttingFormLinksResponse.find((item) => item.code === '3D_MATERIAL' && item.cuttingFormCode.slice(5).includes('BE'));
      } else {
        return undefined;
      }
    } else {
      return cuttingFormLinksResponse.find((item) => item.cuttingFormCode.slice(5).includes('BE'));
    }
  }, [cuttingFormLinksResponse, cuttingOptions]);

  const materialCuttingOptionINT = useMemo(() => {
    if (!cuttingOptions.length) {
      return cuttingFormLinksResponse.find((item) => item.code === '3D_MATERIAL');
    }
    if (!cuttingOptions[0].mappedItems[0]) {
      return cuttingFormLinksResponse.find((item) => item.code === '3D_MATERIAL');
    }
    const selectedSport = cuttingOptions.find((item) => item.code.includes('SPORTART'));
    if (selectedSport) {
      const onlySelectedSports = cuttingFormLinksResponse.slice().filter((item) => item.code.includes('SPORTART') && item.value === selectedSport.value);
      const isSelectedSportHasBeMaterial = onlySelectedSports.find((item) => item.cuttingFormCode.slice(5).includes('INT'));
      if (isSelectedSportHasBeMaterial) {
        return cuttingFormLinksResponse.find((item) => item.code === '3D_MATERIAL' && item.cuttingFormCode.slice(5).includes('INT'));
      } else {
        return undefined;
      }
    } else {
      return cuttingFormLinksResponse.find((item) => item.code === '3D_MATERIAL' && item.cuttingFormCode.slice(5).includes('INT'));
    }
  }, [cuttingFormLinksResponse, cuttingOptions]);

  const materialCuttingOptionRUJ = useMemo(() => {
    if (!cuttingOptions.length) {
      return cuttingFormLinksResponse.find((item) => item.code === '3D_MATERIAL');
    }
    if (!cuttingOptions[0].mappedItems[0]) {
      return cuttingFormLinksResponse.find((item) => item.code === '3D_MATERIAL');
    }
    const selectedSport = cuttingOptions.find((item) => item.code.includes('SPORTART'));
    if (selectedSport) {
      const onlySelectedSports = cuttingFormLinksResponse.slice().filter((item) => item.code.includes('SPORTART') && item.value === selectedSport.value);
      const isSelectedSportHasBeMaterial = onlySelectedSports.find((item) => item.cuttingFormCode.slice(5).includes('RUJ'));
      if (isSelectedSportHasBeMaterial) {
        return cuttingFormLinksResponse.find((item) => item.code === '3D_MATERIAL' && item.cuttingFormCode.slice(5).includes('RUJ'));
      } else {
        return undefined;
      }
    } else {
      return cuttingFormLinksResponse.find((item) => item.code === '3D_MATERIAL' && item.cuttingFormCode.slice(5).includes('RUJ'));
    }
  }, [cuttingFormLinksResponse, cuttingOptions]);

  const materialCuttingOptionRUS = useMemo(() => {
    if (!cuttingOptions.length) {
      return cuttingFormLinksResponse.find((item) => item.code === '3D_MATERIAL');
    }
    if (!cuttingOptions[0].mappedItems[0]) {
      return cuttingFormLinksResponse.find((item) => item.code === '3D_MATERIAL');
    }
    const selectedSport = cuttingOptions.find((item) => item.code.includes('SPORTART'));
    if (selectedSport) {
      const onlySelectedSports = cuttingFormLinksResponse.slice().filter((item) => item.code.includes('SPORTART') && item.value === selectedSport.value);
      const isSelectedSportHasBeMaterial = onlySelectedSports.find((item) => item.cuttingFormCode.slice(5).includes('RUS'));
      if (isSelectedSportHasBeMaterial) {
        return cuttingFormLinksResponse.find((item) => item.code === '3D_MATERIAL' && item.cuttingFormCode.slice(5).includes('RUS'));
      } else {
        return undefined;
      }
    } else {
      return cuttingFormLinksResponse.find((item) => item.code === '3D_MATERIAL' && item.cuttingFormCode.slice(5).includes('RUS'));
    }
  }, [cuttingFormLinksResponse, cuttingOptions]);

  useEffect(() => {
    if (!scrollBarRef.current) {
      return;
    }

    onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
    scrollBarRef.current.getScrollElement()
      .addEventListener("scroll", function(e: any) { onScrollContainerUpdate(e.target); });
  }, []);

  useEffect(() => {
    let results: {
      [key: string]: {
        code: string,
        codeDescription: string,
        codeSorting: number,
        cuttingFormCode: string,
        sorting: string,
        value: string,
        valueDescription: string,
      }
    } = {};
    cuttingOptions.forEach((item) => {
      results = {
        ...results,
        [item.code]: {
          code: item.code,
          codeDescription: item.codeDescription,
          codeSorting: item.codeSorting,
          cuttingFormCode: item.cuttingFormCode,
          sorting: item.sorting,
          value: item.value,
          valueDescription: item.valueDescription,
        }
      };
    });
    setSelectedCutting(results);

    let showMoreResults = {};
    cuttingOptions.forEach((item) => {
      showMoreResults = {
        ...showMoreResults,
        [item.code]: false,
      };
    });
    setShowMoreCuttingOption(showMoreResults);
  }, [cuttingOptions]);

  useEffect(() => {
    if (materialCuttingOptionRUJ) {
      setMaterial(CutMaterial.Ruj);
    } else if (materialCuttingOptionRUS) {
      setMaterial(CutMaterial.Rus);
    } else {
      if (materialCuttingOptionINT && materialCuttingOptionBE) {
        if (cuttingFormCode.includes('INT')) {
          setMaterial(CutMaterial.Int);
        } else {
          setMaterial(CutMaterial.Be);
        }
      } else if (materialCuttingOptionINT && !materialCuttingOptionBE) {
        setMaterial(CutMaterial.Int);
      } else if (!materialCuttingOptionINT && materialCuttingOptionBE) {
        setMaterial(CutMaterial.Be);
      }
    }
  }, [cuttingFormCode, materialCuttingOptionINT, materialCuttingOptionBE, materialCuttingOptionRUJ, materialCuttingOptionRUS]);

  function handleOnMaterialChange(material: CutMaterial) {
    setMaterial(material);
    onSelectMaterial(material);
    handleOnClickCloseMobile();
  }

  function handleOnCuttingChange(
    code: string,
    optionItem: {
      code: string,
      codeDescription: string,
      codeSorting: number,
      cuttingFormCode: string,
      sorting: string,
      value: string,
      valueDescription: string,
    }
  ) {

    const tmpSelectedCutting: {
      [key: string]: {
        code: string,
        codeDescription: string,
        codeSorting: number,
        cuttingFormCode: string,
        sorting: string,
        value: string,
        valueDescription: string,
      }
    } = {
      ...selectedCutting,
      [code]: {
        code: optionItem.code,
        codeDescription: optionItem.codeDescription,
        codeSorting: optionItem.codeSorting,
        cuttingFormCode: optionItem.cuttingFormCode,
        sorting: optionItem.sorting,
        value: optionItem.value,
        valueDescription: optionItem.valueDescription,
      }
    };

    const eachCuttingPossibleItems = cuttingOptions.slice().map((item) => {
      const foundedValue = item.allPossibleItems.find((mapItem) => mapItem.value === tmpSelectedCutting[item.code].value);
      return {
        code: item.code,
        possibleItems: foundedValue && foundedValue.items ? foundedValue.items : []
      };
    });

    const foundedPossibleCuttingFormCodeOfSelectOption = eachCuttingPossibleItems.find((item) => item.code === code);
    if (!foundedPossibleCuttingFormCodeOfSelectOption) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div id="jako-configurator-3d-wc" className="jako w-full w-[200px] mx-auto p-0 bg-white md:w-full md:w-[400px]">
              <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                { t('configurator_3d_cant_find_cutting') }
              </h5>
              <button className="button_primary !bg-theme-primary" onClick={onClose}>
                { t('configurator_3d_ok') }
              </button>
            </div>
          );
        }
      });
      return;
    }

    if (!foundedPossibleCuttingFormCodeOfSelectOption.possibleItems) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div id="jako-configurator-3d-wc" className="jako w-full w-[200px] mx-auto p-0 bg-white md:w-full md:w-[400px]">
              <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                { t('configurator_3d_cant_find_cutting') }
              </h5>
              <button className="button_primary !bg-theme-primary" onClick={onClose}>
                { t('configurator_3d_ok') }
              </button>
            </div>
          );
        }
      });
      return;
    }

    const otherPossibleOptions = eachCuttingPossibleItems.filter((item) => item.code !== foundedPossibleCuttingFormCodeOfSelectOption.code);
    const selectedMaterialFoundedPossibleCuttingFormCodeOfSelectOption = foundedPossibleCuttingFormCodeOfSelectOption.possibleItems.slice()
      .filter((item) => item.cuttingFormCode.slice(0, 8).includes(material));
    const foundedCuttingItem = selectedMaterialFoundedPossibleCuttingFormCodeOfSelectOption.find((item) =>
      otherPossibleOptions.every((otherItem) =>
        otherItem.possibleItems.some((otherItemPossibleItem) => otherItemPossibleItem.cuttingFormCode === item.cuttingFormCode)
      )
    );

    if (!foundedCuttingItem) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div id="jako-configurator-3d-wc" className="jako w-full w-[200px] mx-auto p-0 bg-white md:w-full md:w-[400px]">
              <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                { t('configurator_3d_cant_find_cutting') }
              </h5>
              <button className="button_primary !bg-theme-primary" onClick={onClose}>
                { t('configurator_3d_ok') }
              </button>
            </div>
          );
        }
      });
      return;
    }
    if (isEdited) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div id="jako-configurator-3d-wc" className="jako w-full w-[200px] mx-auto p-0 bg-white md:w-full md:w-[400px]">
               <label htmlFor="saveEnterName" className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                { t('configurator_3d_confirm_change_cutting_title') }
              </label>
              <div className="flex flex-col lg:flex-row justify-between gap-4">
                <button className="outlineButton" type="button" onClick={onClose}>
                  { t('configurator_3d_cancel') }
                </button>
                <button className="button_primary !bg-theme-primary" onClick={() => {
                  setSelectedCutting(tmpSelectedCutting);
                  onSelectCut(foundedCuttingItem.cuttingFormCode, material);
                  handleOnClickCloseMobile();
                  onClose();
                }}>
                  { t('configurator_3d_ok') }
                </button>
              </div>
            </div>
          );
        }
      });
    } else {
      setSelectedCutting(tmpSelectedCutting);
      onSelectCut(foundedCuttingItem.cuttingFormCode, material);
      handleOnClickCloseMobile();
    }
  }

  function onSetShowMoreCuttingOptionMobile(code: string) {
    setShowMoreCuttingOptionMobile(!showMoreCuttingOptionMobile);
    if (code === '3D_MATERIAL' && materialCuttingOption) {
      const data = materialCuttingOption as any;
      setShowingCuttingOptionMobile(data);
    } else {
      const foundedOption = cuttingOptions.find((item) => item.code === code);
      if (foundedOption) {
        setShowingCuttingOptionMobile(foundedOption);
      }
    }
  }

  function onScrollContainerUpdate(el: any) {
    if (el.clientHeight + el.scrollTop >= el.scrollHeight) {
      setHasMoreScrollContainer(false);
    } else {
      setHasMoreScrollContainer(true);
    }
  }

  function handleOnClickCloseMobile() {
    if (scrollBarRef.current) {
      onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
    }
    setShowMoreCuttingOptionMobile(false);
    setShowingCuttingOptionMobile(null);
    if (!showMoreCuttingOptionMobile) {
      onCloseMobile();
    }
  }

  return isMobile
    ? <div className="relative w-full h-[260px] px-6 pt-14 pb-6">
      <button
        className="absolute top-[7px] right-[12px]"
        onClick={handleOnClickCloseMobile}
      >
        <IconCross className="h-[26px]" />
      </button>
      <SimpleBar
        ref={scrollBarRef}
        style={{
          width: '100%',
          height: 200
        }}
        autoHide={false}
        onScroll={onScrollContainerUpdate}
      >
        <div className="grid grid-cols-2 gap-x-5 gap-y-4">
          {
            showingCuttingOptionMobile
              ? showingCuttingOptionMobile.code === '3D_MATERIAL'
                ? <>
                  {
                    materialCuttingOptionINT
                      ? <button
                        key='showingCuttingOptionMobileMaterialINT'
                        className="flex flex-col items-center"
                        onClick={() => handleOnMaterialChange(CutMaterial.Int)}
                      >
                        <p className={
                          "text-[14px] font-semibold"
                          + (CutMaterial.Int === material ? ' text-[#008AC9]' : ' text-[#141414]')
                        }>
                          { t('configurator_3d_material_int') }
                        </p>
                        <div
                          className={
                            "relative border-2 border-[#D4D4D4] mt-4 overflow-hidden"
                              + (CutMaterial.Int === material
                                ? ' !border-b-[3px] !border-b-[#008AC9]'
                                : ''
                              )
                          }
                        >
                          {
                            CutMaterial.Int === material
                              ? <span className="absolute top-1 right-1 text-[#008AC9]">
                                <FontAwesomeIcon icon={faCheckCircle} />
                              </span>
                              : <></>
                          }
                          {
                            materialCuttingOptionINT.valueIconUrl
                              ? <img
                                src={materialCuttingOptionINT.valueIconUrl}
                                alt={t('configurator_3d_material_int') || ''}
                                className="w-[125px] h-[100px] object-cover"
                              />
                              : <></>
                          }
                        </div>
                      </button>
                      : <></>
                  }
                  {
                    materialCuttingOptionBE
                      ? <button
                        key='showingCuttingOptionMobileMaterialBE'
                        className="flex flex-col items-center"
                        onClick={() => handleOnMaterialChange(CutMaterial.Be)}
                      >
                        <p className={
                          "text-[14px] font-semibold"
                          + (CutMaterial.Be === material ? ' text-[#008AC9]' : ' text-[#141414]')
                        }>
                          { t('configurator_3d_material_be') }
                        </p>
                        <div
                          className={
                            "relative border-2 border-[#D4D4D4] mt-4 overflow-hidden"
                              + (CutMaterial.Be === material
                                ? ' !border-b-[3px] !border-b-[#008AC9]'
                                : ''
                              )
                          }
                        >
                          {
                            CutMaterial.Be === material
                              ? <span className="absolute top-1 right-1 text-[#008AC9]">
                                <FontAwesomeIcon icon={faCheckCircle} />
                              </span>
                              : <></>
                          }
                          {
                            materialCuttingOptionBE.valueIconUrl
                              ? <img
                                src={materialCuttingOptionBE.valueIconUrl}
                                alt={t('configurator_3d_material_be') || ''}
                                className="w-[125px] h-[100px] object-cover"
                              />
                              : <></>
                          }
                        </div>
                      </button>
                      : <></>
                  }
                  {
                    materialCuttingOptionRUJ
                      ? <button
                        key='showingCuttingOptionMobileMaterialRUJ'
                        className="flex flex-col items-center"
                        onClick={() => handleOnMaterialChange(CutMaterial.Ruj)}
                      >
                        <p className={
                          "text-[14px] font-semibold"
                          + (CutMaterial.Ruj === material ? ' text-[#008AC9]' : ' text-[#141414]')
                        }>
                          { t('configurator_3d_material_ruj') }
                        </p>
                        <div
                          className={
                            "relative border-2 border-[#D4D4D4] mt-4 overflow-hidden"
                              + (CutMaterial.Ruj === material
                                ? ' !border-b-[3px] !border-b-[#008AC9]'
                                : ''
                              )
                          }
                        >
                          {
                            CutMaterial.Ruj === material
                              ? <span className="absolute top-1 right-1 text-[#008AC9]">
                                <FontAwesomeIcon icon={faCheckCircle} />
                              </span>
                              : <></>
                          }
                          {
                            materialCuttingOptionRUJ.valueIconUrl
                              ? <img
                                src={materialCuttingOptionRUJ.valueIconUrl}
                                alt={t('configurator_3d_material_ruj') || ''}
                                className="w-[125px] h-[100px] object-cover"
                              />
                              : <></>
                          }
                        </div>
                      </button>
                      : <></>
                  }
                  {
                    materialCuttingOptionRUS
                      ? <button
                        key='showingCuttingOptionMobileMaterialRUS'
                        className="flex flex-col items-center"
                        onClick={() => handleOnMaterialChange(CutMaterial.Rus)}
                      >
                        <p className={
                          "text-[14px] font-semibold"
                          + (CutMaterial.Rus === material ? ' text-[#008AC9]' : ' text-[#141414]')
                        }>
                          { t('configurator_3d_material_rus') }
                        </p>
                        <div
                          className={
                            "relative border-2 border-[#D4D4D4] mt-4 overflow-hidden"
                              + (CutMaterial.Rus === material
                                ? ' !border-b-[3px] !border-b-[#008AC9]'
                                : ''
                              )
                          }
                        >
                          {
                            CutMaterial.Rus === material
                              ? <span className="absolute top-1 right-1 text-[#008AC9]">
                                <FontAwesomeIcon icon={faCheckCircle} />
                              </span>
                              : <></>
                          }
                          {
                            materialCuttingOptionRUS.valueIconUrl
                              ? <img
                                src={materialCuttingOptionRUS.valueIconUrl}
                                alt={t('configurator_3d_material_rus') || ''}
                                className="w-[125px] h-[100px] object-cover"
                              />
                              : <></>
                          }
                        </div>
                      </button>
                      : <></>
                  }
                </>
                : showingCuttingOptionMobile.mappedItems.map((item, index) =>
                  <button
                    key={'showingCuttingOptionMobile' + item.groupId + item.value + index}
                    className="flex flex-col items-center"
                    onClick={() => handleOnCuttingChange(item.code, item)}
                  >
                    <p className={
                      "text-[14px] font-semibold"
                      + (selectedCutting[item.code] && item.value === selectedCutting[item.code].value ? ' text-[#008AC9]' : ' text-[#141414]')
                    }>
                      { item.valueDescription }
                    </p>
                    <div
                      className={
                        "relative border-2 border-[#D4D4D4] mt-4 overflow-hidden"
                          + (selectedCutting[item.code] && item.value === selectedCutting[item.code].value
                            ? ' !border-b-[3px] !border-b-[#008AC9]'
                            : ''
                          )
                      }
                    >
                      {
                        selectedCutting[item.code] && item.value === selectedCutting[item.code].value
                          ? <span className="absolute top-1 right-1 text-[#008AC9]">
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </span>
                          : <></>
                      }
                      <img
                        src={item.valueIconUrl}
                        alt={item.value}
                        className="w-[125px] h-[100px] object-cover"
                      />
                    </div>
                  </button>
                )
              : <>
                {
                  customCuttingOptions.map((option, index) =>
                    selectedCutting[option.code]
                      ? <button
                        key={option.value + 'mobileLabel' + index}
                        className="flex flex-col items-center"
                        onClick={() => onSetShowMoreCuttingOptionMobile(option.code)}
                      >
                        <p className="text-[14px] text-[#141414] font-medium">
                          { option.codeDescription }
                        </p>
                        <p className="text-[14px] text-[#008AC9] font-medium">
                          { selectedCutting[option.code].valueDescription }
                        </p>
                        <div className="border-2 border-[#D4D4D4] mt-4 overflow-hidden">
                          <div className="relative">
                            <i className="absolute top-1 right-1 text-[#008AC9] min-h-[18px] min-w-[18px] p-px z-10">
                              <FontAwesomeIcon icon={faPenCircle} />
                            </i>
                            <img
                              src={option.valueIconUrl}
                              alt={selectedCutting[option.code].valueDescription}
                              className="w-[125px] h-[100px] object-cover"
                            />
                          </div>
                        </div>
                      </button>
                      : <div key={option.value + 'mobileLabel'+ index} />
                  )
                }
                {
                  materialCuttingOption
                    ? <button
                      key={materialCuttingOption.value + 'materialOption'}
                      className="flex flex-col items-center"
                      onClick={() => onSetShowMoreCuttingOptionMobile(materialCuttingOption.code)}
                    >
                      <p className="text-[14px] text-[#141414] font-medium">
                        { materialCuttingOption.codeDescription }
                      </p>
                      {
                        material === CutMaterial.Int && materialCuttingOptionINT
                          ? <>
                            <p className="text-[14px] text-[#008AC9] font-medium">
                              { t('configurator_3d_material_int') }
                            </p>
                            <div className="border-2 border-[#D4D4D4] mt-4 overflow-hidden">
                              <div className="relative">
                                <i className="absolute top-1 right-1 text-[#008AC9] min-h-[18px] min-w-[18px] p-px z-10">
                                  <FontAwesomeIcon icon={faPenCircle} />
                                </i>
                                <img
                                  src={materialCuttingOptionINT.valueIconUrl}
                                  alt={materialCuttingOptionINT.valueDescription}
                                  className="w-[125px] h-[100px] object-cover"
                                />
                              </div>
                            </div>
                          </>
                          : <></>
                      }
                      {
                        material === CutMaterial.Be && materialCuttingOptionBE
                          ? <>
                            <p className="text-[14px] text-[#008AC9] font-medium">
                              { t('configurator_3d_material_be') }
                            </p>
                            <div className="border-2 border-[#D4D4D4] mt-4 overflow-hidden">
                              <div className="relative">
                                <i className="absolute top-1 right-1 text-[#008AC9] min-h-[18px] min-w-[18px] p-px z-10">
                                  <FontAwesomeIcon icon={faPenCircle} />
                                </i>
                                <img
                                  src={materialCuttingOptionBE.valueIconUrl}
                                  alt={materialCuttingOptionBE.valueDescription}
                                  className="w-[125px] h-[100px] object-cover"
                                />
                              </div>
                            </div>
                          </>
                          : <></>
                      }
                      {
                        material === CutMaterial.Ruj && materialCuttingOptionRUJ
                          ? <>
                            <p className="text-[14px] text-[#008AC9] font-medium">
                              { t('configurator_3d_material_ruj') }
                            </p>
                            <div className="border-2 border-[#D4D4D4] mt-4 overflow-hidden">
                              <img
                                src={materialCuttingOptionRUJ.valueIconUrl}
                                alt={materialCuttingOptionRUJ.valueDescription}
                                className="w-[125px] h-[100px] object-cover"
                              />
                            </div>
                          </>
                          : <></>
                      }
                      {
                        material === CutMaterial.Rus && materialCuttingOptionRUS
                          ? <>
                            <p className="text-[14px] text-[#008AC9] font-medium">
                              { t('configurator_3d_material_rus') }
                            </p>
                            <div className="border-2 border-[#D4D4D4] mt-4 overflow-hidden">
                              <img
                                src={materialCuttingOptionRUS.valueIconUrl}
                                alt={materialCuttingOptionRUS.valueDescription}
                                className="w-[125px] h-[100px] object-cover"
                              />
                            </div>
                          </>
                          : <></>
                      }
                    </button>
                    : <></>
                }
              </>
          }
        </div>
      </SimpleBar>
      {
        hasMoreScrollContainer
          ? <div
            className={
              "absolute w-full h-[40px] bottom-[23px] left-0 bg-gradient-to-b from-transparent to-white transition-all duration-200"
              + (hasMoreScrollContainer ? ' opacity-100' : ' opacity-0')
            }
          />
          : <></>
      }
    </div>
    : <>
      <div className="h1 text-center">{ t('configurator_3d_cutting_form') }</div>
      <div className="text-center marginBottom--small">
        { t('configurator_3d_cutting_form_text') }
      </div>
      <div className="slideContent">
        {
          customCuttingOptions.map((option, index) =>
            selectedCutting[option.code]
              ? <div
                key={option.value + 'desktopLabel' + index}
                className="slideContainer"
              >
                <button
                  className="flex w-full justify-between items-center"
                  onClick={() => setShowMoreCuttingOption((prevState: any) => ({
                    ...prevState,
                    [option.code]: !prevState[option.code]
                  }))}
                >
                  <p className="text-[#141414]">
                    <span className="font-semibold">{ option.codeDescription } /</span> { selectedCutting[option.code].valueDescription }
                  </p>
                  <div className="flex items-center gap-4">
                    <img
                      src={option.valueIconUrl}
                      alt={selectedCutting[option.code].valueDescription}
                      className="w-[44px] h-[35px] object-cover border border-[#D4D4D4]"
                    />
                    <FontAwesomeIcon
                      className={
                        "transform transition-all duration-500 h-[14px] ml-[3px]"
                        + (showMoreCuttingOption[option.code] ? ' rotate-90' : ' rotate-0')
                      }
                      icon={faAngleRight}
                    />
                  </div>
                </button>
                <div
                  className={
                    "slideContent__show"
                    + (showMoreCuttingOption[option.code] ? ' active' : '')
                  }
                />
                <div className="slideContent__contentContainer">
                  <div className="flex gap-3">
                    {
                      option.mappedItems.map((optionItem, optionItemIndex) =>
                        <label
                          htmlFor={optionItem.groupId}
                          key={optionItem.groupId + 'desktopValue' + index + optionItemIndex}
                          className={
                            "flex flex-col items-center cursor-pointer hover:opacity-60"
                            + (selectedCutting[option.code] && optionItem.value === selectedCutting[option.code].value
                              ? ' pointer-events-none'
                              : ''
                            )
                          }
                        >
                          <input
                            name={option.code + 'select'}
                            id={optionItem.groupId}
                            className="opacity-0 invisible"
                            type="radio"
                            checked={selectedCutting[option.code] && optionItem.value === selectedCutting[option.code].value}
                            onChange={() => handleOnCuttingChange(option.code, optionItem)}
                          />
                          <div
                            className={
                              "relative w-[123px] h-[118px]"
                              + (selectedCutting[option.code] && optionItem.value === selectedCutting[option.code].value
                                  ? ' before:absolute before:left-0 before:bottom-0 before:w-full before:h-[2px] before:bg-[#008AC9] before:z-10'
                                  : ''
                                )
                            }
                          >
                            <img
                              src={optionItem.valueIconUrl}
                              alt={optionItem.value}
                              className="absolute top- left-0 w-[123px] h-[118px] object-cover"
                            />
                          </div>
                          <p className="mt-3 text-[#141414]">
                           { optionItem.valueDescription }
                          </p>
                        </label>
                      )
                    }
                  </div>
                </div>
              </div>
              : <div key={option.value + 'desktopLabel'  + index} />
          )
        }

        {
          materialCuttingOption
            ? <div
            key="MaterialSelect"
            className="slideContainer"
          >
            <button
              className="flex w-full justify-between items-center"
              onClick={() => setShowMoreCuttingOption((prevState: any) => ({
                ...prevState,
                [materialCuttingOption.code]: !prevState[materialCuttingOption.code]
              }))}
            >
              {
                material === CutMaterial.Int && materialCuttingOptionINT
                  ? <>
                    <p className="text-[#141414]">
                      <span className="font-semibold">{ materialCuttingOption.codeDescription } /</span> { t('configurator_3d_material_int') }
                    </p>
                    <div className="flex items-center gap-4">
                      <img
                        src={materialCuttingOptionINT.valueIconUrl}
                        alt={materialCuttingOptionINT.valueDescription}
                        className="w-[44px] h-[35px] object-cover border border-[#D4D4D4]"
                      />
                      <FontAwesomeIcon
                        className={
                          "transform transition-all duration-500 h-[14px] ml-[3px]"
                          + (showMoreCuttingOption[materialCuttingOption.code] ? ' rotate-90' : ' rotate-0')
                        }
                        icon={faAngleRight}
                      />
                    </div>
                  </>
                  : <></>
              }
              {
                material === CutMaterial.Be && materialCuttingOptionBE
                  ? <>
                    <p className="text-[#141414]">
                      <span className="font-semibold">{ materialCuttingOption.codeDescription } /</span> { t('configurator_3d_material_be') }
                    </p>
                    <div className="flex items-center gap-4">
                      <img
                        src={materialCuttingOptionBE.valueIconUrl}
                        alt={materialCuttingOptionBE.valueDescription}
                        className="w-[44px] h-[35px] object-cover border border-[#D4D4D4]"
                      />
                      <FontAwesomeIcon
                        className={
                          "transform transition-all duration-500 h-[14px] ml-[3px]"
                          + (showMoreCuttingOption[materialCuttingOption.code] ? ' rotate-90' : ' rotate-0')
                        }
                        icon={faAngleRight}
                      />
                    </div>
                  </>
                  : <></>
              }
              {
                material === CutMaterial.Ruj && materialCuttingOptionRUJ
                  ? <>
                    <p className="text-[#141414]">
                      <span className="font-semibold">{ materialCuttingOption.codeDescription } /</span> { t('configurator_3d_material_ruj') }
                    </p>
                    <div className="flex items-center gap-4">
                      <img
                        src={materialCuttingOptionRUJ.valueIconUrl}
                        alt={materialCuttingOptionRUJ.valueDescription}
                        className="w-[44px] h-[35px] object-cover border border-[#D4D4D4]"
                      />
                      <FontAwesomeIcon
                        className={
                          "transform transition-all duration-500 h-[14px] ml-[3px]"
                          + (showMoreCuttingOption[materialCuttingOption.code] ? ' rotate-90' : ' rotate-0')
                        }
                        icon={faAngleRight}
                      />
                    </div>
                  </>
                  : <></>
              }
              {
                material === CutMaterial.Rus && materialCuttingOptionRUS
                  ? <>
                    <p className="text-[#141414]">
                      <span className="font-semibold">{ materialCuttingOption.codeDescription } /</span> { t('configurator_3d_material_rus') }
                    </p>
                    <div className="flex items-center gap-4">
                      <img
                        src={materialCuttingOptionRUS.valueIconUrl}
                        alt={materialCuttingOptionRUS.valueDescription}
                        className="w-[44px] h-[35px] object-cover border border-[#D4D4D4]"
                      />
                      <FontAwesomeIcon
                        className={
                          "transform transition-all duration-500 h-[14px] ml-[3px]"
                          + (showMoreCuttingOption[materialCuttingOption.code] ? ' rotate-90' : ' rotate-0')
                        }
                        icon={faAngleRight}
                      />
                    </div>
                  </>
                  : <></>
              }
            </button>
            <div
              className={
                "slideContent__show"
                + (showMoreCuttingOption[materialCuttingOption.code] ? ' active' : '')
              }
            />
            <div className="slideContent__contentContainer">
              <div className="flex gap-3">
                {
                  materialCuttingOptionRUJ
                    ? <label
                      htmlFor="polyester"
                      className={
                        "flex flex-col items-center cursor-pointer hover:opacity-60"
                        + (CutMaterial.Ruj === material
                          ? ' pointer-events-none'
                          : ''
                        )
                      }
                    >
                      <input
                        name="materialSelect"
                        id="polyester"
                        className="opacity-0 invisible"
                        type="radio"
                        checked={CutMaterial.Ruj === material}
                        onChange={() => handleOnMaterialChange(CutMaterial.Ruj)}
                      />
                      <div
                        className={
                          "relative w-[123px] h-[118px]"
                          + (CutMaterial.Ruj === material
                              ? ' before:absolute before:left-0 before:bottom-0 before:w-full before:h-[2px] before:bg-[#008AC9] before:z-10'
                              : ''
                            )
                        }
                      >
                        {
                          materialCuttingOptionRUJ.valueIconUrl
                            ? <img
                              src={materialCuttingOptionRUJ.valueIconUrl}
                              alt={t('configurator_3d_material_ruj') || ''}
                              className="absolute top- left-0 w-[123px] h-[118px] object-cover"
                            />
                            : <></>
                        }
                      </div>
                      <p className="mt-3 text-[#141414]">
                        { t('configurator_3d_material_ruj') }
                      </p>
                    </label>
                    : <></>
                }
                {
                  materialCuttingOptionRUS
                    ? <label
                      htmlFor="polyester"
                      className={
                        "flex flex-col items-center cursor-pointer hover:opacity-60"
                        + (CutMaterial.Rus === material
                          ? ' pointer-events-none'
                          : ''
                        )
                      }
                    >
                      <input
                        name="materialSelect"
                        id="polyester"
                        className="opacity-0 invisible"
                        type="radio"
                        checked={CutMaterial.Rus === material}
                        onChange={() => handleOnMaterialChange(CutMaterial.Rus)}
                      />
                      <div
                        className={
                          "relative w-[123px] h-[118px]"
                          + (CutMaterial.Rus === material
                              ? ' before:absolute before:left-0 before:bottom-0 before:w-full before:h-[2px] before:bg-[#008AC9] before:z-10'
                              : ''
                            )
                        }
                      >
                        {
                          materialCuttingOptionRUS.valueIconUrl
                            ? <img
                              src={materialCuttingOptionRUS.valueIconUrl}
                              alt={t('configurator_3d_material_rus') || ''}
                              className="absolute top- left-0 w-[123px] h-[118px] object-cover"
                            />
                            : <></>
                        }
                      </div>
                      <p className="mt-3 text-[#141414]">
                        { t('configurator_3d_material_rus') }
                      </p>
                    </label>
                    : <></>
                }
                {
                  materialCuttingOptionINT
                    ? <label
                      htmlFor="polyester"
                      className={
                        "flex flex-col items-center cursor-pointer hover:opacity-60"
                        + (CutMaterial.Int === material
                          ? ' pointer-events-none'
                          : ''
                        )
                      }
                    >
                      <input
                        name="materialSelect"
                        id="polyester"
                        className="opacity-0 invisible"
                        type="radio"
                        checked={CutMaterial.Int === material}
                        onChange={() => handleOnMaterialChange(CutMaterial.Int)}
                      />
                      <div
                        className={
                          "relative w-[123px] h-[118px]"
                          + (CutMaterial.Int === material
                              ? ' before:absolute before:left-0 before:bottom-0 before:w-full before:h-[2px] before:bg-[#008AC9] before:z-10'
                              : ''
                            )
                        }
                      >
                        {
                          materialCuttingOptionINT.valueIconUrl
                            ? <img
                              src={materialCuttingOptionINT.valueIconUrl}
                              alt={t('configurator_3d_material_int') || ''}
                              className="absolute top- left-0 w-[123px] h-[118px] object-cover"
                            />
                            : <></>
                        }
                      </div>
                      <p className="mt-3 text-[#141414]">
                        { t('configurator_3d_material_int') }
                      </p>
                    </label>
                    : <></>
                }
                {
                  materialCuttingOptionBE
                    ? <label
                      htmlFor="recycled"
                      className={
                        "flex flex-col items-center cursor-pointer hover:opacity-60"
                        + (CutMaterial.Be === material
                          ? ' pointer-events-none'
                          : ''
                        )
                      }
                    >
                      <input
                        name="materialSelect"
                        id="recycled"
                        className="opacity-0 invisible"
                        type="radio"
                        checked={CutMaterial.Be === material}
                        onChange={() => handleOnMaterialChange(CutMaterial.Be)}
                      />
                      <div
                        className={
                          "relative w-[123px] h-[118px]"
                          + (CutMaterial.Be === material
                              ? ' before:absolute before:left-0 before:bottom-0 before:w-full before:h-[2px] before:bg-[#008AC9] before:z-10'
                              : ''
                            )
                        }
                      >
                        {
                          materialCuttingOptionBE.valueIconUrl
                            ? <img
                              src={materialCuttingOptionBE.valueIconUrl}
                              alt={t('configurator_3d_material_be') || ''}
                              className="absolute top- left-0 w-[123px] h-[118px] object-cover"
                            />
                            : <></>
                        }
                      </div>
                      <p className="mt-3 text-[#141414]">
                        { t('configurator_3d_material_be') }
                      </p>
                    </label>
                    : <></>
                }
              </div>
            </div>
          </div>
            : <></>
        }
      </div>

      <div className="flex justify-between mt-20">
        <button
          className="px-[27px] py-[12px] bg-[#FAFAFA] text-[#5B5B5B]"
          onClick={setSelectTabBack}
        >
          <div className="flex items-center">
            <FontAwesomeIcon
              className="h-[14px] mr-2"
              icon={faAngleLeft}
            />
            <span className='relative top-[-1px]'>{ t('configurator_3d_back') }</span>
          </div>
        </button>
        <button
          className="px-[27px] py-[12px] bg-[#008AC9] text-[#FFFFFF]"
          onClick={setSelectTabNext}
        >
          <div className="flex items-center">
            <span className='relative top-[-1px]'>{ t('configurator_3d_basket_next') }</span>
            <FontAwesomeIcon
              className="h-[14px] ml-2"
              icon={faAngleRight}
            />
          </div>
        </button>
      </div>
    </>;
}
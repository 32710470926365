import type { NameTabInterface , NameInitialAddPosition} from './types';
import { FunctionTab, ModelStyle, NameTabType } from './types';
import { FONT_OPTIONS, INITIAL_ADD_POSITION, NAME_ADD_POSITION } from './constants';
import { useState, useMemo, useEffect, useRef, useContext, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import {
  faAngleRight,
  faAngleLeft,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faMinus as faMinusRegular,
  faPlus as faPlusRegular,
} from '@fortawesome/pro-light-svg-icons';
import {
  faTrash,
  faArrowsUpDownLeftRight,
  faArrowsMaximize,
  faBringForward,
  faSendBackward,
  faXmark,
  faFontCase,
  faPlus,
  faLock,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BaseContext } from '../contexts/BaseContext';
import IconCross from './icons/IconCross';
import IconPen from './icons/IconPen';
import IconLock from './icons/IconLock';
import IconTrashCan from './icons/IconTrashCan';
import IconInfo from './icons/IconInfo';

export type JakoNameTabHandler = {
  openOptionByName: (objectName: string) => void,
};

enum MobileFunction {
  Home,
  Position,
  Size,
  Font,
  Color
}

const JakoNameTab = forwardRef<JakoNameTabHandler, NameTabInterface>(({
  modelStyle,
  functionTab,
  selectedAddedTextKey,
  addedTextItems,
  showAddTextInputRef,
  showEditTextInputRef,
  colorList,
  cmFactor,
  onSubmitAddTextInput,
  onSubmitEditTextInput,
  onClickTextLock,
  onClickDeleteText,
  onSelectText,
  onSelectAddNamePosition,
  onChangeTextColor,
  onChangeFontFamily,
  onChangeFontSizeWidth,
  onChangeFontSizeHeight,
  onChangePosition,
  onSendTextToFront,
  onSendTextToBack,
  onSetTabName,
  setSelectTabBack,
  setSelectTabNext,
  isMobile,
  onCloseMobile,
  onScrollToSelectedItem,
}, ref) => {
  useImperativeHandle(ref, () => ({
    openOptionByName(name: string) {
      let results = {};
      addedNameNameItems.forEach((itemKey) => {
        results = {
          ...results,
          [itemKey]: itemKey === name
        };
      });
      addedNameInitialItems.forEach((itemKey) => {
        results = {
          ...results,
          [itemKey]: itemKey === name
        };
      });
      setShowOption(results);
    },
  }));
  const [selectMobileFunction, setSelectMobileFunction] = useState<MobileFunction | null>(MobileFunction.Home);
  const [hasMoreScrollContainer, setHasMoreScrollContainer] = useState(false);
  const scrollBarRef = useRef(null as any);

  const { customFields } = useContext(BaseContext);
  const [selectTab, setSelectTab] = useState<NameTabType>(NameTabType.Name);

  const [showOption, setShowOption] = useState<any>({});
  const [selectedPosition, setSelectedPosition] = useState<{ [key: string]: NameInitialAddPosition }>({});
  const [showAddPosition, setShowAddPosition] = useState(false);
  const [showMoreColor, setShowMoreColor] = useState(false);
  const [showFontFamily, setShowFontFamily] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const inputWidthRef = useRef(null as any);
  const inputHeightRef = useRef(null as any);
  const [inputSizeWidth, setInputSizeWidth] = useState({} as { [key: string]: number });
  const [inputSizeHeight, setInputSizeHeight] = useState({} as { [key: string]: number });

  const { t } = useTranslation('translations');
  const inputPlaceholder = t('configurator_3d_input_name_placeholder');

  const nameCustomField = useMemo(() => customFields.find((field) => field.code === '3D_NAME'), [customFields]);
  const initialCustomField = useMemo(() => customFields.find((field) => field.code === '3D_INITIALEN'), [customFields]);

  const addedNameItems = useMemo(() => {
    return Object.keys(addedTextItems).slice()
      .filter((itemKey) => addedTextItems[itemKey].functionTab === FunctionTab.Name || addedTextItems[itemKey].functionTab === FunctionTab.Initial);
  }, [addedTextItems]);

  const addedNameNameItems = useMemo(() => addedNameItems.slice().filter((itemKey) => {
    const position = addedTextItems[itemKey].functionTab;
    return FunctionTab.Name === position;
  }), [addedTextItems, addedNameItems]);

  const addedNameInitialItems = useMemo(() => addedNameItems.slice().filter((itemKey) => {
    const position = addedTextItems[itemKey].functionTab;
    return FunctionTab.Initial === position;
  }), [addedTextItems, addedNameItems]);

  const isNoPositionInput = useMemo(() =>
    selectedAddedTextKey
      ? selectedPosition[selectedAddedTextKey] === null || selectedPosition[selectedAddedTextKey] === undefined
      : false
  , [selectedPosition, selectedAddedTextKey]);

  const addPositionNameOptionKeys = useMemo(() => modelStyle === ModelStyle.Short
    ? Object.keys(NAME_ADD_POSITION).filter((key) => key.includes('Short_'))
    : Object.keys(NAME_ADD_POSITION).filter((key) => !key.includes('Short_'))
  , [modelStyle]);

  const addPositionInitialOptionKeys = useMemo(() => modelStyle === ModelStyle.Short
    ? Object.keys(INITIAL_ADD_POSITION).filter((key) => key.includes('Short_'))
    : Object.keys(INITIAL_ADD_POSITION).filter((key) => !key.includes('Short_'))
  , [modelStyle]);

  useEffect(() => {
    if (!scrollBarRef.current) {
      return;
    }

    onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
    scrollBarRef.current.getScrollElement()
      .addEventListener("scroll", function(e: any) { onScrollContainerUpdate(e.target); });
  }, []);

  useEffect(() => {
    if (!scrollBarRef.current) {
      return;
    }
    setHasMoreScrollContainer(false);
    onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
  }, [selectMobileFunction]);

  useEffect(() => {
    if (!selectedAddedTextKey) {
      return;
    }
    if (isMobile) {
      return;
    }
    let results = {};
    addedNameItems.forEach((itemKey) => {
      results = {
        ...results,
        [itemKey]: false
      };
    });
    results = {
      ...results,
      [selectedAddedTextKey]: true
    };
    setShowOption(results);
    onScrollToSelectedItem('trigger'+selectedAddedTextKey);
    // eslint-disable-next-line
  }, [selectedAddedTextKey]);

  useEffect(() => {
    if (selectedAddedTextKey && addedTextItems[selectedAddedTextKey]) {
      const widthCm = addedTextItems[selectedAddedTextKey].scaledWidth;
      const widthFloatToFixedOneBackToFloat = Math.round(widthCm * 10) / 10;
      if (inputWidthRef.current) {
        inputWidthRef.current.value = widthFloatToFixedOneBackToFloat;
      }
      const getWidthInput = document.getElementsByTagName('web-configurator')[0] ? document.getElementsByTagName('web-configurator')[0].shadowRoot?.getElementById(`inputQtyWidth_${selectedAddedTextKey}`) : document.getElementById(`inputQtyWidth_${selectedAddedTextKey}`) as any;
      if (getWidthInput) {
        getWidthInput.value = widthFloatToFixedOneBackToFloat;
      }
      setInputSizeWidth((prevState) => ({
        ...prevState,
        [selectedAddedTextKey]: widthFloatToFixedOneBackToFloat
      }));

      const heightCm = addedTextItems[selectedAddedTextKey].scaledHeight;
      const heightFloatToFixedOneBackToFloat = Math.round(heightCm * 10) / 10;
      if (inputHeightRef.current) {
        inputHeightRef.current.value = heightFloatToFixedOneBackToFloat;
      }
      const getHeightInput = document.getElementsByTagName('web-configurator')[0] ? document.getElementsByTagName('web-configurator')[0].shadowRoot?.getElementById(`inputQtyHeight_${selectedAddedTextKey}`) : document.getElementById(`inputQtyHeight_${selectedAddedTextKey}`) as any;
      if (getHeightInput) {
        getHeightInput.value = heightFloatToFixedOneBackToFloat;
      }
      setInputSizeHeight((prevState) => ({
        ...prevState,
        [selectedAddedTextKey]: heightFloatToFixedOneBackToFloat
      }));

      const addedTextPosition = addedTextItems[selectedAddedTextKey].position as NameInitialAddPosition;
      setSelectedPosition({
        ...selectedPosition,
        [selectedAddedTextKey]:
          selectedPosition[selectedAddedTextKey]
            ? selectedPosition[selectedAddedTextKey]
            : addedTextPosition
      });
    } else {
      let results = {};
      let positionResults = {...selectedPosition};
      addedNameItems.forEach((itemKey) => {
        results = {
          ...results,
          [itemKey]: false
        };
        if (addedTextItems[itemKey].position) {
          const pos = addedTextItems[itemKey].position as NameInitialAddPosition;
          positionResults = {
            ...positionResults,
            [itemKey]: pos
          };
        }
      });
      setSelectedPosition(positionResults);
      setShowOption(results);
      setShowMoreColor(false);
      setShowAddPosition(false);
      setShowFontFamily(false);
    }
    // eslint-disable-next-line
  }, [addedTextItems, selectedAddedTextKey]);

  useEffect(() => {
    if (functionTab === FunctionTab.Initial) {
      setSelectTab(NameTabType.Initial);
    } else {
      setSelectTab(NameTabType.Name);
    }
  }, [functionTab]);

  useEffect(() => {
    /* TOOLTIPS */
    // Get an array of all items on the page with class 'tooltip'.
    if (!selectedAddedTextKey) {
      return;
    }
    if (!isMobile) {
      return;
    }
    setTimeout(() => {
      const tooltipList = document.querySelectorAll('.name-tab .tooltip');
      // Loop through that array
      tooltipList.forEach(function(el) {
        const element = el as any;
        if (el.children.length) {
          return;
        }
        // Create a <label> element
        const tooltip = document.createElement('label');
        // Give it a class of 'tooltipBubble'
        tooltip.classList.add('tooltipBubble');
        // Set the text inside that element to be our 'data-tooltip' value
        tooltip.innerHTML = element.dataset.tooltip;
        // Then insert it in the element we're on in the loop
        element.appendChild(tooltip);
      });
    }, 300);
  }, [selectedAddedTextKey, colorList, isMobile]);

  const isShowCloseButtonTopRight = useMemo(() => {
    return selectedAddedTextKey !== null;
  }, [selectedAddedTextKey]);

  function handleOnSelectText(name: string) {
    if (name === selectedAddedTextKey) {
      handleSetShowMoreOption(name);

      if (showOption[name]) {
        onSelectText(null);
      } else {
        onSelectText(name);
      }
      return;
    }
    onSelectText(name);
    let results = {};
    addedNameNameItems.forEach((itemKey) => {
      results = {
        ...results,
        [itemKey]: itemKey === name
      };
    });
    addedNameInitialItems.forEach((itemKey) => {
      results = {
        ...results,
        [itemKey]: itemKey === name
      };
    });
    setShowOption(results);
  }

  function handleSetShowMoreOption(name: string) {
    let results = {};
    addedNameNameItems.forEach((itemKey) => {
      results = {
        ...results,
        [itemKey]: false
      };
    });
    addedNameInitialItems.forEach((itemKey) => {
      results = {
        ...results,
        [itemKey]: false
      };
    });
    results = {
      ...results,
      [name]: !showOption[name],
    };
    setShowOption(results);
  }

  function handleOnDecreaseFontSize(dimension: string) {
    if (!selectedAddedTextKey) {
      return;
    }
    if (dimension === 'width') {
      const currentValue = inputSizeWidth[selectedAddedTextKey];
      if (currentValue - 0.1 < 1) {
        return;
      }
      const widthCmToPixel = (currentValue - 0.1) * cmFactor;
      onChangeFontSizeWidth(selectedAddedTextKey, widthCmToPixel);
    } else {
      const currentValue = inputSizeHeight[selectedAddedTextKey];
      if (currentValue - 0.1 < 1) {
        return;
      }
      const heightCmToPixel = (currentValue - 0.1) * cmFactor;
      onChangeFontSizeHeight(selectedAddedTextKey, heightCmToPixel);
    }
  }
  function handleOnIncreaseFontSize(dimension: string) {
    if (!selectedAddedTextKey) {
      return;
    }
    if (dimension === 'width') {
      const currentValue = inputSizeWidth[selectedAddedTextKey];
      const widthCmToPixel = (currentValue + 0.1) * cmFactor;
      onChangeFontSizeWidth(selectedAddedTextKey, widthCmToPixel);
    } else {
      const currentValue = inputSizeHeight[selectedAddedTextKey];
      const heightCmToPixel = (currentValue + 0.1) * cmFactor;
      onChangeFontSizeHeight(selectedAddedTextKey, heightCmToPixel);
    }
  }
  function handleOnChangeWidthFontSize(event: any, fontSize:  number) {
    if (!selectedAddedTextKey) {
      return;
    }
    if (!event.target.value) {
      if (parseInt(event.target.value) < 1) {
        if (inputWidthRef.current) {
          inputWidthRef.current.value = 1;
          const widthCmToPixel = 1 * cmFactor;
          onChangeFontSizeWidth(selectedAddedTextKey, widthCmToPixel);
        }
        const getWidthInput = document.getElementsByTagName('web-configurator')[0] ? document.getElementsByTagName('web-configurator')[0].shadowRoot?.getElementById(`inputQtyWidth_${selectedAddedTextKey}`) : document.getElementById(`inputQtyWidth_${selectedAddedTextKey}`) as any;
        if (getWidthInput) {
          getWidthInput.value = 1;
          const widthCmToPixel = 1 * cmFactor;
          onChangeFontSizeWidth(selectedAddedTextKey, widthCmToPixel);
        }
      } else {
        if (inputWidthRef.current) {
          inputWidthRef.current.value = inputSizeWidth[selectedAddedTextKey];
          const widthCmToPixel = inputWidthRef.current.value * cmFactor;
          onChangeFontSizeWidth(selectedAddedTextKey, widthCmToPixel);
        }
        const getWidthInput = document.getElementsByTagName('web-configurator')[0] ? document.getElementsByTagName('web-configurator')[0].shadowRoot?.getElementById(`inputQtyWidth_${selectedAddedTextKey}`) : document.getElementById(`inputQtyWidth_${selectedAddedTextKey}`) as any;
        if (getWidthInput) {
          getWidthInput.value = inputSizeWidth[selectedAddedTextKey];
          const widthCmToPixel = inputSizeWidth[selectedAddedTextKey] * cmFactor;
          onChangeFontSizeWidth(selectedAddedTextKey, widthCmToPixel);
        }
      }
      return;
    }
    const floatToFixedOneBackToFloat = Math.round(parseFloat(event.target.value) * 10) / 10;
    if (inputWidthRef.current) {
      inputWidthRef.current.value = floatToFixedOneBackToFloat;
    }
    const getWidthInput = document.getElementsByTagName('web-configurator')[0] ? document.getElementsByTagName('web-configurator')[0].shadowRoot?.getElementById(`inputQtyWidth_${selectedAddedTextKey}`) : document.getElementById(`inputQtyWidth_${selectedAddedTextKey}`) as any;
    if (getWidthInput) {
      getWidthInput.value = floatToFixedOneBackToFloat;
    }
    setInputSizeWidth((prevState) => ({
      ...prevState,
      [selectedAddedTextKey]: floatToFixedOneBackToFloat
    }));

    const widthCmToPixel = parseFloat(event.target.value) * cmFactor;
    onChangeFontSizeWidth(selectedAddedTextKey, widthCmToPixel);
  }
  function handleOnChangeHeightFontSize(event: any, fontSize:  number) {
    if (!selectedAddedTextKey) {
      return;
    }
    if (!event.target.value) {
      if (parseInt(event.target.value) < 1) {
        if (inputHeightRef.current) {
          inputHeightRef.current.value = 1;
          const heightCmToPixel = 1 * cmFactor;
          onChangeFontSizeHeight(selectedAddedTextKey, heightCmToPixel);
        }
        const getHeightInput = document.getElementsByTagName('web-configurator')[0] ? document.getElementsByTagName('web-configurator')[0].shadowRoot?.getElementById(`inputQtyHeight_${selectedAddedTextKey}`) : document.getElementById(`inputQtyHeight_${selectedAddedTextKey}`) as any;
        if (getHeightInput) {
          getHeightInput.value = 1;
          const heightCmToPixel = 1 * cmFactor;
          onChangeFontSizeHeight(selectedAddedTextKey, heightCmToPixel);
        }
      } else {
        if (inputHeightRef.current) {
          inputHeightRef.current.value = inputSizeHeight[selectedAddedTextKey];
          const heightCmToPixel = inputSizeHeight[selectedAddedTextKey] * cmFactor;
          onChangeFontSizeHeight(selectedAddedTextKey, heightCmToPixel);
        }
        const getHeightInput = document.getElementsByTagName('web-configurator')[0] ? document.getElementsByTagName('web-configurator')[0].shadowRoot?.getElementById(`inputQtyHeight_${selectedAddedTextKey}`) : document.getElementById(`inputQtyHeight_${selectedAddedTextKey}`) as any;
        if (getHeightInput) {
          getHeightInput.value = inputSizeHeight[selectedAddedTextKey];
          const heightCmToPixel = inputSizeHeight[selectedAddedTextKey] * cmFactor;
          onChangeFontSizeHeight(selectedAddedTextKey, heightCmToPixel);
        }
      }
      return;
    }

    const floatToFixedOneBackToFloat = Math.round(parseFloat(event.target.value) * 10) / 10;
    if (inputHeightRef.current) {
      inputHeightRef.current.value = floatToFixedOneBackToFloat;
    }
    const getHeightInput = document.getElementsByTagName('web-configurator')[0] ? document.getElementsByTagName('web-configurator')[0].shadowRoot?.getElementById(`inputQtyHeight_${selectedAddedTextKey}`) : document.getElementById(`inputQtyHeight_${selectedAddedTextKey}`) as any;
    if (getHeightInput) {
      getHeightInput.value = floatToFixedOneBackToFloat;
    }
    setInputSizeHeight((prevState) => ({
      ...prevState,
      [selectedAddedTextKey]: floatToFixedOneBackToFloat
    }));

    const heightCmToPixel = parseFloat(event.target.value) * cmFactor;
    onChangeFontSizeHeight(selectedAddedTextKey, heightCmToPixel);
  }

  function handleOnSubmitNameInput(
    e: any,
    functionTab: FunctionTab,
  ) {
    const value = e.target[0] ? e.target[0].value : '';
    if (value.toString().replaceAll(/\s/g,'').length === 0) {
      e.preventDefault();
      return;
    }
    if (!nameCustomField) {
      nextStepAddNamePosition(e, functionTab);
      return;
    }
    if (!nameCustomField.requireUniqueValue) {
      nextStepAddNamePosition(e, functionTab);
      return;
    }
    const foundedSameText = addedNameNameItems.length === 0 || addedNameNameItems.find((itemKey) => addedTextItems[itemKey].text === value);
    if (foundedSameText) {
      nextStepAddNamePosition(e, functionTab);
    } else {
      e.preventDefault();
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div id="jako-configurator-3d-wc" className="jako w-full w-[200px] mx-auto p-0 bg-white md:w-full md:w-[400px]">
              <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                { t('configurator_3d_require_unique_value') }
              </h5>
              <button className="button_primary !bg-theme-primary" onClick={onClose}>
                { t('configurator_3d_ok') }
              </button>
            </div>
          );
        }
      });
      e.target[0].value = '';
    }
  }
  function nextStepAddNamePosition(
    e: any,
    functionTab: FunctionTab
  ) {
    e.preventDefault();
    const value = e.target[0] ? e.target[0].value : '';
    if (value.toString().replaceAll(/\s/g,'').length === 0) {
      e.preventDefault();
      return;
    }

    if (!nameCustomField) {
      showSelectAddNamePositionHandler(e, functionTab);
      return;
    }
    if (!nameCustomField.requireUniqueValue) {
      showSelectAddNamePositionHandler(e, functionTab);
      return;
    }
    const foundedSameText = addedNameNameItems.length === 0 || addedNameNameItems.find((itemKey) => addedTextItems[itemKey].text === value);
    if (foundedSameText) {
      showSelectAddNamePositionHandler(e, functionTab);
    } else {
      e.preventDefault();
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div id="jako-configurator-3d-wc" className="jako w-full w-[200px] mx-auto p-0 bg-white md:w-full md:w-[400px]">
              <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                { t('configurator_3d_require_unique_value') }
              </h5>
              <button className="button_primary !bg-theme-primary" onClick={onClose}>
                { t('configurator_3d_ok') }
              </button>
            </div>
          );
        }
      });
      e.target[0].value = '';
    }
  }
  function showSelectAddNamePositionHandler(e: any, tab: FunctionTab) {
    onSubmitAddTextInput(tab);
    setTimeout(() => {
      e.target[0].value = '';
    }, 500);
  }

  function handleOnSubmitInitialInput(
    e: any,
    functionTab: FunctionTab,
  ) {
    const value = e.target[0] ? e.target[0].value : '';
    if (value.toString().replaceAll(/\s/g,'').length === 0) {
      e.preventDefault();
      return;
    }
    if (!nameCustomField) {
      nextStepAddInitialPosition(e, functionTab);
      return;
    }
    if (!nameCustomField.requireUniqueValue) {
      nextStepAddInitialPosition(e, functionTab);
      return;
    }
    const foundedSameText = addedNameInitialItems.length === 0 || addedNameInitialItems.find((itemKey) => addedTextItems[itemKey].text === value);
    if (foundedSameText) {
      nextStepAddInitialPosition(e, functionTab);
    } else {
      e.preventDefault();
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div id="jako-configurator-3d-wc" className="jako w-full w-[200px] mx-auto p-0 bg-white md:w-full md:w-[400px]">
              <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                { t('configurator_3d_require_unique_value') }
              </h5>
              <button className="button_primary !bg-theme-primary" onClick={onClose}>
                { t('configurator_3d_ok') }
              </button>
            </div>
          );
        }
      });
      e.target[0].value = '';
    }
  }
  function nextStepAddInitialPosition(
    e: any,
    functionTab: FunctionTab
  ) {
    e.preventDefault();
    const value = e.target[0] ? e.target[0].value : '';
    if (value.toString().replaceAll(/\s/g,'').length === 0) {
      e.preventDefault();
      return;
    }

    if (!nameCustomField) {
      showSelectAddInitialPositionHandler(e, functionTab);
      return;
    }
    if (!nameCustomField.requireUniqueValue) {
      showSelectAddInitialPositionHandler(e, functionTab);
      return;
    }
    const foundedSameText = addedNameInitialItems.length === 0 || addedNameInitialItems.find((itemKey) => addedTextItems[itemKey].text === value);
    if (foundedSameText) {
      showSelectAddInitialPositionHandler(e, functionTab);
    } else {
      e.preventDefault();
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div id="jako-configurator-3d-wc" className="jako w-full w-[200px] mx-auto p-0 bg-white md:w-full md:w-[400px]">
              <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                { t('configurator_3d_require_unique_value') }
              </h5>
              <button className="button_primary !bg-theme-primary" onClick={onClose}>
                { t('configurator_3d_ok') }
              </button>
            </div>
          );
        }
      });
      e.target[0].value = '';
    }
  }
  function showSelectAddInitialPositionHandler(e: any, tab: FunctionTab) {
    onSubmitAddTextInput(tab);
    setTimeout(() => {
      e.target[0].value = '';
    }, 500);
  }

  function handleOnSubmitEditName(
    e: any,
    functionTab: FunctionTab,
  ) {
    const value = e.target[0] ? e.target[0].value : '';
    if (value.toString().replaceAll(/\s/g,'').length === 0) {
      e.preventDefault();
      return;
    }
    if (!selectedAddedTextKey) {
      e.preventDefault();
      return;
    }
    if (!nameCustomField) {
      onSubmitEditTextInput(selectedAddedTextKey, e, functionTab);
      setIsEdit(false);
      return;
    }
    if (!nameCustomField.requireUniqueValue) {
      onSubmitEditTextInput(selectedAddedTextKey, e, functionTab);
      setIsEdit(false);
      return;
    }
    const foundedSameText = addedNameNameItems.length === 0 || addedNameNameItems.find((itemKey) => addedTextItems[itemKey].text === value);
    if (foundedSameText) {
      e.preventDefault();
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div id="jako-configurator-3d-wc" className="jako w-full w-[200px] mx-auto p-0 bg-white md:w-full md:w-[400px]">
              <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                { t('configurator_3d_require_unique_value') }
              </h5>
              <button className="button_primary !bg-theme-primary" onClick={onClose}>
                { t('configurator_3d_ok') }
              </button>
            </div>
          );
        }
      });
      setIsEdit(false);
    } else {
      onSubmitEditTextInput(selectedAddedTextKey, e, functionTab);
      setIsEdit(false);
      e.preventDefault();
    }
  }

  function handleOnSubmitEditInitial(
    e: any,
    functionTab: FunctionTab,
  ) {
    const value = e.target[0] ? e.target[0].value : '';
    if (value.toString().replaceAll(/\s/g,'').length === 0) {
      e.preventDefault();
      return;
    }
    if (!selectedAddedTextKey) {
      e.preventDefault();
      return;
    }
    if (!nameCustomField) {
      onSubmitEditTextInput(selectedAddedTextKey, e, functionTab);
      setIsEdit(false);
      return;
    }
    if (!nameCustomField.requireUniqueValue) {
      onSubmitEditTextInput(selectedAddedTextKey, e, functionTab);
      setIsEdit(false);
      return;
    }
    const foundedSameText = addedNameInitialItems.find((itemKey) => addedTextItems[itemKey].text === value);
    if (foundedSameText) {
      e.preventDefault();
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div id="jako-configurator-3d-wc" className="jako w-full w-[200px] mx-auto p-0 bg-white md:w-full md:w-[400px]">
              <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                { t('configurator_3d_require_unique_value') }
              </h5>
              <button className="button_primary !bg-theme-primary" onClick={onClose}>
                { t('configurator_3d_ok') }
              </button>
            </div>
          );
        }
      });
      setIsEdit(false);
    } else {
      onSubmitEditTextInput(selectedAddedTextKey, e, functionTab);
      setIsEdit(false);
      e.preventDefault();
    }
  }

  function handleClickEditBtn(selectText?: string) {
    if (!selectText) {
      setIsEdit((prevState) => !prevState);
      setTimeout(() => {
        showEditTextInputRef.current?.focus();
      }, 100);
      return;
    }

    onSelectText(selectText);
    setTimeout(() => {
      setIsEdit((prevState) => !prevState);
      setTimeout(() => {
        showEditTextInputRef.current?.focus();
      }, 100);
    }, 200);
  }

  function onSetSelectTab(tab: NameTabType) {
    setSelectTab(tab);
    if (tab === NameTabType.Initial) {
      onSetTabName(FunctionTab.Initial);
    } else if (tab === NameTabType.Name) {
      onSetTabName(FunctionTab.Name);
    }
  }

  function handleOnChangePositionName(name: string, positionKey: NameInitialAddPosition, isInit?: boolean) {
    setSelectedPosition({
      ...selectedPosition,
      [name]: NAME_ADD_POSITION[positionKey].position
    });
    onSelectAddNamePosition(NAME_ADD_POSITION[positionKey].jerseySection, NAME_ADD_POSITION[positionKey].position, true);
    onChangePosition(name, positionKey, NAME_ADD_POSITION[positionKey].jerseySection, isInit);
  }

  function handleOnChangePositionInitial(name: string, positionKey: NameInitialAddPosition, isInit?: boolean) {
    setSelectedPosition({
      ...selectedPosition,
      [name]: INITIAL_ADD_POSITION[positionKey].position
    });
    onSelectAddNamePosition(INITIAL_ADD_POSITION[positionKey].jerseySection, INITIAL_ADD_POSITION[positionKey].position, true);
    onChangePosition(name, positionKey, INITIAL_ADD_POSITION[positionKey].jerseySection, isInit);
  }

  function onScrollContainerUpdate(el: any) {
    if (el.clientHeight + el.scrollTop >= el.scrollHeight) {
      setHasMoreScrollContainer(false);
    } else {
      setHasMoreScrollContainer(true);
    }
  }

  function handleOnClickCloseMobile() {
    if (scrollBarRef.current) {
      onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
    }
    if (!selectedAddedTextKey) {
      onCloseMobile();
      return;
    }
    if (isNoPositionInput) {
      onSelectText(null);
      return;
    }
    if (selectMobileFunction === MobileFunction.Home) {
      setHasMoreScrollContainer(false);
    }
    if (selectMobileFunction !== null && selectMobileFunction !== MobileFunction.Home) {
      setSelectMobileFunction(MobileFunction.Home);
      return;
    }
    if (selectMobileFunction !== null) {
      onSelectText(null);
      return;
    }
    onCloseMobile();
  }

  function handleOnSetSelectTab(tab: NameTabType) {
    onSelectText(null);
    onSetSelectTab(tab);

    if (tab === NameTabType.Name) {
      onSetTabName(FunctionTab.Name);
    } else {
      onSetTabName(FunctionTab.Initial);
    }
  }
  return isMobile
  ? <div
    className={
      "relative w-full px-6 pt-10 pb-10 "
      // + (
      //   selectedAddedTextKey
      //     ? isNoPositionInput || selectMobileFunction === MobileFunction.Position || selectMobileFunction === MobileFunction.Font || selectMobileFunction === MobileFunction.Color
      //       ? 'h-[280px]'
      //       : 'h-[180px]'
      //     : 'h-[200px]'
      // )
    }
  >
    {
      isShowCloseButtonTopRight
        ? <button
          className="absolute top-[18px] right-[12px] z-10 text-[#141414]"
          onClick={handleOnClickCloseMobile}
        >
          <IconCross className="h-[20px]" />
        </button>
        : <></>
    }
    <div className="relative w-full pb-3 flex items-center gap-5 items-center before:absolute before:w-full before:h-[2px] before:bottom-0 before:left-0 before:bg-[#F0F0F0]">
      <button
        className={
          "relative font-semibold text-[14px] before:absolute before:w-full before:h-[2px] before:bg-[#141414] before:left-0 before:bottom-[-8px] "
          + (selectTab === NameTabType.Name ? 'text-[#141414] before:opacity-100' : 'text-[#C3C3C3] before:opacity-0')
        }
        onClick={() => handleOnSetSelectTab(NameTabType.Name)}
      >
        { t('configurator_3d_add_name') }
      </button>
      <button
        className={
          "relative font-semibold text-[14px] before:absolute before:w-full before:h-[2px] before:bg-[#141414] before:left-0 before:bottom-[-8px]  "
          + (selectTab === NameTabType.Initial ? 'text-[#141414] before:opacity-100' : 'text-[#C3C3C3] before:opacity-0')
        }
        onClick={() => handleOnSetSelectTab(NameTabType.Initial)}
      >
        { t('configurator_3d_add_initial') }
      </button>
    </div>
    {
      selectTab === NameTabType.Name
        ? <>
          <SimpleBar
            ref={scrollBarRef}
            style={{
              width: '100%',
              height: selectedAddedTextKey
                ? isNoPositionInput || selectMobileFunction === MobileFunction.Position || selectMobileFunction === MobileFunction.Font || selectMobileFunction === MobileFunction.Color
                  ? '221px'
                  : 'auto'
                : '142px'
            }}
            autoHide={false}
            onScroll={onScrollContainerUpdate}
          >
            {
              selectedAddedTextKey
                ? isNoPositionInput
                  ? <div className="grid grid-cols-2 items-center justify-items-center gap-x-5 gap-y-4 mt-10">
                    {
                     addPositionNameOptionKeys.map((addPositionKey, index) => {
                        const position = addPositionKey as NameInitialAddPosition;
                        return <button
                          key={'OPEN_NAME_ADD_POSITION_INITIAL' + selectedAddedTextKey + index}
                          className="bg-white group flex flex-col items-center w-[125px]"
                          onClick={() => handleOnChangePositionName(selectedAddedTextKey, position, true)}
                        >
                          <p className="font-semibold text-[#141414] mb-3 text-[12px] whitespace-nowrap">
                            { t(NAME_ADD_POSITION[position].label) }
                          </p>
                          <div
                            className={
                              "relative w-full h-[150px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                              + (selectedPosition[selectedAddedTextKey] === NAME_ADD_POSITION[position].position ? ' !border-b-2 !border-b-[#008AC9]' : '')
                            }
                          >
                            <img
                              src={NAME_ADD_POSITION[position].thumbnail}
                              alt=""
                              className="absolute !w-full h-[220px] object-cover"
                              style={{
                                objectPosition: 'center ' + NAME_ADD_POSITION[position].thumbnailYPostionOption
                              }}
                            />
                          </div>
                        </button>;
                      })
                    }
                  </div>
                  : <>
                  {
                    selectMobileFunction === MobileFunction.Home
                      ? <div className="mt-5">
                        <div className="flex items-center justify-between mb-5">
                          {
                            isEdit
                            ? <form onSubmit={(event) => handleOnSubmitEditName(event, FunctionTab.Name)} className="w-full form-group space--between">
                              <div className="inputInlineBtn saveInput">
                                <input
                                  ref={showEditTextInputRef}
                                  type="text"
                                  className="text"
                                  id="input_text"
                                  placeholder={inputPlaceholder}
                                  defaultValue={addedTextItems[selectedAddedTextKey].text}
                                  maxLength={nameCustomField?.maxLength}
                                />
                              </div>
                              <button type="submit" className="iconButton !text-sm">
                                { t('configurator_3d_save_edit_text') }
                              </button>
                            </form>
                            : <div className="flex items-center gap-4 max-w-[80%]">
                              <p
                                className="font-bold text-[16px] truncate"
                                style={{
                                  maxWidth: 'calc(100% - 16px)'
                                }}
                              >
                                { addedTextItems[selectedAddedTextKey].text }
                              </p>
                              <button className="hover:text-theme-primary" onClick={() => setIsEdit(true)}>
                                <i className="w-[16px] h-[16px]">
                                  <IconPen className="w-[16px] h-[16px]" />
                                </i>
                              </button>
                            </div>
                          }
                          {
                            isEdit
                            ? <></>
                            : <div className="flex gap-5">
                              <button
                                className="relative z-20"
                                style={{
                                  color: addedTextItems[selectedAddedTextKey].isLock ? '#008AC9' : '#141414'
                                }}
                                onClick={() => onClickTextLock(selectedAddedTextKey)}
                              >
                                <i className="w-[22px] h-[22px]">
                                  <IconLock className="w-[22px] h-[22px]" />
                                </i>
                              </button>
                              <button
                                className="relative z-20"
                                onClick={() => onClickDeleteText(selectedAddedTextKey)}
                              >
                                <i className="w-[20px] h-[20px] text-[#141414]">
                                  <IconTrashCan className="w-[20px] h-[20px]" />
                                </i>
                              </button>
                            </div>
                          }
                        </div>
                        <div className="grid grid-cols-6 justify-center overflow-hidden">
                          <button className="flex flex-col items-center group" onClick={() => setSelectMobileFunction(MobileFunction.Position)}>
                            <div className="p-2">
                              <FontAwesomeIcon icon={faArrowsUpDownLeftRight} className="h-[25px] text-[#141414]" />
                            </div>
                            <span className="text-[11px] whitespace-nowrap font-medium text-[#141414]">
                              { t('configurator_3d_position') }
                            </span>
                          </button>
                          <button className="flex flex-col items-center group" onClick={() => setSelectMobileFunction(MobileFunction.Size)}>
                            <div className="p-2">
                              <FontAwesomeIcon icon={faArrowsMaximize} className="h-[25px] text-[#141414]" />
                            </div>
                            <span className="text-[11px] whitespace-nowrap font-medium text-[#141414]">
                              { t('configurator_3d_size') }
                            </span>
                          </button>
                          <button className="flex flex-col items-center group" onClick={() => onSendTextToFront(selectedAddedTextKey)}>
                            <div className="p-2">
                              <FontAwesomeIcon icon={faBringForward} className="h-[25px] text-[#141414]" />
                            </div>
                            <span className="text-[11px] whitespace-nowrap font-medium text-[#141414]">
                              { t('configurator_3d_foreground') }
                            </span>
                          </button>
                          <button className="flex flex-col items-center group" onClick={() => onSendTextToBack(selectedAddedTextKey)}>
                            <div className="p-2">
                              <FontAwesomeIcon icon={faSendBackward} className="h-[25px] text-[#141414]" />
                            </div>
                            <span className="text-[11px] whitespace-nowrap font-medium text-[#141414]">
                              { t('configurator_3d_background') }
                            </span>
                          </button>
                          <button className="flex flex-col items-center group" onClick={() => setSelectMobileFunction(MobileFunction.Font)}>
                            <div className="p-2">
                              <FontAwesomeIcon icon={faFontCase} className="h-[25px] text-[#141414]" />
                            </div>
                            <span className="text-[11px] whitespace-nowrap font-medium text-[#141414]">
                              { t('configurator_3d_font_mobile') }
                            </span>
                          </button>
                          <button className="flex flex-col items-center group" onClick={() => setSelectMobileFunction(MobileFunction.Color)}>
                            <div className="relative w-[37px] h-[37px] min-w-[37px] border border-[#141414]">
                              <div
                                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[27px] h-[27px]"
                                style={{
                                  backgroundColor: addedTextItems[selectedAddedTextKey].fill
                                }}
                              />
                            </div>
                          <span className="text-[11px] whitespace-nowrap font-medium text-[#141414]">
                              { t('configurator_3d_color') }
                            </span>
                          </button>
                        </div>
                        <div className="w-full flex justify-end gap-4 mt-5">
                          <i className="relative top-px w-[14px] h-[14px] text-[#FF6C00]">
                            <IconInfo className="w-[14px] h-[14px]" />
                          </i>
                          <p className="relative z-20 text-[11px] text-[#FF6C00]">
                            { t('configurator_3d_size_info_text')}
                          </p>
                        </div>
                      </div>
                      : <></>
                  }
                  {
                    selectMobileFunction === MobileFunction.Position
                      ? <div>
                        <p className="text-[#141414] text-[14px] font-semibold text-center mt-5">
                          { t('configurator_3d_choose_position') }
                        </p>
                        <div className="grid grid-cols-2 items-center justify-items-center gap-x-5 gap-y-4 mt-5">
                          {
                            addPositionNameOptionKeys.map((addPositionKey, index) => {
                              const position = addPositionKey as NameInitialAddPosition;
                              return <button
                                key={'OPEN_NAME_ADD_POSITION' + selectedAddedTextKey + index}
                                className="bg-white group flex flex-col items-center w-[125px]"
                                onClick={() => handleOnChangePositionName(selectedAddedTextKey, position)}
                              >
                                <p className="font-semibold text-[#141414] mb-3 text-[12px] whitespace-nowrap">
                                  { t(NAME_ADD_POSITION[position].label) }
                                </p>
                                <div
                                  className={
                                    "relative w-full h-[150px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                                    + (selectedPosition[selectedAddedTextKey] === NAME_ADD_POSITION[position].position ? ' !border-b-2 !border-b-[#008AC9]' : '')
                                  }
                                >
                                  <img
                                    src={NAME_ADD_POSITION[position].thumbnail}
                                    alt=""
                                    className="absolute !w-full h-[220px] object-cover"
                                    style={{
                                      objectPosition: 'center ' + NAME_ADD_POSITION[position].thumbnailYPostionOption
                                    }}
                                  />
                                </div>
                              </button>;
                            })
                          }
                        </div>
                      </div>
                      : <></>
                  }
                  {
                    selectMobileFunction === MobileFunction.Size
                      ? <div className="mt-10">
                        <div className="flex items-center justify-around">
                          <div className="flex flex-col items-center">
                            <p className="text-[14px] mb-5">
                              { t('configurator_3d_font_size') } { t('configurator_3d_height_cm') }
                            </p>
                            <div className="quantity quantitySizes">
                              <div className="spinner_wrapper">
                                <div
                                  className="spinner_down"
                                  onClick={() => handleOnDecreaseFontSize('height')}
                                >
                                  <FontAwesomeIcon icon={faMinusRegular} className="icon" />
                                </div>
                                {/* // 1cm = 37.79527559055118px */}
                                <input
                                  ref={inputHeightRef}
                                  defaultValue={Math.round((addedTextItems[selectedAddedTextKey].scaledHeight) * 10) / 10}
                                  className="input_quantity_line"
                                  name="input_item_quantity_sizes_"
                                  id="input_item_quantity_sizes_"
                                  inputMode="numeric" pattern="[0-9]*" type="number"
                                  onFocus={(e) => e.target.select()}
                                  onChange={(event) => handleOnChangeHeightFontSize(event, addedTextItems[selectedAddedTextKey].fontSize)}
                                />
                                <div
                                  className="spinner_up"
                                  onClick={() => handleOnIncreaseFontSize('height')}
                                >
                                  <FontAwesomeIcon icon={faPlusRegular} className="icon" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col items-center">
                            <p className="text-[14px] mb-5">
                              { t('configurator_3d_font_size') } { t('configurator_3d_width_cm') }
                            </p>
                            <div className="quantity quantitySizes">
                              <div className="spinner_wrapper">
                                <div
                                  className="spinner_down"
                                  onClick={() => handleOnDecreaseFontSize('width')}
                                >
                                  <FontAwesomeIcon icon={faMinusRegular} className="icon" />
                                </div>
                                {/* // 1cm = 37.79527559055118px */}
                                <input
                                  ref={inputWidthRef}
                                  defaultValue={Math.round((addedTextItems[selectedAddedTextKey].scaledWidth) * 10) / 10}
                                  className="input_quantity_line"
                                  name="input_item_quantity_sizes_"
                                  id="input_item_quantity_sizes_"
                                  inputMode="numeric" pattern="[0-9]*" type="number"
                                  onFocus={(e) => e.target.select()}
                                  onChange={(e) => handleOnChangeWidthFontSize(e, addedTextItems[selectedAddedTextKey].fontSize)}
                                />
                                <div
                                  className="spinner_up"
                                  onClick={() => handleOnIncreaseFontSize('width')}
                                >
                                  <FontAwesomeIcon icon={faPlusRegular} className="icon" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      : <></>
                  }
                  {
                    selectMobileFunction === MobileFunction.Font
                      ? <div className="mt-10 pr-[16px]">
                          <div className="itemsWrapper--1col">
                            {
                              FONT_OPTIONS.map((font, index) =>
                                <div
                                  key={'fontOption' + index + font.src}
                                  className={
                                    "itemTemplate "
                                    + (font.fontFamily === addedTextItems[selectedAddedTextKey].fontFamily ? 'active' : '')
                                  }
                                  onClick={() => onChangeFontFamily(selectedAddedTextKey, font.fontFamily)}
                                >
                                  <div className="itemTemplate__description" data-value={font.fontFamily}>
                                    <span
                                      style={{
                                        fontFamily: font.fontFamily
                                      }}
                                    >
                                      {font.fontFamily}
                                    </span>
                                  </div>
                                </div>
                              )
                            }
                          </div>
                        </div>
                        : <></>
                  }
                  {
                    selectMobileFunction === MobileFunction.Color
                    ? <div className="pr-[16px]">
                        <div className="colorWrapper colorWrapper--responsive !pr-0">
                          {
                            colorList.map((itemColor, index) =>
                              <div
                                key={'colorMore' + itemColor.colorHexCode + index + itemColor.description}
                                className={
                                  "itemColor "
                                  + (addedTextItems[selectedAddedTextKey].fill === '#' + itemColor.colorHexCode ? 'active' : '')
                                }
                                onClick={() => onChangeTextColor('#' + itemColor.colorHexCode)}
                              >
                                <div
                                  className="itemColor--bgColor tooltip"
                                  style={{ backgroundColor: '#' + itemColor.colorHexCode }}
                                />
                              </div>
                            )
                          }
                        </div>
                      </div>
                      : <></>
                  }
                </>
                : addedNameNameItems.length
                  ? <div className="flex flex-col gap-5">
                    <form onSubmit={(event) => handleOnSubmitNameInput(event, FunctionTab.Name)} className="form-group space--between mt-10">
                      <div className="inputInlineBtn saveInput">
                        <input
                          ref={showAddTextInputRef}
                          type="text"
                          className="text"
                          id="input_name"
                          placeholder={inputPlaceholder}
                          maxLength={nameCustomField ? nameCustomField.maxLength : 30}
                        />
                      </div>
                      <button type="submit" className="iconButton add">
                        <FontAwesomeIcon icon={faPlus} className="icon h-[20px]" />
                      </button>
                    </form>
                    {
                      addedNameNameItems.map((itemKey, index: number) =>
                        <div key={'addedNameObjMobile' + itemKey + addedTextItems[itemKey].text + index}>
                          <form onClick={() => handleOnSelectText(itemKey)} className="form-group space--between border-b border-[#F0F0F0]">
                            <div className="inputInlineBtn saveInput">
                              <input
                                className="text pointer-events-none"
                                id="input_text"
                                placeholder={inputPlaceholder}
                                defaultValue={addedTextItems[itemKey].text}
                                maxLength={nameCustomField?.maxLength}
                              />
                            </div>
                            <button
                              type="button"
                              className="relative iconButton z-20 text-[20px] text-[#141414]"
                              onClick={() => onClickDeleteText(itemKey)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </form>
                        </div>
                      )
                    }
                  </div>
                  : <form onSubmit={(event) => handleOnSubmitNameInput(event, FunctionTab.Name)} className="form-group space--between mt-10">
                    <div className="inputInlineBtn saveInput">
                      <input
                        ref={showAddTextInputRef}
                        type="text"
                        className="text"
                        id="input_name"
                        placeholder={inputPlaceholder}
                        maxLength={nameCustomField ? nameCustomField.maxLength : 30}
                      />
                    </div>
                    <button type="submit" className="iconButton add">
                      <FontAwesomeIcon icon={faPlus} className="icon h-[20px]" />
                    </button>
                  </form>
            }
          </SimpleBar>
        </>
        : <>
          <SimpleBar
            ref={scrollBarRef}
            style={{
              width: '100%',
              height: selectedAddedTextKey
                ? isNoPositionInput || selectMobileFunction === MobileFunction.Position || selectMobileFunction === MobileFunction.Font || selectMobileFunction === MobileFunction.Color
                  ? '221px'
                  : 'auto'
                : '142px'
            }}
            autoHide={false}
            onScroll={onScrollContainerUpdate}
          >
          {
            selectedAddedTextKey
              ? isNoPositionInput
                ? <div className="grid grid-cols-2 items-center justify-items-center gap-x-5 gap-y-4 mt-10 pr-[16px]">
                  {
                    addPositionInitialOptionKeys.map((addPositionKey, index) => {
                      const position = addPositionKey as NameInitialAddPosition;
                      return <button
                        key={'OPEN_INITIAL_ADD_POSITION_INITIAL' + selectedAddedTextKey + index}
                        className="bg-white group flex flex-col items-center w-[125px]"
                        onClick={() => handleOnChangePositionInitial(selectedAddedTextKey, position, true)}
                      >
                        <p className="font-semibold text-[#141414] mb-3 text-[12px] whitespace-nowrap">
                          { t(INITIAL_ADD_POSITION[position].label) }
                        </p>
                        <div
                          className={
                            "relative w-full h-[150px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                            + (selectedPosition[selectedAddedTextKey] === INITIAL_ADD_POSITION[position].position ? ' !border-b-2 !border-b-[#008AC9]' : '')
                          }
                        >
                          <img
                            src={INITIAL_ADD_POSITION[position].thumbnail}
                            alt=""
                            className="absolute !w-full h-[220px] object-cover"
                            style={{
                              objectPosition: 'center ' + INITIAL_ADD_POSITION[position].thumbnailYPostionOption
                            }}
                          />
                        </div>
                      </button>;
                    })
                  }
                </div>
                : <>
                {
                  selectMobileFunction === MobileFunction.Home
                    ? <div className="mt-5">
                      <div className="flex items-center justify-between mb-5">
                        {
                          isEdit
                          ? <form onSubmit={(event) => handleOnSubmitEditName(event, FunctionTab.Initial)} className="w-full form-group space--between">
                            <div className="inputInlineBtn saveInput">
                              <input
                                ref={showEditTextInputRef}
                                type="text"
                                className="text"
                                id="input_text"
                                placeholder={inputPlaceholder}
                                defaultValue={addedTextItems[selectedAddedTextKey].text}
                                maxLength={nameCustomField?.maxLength}
                              />
                            </div>
                            <button type="submit" className="iconButton !text-sm">
                              { t('configurator_3d_save_edit_text') }
                            </button>
                          </form>
                          : <div className="flex items-center gap-4 max-w-[80%]">
                            <p
                              className="font-bold text-[16px] truncate"
                              style={{
                                maxWidth: 'calc(100% - 16px)'
                              }}
                            >
                              { addedTextItems[selectedAddedTextKey].text }
                            </p>
                            <button className="hover:text-theme-primary" onClick={() => setIsEdit(true)}>
                              <i className="w-[16px] h-[16px]">
                                <IconPen className="w-[16px] h-[16px]" />
                              </i>
                            </button>
                          </div>
                        }
                        {
                          isEdit
                          ? <></>
                          : <div className="flex gap-5">
                            <button
                              className="relative z-20"
                              style={{
                                color: addedTextItems[selectedAddedTextKey].isLock ? '#008AC9' : '#141414'
                              }}
                              onClick={() => onClickTextLock(selectedAddedTextKey)}
                            >
                              <i className="w-[22px] h-[22px]">
                                <IconLock className="w-[22px] h-[22px]" />
                              </i>
                            </button>
                            <button
                              className="relative z-20"
                              onClick={() => onClickDeleteText(selectedAddedTextKey)}
                            >
                              <i className="w-[20px] h-[20px] text-[#141414]">
                                <IconTrashCan className="w-[20px] h-[20px]" />
                              </i>
                            </button>
                          </div>
                        }
                      </div>
                      <div className="grid grid-cols-6 justify-center overflow-hidden">
                        <button className="flex flex-col items-center group" onClick={() => setSelectMobileFunction(MobileFunction.Position)}>
                          <div className="p-2">
                            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} className="h-[25px] text-[#141414]" />
                          </div>
                          <span className="text-[11px] whitespace-nowrap font-medium text-[#141414]">
                            { t('configurator_3d_position') }
                          </span>
                        </button>
                        <button className="flex flex-col items-center group" onClick={() => setSelectMobileFunction(MobileFunction.Size)}>
                          <div className="p-2">
                            <FontAwesomeIcon icon={faArrowsMaximize} className="h-[25px] text-[#141414]" />
                          </div>
                          <span className="text-[11px] whitespace-nowrap font-medium text-[#141414]">
                            { t('configurator_3d_size') }
                          </span>
                        </button>
                        <button className="flex flex-col items-center group" onClick={() => onSendTextToFront(selectedAddedTextKey)}>
                          <div className="p-2">
                            <FontAwesomeIcon icon={faBringForward} className="h-[25px] text-[#141414]" />
                          </div>
                          <span className="text-[11px] whitespace-nowrap font-medium text-[#141414]">
                            { t('configurator_3d_foreground') }
                          </span>
                        </button>
                        <button className="flex flex-col items-center group" onClick={() => onSendTextToBack(selectedAddedTextKey)}>
                          <div className="p-2">
                            <FontAwesomeIcon icon={faSendBackward} className="h-[25px] text-[#141414]" />
                          </div>
                          <span className="text-[11px] whitespace-nowrap font-medium text-[#141414]">
                            { t('configurator_3d_background') }
                          </span>
                        </button>
                        <button className="flex flex-col items-center group" onClick={() => setSelectMobileFunction(MobileFunction.Font)}>
                          <div className="p-2">
                            <FontAwesomeIcon icon={faFontCase} className="h-[25px] text-[#141414]" />
                          </div>
                          <span className="text-[11px] whitespace-nowrap font-medium text-[#141414]">
                            { t('configurator_3d_font_mobile') }
                          </span>
                        </button>
                        <button className="flex flex-col items-center group" onClick={() => setSelectMobileFunction(MobileFunction.Color)}>
                          <div className="relative w-[37px] h-[37px] min-w-[37px] border border-[#141414]">
                            <div
                              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[27px] h-[27px]"
                              style={{
                                backgroundColor: addedTextItems[selectedAddedTextKey].fill
                              }}
                            />
                          </div>
                        <span className="text-[11px] whitespace-nowrap font-medium text-[#141414]">
                            { t('configurator_3d_color') }
                          </span>
                        </button>
                      </div>
                      <div className="w-full flex justify-end gap-4 mt-5">
                        <i className="relative top-px w-[14px] h-[14px] text-[#FF6C00]">
                          <IconInfo className="w-[14px] h-[14px]" />
                        </i>
                        <p className="relative z-20 text-[11px] text-[#FF6C00]">
                          { t('configurator_3d_size_info_text')}
                        </p>
                      </div>
                    </div>
                    : <></>
                }
                {
                  selectMobileFunction === MobileFunction.Position
                    ? <div>
                      <p className="text-[#141414] text-[14px] font-semibold text-center mt-5">
                        { t('configurator_3d_choose_position') }
                      </p>
                      <div className="grid grid-cols-2 items-center justify-items-center gap-x-5 gap-y-4 mt-5">
                        {
                          addPositionInitialOptionKeys.map((addPositionKey, index) => {
                            const position = addPositionKey as NameInitialAddPosition;
                            return <button
                              key={'OPEN_INITIAL_ADD_POSITION' + selectedAddedTextKey + index}
                              className="bg-white group flex flex-col items-center w-[125px]"
                              onClick={() => handleOnChangePositionInitial(selectedAddedTextKey, position)}
                            >
                              <p className="font-semibold text-[#141414] mb-3 text-[12px] whitespace-nowrap">
                                { t(INITIAL_ADD_POSITION[position].label) }
                              </p>
                              <div
                                className={
                                  "relative w-full h-[150px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                                  + (selectedPosition[selectedAddedTextKey] === INITIAL_ADD_POSITION[position].position ? ' !border-b-2 !border-b-[#008AC9]' : '')
                                }
                              >
                                <img
                                  src={INITIAL_ADD_POSITION[position].thumbnail}
                                  alt=""
                                  className="absolute !w-full h-[220px] object-cover"
                                  style={{
                                    objectPosition: 'center ' + INITIAL_ADD_POSITION[position].thumbnailYPostionOption
                                  }}
                                />
                              </div>
                            </button>;
                          })
                        }
                      </div>
                    </div>
                    : <></>
                }
                {
                  selectMobileFunction === MobileFunction.Size
                    ? <div className="mt-10 pr-[16px]">
                      <div className="flex items-center justify-around">
                        <div className="flex flex-col items-center">
                          <p className="text-[14px] mb-5">
                            { t('configurator_3d_font_size') } { t('configurator_3d_height_cm') }
                          </p>
                          <div className="quantity quantitySizes">
                            <div className="spinner_wrapper">
                              <div
                                className="spinner_down"
                                onClick={() => handleOnDecreaseFontSize('height')}
                              >
                                <FontAwesomeIcon icon={faMinusRegular} className="icon" />
                              </div>
                              {/* // 1cm = 37.79527559055118px */}
                              <input
                                ref={inputHeightRef}
                                defaultValue={Math.round((addedTextItems[selectedAddedTextKey].scaledHeight) * 10) / 10}
                                className="input_quantity_line"
                                name="input_item_quantity_sizes_"
                                id="input_item_quantity_sizes_"
                                inputMode="numeric" pattern="[0-9]*" type="number"
                                onFocus={(e) => e.target.select()}
                                onChange={(event) => handleOnChangeHeightFontSize(event, addedTextItems[selectedAddedTextKey].fontSize)}
                              />
                              <div
                                className="spinner_up"
                                onClick={() => handleOnIncreaseFontSize('height')}
                              >
                                <FontAwesomeIcon icon={faPlusRegular} className="icon" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col items-center">
                          <p className="text-[14px] mb-5">
                            { t('configurator_3d_font_size') } { t('configurator_3d_width_cm') }
                          </p>
                          <div className="quantity quantitySizes">
                            <div className="spinner_wrapper">
                              <div
                                className="spinner_down"
                                onClick={() => handleOnDecreaseFontSize('width')}
                              >
                                <FontAwesomeIcon icon={faMinusRegular} className="icon" />
                              </div>
                              {/* // 1cm = 37.79527559055118px */}
                              <input
                                ref={inputWidthRef}
                                defaultValue={Math.round((addedTextItems[selectedAddedTextKey].scaledWidth) * 10) / 10}
                                className="input_quantity_line"
                                name="input_item_quantity_sizes_"
                                id="input_item_quantity_sizes_"
                                inputMode="numeric" pattern="[0-9]*" type="number"
                                onFocus={(e) => e.target.select()}
                                onChange={(e) => handleOnChangeWidthFontSize(e, addedTextItems[selectedAddedTextKey].fontSize)}
                              />
                              <div
                                className="spinner_up"
                                onClick={() => handleOnIncreaseFontSize('width')}
                              >
                                <FontAwesomeIcon icon={faPlusRegular} className="icon" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    : <></>
                }
                {
                  selectMobileFunction === MobileFunction.Font
                    ? <div className="mt-10 pr-[16px]">
                        <div className="itemsWrapper--1col">
                          {
                            FONT_OPTIONS.map((font, index) =>
                              <div
                                key={'fontOption' + index + font.src}
                                className={
                                  "itemTemplate "
                                  + (font.fontFamily === addedTextItems[selectedAddedTextKey].fontFamily ? 'active' : '')
                                }
                                onClick={() => onChangeFontFamily(selectedAddedTextKey, font.fontFamily)}
                              >
                                <div className="itemTemplate__description" data-value={font.fontFamily}>
                                  <span
                                    style={{
                                      fontFamily: font.fontFamily
                                    }}
                                  >
                                    {font.fontFamily}
                                  </span>
                                </div>
                              </div>
                            )
                          }
                        </div>
                      </div>
                      : <></>
                }
                {
                  selectMobileFunction === MobileFunction.Color
                  ? <div className="pr-[16px]">
                      <div className="colorWrapper colorWrapper--responsive !pr-0">
                        {
                          colorList.map((itemColor, index) =>
                            <div
                              key={'colorMore' + itemColor.colorHexCode + index + itemColor.description}
                              className={
                                "itemColor "
                                + (addedTextItems[selectedAddedTextKey].fill === '#' + itemColor.colorHexCode ? 'active' : '')
                              }
                              onClick={() => onChangeTextColor('#' + itemColor.colorHexCode)}
                            >
                              <div
                                className="itemColor--bgColor tooltip"
                                style={{ backgroundColor: '#' + itemColor.colorHexCode }}
                              />
                            </div>
                          )
                        }
                      </div>
                    </div>
                    : <></>
                }
              </>
              : addedNameInitialItems.length
                ? <div className="flex flex-col gap-5">
                  <form onSubmit={(event) => handleOnSubmitInitialInput(event, FunctionTab.Initial)} className="form-group space--between mt-10">
                    <div className="inputInlineBtn saveInput">
                      <input
                        ref={showAddTextInputRef}
                        type="text"
                        className="text"
                        id="input_name"
                        placeholder={inputPlaceholder}
                        maxLength={initialCustomField ? initialCustomField.maxLength : 30}
                      />
                    </div>
                    <button type="submit" className="iconButton add">
                      <FontAwesomeIcon icon={faPlus} className="icon h-[20px]" />
                    </button>
                  </form>
                  {
                    addedNameInitialItems.map((itemKey, index: number) =>
                      <div key={'addedInitialObjMobile' + itemKey + addedTextItems[itemKey].text + index}>
                        <form onClick={() => handleOnSelectText(itemKey)} className="form-group space--between border-b border-[#F0F0F0]">
                          <div className="inputInlineBtn saveInput">
                            <input
                              className="text pointer-events-none"
                              id="input_text"
                              placeholder={inputPlaceholder}
                              defaultValue={addedTextItems[itemKey].text}
                              maxLength={initialCustomField?.maxLength}
                            />
                          </div>
                          <button
                            className="relative iconButton z-20 text-[20px] text-[#141414]"
                            onClick={() => onClickDeleteText(itemKey)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </form>
                      </div>
                    )
                  }
                </div>
                : <form onSubmit={(event) => handleOnSubmitInitialInput(event, FunctionTab.Initial)} className="form-group space--between mt-10">
                  <div className="inputInlineBtn saveInput">
                    <input
                      ref={showAddTextInputRef}
                      type="text"
                      className="text"
                      id="input_name"
                      placeholder={inputPlaceholder}
                      maxLength={initialCustomField ? initialCustomField.maxLength : 30}
                    />
                  </div>
                  <button type="submit" className="iconButton add">
                    <FontAwesomeIcon icon={faPlus} className="icon h-[20px]" />
                  </button>
                </form>
          }
        </SimpleBar>
      </>
    }
    {
      hasMoreScrollContainer
        ? <div
          className={
            "absolute w-full h-[40px] bottom-[-1px] left-0 bg-gradient-to-b from-transparent to-white transition-all duration-200"
            + (hasMoreScrollContainer ? ' opacity-100' : ' opacity-0')
          }
        />
        : <></>
    }
    </div>
    : <>
      {/* <!-- Pattern Color / Background Color --> */}
      <div className="tabs">
        <ul className="tabs__list">
          <li
            className={"tabs__li" + (selectTab === NameTabType.Name ? ' active' : '')}
            onClick={() => onSetSelectTab(NameTabType.Name)}
          >
            { t('configurator_3d_add_name') }
          </li>
          <li
            className={"tabs__li " + (selectTab === NameTabType.Initial ? ' active' : '')}
            onClick={() => onSetSelectTab(NameTabType.Initial)}
          >
            {t('configurator_3d_add_initial')}
          </li>
        </ul>
        {
          selectTab === NameTabType.Name
            ? <div
              className={
                "tabs__content mt-10 "
                + (selectTab === NameTabType.Name ? ' active' : '')
              }
            >
              <h5 className="h1 text-center">{ t('configurator_3d_add_name') }</h5>
              <p className="text-center marginBottom--small">{ t('configurator_3d_add_name_text') }</p>
              <form onSubmit={(event) => handleOnSubmitNameInput(event, FunctionTab.Name)} className="form-group space--between">
                <div className="inputInlineBtn saveInput">
                  <input
                    ref={showAddTextInputRef}
                    type="text"
                    className="text"
                    id="input_name"
                    placeholder={inputPlaceholder}
                    maxLength={nameCustomField ? nameCustomField.maxLength : 30}
                  />
                </div>
                <button type="submit" className="iconButton add">
                  <FontAwesomeIcon icon={faPlus} className="icon h-[20px]" />
                </button>
              </form>
              <div className="slideContent slideContent__borderTop">
                {
                  addedNameNameItems.length
                  ? <div className="relative py-4">
                    <div className="slideContainer !border-b-0">
                      <div className="slideContainer__headline">
                        { t('configurator_3d_added_name') }
                      </div>
                      <div className="flex-col">
                      {
                        addedNameNameItems.map((itemKey, index: number) =>
                          <div
                            id={'trigger' + itemKey}
                            key={'addedNameObj' + showOption[itemKey] + itemKey + index}
                          >
                            {
                              isEdit && showOption[itemKey]
                                ? <form onSubmit={(event) => handleOnSubmitEditName(event, FunctionTab.Name)} className="form-group space--between py-8 border-b border-[#F0F0F0]">
                                  <div className="inputInlineBtn saveInput">
                                    <input
                                      ref={showEditTextInputRef}
                                      className="text"
                                      id="input_text"
                                      placeholder={inputPlaceholder}
                                      defaultValue={addedTextItems[itemKey].text}
                                      maxLength={nameCustomField?.maxLength}
                                      onBlur={(event) => handleOnSubmitEditName(event, FunctionTab.Name)}
                                    />
                                  </div>
                                  <button type="submit" className="iconButton !text-sm">
                                    { t('configurator_3d_save_edit_text') }
                                  </button>
                                </form>
                                : <div
                                  key={'name' + addedTextItems[itemKey].text + '_' + index}
                                  className={
                                    "relative w-full flex items-center justify-between py-8 px-4 border-b"
                                    + (showOption[itemKey] ? ' border-transparent' : ' border-[#F0F0F0]')
                                  }
                                >
                                  <button
                                    className="absolute w-full h-full inset-0 z-10"
                                    onClick={() => handleOnSelectText(itemKey)}
                                  />
                                  <p key={addedTextItems[itemKey].text} className={
                                    "font-semibold"
                                    + (
                                      showOption[itemKey]
                                        ? ' text-[#008AC9]'
                                        : ''
                                    )
                                  }>
                                    {index + 1}. {addedTextItems[itemKey].text}
                                  </p>
                                  <div className="relative flex items-center gap-4 z-20">
                                    <button
                                      className="relative iconButton !text-sm z-20"
                                      onClick={() => handleClickEditBtn(itemKey)}
                                    >
                                      {t('configurator_3d_save_edit_text')}
                                    </button>
                                    <button
                                      className="relative iconButton delete z-20 text-[20px] text-[#141414]"
                                      onClick={() => onClickDeleteText(itemKey)}
                                    >
                                      <FontAwesomeIcon icon={faTrash} className="icon" />
                                    </button>
                                    <button onClick={() => handleOnSelectText(itemKey)}>
                                      <FontAwesomeIcon
                                        icon={faAngleRight}
                                        className={
                                          "icon transform transition-all duration-500"
                                          + (showOption[itemKey] ? ' rotate-90' : ' rotate-0')
                                        }
                                      />
                                    </button>
                                  </div>
                                </div>
                            }
                            {
                              selectedPosition[itemKey] === null || selectedPosition[itemKey] === undefined
                                ? <div className={showOption[itemKey] ? ' block' : ' hidden'}>
                                  {/* Add Position */}
                                  <div className="slideContainer">
                                    <div className="flex justify-between items-center">
                                      <p className="text-[#141414]">
                                        <span className="font-semibold">{t('configurator_3d_choose_position')}</span>
                                      </p>
                                      <button
                                        className="flex items-center gap-4"
                                      >
                                        <div className="relative w-[44px] h-[30px] border border-[#D4D4D4] overflow-hidden z-10">
                                          <div className="absolute top-0 left-0 w-[100px] h-[115px] object-cover bg-white" />
                                        </div>
                                        <FontAwesomeIcon icon={faAngleRight} className="transform rotate-90" />
                                      </button>
                                    </div>

                                    <div
                                      className={
                                        "slideContent__show active"
                                      }
                                    />
                                    <div className="slideContent__contentContainer mt-4">
                                      <div
                                        className={
                                          "relative border-t-2 border-[#f0f0f0]"
                                          + " before:bottom-0 before:left-0 before:w-full before:h-6 before:absolute before:z-10 before:bg-gradient-to-t before:from-white before:to-transparent"
                                        }
                                      >
                                        <div className="itemsWrapper--3cols gap-4 pt-6 pr-6 pb-8 transition-all">
                                          {
                                            addPositionNameOptionKeys.map((addPositionKey, index) => {
                                              const position = addPositionKey as NameInitialAddPosition;
                                              return <button
                                                key={'OPEN_NAME_ADD_POSITION' + itemKey + index}
                                                className="bg-white group flex flex-col min-w-[123px]"
                                                onClick={() => handleOnChangePositionName(itemKey, position, true)}
                                              >
                                                <div
                                                  className={
                                                    "relative w-full h-[110px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                                                    + (selectedPosition[itemKey] === NAME_ADD_POSITION[position].position ? ' !border-b-2 !border-b-[#008AC9]' : '')
                                                  }
                                                >
                                                  <img
                                                    src={NAME_ADD_POSITION[position].thumbnail}
                                                    alt=""
                                                    className="absolute !w-full h-auto object-cover"
                                                    style={{
                                                      objectPosition: 'center ' + NAME_ADD_POSITION[position].thumbnailYPostionOption
                                                    }}
                                                  />
                                                </div>
                                                <div className="font-semibold text-[#141414] mt-3 text-[12px]">
                                                  { t(NAME_ADD_POSITION[position].label) }
                                                </div>
                                              </button>;
                                            })
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                : <div className={showOption[itemKey] ? ' block' : ' hidden'}>
                                <div className="controlBarWrapper borderBottom">
                                  <div className="controlBar--left">
                                    <button
                                      className="icon icon-3d-lock"
                                      style={{
                                        color: addedTextItems[itemKey].isLock ? '#008AC9' : 'inherit'
                                      }}
                                      onClick={() => onClickTextLock(itemKey)}
                                    />
                                  </div>
                                </div>

                                {/* Add Position */}
                                <div className="slideContainer">
                                  <div className="flex justify-between items-center">
                                    <p className="text-[#141414]">
                                      <span className="font-semibold">{t('configurator_3d_choose_position')}</span> / {t(NAME_ADD_POSITION[selectedPosition[itemKey] || addedTextItems[itemKey].position].label)}
                                    </p>
                                    <button
                                      className="flex items-center gap-4"
                                      onClick={() => setShowAddPosition(!showAddPosition)}
                                    >
                                      <div className="relative w-[44px] h-[30px] border border-[#D4D4D4] overflow-hidden z-10">
                                        <img
                                          src={NAME_ADD_POSITION[selectedPosition[itemKey] || addedTextItems[itemKey].position].thumbnail}
                                          alt=''
                                          className="absolute top-0 left-0 w-[100px] h-[115px] object-cover"
                                          style={{
                                            objectPosition: 'center ' + NAME_ADD_POSITION[selectedPosition[itemKey] || addedTextItems[itemKey].position].thumbnailYPostionOption
                                          }}
                                        />
                                      </div>
                                      <FontAwesomeIcon
                                        icon={faAngleRight}
                                        className={
                                          "icon transform transition-all duration-500"
                                          + (showAddPosition ? ' rotate-90' : ' rotate-0')
                                        }
                                      />
                                    </button>
                                  </div>

                                  <div
                                    className={
                                      "slideContent__show"
                                      + (showAddPosition ? ' active' : '')
                                    }
                                  />
                                  <div className="slideContent__contentContainer mt-4">
                                    <div
                                      className={
                                        "relative border-t-2 border-[#f0f0f0]"
                                        + " before:bottom-0 before:left-0 before:w-full before:h-6 before:absolute before:z-10 before:bg-gradient-to-t before:from-white before:to-transparent"
                                      }
                                    >
                                      <div className="itemsWrapper--3cols gap-4 pt-6 pr-6 pb-8 transition-all">
                                        {
                                          addPositionNameOptionKeys.map((addPositionKey, index) => {
                                            const position = addPositionKey as NameInitialAddPosition;
                                            return <button
                                              key={'OPEN_NAME_ADD_POSITION' + itemKey + index}
                                              className="bg-white group flex flex-col min-w-[123px]"
                                              onClick={() => handleOnChangePositionName(itemKey, position)}
                                            >
                                              <div
                                                className={
                                                  "relative w-full h-[110px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                                                  + (selectedPosition[itemKey] === NAME_ADD_POSITION[position].position ? ' !border-b-2 !border-b-[#008AC9]' : '')
                                                }
                                              >
                                                <img
                                                  src={NAME_ADD_POSITION[position].thumbnail}
                                                  alt=""
                                                  className="absolute !w-full h-auto object-cover"
                                                  style={{
                                                    objectPosition: 'center ' + NAME_ADD_POSITION[position].thumbnailYPostionOption
                                                  }}
                                                />
                                              </div>
                                              <div className="font-semibold text-[#141414] mt-3 text-[12px]">
                                                { t(NAME_ADD_POSITION[position].label) }
                                              </div>
                                            </button>;
                                          })
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="slideContent">
                                  <div className="slideContainer">
                                    <button
                                      className="flex justify-between items-center py-4 w-full"
                                      onClick={() => setShowFontFamily(!showFontFamily)}
                                    >
                                      <p className="text-[#141414]">
                                        <span className="font-semibold">{t('configurator_3d_font')}</span> / {addedTextItems[itemKey].fontFamily}
                                      </p>
                                      <FontAwesomeIcon
                                        icon={faAngleRight}
                                        className={
                                          "icon transform transition-all duration-500"
                                          + (showFontFamily ? ' rotate-90' : ' rotate-0')
                                        }
                                      />
                                    </button>
                                    <div
                                      className={
                                        "slideContent__show slideContent__show--small z-[-1] "
                                        + (showFontFamily ? 'active' : '')
                                      }
                                    />
                                    <div className="slideContent__contentContainer">
                                      <div className="itemsWrapper--1col">
                                        {
                                          FONT_OPTIONS.map((font, index) =>
                                            <div
                                              key={'fontOption' + index + font.src}
                                              className={
                                                "itemTemplate "
                                                + (font.fontFamily === addedTextItems[itemKey].fontFamily ? 'active' : '')
                                              }
                                              onClick={() => onChangeFontFamily(itemKey, font.fontFamily)}
                                            >
                                              <div className="itemTemplate__description" data-value={font.fontFamily}>
                                                <span
                                                  style={{
                                                    fontFamily: font.fontFamily
                                                  }}
                                                >
                                                  {font.fontFamily}
                                                </span>
                                              </div>
                                            </div>
                                          )
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="slideContent">
                                  <div className="slideContainer">
                                    <div className="slideContainer__headline marginBottom--xsmall">
                                      { t('configurator_3d_choose_colors') }
                                    </div>
                                    <div className="colorWrapper">
                                      <button
                                        className="itemColor active !cursor-pointer "
                                        onClick={() => setShowMoreColor(!showMoreColor)}
                                      >
                                        <div className="itemColor--bgColor" style={{ backgroundColor: addedTextItems[itemKey].fill }} />
                                      </button>
                                    </div>
                                    <div
                                      className={
                                        "slideContent__show "
                                        + (showMoreColor ? 'active' : '')
                                      }
                                    />
                                    <div className="number-tab slideContent__contentContainer">
                                      <div className="colorWrapper">
                                        {
                                          colorList.map((itemColor, index) =>
                                            <div
                                              key={'colorMore' + itemColor.colorHexCode + index + itemColor.description}
                                              className={
                                                "itemColor "
                                                + (addedTextItems[itemKey].fill === '#' + itemColor.colorHexCode ? 'active' : '')
                                              }
                                              onClick={() => onChangeTextColor('#' + itemColor.colorHexCode)}
                                            >
                                              <div
                                                className="itemColor--bgColor tooltip"
                                                style={{ backgroundColor: '#' + itemColor.colorHexCode }}
                                                data-tooltip={itemColor.description}
                                              />
                                            </div>
                                          )
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group borderBottom fontSize space--between">
                                  <div className="form-group--headline">
                                    { t('configurator_3d_font_size') } { t('configurator_3d_width_cm') }
                                  </div>
                                  <div className="quantity quantitySizes">
                                    <div className="spinner_wrapper">
                                      <div
                                        className="spinner_down"
                                        onClick={() => handleOnDecreaseFontSize('width')}
                                      >
                                        <FontAwesomeIcon icon={faMinusRegular} className="icon" />
                                      </div>
                                      {/* // 1cm = 37.79527559055118px */}
                                      <input
                                        id={`inputQtyWidth_${itemKey}`}
                                        defaultValue={Math.round((addedTextItems[itemKey].scaledWidth) * 10) / 10}
                                        className="input_quantity_line"
                                        name="input_item_quantity_sizes_"
                                        type="number"
                                        onFocus={(e) => e.target.select()}
                                        onChange={(e) => handleOnChangeWidthFontSize(e, addedTextItems[itemKey].fontSize)}
                                      />
                                      <div
                                        className="spinner_up"
                                        onClick={() => handleOnIncreaseFontSize('width')}
                                      >
                                        <FontAwesomeIcon icon={faPlusRegular} className="icon" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group borderBottom fontSize space--between">
                                  <div className="form-group--headline">
                                    { t('configurator_3d_font_size') } { t('configurator_3d_height_cm') }
                                  </div>
                                  <div className="quantity quantitySizes">
                                    <div className="spinner_wrapper">
                                      <div
                                        className="spinner_down"
                                        onClick={() => handleOnDecreaseFontSize('height')}
                                      >
                                        <FontAwesomeIcon icon={faMinusRegular} className="icon" />
                                      </div>
                                      {/* // 1cm = 37.79527559055118px */}
                                      <input
                                        id={`inputQtyHeight_${itemKey}`}
                                        defaultValue={Math.round((addedTextItems[itemKey].scaledHeight) * 10) / 10}
                                        className="input_quantity_line"
                                        name="input_item_quantity_sizes_"
                                        type="number"
                                        onFocus={(e) => e.target.select()}
                                        onChange={(event) => handleOnChangeHeightFontSize(event, addedTextItems[itemKey].fontSize)}
                                      />
                                      <div
                                        className="spinner_up"
                                        onClick={() => handleOnIncreaseFontSize('height')}
                                      >
                                        <FontAwesomeIcon icon={faPlusRegular} className="icon" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="relative z-20 controlBarWrapper marginBottom--small">
                                  <div className="controlBar--left">
                                    <button
                                      className="icon text-[26px] p-[10px]"
                                      onClick={() => onSendTextToFront(itemKey)}
                                    >
                                      <FontAwesomeIcon icon={faBringForward} />
                                    </button>
                                    <button
                                      className="icon text-[26px] p-[10px]"
                                      onClick={() => onSendTextToBack(itemKey)}
                                    >
                                      <FontAwesomeIcon icon={faSendBackward} />
                                    </button>
                                  </div>
                                  <div className="controlBar--right">
                                    <button
                                      className="icon text-[26px] p-[10px]"
                                      onClick={() => onClickDeleteText(itemKey)}
                                    >
                                      <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                  </div>
                                </div>
                                <div className="flex gap-4">
                                  <i className="relative top-px w-[16px] h-[16px] text-[#FF6C00]">
                                    <IconInfo className="w-[16px] h-[16px]" />
                                  </i>
                                  <p className="relative z-20 text-[#FF6C00] leading-8">
                                    { t('configurator_3d_size_info_text')}
                                  </p>
                                </div>
                              </div>
                            }
                          </div>
                        )
                      }
                      </div>
                    </div>
                  </div>
                  : <></>
                }
              </div>
            </div>
            : <div
              className={
                "tabs__content mt-10 "
                + (selectTab === NameTabType.Initial ? ' active' : '')
              }
            >
              <h5 className="h1 text-center">
                { t('configurator_3d_add_initial') }
              </h5>
              <p className="text-center marginBottom--small">{ t('configurator_3d_add_initial_text') }</p>
              <form onSubmit={(event) => handleOnSubmitInitialInput(event, FunctionTab.Initial)} className="form-group space--between">
                <div className="inputInlineBtn saveInput">
                  <input
                    ref={showAddTextInputRef}
                    type="text"
                    className="text"
                    id="input_number"
                    placeholder={inputPlaceholder}
                    maxLength={initialCustomField ? initialCustomField.maxLength : 3}
                  />
                </div>
                <button type="submit" className="iconButton add">
                  <FontAwesomeIcon icon={faPlus} className="icon h-[20px]" />
                </button>
              </form>
              <div className="slideContent slideContent__borderTop">
              {
                addedNameInitialItems.length
                  ? <div className="relative py-4">
                    <div className="slideContainer !border-b-0">
                      <div className="slideContainer__headline">
                        { t('configurator_3d_added_initial') }
                      </div>
                      <div className="flex-col">
                        {
                          addedNameInitialItems.map((itemKey, index: number) =>
                            <div
                              id={'trigger' + itemKey}
                              key={'addedNameInitialObj' + showOption[itemKey] + itemKey + index}
                            >
                              {
                                isEdit && showOption[itemKey]
                                  ? <form onSubmit={(event) => handleOnSubmitEditInitial(event, FunctionTab.Initial)} className="form-group space--between">
                                    <div className="inputInlineBtn saveInput">
                                      <input
                                        ref={showEditTextInputRef}
                                        type="text"
                                        className="text"
                                        id="input_text"
                                        placeholder={inputPlaceholder}
                                        defaultValue={addedTextItems[itemKey].text}
                                        maxLength={nameCustomField?.maxLength}
                                        onBlur={(event) => handleOnSubmitEditInitial(event, FunctionTab.Initial)}
                                      />
                                    </div>
                                    <button type="submit" className="iconButton !text-sm">
                                      { t('configurator_3d_save_edit_text') }
                                    </button>
                                  </form>
                                  : <div
                                    key={'initial' + addedTextItems[itemKey].text + '_' + index}
                                    className={
                                      "relative w-full flex items-center justify-between py-8 px-4 border-b"
                                      + (showOption[itemKey] ? ' border-transparent' : ' border-[#F0F0F0]')
                                    }
                                  >
                                    <button
                                      className="absolute w-full h-full inset-0 z-10"
                                      onClick={() => handleOnSelectText(itemKey)}
                                    />
                                    <p
                                      key={addedTextItems[itemKey].text}
                                      className={
                                      "font-semibold"
                                      + (
                                        showOption[itemKey]
                                          ? ' text-[#008AC9]'
                                          : ''
                                      )
                                    }>
                                      {index + 1}. {addedTextItems[itemKey].text}
                                    </p>
                                    <div className="relative flex items-center gap-4 z-20">
                                      <button
                                        className="relative iconButton !text-sm z-20"
                                        onClick={() => handleClickEditBtn(itemKey)}
                                      >
                                        {t('configurator_3d_save_edit_text')}
                                      </button>
                                      <button
                                        className="relative iconButton delete z-20 text-[20px] text-[#141414]"
                                        onClick={() => onClickDeleteText(itemKey)}
                                      >
                                        <FontAwesomeIcon icon={faTrash} className="icon" />
                                      </button>
                                      <button onClick={() => handleOnSelectText(itemKey)}>
                                        <FontAwesomeIcon
                                          icon={faAngleRight}
                                          className={
                                            "icon transform transition-all duration-500"
                                            + (showOption[itemKey] ? ' rotate-90' : ' rotate-0')
                                          }
                                        />
                                      </button>
                                    </div>
                                  </div>
                              }

                              {
                                selectedPosition[itemKey] === null || selectedPosition[itemKey] === undefined
                                  ? <div className={showOption[itemKey] ? ' block' : ' hidden'}>
                                    {/* Add Position */}
                                    <div className="slideContainer">
                                      <div className="flex justify-between items-center">
                                        <p className="text-[#141414]">
                                          <span className="font-semibold">{t('configurator_3d_choose_position')}</span>
                                        </p>
                                        <button
                                          className="flex items-center gap-4"
                                        >
                                          <div className="relative w-[44px] h-[30px] border border-[#D4D4D4] overflow-hidden z-10">
                                            <div className="absolute top-0 left-0 w-[100px] h-[115px] object-cover" />
                                          </div>
                                          <FontAwesomeIcon
                                            icon={faAngleRight}
                                            className={
                                              "icon transform rotate-90"
                                            }
                                          />
                                        </button>
                                      </div>
                                      <div
                                        className={
                                          "slideContent__show active"
                                        }
                                      />
                                      <div className="slideContent__contentContainer mt-4">
                                        <div
                                          className={
                                            "relative border-t-2 border-[#f0f0f0]"
                                            + " before:bottom-0 before:left-0 before:w-full before:h-6 before:absolute before:z-10 before:bg-gradient-to-t before:from-white before:to-transparent"
                                          }
                                        >
                                          <div className="itemsWrapper--3cols gap-4 pt-6 pr-6 pb-8 transition-all">
                                            {
                                              addPositionInitialOptionKeys.map((addPositionKey, index) => {
                                                const position = addPositionKey as NameInitialAddPosition;
                                                return <button
                                                  key={'OPEN_INITIAL_ADD_POSITION' + itemKey + index}
                                                  className="bg-white group flex flex-col min-w-[123px]"
                                                  onClick={() => handleOnChangePositionInitial(itemKey, position, true)}
                                                >
                                                  <div
                                                    className={
                                                      "relative w-full h-[110px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                                                      + (selectedPosition[itemKey] === INITIAL_ADD_POSITION[position].position ? ' !border-b-2 !border-b-[#008AC9]' : '')
                                                    }
                                                  >
                                                    <img
                                                      src={INITIAL_ADD_POSITION[position].thumbnail}
                                                      alt=""
                                                      className="absolute !w-full h-auto object-cover"
                                                      style={{
                                                        objectPosition: 'center ' + INITIAL_ADD_POSITION[position].thumbnailYPostionOption
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="font-semibold text-[#141414] mt-3 text-[12px]">
                                                    { t(INITIAL_ADD_POSITION[position].label) }
                                                  </div>
                                                </button>;
                                              })
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  : <div className={showOption[itemKey] ? ' block' : ' hidden'}>
                                  <div className="controlBarWrapper borderBottom">
                                    <div className="controlBar--left">
                                      <button
                                        className="icon p-3"
                                        style={{
                                          color: addedTextItems[itemKey].isLock ? '#008AC9' : 'inherit'
                                        }}
                                        onClick={() => onClickTextLock(itemKey)}
                                      >
                                        <FontAwesomeIcon icon={faLock} className="h-[25px]" />
                                      </button>
                                    </div>
                                  </div>

                                  {/* Add Position */}
                                  <div className="slideContainer">
                                    <div className="flex justify-between items-center">
                                      <p className="text-[#141414]">
                                        <span className="font-semibold">{t('configurator_3d_choose_position')}</span> / {t(INITIAL_ADD_POSITION[selectedPosition[itemKey] || addedTextItems[itemKey].position].label)}
                                      </p>
                                      <button
                                        className="flex items-center gap-4"
                                        onClick={() => setShowAddPosition(!showAddPosition)}
                                      >
                                        <div className="relative w-[44px] h-[30px] border border-[#D4D4D4] overflow-hidden z-10">
                                          <img
                                            src={INITIAL_ADD_POSITION[selectedPosition[itemKey] || addedTextItems[itemKey].position].thumbnail}
                                            alt=''
                                            className="absolute top-0 left-0 w-[100px] h-[115px] object-cover"
                                            style={{
                                              objectPosition: 'center ' + INITIAL_ADD_POSITION[selectedPosition[itemKey] || addedTextItems[itemKey].position].thumbnailYPostionOption
                                            }}
                                          />
                                        </div>
                                        <FontAwesomeIcon
                                          icon={faAngleRight}
                                          className={
                                            "icon transform transition-all duration-500"
                                            + (showAddPosition ? ' rotate-90' : ' rotate-0')
                                          }
                                        />
                                      </button>
                                    </div>
                                    <div
                                      className={
                                        "slideContent__show"
                                        + (showAddPosition ? ' active' : '')
                                      }
                                    />
                                    <div className="slideContent__contentContainer mt-4">
                                      <div
                                        className={
                                          "relative border-t-2 border-[#f0f0f0]"
                                          + " before:bottom-0 before:left-0 before:w-full before:h-6 before:absolute before:z-10 before:bg-gradient-to-t before:from-white before:to-transparent"
                                        }
                                      >
                                        <div className="itemsWrapper--3cols gap-4 pt-6 pr-6 pb-8 transition-all">
                                          {
                                            addPositionInitialOptionKeys.map((addPositionKey, index) => {
                                              const position = addPositionKey as NameInitialAddPosition;
                                              return <button
                                                key={'OPEN_INITIAL_ADD_POSITION' + itemKey + index}
                                                className="bg-white group flex flex-col min-w-[123px]"
                                                onClick={() => handleOnChangePositionInitial(itemKey, position)}
                                              >
                                                <div
                                                  className={
                                                    "relative w-full h-[110px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                                                    + (selectedPosition[itemKey] === INITIAL_ADD_POSITION[position].position ? ' !border-b-2 !border-b-[#008AC9]' : '')
                                                  }
                                                >
                                                  <img
                                                    src={INITIAL_ADD_POSITION[position].thumbnail}
                                                    alt=""
                                                    className="absolute !w-full h-auto object-cover"
                                                    style={{
                                                      objectPosition: 'center ' + INITIAL_ADD_POSITION[position].thumbnailYPostionOption
                                                    }}
                                                  />
                                                </div>
                                                <div className="font-semibold text-[#141414] mt-3 text-[12px]">
                                                  { t(INITIAL_ADD_POSITION[position].label) }
                                                </div>
                                              </button>;
                                            })
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
    
                                  <div className="slideContent">
                                    <div className="slideContainer">
                                      <button
                                        className="flex justify-between items-center py-4 w-full"
                                        onClick={() => setShowFontFamily(!showFontFamily)}
                                      >
                                        <p className="text-[#141414]">
                                          <span className="font-semibold">{t('configurator_3d_font')}</span> / {addedTextItems[itemKey].fontFamily}
                                        </p>
                                        <FontAwesomeIcon
                                          icon={faAngleRight}
                                          className={
                                            "icon transform transition-all duration-500"
                                            + (showFontFamily ? ' rotate-90' : ' rotate-0')
                                          }
                                        />
                                      </button>
    
                                      <div
                                        className={
                                          "slideContent__show slideContent__show--small z-[-1] "
                                          + (showFontFamily ? 'active' : '')
                                        }
                                      />
                                      <div className="slideContent__contentContainer">
                                        <div className="itemsWrapper--1col">
                                          {
                                            FONT_OPTIONS.map((font, index) =>
                                              <div
                                                key={'fontOption' + index + font.src}
                                                className={
                                                  "itemTemplate "
                                                  + (font.fontFamily === addedTextItems[itemKey].fontFamily ? 'active' : '')
                                                }
                                                onClick={() => onChangeFontFamily(itemKey, font.fontFamily)}
                                              >
                                                <div className="itemTemplate__description" data-value={font.fontFamily}>
                                                  <span
                                                    style={{
                                                      fontFamily: font.fontFamily
                                                    }}
                                                  >
                                                    {font.fontFamily}
                                                  </span>
                                                </div>
                                              </div>
                                            )
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="slideContent">
                                    <div className="slideContainer">
                                      <div className="slideContainer__headline marginBottom--xsmall">
                                        { t('configurator_3d_choose_colors') }
                                      </div>
                                      <div className="colorWrapper">
                                        <button
                                          className="itemColor active !cursor-pointer "
                                          onClick={() => setShowMoreColor(!showMoreColor)}
                                        >
                                          <div className="itemColor--bgColor" style={{ backgroundColor: addedTextItems[itemKey].fill }} />
                                        </button>
                                      </div>
                                      <div
                                        className={
                                          "slideContent__show "
                                          + (showMoreColor ? 'active' : '')
                                        }
                                      />
                                      <div className="number-tab slideContent__contentContainer">
                                        <div className="colorWrapper">
                                          {
                                            colorList.map((itemColor, index) =>
                                              <div
                                                key={'colorMore' + itemColor.colorHexCode + index + itemColor.description}
                                                className={
                                                  "itemColor "
                                                  + (addedTextItems[itemKey].fill === '#' + itemColor.colorHexCode ? 'active' : '')
                                                }
                                                onClick={() => onChangeTextColor('#' + itemColor.colorHexCode)}
                                              >
                                                <div
                                                  className="itemColor--bgColor tooltip"
                                                  style={{ backgroundColor: '#' + itemColor.colorHexCode }}
                                                  data-tooltip={itemColor.description}
                                                />
                                              </div>
                                            )
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-group borderBottom fontSize space--between">
                                    <div className="form-group--headline">
                                      { t('configurator_3d_font_size') } { t('configurator_3d_width_cm') }
                                    </div>
                                    <div className="quantity quantitySizes">
                                      <div className="spinner_wrapper">
                                        <div
                                          className="spinner_down"
                                          onClick={() => handleOnDecreaseFontSize('width')}
                                        >
                                          <FontAwesomeIcon icon={faMinusRegular} className="icon" />
                                        </div>
                                        {/* // 1cm = 37.79527559055118px */}
                                        <input
                                          id={`inputQtyWidth_${itemKey}`}
                                          defaultValue={Math.round((addedTextItems[itemKey].scaledWidth) * 10) / 10}
                                          className="input_quantity_line"
                                          name="input_item_quantity_sizes_"
                                          type="number"
                                          onFocus={(e) => e.target.select()}
                                          onChange={(e) => handleOnChangeWidthFontSize(e, addedTextItems[itemKey].fontSize)}
                                        />
                                        <div
                                          className="spinner_up"
                                          onClick={() => handleOnIncreaseFontSize('width')}
                                        >
                                          <FontAwesomeIcon icon={faPlusRegular} className="icon" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-group borderBottom fontSize space--between">
                                    <div className="form-group--headline">
                                      { t('configurator_3d_font_size') } { t('configurator_3d_height_cm') }
                                    </div>
                                    <div className="quantity quantitySizes">
                                      <div className="spinner_wrapper">
                                        <div
                                          className="spinner_down"
                                          onClick={() => handleOnDecreaseFontSize('height')}
                                        >
                                          <FontAwesomeIcon icon={faMinusRegular} className="icon" />
                                        </div>
                                        {/* // 1cm = 37.79527559055118px */}
                                        <input
                                          id={`inputQtyHeight_${itemKey}`}
                                          defaultValue={Math.round((addedTextItems[itemKey].scaledHeight) * 10) / 10}
                                          className="input_quantity_line"
                                          name="input_item_quantity_sizes_"
                                          type="number"
                                          onFocus={(e) => e.target.select()}
                                          onChange={(event) => handleOnChangeHeightFontSize(event, addedTextItems[itemKey].fontSize)}
                                        />
                                        <div
                                          className="spinner_up"
                                          onClick={() => handleOnIncreaseFontSize('height')}
                                        >
                                          <FontAwesomeIcon icon={faPlusRegular} className="icon" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="relative z-20 controlBarWrapper marginBottom--small">
                                    <div className="controlBar--left">
                                      <button
                                        className="icon text-[26px] p-[10px]"
                                        onClick={() => onSendTextToFront(itemKey)}
                                      >
                                        <FontAwesomeIcon icon={faBringForward} />
                                      </button>
                                      <button
                                        className="icon text-[26px] p-[10px]"
                                        onClick={() => onSendTextToBack(itemKey)}
                                      >
                                        <FontAwesomeIcon icon={faSendBackward} />
                                      </button>
                                    </div>
                                    <div className="controlBar--right">
                                      <button
                                        className="icon text-[26px] p-[10px]"
                                        onClick={() => onClickDeleteText(itemKey)}
                                      >
                                        <FontAwesomeIcon icon={faTrash} />
                                      </button>
                                    </div>
                                  </div>
                                  <div className="flex gap-4">
                                    <i className="relative top-px w-[16px] h-[16px] text-[#FF6C00]">
                                      <IconInfo className="w-[16px] h-[16px]" />
                                    </i>
                                    <p className="relative z-20 text-[#FF6C00] leading-8">
                                      { t('configurator_3d_size_info_text')}
                                    </p>
                                  </div>
                                </div>
                              }
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                : <></>
              }
              </div>
            </div>
        }
      </div>

      <div className="flex justify-between mt-20">
        <button
          className="px-[27px] py-[12px] bg-[#FAFAFA] text-[#5B5B5B]"
          onClick={setSelectTabBack}
        >
          <div className="flex items-center">
            <FontAwesomeIcon
              className="h-[14px] mr-2"
              icon={faAngleLeft}
            />
            <span className='relative top-[-1px]'>{ t('configurator_3d_back') }</span>
          </div>
        </button>
        <button
          className="px-[27px] py-[12px] bg-[#008AC9] text-[#FFFFFF]"
          onClick={setSelectTabNext}
        >
          <div className="flex items-center">
            <span className='relative top-[-1px]'>{ t('configurator_3d_basket_next') }</span>
            <FontAwesomeIcon
              className="h-[14px] ml-2"
              icon={faAngleRight}
            />
          </div>
        </button>
      </div>
    </>;
});

export default JakoNameTab;

import JakoReactComponent from './webcomponent/JakoReactComponent';
import BaseStore from './contexts/BaseStore';
import "./styles/shadow.css";
import "./styles/pages/customizer-page.css";
import "./styles/root.css";
import "./styles/index.css";
//pages
export default function App() {
  return (
    <BaseStore>
      <JakoReactComponent />
    </BaseStore>
  );
}

import { useGLTF } from '@react-three/drei';
import { useThree, useLoader } from '@react-three/fiber';
import { fabric } from 'fabric';
import React, { useState, useEffect, useCallback, useMemo, useRef, forwardRef, useImperativeHandle } from 'react';
import { CanvasTexture, MeshStandardMaterial, Raycaster, FrontSide, BufferGeometry, Mesh } from 'three';
import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh';
import { EquirectangularReflectionMapping, LinearFilter } from 'three/src/constants';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import { v4 as uuidv4 } from 'uuid';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';

import {
  FunctionTab,
  JakoLogoPosition,
  JerseySection,
  ModelStyle
} from '../webcomponent/types';

import type { Transform } from 'fabric/fabric-impl';
import type {
  LogoUploadObject,
  AddedTextItems,
  AddedLogoItems,
  AddedTextPosition,
  AddedImagePosition,
  AddedPatternJerseySectionItems,
  DownloadSvgFontToBase64,
  BasicAddPosition,
  TextAddPosition,
  NameInitialAddPosition,
  NumberAddPosition,
  TeamnameAddPosition,
  TextEditorObject,
  LogoEditorObject
} from '../webcomponent/types';
import type { ConfiguratorFabricHandler, ConfiguratorFabricProps } from './types';
import { JfnetServices } from '../helpers/services';
import {
  BASE_JAKO_LOGO_HEIGHT_CM,
  MAX_TEXT_SIZE_HEIGHT_CM,
  MAX_LOGO_SIZE_HEIGHT_CM,
  DEFAULT_ENABLE_INSIDE_COLOR_PART_KEYS,
  ENABLE_INSIDE_COLOR_PART_KEYS_BY_CUTTING_CODE,
} from '../webcomponent/constants';

BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
Mesh.prototype.raycast = acceleratedRaycast;

export const ConfiguratorModelFabric = forwardRef<ConfiguratorFabricHandler, ConfiguratorFabricProps>((props, ref) => {
  const { gl, camera, mouse } = useThree();
  const { t } = useTranslation('translations');

  const { scene, nodes } = useGLTF(props.modelFile, '/draco/gltf/') as any;
  const jerseyNormalMap = useLoader(TextureLoader, props.normalMap || '');
  // const [hovered, setHover] = useState(null)
  const fabricCanvas = useRef<fabric.Canvas | undefined>();
  const fabricInsideCanvas = useRef<fabric.Canvas | undefined>();
  const canvasRef = useRef<HTMLCanvasElement >();
  const canvasInsideRef = useRef<HTMLCanvasElement >();
  const clipPathGroupRef = useRef<any>();
  const designSvgPatternObject = useRef<any>();
  const designSvgPatternInsideObject = useRef<any>();
  const designSvgPatternObjectsBounding = useRef<{
    [key: string]: any
  }>({});
  const [initialized, setInitialized] = useState(false);
  const SVG_SIZE = useMemo(() => {
    return props.isMobile ? 512 : 2048;
  }, [props.isMobile]);
  const cmFactorAdjustedRef = useRef(0);

  const jakoLogoPositionUv = useMemo(() => {
    if (props.jakoLogoPosition === JakoLogoPosition.Left) {
      return props.jakoLogoLeft;
    } else if (props.jakoLogoPosition === JakoLogoPosition.Right) {
      return props.jakoLogoRight;
    } else {
      return props.jakoLogoCenter;
    }
  }, [props.jakoLogoLeft, props.jakoLogoRight, props.jakoLogoCenter, props.jakoLogoPosition]);

  const jakoDotsUvLeft = useMemo(() => {
    return props.dotsLeft;
  }, [props.dotsLeft]);
  const jakoDotsUvRight = useMemo(() => {
    return props.dotsRight;
  }, [props.dotsRight]);
  const raycaster = useMemo(() => new Raycaster(), []);

  const centerLineColor = 'rgb(105,105,105)';
  const centerLineWidth = props.isMobile ? 2 : 5;
  const centerLineMargin = 5;
  const centerHorizontalLine = useRef<{
    startX: number,
    width: number,
    heightCenter: number,
    viewportTransform: number[] | undefined
  }>({
    startX: 0,
    width: 0,
    heightCenter: 0,
    viewportTransform: []
  });
  const centerVerticalLine = useRef<{
    startY: number,
    widthCenter: number,
    height: number,
    viewportTransform: number[] | undefined
  }>({
    startY: 0,
    widthCenter: 0,
    height: 0,
    viewportTransform: []
  });
  const frontWidthCenterMap = useRef({});
  const frontHeightCenterMap = useRef({});

  const backWidthCenterMap = useRef({});
  const backHeightCenterMap = useRef({});

  const leftWidthCenterMap = useRef({});
  const leftHeightCenterMap = useRef({});

  const rightWidthCenterMap = useRef({});
  const rightHeightCenterMap = useRef({});

  // ------ Alignment Guideline ------
  const viewportTransform = useRef<number[] | undefined>();
  const zoom = useRef<number>(1);
  const isInVerticalCenter = useRef(false);
  const isInHorizontalCenter = useRef(false);
  const verticalLines = useRef<{
    y1: number,
    y2: number,
    x: number
  }[]>([]);
  const horizontalLines = useRef<{
    x1: number,
    x2: number,
    y: number
  }[]>([]);
  const horizontalInTheRange = useRef(false);
  const verticalInTheRange = useRef(false);

  const aligningLineOffset = 15;
  const aligningLineMargin = 10;
  const aligningLineWidth = props.isMobile ? 2 : 5;
  const aligningLineColor = 'rgb(105,105,105)';
  // ------ End of Alignment Guideline ------

  useImperativeHandle(ref, () => ({
    rasterizeSVG (base64Fonts: DownloadSvgFontToBase64[]) {
      if (!fabricCanvas.current) {
        return;
      }

      const fileData = fabricCanvas.current.toSVG();
      let fontFaceText = ``;
      base64Fonts.forEach((item) => {
        fontFaceText += `
          @font-face{
            font-family: "${item.font}";
            src: url("${item.base64}");
          }
        `;
      });
      const addedCustomFont = fileData.replace('<defs>', `
      <defs>
        <style type="text/css">
          ${fontFaceText}
        </style>
      `);

      const locFile = new Blob([addedCustomFont], {type: "image/svg+xml;charset=utf-8"});
      const locFileSrc = URL.createObjectURL(locFile);
      const dwn = document.createElement('a');
      dwn.download = 'myshirt.svg';
      dwn.href = locFileSrc;
      dwn.click();
    },
    getRasterizeSVGFile() {
      if (!fabricCanvas.current) {
        return;
      }
      const fileData = fabricCanvas.current.toSVG({
        width: 2048,
        height: 2048
      });
      const locFile = new Blob([fileData], {type: "image/svg+xml;charset=utf-8"});
      // // ----- localDevelopment_testResult -----

      // const locFileSrc = URL.createObjectURL(locFile);
      // const dwn = document.createElement('a');
      // dwn.download = 'myshirt.svg';
      // dwn.href = locFileSrc;
      // dwn.click();

      // // ----- End of localDevelopment_testResult -----
      return locFile;
    },
    lockObject(textName: string) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedTextObj = fabricCanvas.current._objects.find((obj) => obj.name === textName) as fabric.Object | fabric.Text;
      if (foundedTextObj) {
        foundedTextObj.set({
          lockMovementX: true,
          lockMovementY: true,
          lockScalingX: true,
          lockScalingY: true,
          lockRotation: true,
        });
        setTimeout(() => {
          fabricCanvas.current?.requestRenderAll();
          updateCanvasTextureMap();
        }, 100);
      }
    },
    unlockObject(textName: string) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedTextObj = fabricCanvas.current._objects.find((obj) => obj.name === textName) as fabric.Object | fabric.Text;
      if (!foundedTextObj) {
        return;
      }
      foundedTextObj.set({
        selectable: true,
        lockMovementX: false,
        lockMovementY: false,
        lockScalingX: false,
        lockScalingY: false,
        lockRotation: false,
      });
      fabricCanvas.current.setActiveObject(foundedTextObj);
      setTimeout(() => {
        fabricCanvas.current?.requestRenderAll();
        updateCanvasTextureMap();
      }, 100);
    },
    deleteObject(textName: string) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedTextObj = fabricCanvas.current._objects.find((obj) => obj.name === textName) as any;
      if (!foundedTextObj) {
        return;
      }
      fabricCanvas.current.remove(foundedTextObj);
      setTimeout(() => {
        fabricCanvas.current?.requestRenderAll();
        updateCanvasTextureMap();
      }, 100);
    },
    updateTextColor(textName: string, color: string) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedTextObj = fabricCanvas.current._objects.find((obj) => obj.name === textName) as any;
      if (!foundedTextObj) {
        return;
      }
      foundedTextObj.set('selectable', true);
      foundedTextObj.set('fill', color);

      const canvas = fabricCanvas.current;
      setTimeout(() => {
        canvas.setActiveObject(foundedTextObj);
        setTimeout(() => {
          canvas.requestRenderAll();
          updateCanvasTextureMap();
        }, 100);
      }, 100);
    },
    updateTextFontFamily(textName: string, font: string) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedTextObj = fabricCanvas.current._objects.find((obj) => obj.name === textName) as any;
      if (!foundedTextObj) {
        return;
      }
      foundedTextObj.set('selectable', true);
      foundedTextObj.set('fontFamily', font);

      const canvas = fabricCanvas.current;
      setTimeout(() => {
        canvas.setActiveObject(foundedTextObj);
        canvas.requestRenderAll();
        updateCanvasTextureMap();
      }, 100);
    },
    updateTextNewTextValue(name: string, textNames: string[], textNewValue: string, reference: string) {
      if (!fabricCanvas.current) {
        return Promise.resolve([]);
      }
      const foundedSelectedTextObj = fabricCanvas.current._objects.find((obj) => obj.name === name) as any;
      const getTextWithOutCurrentNameOfReplaceItems = textNames.slice().filter((itemKey) => itemKey !== name);
      const loadAndSetNewTextPromises: Promise<{
        itemName: string,
        reference: string,
        scaledHeight: number,
        scaledWidth: number,
      }>[] =
        getTextWithOutCurrentNameOfReplaceItems
          .map((textItemKey: any) => new Promise((resolve, reject) => {
            if (!fabricCanvas.current) {
              reject();
              return;
            }

            const foundedTextObjOfReplaceItem = fabricCanvas.current._objects.find((obj) => obj.name === textItemKey) as any;
            // if they are using the same font family
            if (foundedSelectedTextObj && foundedSelectedTextObj.fontFamily === foundedTextObjOfReplaceItem.fontFamily) {
              JfnetServices.getLogoByReference(reference)
                .then((textImageByRefRes) => {
                  if (textImageByRefRes.data) {
                    let svgTextString = textImageByRefRes.data;
                    if (svgTextString.slice(0, 10).includes('<?xml')) {
                      svgTextString = svgTextString.substring(svgTextString.indexOf('<svg')+1);
                      svgTextString = '<' + svgTextString;
                    }
                    const onlySvgString = svgTextString.substring(svgTextString.indexOf('<svg'));
                    const formattedSvgString = onlySvgString.includes('<image')
                      ? onlySvgString.replaceAll(/[\u{0080}-\u{FFFF}\r\n]/gu, ' ')
                      : onlySvgString.replaceAll(/[\u{0080}-\u{FFFF}\r\n]/gu, ' ');
                    fabric.loadSVGFromString(
                      formattedSvgString,
                      function (objects) {
                        const svgImage = fabric.util.groupSVGElements(objects, {
                          left: foundedTextObjOfReplaceItem.left,
                          top: foundedTextObjOfReplaceItem.top,
                          selectable: true,
                          centeredScaling: true,
                          cornerStyle: 'circle',
                          transparentCorners: false,
                          snapAngle: 90,
                          snapThreshold: 5,
                          editable: false,
                          name: foundedTextObjOfReplaceItem.name,
                          noScaleCache: true,
                          lockUniScaling: true,
                          objectCaching: false,
                          padding: 0,
                          caching: false,
                          originX: 'center',
                          originY: 'center',
                        }) as any;

                        svgImage.name = foundedTextObjOfReplaceItem.name;
                        svgImage.left = foundedTextObjOfReplaceItem.left;
                        svgImage.top = foundedTextObjOfReplaceItem.top;
                        svgImage.originX = 'center';
                        svgImage.originY = 'center';
                        svgImage.centeredScaling = true;
                        svgImage.lockUniScaling = true;
                        svgImage.snapAngle = 90;
                        svgImage.snapThreshold = 5;
                        svgImage.noScaleCache = true;
                        svgImage.objectCaching = false;
                        svgImage.fontFamily = foundedTextObjOfReplaceItem.fontFamily;
                        svgImage.fill = foundedTextObjOfReplaceItem.fill;
                        svgImage.text = textNewValue;

                        svgImage.scaleToHeight(foundedTextObjOfReplaceItem.getScaledHeight());
                        svgImage.scaledWidth = svgImage.getScaledWidth() / cmFactorAdjustedRef.current;

                        fabricCanvas.current?.remove(foundedTextObjOfReplaceItem);
                        fabricCanvas.current?.add(svgImage);
                        resolve({
                          itemName: foundedTextObjOfReplaceItem.name,
                          reference: reference,
                          scaledHeight: foundedTextObjOfReplaceItem.getScaledHeight() / cmFactorAdjustedRef.current,
                          scaledWidth: svgImage.getScaledWidth() / cmFactorAdjustedRef.current
                        });
                      }
                    );
                  }
                });
            } else {
              JfnetServices.fontToSvg({
                text: textNewValue,
                font: foundedTextObjOfReplaceItem.fontFamily
              }).then((newTextFontRes) => {
                if (newTextFontRes.data) {
                  let svgTextString = newTextFontRes.data;
                  if (svgTextString.slice(0, 10).includes('<?xml')) {
                    svgTextString = svgTextString.substring(svgTextString.indexOf('<svg')+1);
                    svgTextString = '<' + svgTextString;
                  }
                  const onlySvgString = svgTextString.substring(svgTextString.indexOf('<svg'));
                  const formattedSvgString = onlySvgString.includes('<image')
                    ? onlySvgString.replaceAll(/[\u{0080}-\u{FFFF}\r\n]/gu, ' ')
                    : onlySvgString.replaceAll(/[\u{0080}-\u{FFFF}\r\n]/gu, ' ');
                  fabric.loadSVGFromString(
                    formattedSvgString,
                    function (objects) {
                      const svgImage = fabric.util.groupSVGElements(objects, {
                        left: foundedTextObjOfReplaceItem.left,
                        top: foundedTextObjOfReplaceItem.top,
                        selectable: true,
                        centeredScaling: true,
                        cornerStyle: 'circle',
                        transparentCorners: false,
                        snapAngle: 90,
                        snapThreshold: 5,
                        editable: false,
                        name: foundedTextObjOfReplaceItem.name,
                        noScaleCache: true,
                        lockUniScaling: true,
                        objectCaching: false,
                        padding: 0,
                        caching: false,
                        originX: 'center',
                        originY: 'center',
                      }) as any;

                      svgImage.name = foundedTextObjOfReplaceItem.name;
                      svgImage.left = foundedTextObjOfReplaceItem.left;
                      svgImage.top = foundedTextObjOfReplaceItem.top;
                      svgImage.originX = 'center';
                      svgImage.originY = 'center';
                      svgImage.centeredScaling = true;
                      svgImage.lockUniScaling = true;
                      svgImage.snapAngle = 90;
                      svgImage.snapThreshold = 5;
                      svgImage.noScaleCache = true;
                      svgImage.objectCaching = false;
                      svgImage.fontFamily = foundedTextObjOfReplaceItem.fontFamily;
                      svgImage.fill = foundedTextObjOfReplaceItem.fill;
                      svgImage.text = textNewValue;

                      svgImage.scaleToHeight(foundedTextObjOfReplaceItem.getScaledHeight());
                      svgImage.scaledWidth = svgImage.getScaledWidth() / cmFactorAdjustedRef.current;

                      fabricCanvas.current?.remove(foundedTextObjOfReplaceItem);
                      fabricCanvas.current?.add(svgImage);

                      const file = new File([svgTextString], 'test.svg', {
                        type: 'image/svg+xml'
                      });
                      JfnetServices.checkLogoUpload(file)
                        .then((uploadRes) => {
                          resolve({
                            itemName: foundedTextObjOfReplaceItem.name,
                            reference: uploadRes.data.reference,
                            scaledHeight: foundedTextObjOfReplaceItem.getScaledHeight() / cmFactorAdjustedRef.current,
                            scaledWidth: svgImage.getScaledWidth() / cmFactorAdjustedRef.current
                          });
                        })
                        .catch((err) => {
                          reject();
                          confirmAlert({
                            customUI: ({ onClose }) => {
                              if (err.response && err.response.status === 413) {
                                return (
                                  <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                                    <h5 className="confirm-popup-container__title">
                                      { t('configurator_3d_error_file_too_large') }
                                    </h5>
                                    <button className="button_primary" onClick={onClose}>
                                      { t('configurator_3d_ok') }
                                    </button>
                                  </div>
                                );
                              }
                              return (
                                <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                                  <h5 className="confirm-popup-container__title">
                                    { t('configurator_3d_error_something_wrong') }
                                  </h5>
                                  <button className="button_primary" onClick={onClose}>
                                    { t('configurator_3d_ok') }
                                  </button>
                                </div>
                              );
                            }
                          });
                        });
                    }
                  );
                }
              });
            }
          })
        );
      return Promise.all(loadAndSetNewTextPromises)
        .then((res) => {
          if (!fabricCanvas.current) {
            return res;
          }
          const canvas = fabricCanvas.current;
          setTimeout(() => {
            if (foundedSelectedTextObj) {
              canvas.setActiveObject(foundedSelectedTextObj);
              setTimeout(() => {
                canvas.requestRenderAll();
                updateCanvasTextureMap();
              }, 100);
            }
          }, 100);
          return res;
        });
    },
    updateTextFontSizeWidth(textName: string, width: number) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedTextObj = fabricCanvas.current._objects.find((obj) => obj.name === textName) as any;
      if (!foundedTextObj) {
        return;
      }
      foundedTextObj.clone((obj:any) => {
        let maxHeight = MAX_TEXT_SIZE_HEIGHT_CM;
        obj.scaleToWidth(width);
        if (obj.getScaledHeight() / cmFactorAdjustedRef.current > maxHeight) {
          foundedTextObj.set('selectable', true);
          foundedTextObj.scaleToHeight(maxHeight * cmFactorAdjustedRef.current);
          if (!fabricCanvas.current) {
            return;
          }
          const canvas = fabricCanvas.current;
          setTimeout(() => {
            props.onUpdateTextFontSize(textName, {
              width: foundedTextObj.getScaledWidth() / cmFactorAdjustedRef.current,
              height: foundedTextObj.getScaledHeight() / cmFactorAdjustedRef.current,
            });
            canvas.setActiveObject(foundedTextObj);
            setTimeout(() => {
              canvas.requestRenderAll();
              updateCanvasTextureMap();
            }, 100);
          }, 100);
          return;
        }

        foundedTextObj.set('selectable', true);
        foundedTextObj.scaleToWidth(width);

        if (!fabricCanvas.current) {
          return;
        }
        const canvas = fabricCanvas.current;
        setTimeout(() => {
          props.onUpdateTextFontSize(textName, {
            width: foundedTextObj.getScaledWidth() / cmFactorAdjustedRef.current,
            height: foundedTextObj.getScaledHeight() / cmFactorAdjustedRef.current,
          });
          canvas.setActiveObject(foundedTextObj);
          setTimeout(() => {
            canvas.requestRenderAll();
            updateCanvasTextureMap();
          }, 100);
        }, 100);
      });
    },
    updateTextFontSizeHeight(textName: string, height: number) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedTextObj = fabricCanvas.current._objects.find((obj) => obj.name === textName) as any;
      if (!foundedTextObj) {
        return;
      }
      foundedTextObj.clone((obj:any) => {
        let maxHeight = MAX_TEXT_SIZE_HEIGHT_CM;
        obj.scaleToHeight(height);
        if (obj.getScaledHeight() / cmFactorAdjustedRef.current > maxHeight) {
          foundedTextObj.set('selectable', true);
          foundedTextObj.scaleToHeight(maxHeight * cmFactorAdjustedRef.current);
          if (!fabricCanvas.current) {
            return;
          }
          const canvas = fabricCanvas.current;
          setTimeout(() => {
            props.onUpdateTextFontSize(textName, {
              width: foundedTextObj.getScaledWidth() / cmFactorAdjustedRef.current,
              height: foundedTextObj.getScaledHeight() / cmFactorAdjustedRef.current,
            });
            canvas.setActiveObject(foundedTextObj);
            setTimeout(() => {
              canvas.requestRenderAll();
              updateCanvasTextureMap();
            }, 100);
          }, 100);
          return;
        }

        foundedTextObj.set('selectable', true);
        foundedTextObj.scaleToHeight(height);

        if (!fabricCanvas.current) {
          return;
        }
        const canvas = fabricCanvas.current;
        setTimeout(() => {
          props.onUpdateTextFontSize(textName, {
            width: foundedTextObj.getScaledWidth() / cmFactorAdjustedRef.current,
            height: foundedTextObj.getScaledHeight() / cmFactorAdjustedRef.current,
          });
          canvas.setActiveObject(foundedTextObj);
          setTimeout(() => {
            canvas.requestRenderAll();
            updateCanvasTextureMap();
          }, 100);
        }, 100);
      });
    },
    updateLogoSizeWidth(textName: string, width: number) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedLogoObj = fabricCanvas.current._objects.find((obj) => obj.name === textName) as fabric.Object;
      if (!foundedLogoObj) {
        return;
      }
      foundedLogoObj.set('selectable', true);
      foundedLogoObj.scaleToWidth(width);
      const canvas = fabricCanvas.current;
      setTimeout(() => {
        props.onUpdateLogoSize(textName, {
          width: foundedLogoObj.getScaledWidth() / cmFactorAdjustedRef.current,
          height: foundedLogoObj.getScaledHeight() / cmFactorAdjustedRef.current,
        });
        canvas.setActiveObject(foundedLogoObj);
        setTimeout(() => {
          canvas.requestRenderAll();
          updateCanvasTextureMap();
        }, 100);
      }, 100);
    },
    updateLogoSizeHeight(textName: string, height: number) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedLogoObj = fabricCanvas.current._objects.find((obj) => obj.name === textName) as fabric.Object;
      if (!foundedLogoObj) {
        return;
      }
      foundedLogoObj.set('selectable', true);
      foundedLogoObj.scaleToHeight(height);
      const canvas = fabricCanvas.current;
      setTimeout(() => {
        props.onUpdateLogoSize(textName, {
          width: foundedLogoObj.getScaledWidth() / cmFactorAdjustedRef.current,
          height: foundedLogoObj.getScaledHeight() / cmFactorAdjustedRef.current,
        });
        canvas.setActiveObject(foundedLogoObj);
        setTimeout(() => {
          canvas.requestRenderAll();
          updateCanvasTextureMap();
        }, 100);
      }, 100);
    },
    updateLogoColor(name: string, color: string) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedLogoObj = fabricCanvas.current._objects.find((obj) => obj.name === name) as fabric.Object;
      if (!foundedLogoObj) {
        return;
      }
      foundedLogoObj.set('selectable', true);
      foundedLogoObj.set('fill', color);
      const canvas = fabricCanvas.current;
      setTimeout(() => {
        canvas.setActiveObject(foundedLogoObj);
        setTimeout(() => {
          canvas.requestRenderAll();
          updateCanvasTextureMap();
        }, 100);
      }, 100);
    },
    updateObjectPosition(
      position: string,
      name?: string,
      axis?: {
        left: number,
        top: number,
      },
    ) {
      if (!fabricCanvas.current) {
        return;
      }
      let foundedObj = fabricCanvas.current.getActiveObject() as any;
      if (name) {
        foundedObj = fabricCanvas.current._objects.find((obj) => obj.name === name) as fabric.Object;
      }
      if (!foundedObj) {
        return;
      }
      const canvas = fabricCanvas.current;
      if (position === 'left' && foundedObj.left) {
        foundedObj.left = foundedObj.left - 1;
      } else if (position === 'up' && foundedObj.top) {
        foundedObj.top = foundedObj.top - 1;
      } else if (position === 'right' && foundedObj.left) {
        foundedObj.left = foundedObj.left + 1;
      } else if (position === 'down' && foundedObj.top) {
        foundedObj.top = foundedObj.top + 1;
      }
      if (
        foundedObj.name
        && (
          position === 'left'
          || position === 'up'
          || position === 'right'
          || position === 'down'
        )
      ) {
        canvas.setActiveObject(foundedObj);
        if (foundedObj && foundedObj.fontFamily) {
          props.onObjectModified('text', foundedObj.name);
        } else if (foundedObj.name) {
          props.onObjectModified('logo', foundedObj.name);
        }
        setTimeout(() => {
          canvas.requestRenderAll();
          updateCanvasTextureMap();
        }, 100);
        return;
      }

      if (!axis) {
        return;
      }

      foundedObj.left = props.isMobile ? axis.left / 4 : axis.left;
      foundedObj.top = props.isMobile ? axis.top / 4 : axis.top;

      canvas.setActiveObject(foundedObj);
      if (foundedObj && foundedObj.fontFamily) {
        props.onObjectModified('text', foundedObj.name);
      } else if (foundedObj.name) {
        props.onObjectModified('logo', foundedObj.name);
      }

      setTimeout(() => {
        canvas.requestRenderAll();
        updateCanvasTextureMap();
      }, 100);
    },
    editTextHandler({
      textName,
      text,
      svgData,
      fontFamily
    }: {
      textName: string,
      text: string,
      svgData: string,
      fontFamily?: string,
    }) {
      return new Promise((resolve, reject) => {
        const foundedTextObj = fabricCanvas.current?._objects.find((obj) => obj.name === textName) as any;
        if (!foundedTextObj) {
          reject();
        }
        let svgTextString = svgData;
        if (svgTextString.slice(0, 10).includes('<?xml')) {
          svgTextString = svgTextString.substring(svgTextString.indexOf('<svg')+1);
          svgTextString = '<' + svgTextString;
        }
        const onlySvgString = svgTextString.substring(svgTextString.indexOf('<svg'));
        const formattedSvgString = onlySvgString.includes('<image')
          ? onlySvgString.replaceAll(/[\u{0080}-\u{FFFF}\r\n]/gu, ' ')
          : onlySvgString.replaceAll(/[\u{0080}-\u{FFFF}\r\n]/gu, ' ');
        fabric.loadSVGFromString(
          formattedSvgString,
          function (objects) {
            const svgImage = fabric.util.groupSVGElements(objects, {
              left: foundedTextObj.left,
              top: foundedTextObj.top,
              selectable: true,
              centeredScaling: true,
              cornerStyle: 'circle',
              transparentCorners: false,
              snapAngle: 90,
              snapThreshold: 5,
              editable: false,
              name: textName,
              noScaleCache: true,
              lockUniScaling: true,
              objectCaching: false,
              padding: 0,
              caching: false,
              originX: 'center',
              originY: 'center',
            }) as any;

            svgImage.name = textName;
            svgImage.left = foundedTextObj.left;
            svgImage.top = foundedTextObj.top;
            svgImage.originX = 'center';
            svgImage.originY = 'center';
            svgImage.centeredScaling = true;
            svgImage.lockUniScaling = true;
            svgImage.snapAngle = 90;
            svgImage.snapThreshold = 5;
            svgImage.noScaleCache = true;
            svgImage.objectCaching = false;
            svgImage.fontFamily =
              fontFamily
                ? fontFamily
                : foundedTextObj.fontFamily
                  ? foundedTextObj.fontFamily
                  : props.selectedFontFamily;
            svgImage.fill = foundedTextObj.fill;
            svgImage.text = text;

            svgImage.scaleToHeight(foundedTextObj.getScaledHeight());
            svgImage.scaledWidth = svgImage.getScaledWidth() / cmFactorAdjustedRef.current;

            fabricCanvas.current?.remove(foundedTextObj);
            fabricCanvas.current?.add(svgImage);
            const file = new File([svgTextString], 'test.svg', {
              type: 'image/svg+xml'
            });
            JfnetServices.checkLogoUpload(file)
              .then((uploadRes) => {
                resolve({
                  reference: uploadRes.data.reference,
                  scaledHeight: foundedTextObj.getScaledHeight() / cmFactorAdjustedRef.current,
                  scaledWidth: svgImage.getScaledWidth() / cmFactorAdjustedRef.current
                });
              })
              .catch((err) => {
                reject();
                confirmAlert({
                  customUI: ({ onClose }) => {
                    if (err.response && err.response.status === 413) {
                      return (
                        <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                          <h5 className="confirm-popup-container__title">
                            { t('configurator_3d_error_file_too_large') }
                          </h5>
                          <button className="button_primary" onClick={onClose}>
                            { t('configurator_3d_ok') }
                          </button>
                        </div>
                      );
                    }
                    return (
                      <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                        <h5 className="confirm-popup-container__title">
                          { t('configurator_3d_error_something_wrong') }
                        </h5>
                        <button className="button_primary" onClick={onClose}>
                          { t('configurator_3d_ok') }
                        </button>
                      </div>
                    );
                  }
                });
              });
          }
        );
      });
    },

    resetDefaultPosition(name: string, defaultX: number, defaultY: number) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedObj = fabricCanvas.current._objects.find((obj) => obj.name === name) as any;
      if (!foundedObj) {
        return;
      }
      const canvas = fabricCanvas.current;
      if (foundedObj.top && foundedObj.left) {
        foundedObj.top = props.isMobile ? defaultY / 4 : defaultY;
        foundedObj.left = props.isMobile ? defaultX / 4 : defaultX;
      }
      canvas.setActiveObject(foundedObj);

      if (foundedObj && foundedObj.fontFamily) {
        props.onObjectModified('text', name);
      } else if (foundedObj.name) {
        props.onObjectModified('logo', name);
      }

      setTimeout(() => {
        canvas.requestRenderAll();
        updateCanvasTextureMap();
      }, 100);
    },

    bringToFront(name: string) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedObj = fabricCanvas.current._objects.find((obj) => obj.name === name) as fabric.Object;
      if (!foundedObj) {
        return;
      }
      foundedObj.bringForward();
      const logoObj = fabricCanvas.current._objects.find((obj: any) => obj.id === 'logo_front') as fabric.Object;
      const dotsLeftObj = fabricCanvas.current._objects.find((obj: any) => obj.id === 'dots_frontleft') as fabric.Object;
      const dotsRightObj = fabricCanvas.current._objects.find((obj: any) => obj.id === 'dots_frontright') as fabric.Object;
      const uvObj = fabricCanvas.current._objects.find((obj: any) => obj.id === 'mainUv') as fabric.Object;

      if (logoObj) {
        fabricCanvas.current.sendToBack(logoObj);
      }
      if (dotsLeftObj) {
        fabricCanvas.current.sendToBack(dotsLeftObj);
      }
      if (dotsRightObj) {
        fabricCanvas.current.sendToBack(dotsRightObj);
      }
      if (uvObj) {
        fabricCanvas.current.sendToBack(uvObj);
      }
      setTimeout(() => {
        fabricCanvas.current?.requestRenderAll();
        updateCanvasTextureMap();
      }, 100);
    },
    sendToBack(name: string) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedObj = fabricCanvas.current._objects.find((obj) => obj.name === name) as fabric.Object;
      if (!foundedObj) {
        return;
      }
      foundedObj.sendBackwards();
      const logoObj = fabricCanvas.current._objects.find((obj: any) => obj.id === 'logo_front') as fabric.Object;
      const dotsLeftObj = fabricCanvas.current._objects.find((obj: any) => obj.id === 'dots_frontleft') as fabric.Object;
      const dotsRightObj = fabricCanvas.current._objects.find((obj: any) => obj.id === 'dots_frontright') as fabric.Object;
      const uvObj = fabricCanvas.current._objects.find((obj: any) => obj.id === 'mainUv') as fabric.Object;

      if (logoObj) {
        fabricCanvas.current.sendToBack(logoObj);
      }
      if (dotsLeftObj) {
        fabricCanvas.current.sendToBack(dotsLeftObj);
      }
      if (dotsRightObj) {
        fabricCanvas.current.sendToBack(dotsRightObj);
      }
      if (uvObj) {
        fabricCanvas.current.sendToBack(uvObj);
      }
      setTimeout(() => {
        fabricCanvas.current?.requestRenderAll();
        updateCanvasTextureMap();
      }, 100);
    },
    setActiveObjectHandler(obj: any) {
      const canvas = fabricCanvas.current;
      canvas?.setActiveObject(obj);
      canvas?.requestRenderAll();
      updateCanvasTextureMap();
    },
    updateCanvasTextureMap,
    scene,
    fabricCanvas,
    designSvgPatternObjectsBounding,
    designSvgPatternObject,
    initNewConfigurator,
    loadSvgDesignToFabric,
    changeColor,
    changePattern,
    getPatternConfigs,
    handleUnprocessableIconInControl
  }));

  function getMouseUvPosition() {
    raycaster.setFromCamera( mouse, camera );
    const intersects = raycaster.intersectObjects([nodes['outside']]);
    if (intersects.length < 0) {
      return null;
    }
    if (!intersects[0]) {
      return null;
    }
    if (!intersects[0].uv) {
      return null;
    }

    const getOutsideIntersect = intersects.find((item) => item.object.name === 'outside');
    let pixelX = 0;
    let pixelY = 0;

    if (getOutsideIntersect && getOutsideIntersect.uv) {
      pixelX = Math.round(getOutsideIntersect.uv.x * SVG_SIZE);
      pixelY = Math.round(getOutsideIntersect.uv.y * SVG_SIZE);
    } else {
      pixelX = Math.round(intersects[0].uv.x * SVG_SIZE);
      pixelY = Math.round(intersects[0].uv.y * SVG_SIZE);
    }

    return {
      x: pixelX,
      y: pixelY,
    };
    // eslint-disable-next-line
  };

  const onAddTextItemHandler = useCallback((
    adding: FunctionTab,
    object: fabric.Object | fabric.Group,
    reference: string,
    textSize: {
      maxHeight: number,
      // scaledWidth: number,
      // scaledHeight: number
    },
    position?: BasicAddPosition | TextAddPosition | TeamnameAddPosition | NameInitialAddPosition | NumberAddPosition
  ) => {
    props.onAddTextPosition({
      adding,
      object,
      position,
      reference,
      textSize
    });
  }, [props]);

  const onAddImageItemHandler = useCallback((
    adding: FunctionTab,
    object: fabric.Object | fabric.Group,
    imageObj: LogoUploadObject,
    logoSize: {
      maxHeight: number,
      scaledWidth: number,
      scaledHeight: number,
    },
  ) => {
    props.onAddImagePosition({
      adding,
      object,
      imageObj,
      logoSize
    });
  }, [props]);

  // ------ Centering Guideline --------
  // AlignmentGuidelines.showCenterLine()
  function showCenterLine(x1: number, y1: number, x2: number, y2: number, viewportTransform: number[] | undefined) {
    if (!canvasRef.current) {
      return;
    }
    if (!viewportTransform) {
      return;
    }
    if (viewportTransform.length === 0) {
      return;
    }

    const ctx = canvasRef.current.getContext('2d');
    if (!ctx) {
      return;
    }
    ctx.save();
    ctx.strokeStyle = centerLineColor;
    ctx.lineWidth = centerLineWidth;
    ctx.beginPath();
    ctx.setLineDash([5,5]);
    ctx.moveTo(x1 * viewportTransform[0], y1 * viewportTransform[3]);
    ctx.lineTo(x2 * viewportTransform[0], y2 * viewportTransform[3]);
    ctx.stroke();
    ctx.restore();
  }
  function showVerticalCenterLine({ startY, widthCenter, height, viewportTransform }: {
    startY: number,
    widthCenter: number,
    height: number,
    viewportTransform: number[] | undefined
  }) {
  // AlignmentGuidelines.showCenterLine()

    showCenterLine(widthCenter + 0.5, startY, widthCenter + 0.5, startY + height, viewportTransform);
  }
  function showHorizontalCenterLine({ startX, width, heightCenter, viewportTransform }: {
    startX: number,
    width: number,
    heightCenter: number,
    viewportTransform: number[] | undefined
  }) {
    showCenterLine(startX, heightCenter + 0.5, startX + width, heightCenter + 0.5, viewportTransform);
  }
  // ------ End of Centering Guideline --------

  // ------ Alignment Guideline -------
  function drawLine(x1: number, y1: number, x2: number, y2: number, viewportTransform: number[] | undefined) {
    if (!canvasRef.current) {
      return;
    }
    if (!viewportTransform) {
      return;
    }
    if (viewportTransform.length === 0) {
      return;
    }

    const ctx = canvasRef.current.getContext('2d');
    if (!ctx) {
      return;
    }

    ctx.save();
    ctx.lineWidth = aligningLineWidth;
    ctx.strokeStyle = aligningLineColor;
    ctx.beginPath();
    ctx.setLineDash([5,5]);
    ctx.moveTo(((x1 + viewportTransform[4]) * zoom.current), ((y1 + viewportTransform[5]) * zoom.current));
    ctx.lineTo(((x2 + viewportTransform[4]) * zoom.current), ((y2 + viewportTransform[5]) * zoom.current));
    ctx.stroke();
    updateCanvasTextureMap();
    ctx.restore();
  }

  function drawVerticalLine(coords: {
    y1: number,
    y2: number,
    x: number
  }) {
    if (fabricCanvas.current) {
      drawLine(
        coords.x + 0.5,
        coords.y1 > coords.y2 ? coords.y2 : coords.y1,
        coords.x + 0.5,
        coords.y2 > coords.y1 ? coords.y2 : coords.y1,
        viewportTransform.current
      );
    }
  }

  function drawHorizontalLine(coords: {
    x1: number,
    x2: number,
    y: number
  }) {
    if (fabricCanvas.current) {
      drawLine(
        coords.x1 > coords.x2 ? coords.x2 : coords.x1,
        coords.y + 0.5,
        coords.x2 > coords.x1 ? coords.x2 : coords.x1,
        coords.y + 0.5,
        viewportTransform.current
      );
    }
  }
  function isInRange(value1: number, value2: number) {
    value1 = Math.round(value1);
    value2 = Math.round(value2);
    for (let i = value1 - aligningLineMargin, len = value1 + aligningLineMargin; i <= len; i++) {
      if (i === value2) {
        return true;
      }
    }
    return false;
  }
  // ------ End of Alignment Guideline -------

  function onDocumentMouseDown(e: MouseEvent) {
    e.preventDefault();
    const mouseUvPos = getMouseUvPosition();
    if (!mouseUvPos) {
      props.onCloseMobileTab();
      fabricCanvas.current?.discardActiveObject();
      fabricCanvas.current?.requestRenderAll();
      updateCanvasTextureMap();
      return;
    }

    const hasActiveObject = fabricCanvas.current && fabricCanvas.current.getActiveObjects().length > 0;
    if (fabricCanvas.current && hasActiveObject) {
      const objects = fabricCanvas.current.getActiveObjects();
      const isStillClickOnObject = ((objects[0].oCoords?.tl.x || 0) <= mouseUvPos.x  && mouseUvPos.x <= (objects[0].oCoords?.tr.x || 0))
        && ((objects[0].oCoords?.tl.y || 0) <= mouseUvPos.y  && mouseUvPos.y <= (objects[0].oCoords?.bl.y || 0));
      if (isStillClickOnObject) {
        props.onDisableCameraControl();
      }
    } else {
      props.onEnableCameraControl();
      props.onCloseMobileTab();
    }
    // Dispatch mouse event to Fabric
    const pixelX = mouseUvPos.x;
    const pixelY = mouseUvPos.y;
    const fabricCanvasAny = fabricCanvas.current as any;
    const canvasRect = fabricCanvasAny._offset;
    const evt = new MouseEvent(e.type, {
      clientX: canvasRect.left + pixelX,
      clientY: canvasRect.top + pixelY
    });
    fabricCanvasAny.upperCanvasEl.dispatchEvent(evt);
  }

  function onDocumentTouchDown(e: TouchEvent) {
    if (e.cancelable) {
      e.preventDefault();
    }
    const shadowRootContainer = document.querySelector('jako-configurator');
    const shadowRoot = shadowRootContainer ? shadowRootContainer.shadowRoot : null;
    const mouseCursorContainer = shadowRoot ? shadowRoot.getElementById('configurator-container') : document.getElementById('configurator-container');
    if (!mouseCursorContainer) {
      return;
    }

    const mouseUvPos = getMouseUvPosition();
    if (!mouseUvPos) {
      props.onCloseMobileTab();
      fabricCanvas.current?.discardActiveObject();
      fabricCanvas.current?.requestRenderAll();
      updateCanvasTextureMap();
      return;
    }

    const hasActiveObject = fabricCanvas.current && fabricCanvas.current.getActiveObjects().length > 0;
    if (fabricCanvas.current && hasActiveObject) {
      const objects = fabricCanvas.current.getActiveObjects();
      const isStillClickOnObject = ((objects[0].oCoords?.tl.x || 0) <= mouseUvPos.x  && mouseUvPos.x <= (objects[0].oCoords?.tr.x || 0))
        && ((objects[0].oCoords?.tl.y || 0) <= mouseUvPos.y  && mouseUvPos.y <= (objects[0].oCoords?.bl.y || 0));
      if (isStillClickOnObject) {
        props.onDisableCameraControl();
      }
    } else {
      props.onEnableCameraControl();
      props.onCloseMobileTab();
    }
    // Dispatch mouse event to Fabric
    const pixelX = mouseUvPos.x;
    const pixelY = mouseUvPos.y;
    const fabricCanvasAny = fabricCanvas.current as any;
    const canvasRect = fabricCanvasAny._offset;
    const evt = new MouseEvent(e.type, {
      clientX: canvasRect.left + pixelX,
      clientY: canvasRect.top + pixelY
    });
    fabricCanvasAny.upperCanvasEl.dispatchEvent(evt);
  };

  function onDocumentTouchMove(e: TouchEvent) {
    if (e.cancelable) {
      e.preventDefault();
    }
    const shadowRootContainer = document.querySelector('jako-configurator');
    const shadowRoot = shadowRootContainer ? shadowRootContainer.shadowRoot : null;
    const mouseCursorContainer = shadowRoot ? shadowRoot.getElementById('configurator-container') : document.getElementById('configurator-container');
    if (!mouseCursorContainer) {
      return;
    }

    const mouseUvPos = getMouseUvPosition();
    if (!mouseUvPos) {
      props.onCloseMobileTab();
      fabricCanvas.current?.discardActiveObject();
      fabricCanvas.current?.requestRenderAll();
      updateCanvasTextureMap();
      return;
    }

    const hasActiveObject = fabricCanvas.current && fabricCanvas.current.getActiveObjects().length > 0;
    if (fabricCanvas.current && hasActiveObject) {
      const objects = fabricCanvas.current.getActiveObjects();
      const isStillClickOnObject = ((objects[0].oCoords?.tl.x || 0) <= mouseUvPos.x  && mouseUvPos.x <= (objects[0].oCoords?.tr.x || 0))
        && ((objects[0].oCoords?.tl.y || 0) <= mouseUvPos.y  && mouseUvPos.y <= (objects[0].oCoords?.bl.y || 0));
      if (isStillClickOnObject) {
        props.onDisableCameraControl();
      }
    } else {
      props.onEnableCameraControl();
      props.onCloseMobileTab();
    }
    // Dispatch mouse event to Fabric
    const pixelX = mouseUvPos.x;
    const pixelY = mouseUvPos.y;
    const fabricCanvasAny = fabricCanvas.current as any;
    const evt = new MouseEvent('mousemove', {
      clientX: pixelX,
      clientY: pixelY
    });
    fabricCanvasAny.upperCanvasEl.dispatchEvent(evt);
  }

  function onDocumentMouseUp(e: MouseEvent) {
    e.preventDefault();
    props.onEnableCameraControl();
  }

  function onDocumentTouchUp(e: TouchEvent) {
    if (e.cancelable) {
      e.preventDefault();
    }
    props.onEnableCameraControl();
  }

  function initDesignBoundingAndCenterMap(designObjects: any[]) {
    frontWidthCenterMap.current = {};
    frontHeightCenterMap.current = {};
    backWidthCenterMap.current = {};
    backHeightCenterMap.current = {};
    leftWidthCenterMap.current = {};
    leftHeightCenterMap.current = {};
    rightWidthCenterMap.current = {};
    rightHeightCenterMap.current = {};

    designSvgPatternObjectsBounding.current = {};

    designObjects.forEach((obj: any) => {
      if (obj.id === 'front_main') {
        designSvgPatternObjectsBounding.current = {
          ...designSvgPatternObjectsBounding.current,
          [obj.id]: {
            ...obj,
            centerPoint: obj.getCenterPoint()
          }
        };
      } else if (obj.id === 'back_main') {
        designSvgPatternObjectsBounding.current = {
          ...designSvgPatternObjectsBounding.current,
          [obj.id]: obj
        };
      } else if (obj.id === 'left_main') {
        designSvgPatternObjectsBounding.current = {
          ...designSvgPatternObjectsBounding.current,
          [obj.id]: obj
        };
      } else if (obj.id === 'right_main') {
        designSvgPatternObjectsBounding.current = {
          ...designSvgPatternObjectsBounding.current,
          [obj.id]: obj
        };
      } else if (obj.id === 'collar_main') {
        designSvgPatternObjectsBounding.current = {
          ...designSvgPatternObjectsBounding.current,
          [obj.id]: obj
        };
      }
    });

    if (props.modelStyle === ModelStyle.Short) {
      initDesignBoundingAndCenterMapForShort();
      return;
    }

    if (props.modelStyle === ModelStyle.ShirtNoSleeve) {
      initDesignBoundingAndCenterMapForShirtNoSleeve();
      return;
    }
    initDesignBoundingAndCenterMapForShirt();
    props.onSetEndEdgeSleevePos(designSvgPatternObjectsBounding.current.right_main.pathOffset.y + (designSvgPatternObjectsBounding.current.right_main.height / 2));
  }

  function delay(time: number) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  function initDesignBoundingAndCenterMapForShirt() {
    const fabricFrontObjCenterX = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.front_main.pathOffset.x / 4) : Math.round(designSvgPatternObjectsBounding.current.front_main.pathOffset.x);
    const fabricBackObjCenterX = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.back_main.pathOffset.x / 4) : Math.round(designSvgPatternObjectsBounding.current.back_main.pathOffset.x);
    const fabricLeftObjCenterX = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.x / 4) : Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.x);
    const fabricRightObjCenterX = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.x / 4) : Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.x);
    const fabricFrontObjCenterY = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.front_main.pathOffset.y / 4) : Math.round(designSvgPatternObjectsBounding.current.front_main.pathOffset.y);
    const fabricBackObjCenterY = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.back_main.pathOffset.y / 4) : Math.round(designSvgPatternObjectsBounding.current.back_main.pathOffset.y);
    const fabricLeftObjCenterY = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.y / 4) : Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.y);
    const fabricRightObjCenterY = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.y / 4) : Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.y);

    for (let i = fabricFrontObjCenterX - centerLineMargin, len = fabricFrontObjCenterX + centerLineMargin; i <= len; i++) {
      frontWidthCenterMap.current = {
        ...frontWidthCenterMap.current,
        [Math.round(i)]: true
      };
    }
    for (let i = fabricFrontObjCenterY - centerLineMargin, len = fabricFrontObjCenterY + centerLineMargin; i <= len; i++) {
      frontHeightCenterMap.current = {
        ...frontHeightCenterMap.current,
        [Math.round(i)]: true
      };
    }
    for (let i = fabricBackObjCenterX - centerLineMargin, len = fabricBackObjCenterX + centerLineMargin; i <= len; i++) {
      backWidthCenterMap.current = {
        ...backWidthCenterMap.current,
        [Math.round(i)]: true
      };
    }
    for (let i = fabricBackObjCenterY - centerLineMargin, len = fabricBackObjCenterY + centerLineMargin; i <= len; i++) {
      backHeightCenterMap.current = {
        ...backHeightCenterMap.current,
        [Math.round(i)]: true
      };
    }
    for (let i = fabricLeftObjCenterX - centerLineMargin, len = fabricLeftObjCenterX + centerLineMargin; i <= len; i++) {
      leftWidthCenterMap.current = {
        ...leftWidthCenterMap.current,
        [Math.round(i)]: true
      };
    }
    for (let i = fabricLeftObjCenterY - centerLineMargin, len = fabricLeftObjCenterY + centerLineMargin; i <= len; i++) {
      leftHeightCenterMap.current = {
        ...leftHeightCenterMap.current,
        [Math.round(i)]: true
      };
    }
    for (let i = fabricRightObjCenterX - centerLineMargin, len = fabricRightObjCenterX + centerLineMargin; i <= len; i++) {
      rightWidthCenterMap.current = {
        ...rightWidthCenterMap.current,
        [Math.round(i)]: true
      };
    }
    for (let i = fabricRightObjCenterY - centerLineMargin, len = fabricRightObjCenterY + centerLineMargin; i <= len; i++) {
      rightHeightCenterMap.current = {
        ...rightHeightCenterMap.current,
        [Math.round(i)]: true
      };
    }
  }
  function initDesignBoundingAndCenterMapForShirtNoSleeve() {
    const fabricFrontObjCenterX = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.front_main.pathOffset.x / 4) : Math.round(designSvgPatternObjectsBounding.current.front_main.pathOffset.x);
    const fabricBackObjCenterX = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.back_main.pathOffset.x / 4) : Math.round(designSvgPatternObjectsBounding.current.back_main.pathOffset.x);
    const fabricFrontObjCenterY = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.front_main.pathOffset.y / 4) : Math.round(designSvgPatternObjectsBounding.current.front_main.pathOffset.y);
    const fabricBackObjCenterY = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.back_main.pathOffset.y / 4) : Math.round(designSvgPatternObjectsBounding.current.back_main.pathOffset.y);

    for (let i = fabricFrontObjCenterX - centerLineMargin, len = fabricFrontObjCenterX + centerLineMargin; i <= len; i++) {
      frontWidthCenterMap.current = {
        ...frontWidthCenterMap.current,
        [Math.round(i)]: true
      };
    }
    for (let i = fabricFrontObjCenterY - centerLineMargin, len = fabricFrontObjCenterY + centerLineMargin; i <= len; i++) {
      frontHeightCenterMap.current = {
        ...frontHeightCenterMap.current,
        [Math.round(i)]: true
      };
    }
    for (let i = fabricBackObjCenterX - centerLineMargin, len = fabricBackObjCenterX + centerLineMargin; i <= len; i++) {
      backWidthCenterMap.current = {
        ...backWidthCenterMap.current,
        [Math.round(i)]: true
      };
    }
    for (let i = fabricBackObjCenterY - centerLineMargin, len = fabricBackObjCenterY + centerLineMargin; i <= len; i++) {
      backHeightCenterMap.current = {
        ...backHeightCenterMap.current,
        [Math.round(i)]: true
      };
    }
  }
  function initDesignBoundingAndCenterMapForShort() {
    const fabricLeftObjCenterX = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.x / 4) : Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.x);
    const fabricRightObjCenterX = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.x / 4) : Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.x);
    const fabricLeftObjCenterY = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.y / 4) : Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.y);
    const fabricRightObjCenterY = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.y / 4) : Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.y);

    for (let i = fabricLeftObjCenterX - centerLineMargin, len = fabricLeftObjCenterX + centerLineMargin; i <= len; i++) {
      leftWidthCenterMap.current = {
        ...leftWidthCenterMap.current,
        [Math.round(i)]: true
      };
    }
    for (let i = fabricLeftObjCenterY - centerLineMargin, len = fabricLeftObjCenterY + centerLineMargin; i <= len; i++) {
      leftHeightCenterMap.current = {
        ...leftHeightCenterMap.current,
        [Math.round(i)]: true
      };
    }
    for (let i = fabricRightObjCenterX - centerLineMargin, len = fabricRightObjCenterX + centerLineMargin; i <= len; i++) {
      rightWidthCenterMap.current = {
        ...rightWidthCenterMap.current,
        [Math.round(i)]: true
      };
    }
    for (let i = fabricRightObjCenterY - centerLineMargin, len = fabricRightObjCenterY + centerLineMargin; i <= len; i++) {
      rightHeightCenterMap.current = {
        ...rightHeightCenterMap.current,
        [Math.round(i)]: true
      };
    }
  }

  const loadSvgDesignToFabric = (reset?: boolean) => {
    if (!fabricCanvas.current) {
      return;
    }
    if (!props.designSvg) {
      return;
    }

    props.onSetLoading(true);
    const fileReader = new FileReader();
    fetch(props.designSvg).then((res) => {
      res.blob().then((blobRes) => {
        fileReader.readAsText(blobRes);
        fileReader.onload = () => {
          const svgFileTextResult = fileReader.result as string;
          if (!svgFileTextResult) {
            return;
          }
          prepareLoadSvgDesignFile(svgFileTextResult)
            .then((svgDesignString) => {
              setSvgDesignToFabricAndModel(svgDesignString, reset);
            });
        };
      });
    });
  };


  function prepareLoadSvgDesignFile(svgFileTextResult: string): Promise<string> {
    return new Promise((resolve) => {
      const htmlObjectMain = document.createElement('div');
      htmlObjectMain.innerHTML = svgFileTextResult.toString();
      const mainSVG = htmlObjectMain.children[0];
      // mainSVG.setAttribute('viewBox', `0 0 ${SVG_SIZE} ${SVG_SIZE}`);
      mainSVG.setAttribute('width', SVG_SIZE.toString());
      mainSVG.setAttribute('height', SVG_SIZE.toString());
      const resetSvgNode = mainSVG.cloneNode(true) as any;
      Array.prototype.slice.call(resetSvgNode.children).forEach((ch: any) => {
        if (ch.id) {
          ch.remove();
        }
      });
      const resetSvgTextResult = resetSvgNode.outerHTML;
      const svgChildrenArrayMain = Array.prototype.slice.call(htmlObjectMain.children[0].children);
      // Handle Has Back Design Here
      const hasBackDesign = svgChildrenArrayMain.find((ch) => ch.id.includes('design-1_back'));
      props.onSetHasBackDesign(hasBackDesign);
      const onlyIdPathsMain = props.activateDesignOnBack
        ? svgChildrenArrayMain.slice().filter((ch) => ch.id)
        : svgChildrenArrayMain.slice().filter((ch) => ch.id && !ch.id.includes('design-1_back'));
      const newPathsMain = onlyIdPathsMain.slice().map((ch) => {
        const cloneCh = ch.cloneNode();
        return cloneCh;
      });
      const newHtmlStringPathsMain = newPathsMain.map((ch) => ch.outerHTML);
      const htmlObjectCopy = document.createElement('div');
      htmlObjectCopy.innerHTML = resetSvgTextResult;
      const filteredOnlyIdPathsCopy = onlyIdPathsMain.slice().map((ch) => {
        const cloneCh = ch.cloneNode();
        return cloneCh;
      });
      const newIdPathsCopy = filteredOnlyIdPathsCopy.map((ch) => {
        const newIdCh = ch;
        newIdCh.setAttribute('id', 'copy-' + ch.id);
        return newIdCh;
      });
      const newIdHtmlStringPathsCopy = newIdPathsCopy.map((ch) => ch.outerHTML);
      // BG AGAIN
      const htmlObjectBG = document.createElement('div');
      htmlObjectBG.innerHTML = resetSvgTextResult;
      const filteredOnlyIdPathsBG = onlyIdPathsMain.slice().map((ch) => {
        const cloneCh = ch.cloneNode();
        return cloneCh;
      });
      const newIdPathsBG = filteredOnlyIdPathsBG.map((ch) => {
        const newIdCh = ch;
        newIdCh.setAttribute('id', 'bg-' + ch.id);
        return newIdCh;
      });
      const newIdHtmlStringPathsBG = newIdPathsBG.map((ch) => ch.outerHTML);
      let stringCopySvgPath = '';
      newIdHtmlStringPathsCopy.forEach((itemStr, index) => {
        stringCopySvgPath += newIdHtmlStringPathsBG[index];
        stringCopySvgPath += itemStr;
      });
      let stringSvgMainPath = '';
      newHtmlStringPathsMain.forEach((itemStr, index) => {
        stringSvgMainPath += itemStr;
      });
      const appendCopyPathsInSVG = resetSvgTextResult.toString().replace('</svg>', stringSvgMainPath + '<g>' + stringCopySvgPath + '</g></svg>');
      resolve(appendCopyPathsInSVG);
    });
  }

  function handleInitSvgDesignForFabric(objects: any[], reset?: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!fabricCanvas.current || !fabricInsideCanvas.current || !canvasRef.current || !canvasInsideRef.current) {
        return Promise.reject();
      }
      const loadedSvgPattern = fabric.util.groupSVGElements(objects, {
        width: SVG_SIZE,
        height: SVG_SIZE,
        left: 0,
        top: 0,
        selectable: false,
        id: 'mainUv'
      });
      const mainUvObject = fabricCanvas.current._objects.find((obj: any) => obj.id === 'mainUv');

      // If already init FabricJS
      if (mainUvObject) {
        loadedSvgPattern.objectCaching = false;
        loadedSvgPattern.set({
          selectable: false
        });
        designSvgPatternObject.current = loadedSvgPattern;
        fabricCanvas.current.remove(mainUvObject);
        fabricCanvas.current.add(designSvgPatternObject.current);
        fabricCanvas.current.sendToBack(designSvgPatternObject.current);
        fabricInsideCanvas.current.remove(designSvgPatternInsideObject.current);
        fabricInsideCanvas.current.add(designSvgPatternInsideObject.current);
        fabricInsideCanvas.current.sendToBack(designSvgPatternInsideObject.current);
        initDesignBoundingAndCenterMap(designSvgPatternObject.current._objects);
        const possibleColorAreaIds = designSvgPatternObject.current._objects.map((v: any) => v.id) as string[];
        props.onSetPossibleDesignAreaId(possibleColorAreaIds, reset)
          .then((selectedColorsObject) => {
            changeColor(selectedColorsObject)
              .then(() => {
                changePattern(props.selectedJerseySectionPattern)
                  .finally(() => {
                    resolve();
                    setTimeout(() => {
                      props.onSetLoading(false);
                    }, 1500);
                  });
              });
          });
      } else {
        getJakoRightLeftString()
          .then((logoStringRes) => {
            const rightLogo = logoStringRes[0];
            const leftLogo = logoStringRes[1];
            if (rightLogo) {
              fabric.loadSVGFromString(
                rightLogo,
                function (logoObjects) {
                  const loadedLogoSvg = fabric.util.groupSVGElements(logoObjects, {
                    width: SVG_SIZE,
                    height: SVG_SIZE,
                    left: 0,
                    top: 0,
                    selectable: false,
                  }) as any;
                  if (loadedLogoSvg) {
                    props.onSetLogoRightPosition(loadedLogoSvg);
                  }
                }
              );
            }
            if (leftLogo) {
              fabric.loadSVGFromString(
                leftLogo,
                function (logoObjects) {
                  const loadedLogoSvg = fabric.util.groupSVGElements(logoObjects, {
                    width: SVG_SIZE,
                    height: SVG_SIZE,
                    left: 0,
                    top: 0,
                    selectable: false,
                  }) as any;
                  if (loadedLogoSvg && loadedLogoSvg.height) {
                    props.onSetLogoLeftPosition(loadedLogoSvg);
                  }
                }
              );
            }

            getJakoLogoColorSvg().then((logoStringRes) => {
              fabric.loadSVGFromString(
                logoStringRes[0],
                function (logoObjects) {
                  const loadedLogoSvg = fabric.util.groupSVGElements(logoObjects, {
                    width: SVG_SIZE,
                    height: SVG_SIZE,
                    left: 0,
                    top: 0,
                    selectable: false,
                  });
                  if (loadedLogoSvg && loadedLogoSvg.height) {
                    const factorByBaseJakoLogoHeight = loadedLogoSvg.getScaledHeight() / BASE_JAKO_LOGO_HEIGHT_CM;
                    cmFactorAdjustedRef.current = factorByBaseJakoLogoHeight;
                    props.onSetBaseCmFactor(cmFactorAdjustedRef.current);
                  }
                  fabric.loadSVGFromString(
                    logoStringRes[1],
                    function (dotsLeftObjects) {
                      const loadedDotsLeftSvg = fabric.util.groupSVGElements(dotsLeftObjects, {
                        width: SVG_SIZE,
                        height: SVG_SIZE,
                        left: 0,
                        top: 0,
                        selectable: false,
                      });
                      fabric.loadSVGFromString(
                        logoStringRes[2],
                        function (dotsRightObjects) {
                          const loadedDotsRightSvg = fabric.util.groupSVGElements(dotsRightObjects, {
                            width: SVG_SIZE,
                            height: SVG_SIZE,
                            left: 0,
                            top: 0,
                            selectable: false,
                          });
                          if (!fabricCanvas.current || !fabricInsideCanvas.current || !canvasRef.current) {
                            reject();
                            return;
                          }
                          loadedSvgPattern.objectCaching = false;
                          loadedLogoSvg.objectCaching = false;
                          loadedDotsLeftSvg.objectCaching = false;
                          loadedDotsRightSvg.objectCaching = false;
                          const loadedLogoSvgAny = loadedLogoSvg as any;
                          loadedLogoSvgAny.set({
                            id: 'logo_front',
                            selectable: false,
                          });
                          designSvgPatternObject.current = loadedSvgPattern;
                          initDesignBoundingAndCenterMap(designSvgPatternObject.current._objects);
                          fabricCanvas.current.add(loadedSvgPattern);
                          setClipPathGroup();
                          loadedSvgPattern.clone((obj: any) => {
                            designSvgPatternInsideObject.current = obj;
                            designSvgPatternInsideObject.current._objects.forEach((insideObj: any) => {
                              if (insideObj.id) {
                                insideObj.fill = '#f0f5f0';
                              }
                            });
                            fabricInsideCanvas.current?.add(designSvgPatternInsideObject.current);
                            fabricInsideCanvas.current?.sendToBack(designSvgPatternInsideObject.current);
                          }, ['id', 'objects']);
                          fabricCanvas.current.add(loadedLogoSvgAny);
                          fabricCanvas.current.sendToBack(loadedLogoSvgAny);
                          if (logoStringRes[1]) {
                            const loadedDotsLeftSvgAny = loadedDotsLeftSvg as any;
                            loadedDotsLeftSvgAny.set({
                              id: 'dots_frontleft',
                              selectable: false,
                            });
                            fabricCanvas.current.add(loadedDotsLeftSvgAny);
                            fabricCanvas.current.sendToBack(loadedDotsLeftSvgAny);
                          }
                          if (logoStringRes[2]) {
                            const loadedDotsRightSvgAny = loadedDotsRightSvg as any;
                            loadedDotsRightSvgAny.set({
                              id: 'dots_frontright',
                              selectable: false,
                            });
                            fabricCanvas.current.add(loadedDotsRightSvgAny);
                            fabricCanvas.current.sendToBack(loadedDotsRightSvgAny);
                          }
                          fabricCanvas.current.sendToBack(loadedSvgPattern);
                          const possibleColorAreaIds = designSvgPatternObject.current._objects.map((v: any) => v.id) as string[];
                          props.onSetPossibleDesignAreaId(possibleColorAreaIds, reset)
                            .then((selectedColorsObject) => {
                              changeColor(selectedColorsObject)
                                .then(() => {
                                  setTimeout(() => {
                                    initAddSaveObjects(resolve);
                                  }, 200);
                                });
                            });
                        }
                      );
                    }
                  );
                }
              );
            });
          });
      }
    });
  }

  function initAddSaveObjects(callback: () => void) {
    initAddTexts(props.addedTextItems)
      .then(() => {
        initAddLogos(props.addedLogoItems)
        .then((res) => {
          const objects = res as any[];
          props.onAddImageLogoInitSrc(objects);
          changePattern(props.selectedJerseySectionPattern)
            .finally(() => {
              if (fabricCanvas.current) {
                const anyFabricObjects = fabricCanvas.current._objects.slice() as any;
                fabricCanvas.current._objects = anyFabricObjects.sort((a: any, b: any) => {
                  if (!a.indexLayer || !b.indexLayer) {
                    return 0;
                  } else {
                    return a.indexLayer - b.indexLayer;
                  }
                });
                callback();
                setTimeout(() => {
                  props.onSetLoadingInitial(false);
                  setTimeout(() => {
                    props.onSetLoading(false);
                  }, 300);
                }, 1500);
              }
            });
        });
      });
  }

  function addFabricInside3DModel() {
    if (!canvasRef.current) {
      return;
    }
    if (!canvasInsideRef.current) {
      return;
    }
    const canvasTexture = new CanvasTexture(canvasRef.current, EquirectangularReflectionMapping);
    canvasTexture.anisotropy = gl.capabilities.getMaxAnisotropy();
    canvasTexture.flipY = false;
    canvasTexture.needsUpdate = true;
    canvasTexture.needsPMREMUpdate = true;
    canvasTexture.minFilter = LinearFilter;
    // canvasTexture.magFilter = NearestFilter;
    // canvasTexture.generateMipmaps = false;

    const insideCanvasTexture = new CanvasTexture(canvasInsideRef.current, EquirectangularReflectionMapping);
    insideCanvasTexture.anisotropy = gl.capabilities.getMaxAnisotropy();
    insideCanvasTexture.flipY = false;
    insideCanvasTexture.needsUpdate = true;
    insideCanvasTexture.needsPMREMUpdate = true;
    insideCanvasTexture.minFilter = LinearFilter;

    jerseyNormalMap.mapping = EquirectangularReflectionMapping;
    jerseyNormalMap.flipY = false;
    jerseyNormalMap.anisotropy = gl.capabilities.getMaxAnisotropy();
    jerseyNormalMap.minFilter = LinearFilter;
    // jerseyNormalMap.generateMipmaps = false;
    jerseyNormalMap.needsUpdate = true;

    nodes['Scene'].position.set(0, -0.1, 0);
    nodes['Scene'].scale.set(0.055, 0.055, 0.055);

    const material = new MeshStandardMaterial({
      map: canvasTexture,
      normalMap: jerseyNormalMap,
      side: FrontSide,
      roughness: 1,
      envMapIntensity: 0.85,
      transparent: false
    });
    const materialInside = new MeshStandardMaterial({
      map: insideCanvasTexture,
      normalMap: jerseyNormalMap,
      side: FrontSide,
      roughness: 1,
      envMapIntensity: 0.5,
      transparent: false
    });

    // const materialInsideCollar = new MeshStandardMaterial({
    //   map: insideCanvasTexture,
    //   normalMap: jerseyNormalMap,
    //   side: FrontSide,
    //   roughness: 1,
    //   envMapIntensity: 0.5,
    //   transparent: false,
    // });
    nodes['outside'].material = material;
    const insideKeys = Object.keys(nodes).filter((key) => key.includes('inside'));
    if (insideKeys.length) {
      insideKeys.forEach((insideKey) => {
        nodes[insideKey].material = materialInside;
      });
    }
  }
  function getActiveObjectPosition(activeObject: fabric.Object): string {
    const onlyMainArea = designSvgPatternObject.current._objects.slice().filter((obj: any) => obj.id.includes('main'));
    const activeObjTop = activeObject.aCoords ? activeObject.aCoords.tl.y : 0;
    const activeObjWidth = activeObject.aCoords ? activeObject.aCoords.tr.x - activeObject.aCoords.tl.x : 0;
    const activeObjLeft = activeObject.aCoords ? activeObject.aCoords.tl.x : 0;
    const activeObjHeight = activeObject.aCoords ? activeObject.aCoords.bl.y - activeObject.aCoords.tl.y: 0;
    const foundedIntersect = onlyMainArea.find((sectionObj: any) => {
      const sectionObjPathOffsetX = props.isMobile ? sectionObj.pathOffset.x / 4 : sectionObj.pathOffset.x;
      const sectionObjPathOffsetY = props.isMobile ? sectionObj.pathOffset.y / 4 : sectionObj.pathOffset.y;
      const isOverflowX = ((activeObjWidth + activeObjLeft) > (sectionObjPathOffsetX + (props.isMobile ? sectionObj.width / 4 : sectionObj.width) / 2)) || ((activeObjWidth + activeObjLeft) < (sectionObjPathOffsetX - (props.isMobile ? sectionObj.width / 4 : sectionObj.width) / 2));
      const isOverflowY = ((activeObjHeight + activeObjTop) > (sectionObjPathOffsetY + (props.isMobile ? sectionObj.height / 4 : sectionObj.height) / 2)) || ((activeObjHeight + activeObjTop) < (sectionObjPathOffsetY - (props.isMobile ? sectionObj.height / 4 : sectionObj.height) / 2));
      return !isOverflowX && !isOverflowY;
    });
    if (foundedIntersect) {
      return foundedIntersect.id;
    }
    return '';
  }
  function setClipPathGroup() {
    if (!fabricCanvas.current) {
      return;
    }
    const mainUvObject = fabricCanvas.current._objects.find((obj: any) => obj.id === 'mainUv') as any;
    if (mainUvObject) {
      let clipPathGroup = {};
      mainUvObject._objects.forEach((item: any) => {
        if (!item.id.includes('bg-') && !item.id.includes('copy-')) {
          const clipPathArea = new fabric.Rect({
            width: item.width,
            height: item.height,
            left: item.pathOffset.x - (item.width / 2),
            top: item.pathOffset.y - (item.height / 2),
            name: `${item.id}__clippath`,
            selectable: false,
            noScaleCache: true,
            dirty: false,
            objectCaching: false,
            fill: 'transparent',
            opacity: 1,
            inverted: false,
            absolutePositioned: true,
          });
          fabricCanvas.current?.add(clipPathArea);
          clipPathGroup = {
            ...clipPathGroup,
            [`${item.id}__clippath`]: clipPathArea
          };
        }
      });
      clipPathGroupRef.current = clipPathGroup;
    }
  }
  function handleMovingObjectOverflow(activeObject: any) {
    const mouseUvPos = getMouseUvPosition();
    if (!mouseUvPos) {
      activeObject.clipPath = undefined;
      activeObject.currentPosition = undefined;
      return;
    }

    const clipPathKeys = Object.keys(clipPathGroupRef.current);
    const foundedAreaKey = clipPathKeys.find((key) => {
      const currenctActivePathClipPathRect = clipPathGroupRef.current[key];
      const leftOfShirt = currenctActivePathClipPathRect.aCoords.tl.x;
      const rightOfShirt = currenctActivePathClipPathRect.aCoords.tr.x;
      const topOfShirt = currenctActivePathClipPathRect.aCoords.tl.y;
      const bottomOfShirt = currenctActivePathClipPathRect.aCoords.bl.y;
      return mouseUvPos.x >= leftOfShirt
        && mouseUvPos.x <= rightOfShirt
        && mouseUvPos.y >= topOfShirt
        && mouseUvPos.y <= bottomOfShirt;
    });
    if (!foundedAreaKey) {
      activeObject.clipPath = undefined;
      activeObject.currentPosition = undefined;
      return;
    }

    if (!activeObject.aCoords) {
      activeObject.clipPath = undefined;
      activeObject.currentPosition = undefined;
      return;
    }

    const currenctActivePathClipPathRect = clipPathGroupRef.current[foundedAreaKey];
    const leftOfShirt = currenctActivePathClipPathRect.aCoords.tl.x;
    const rightOfShirt = currenctActivePathClipPathRect.aCoords.tr.x;
    // const topOfShirt = currenctActivePathClipPathRect.aCoords.tl.y;
    // const bottomOfShirt = currenctActivePathClipPathRect.aCoords.bl.y;
    const leftOfActiveObject = activeObject.aCoords.tl.x;
    const rightOfActiveObject = activeObject.aCoords.tr.x;
    if (rightOfActiveObject > rightOfShirt || leftOfActiveObject < leftOfShirt) {
      activeObject.clipPath = currenctActivePathClipPathRect;
      activeObject.currentPosition = foundedAreaKey;
    } else {
      activeObject.clipPath = undefined;
    }
  }
  function initHandleOnFabricMouseMove() {
    if (!fabricCanvas.current) {
      return;
    }
    fabricCanvas.current.on('mouse:move',  function() {
      document.body.style.cursor = 'grabbing';
      const hasActiveObject = fabricCanvas.current && fabricCanvas.current.getActiveObjects().length > 0;
      if (hasActiveObject) {
        const mainDotsLeftObject = fabricCanvas.current?._objects.find((obj: any) => obj.id === 'dots_frontleft');
        if (mainDotsLeftObject) {
          fabricCanvas.current?.bringToFront(mainDotsLeftObject);
        }
        const mainDotsRightObject = fabricCanvas.current?._objects.find((obj: any) => obj.id === 'dots_frontright');
        if (mainDotsRightObject) {
          fabricCanvas.current?.bringToFront(mainDotsRightObject);
        }
        const mainLogoObject = fabricCanvas.current?._objects.find((obj: any) => obj.id === 'logo_front');
        if (mainLogoObject) {
          fabricCanvas.current?.bringToFront(mainLogoObject);
        }
        props.onDisableCameraControl();
      } else {
        props.onEnableCameraControl();
      }
    });
  }
  function initHandleOnFabricMouseDown() {
    if (!fabricCanvas.current) {
      return;
    }
    fabricCanvas.current.on('mouse:down', function (obj) {
      if (!fabricCanvas.current) {
        return;
      }
      if (obj && obj.target && obj.target.name?.includes('tab')) {
        props.onSelectObjectItem(obj.target.name || '');
      } else {
        props.onSelectObjectItem('');
      }
      const hasActiveObject = fabricCanvas.current && fabricCanvas.current.getActiveObjects().length > 0;
      if (hasActiveObject) {
        const mainDotsLeftObject = fabricCanvas.current?._objects.find((obj: any) => obj.id === 'dots_frontleft');
        if (mainDotsLeftObject) {
          fabricCanvas.current?.bringToFront(mainDotsLeftObject);
        }
        const mainDotsRightObject = fabricCanvas.current?._objects.find((obj: any) => obj.id === 'dots_frontright');
        if (mainDotsRightObject) {
          fabricCanvas.current?.bringToFront(mainDotsRightObject);
        }
        const mainLogoObject = fabricCanvas.current?._objects.find((obj: any) => obj.id === 'logo_front');
        if (mainLogoObject) {
          fabricCanvas.current?.bringToFront(mainLogoObject);
        }
        props.onDisableCameraControl();
      } else {
        props.onEnableCameraControl();
        props.onTextTabBack();
      }
      viewportTransform.current = fabricCanvas.current.viewportTransform;
      zoom.current = fabricCanvas.current.getZoom();
    });
  }
  function initHandleOnFabricMouseUp() {
    if (!fabricCanvas.current) {
      return;
    }
    fabricCanvas.current.on('mouse:up', function(e) {
      document.body.style.cursor = 'auto';
      props.onEnableCameraControl();
      const mainDotsLeftObject = fabricCanvas.current?._objects.find((obj: any) => obj.id === 'dots_frontleft');
      if (mainDotsLeftObject) {
        fabricCanvas.current?.bringToFront(mainDotsLeftObject);
      }
      const mainDotsRightObject = fabricCanvas.current?._objects.find((obj: any) => obj.id === 'dots_frontright');
      if (mainDotsRightObject) {
        fabricCanvas.current?.bringToFront(mainDotsRightObject);
      }
      const mainLogoObject = fabricCanvas.current?._objects.find((obj: any) => obj.id === 'logo_front');
      if (mainLogoObject) {
          fabricCanvas.current?.bringToFront(mainLogoObject);
      }

      isInVerticalCenter.current = false;
      isInHorizontalCenter.current = false;

      if (horizontalLines.current) {
        horizontalLines.current.length = 0;
      }

      if (verticalLines.current) {
        verticalLines.current.length = 0;
      }
      fabricCanvas.current?.renderAll();
    });
  }
  function initHandleOnFabricObjectScaling() {
    if (!fabricCanvas.current) {
      return;
    }
    fabricCanvas.current.on('object:scaling', function(event: any) {
      if (event.target && event.target.fontFamily) {
        const foundedObj = event.target as any;
        foundedObj.clone((obj: any) => {
          let maxHeight = MAX_TEXT_SIZE_HEIGHT_CM;
          if (maxHeight !== 0) {
            obj.scaleToHeight(maxHeight * cmFactorAdjustedRef.current);
            let maxScaleX = obj.scaleX;
            let maxScaleY = obj.scaleY;
            if (maxScaleX && (event.target.scaleX > maxScaleX)) {
              event.target.scaleX = maxScaleX;
              event.target.scaleY = maxScaleY;
            }
          }
          props.onUpdateTextFontSize(event.target.name, {
            width: event.target.getScaledWidth() / cmFactorAdjustedRef.current,
            height: event.target.getScaledHeight() / cmFactorAdjustedRef.current
          });
        });
      } else {
        props.onUpdateLogoSize(event.target.name, {
          width: event.target.getScaledWidth() / cmFactorAdjustedRef.current,
          height: event.target.getScaledHeight() / cmFactorAdjustedRef.current,
        });
      }
    });
  }
  function initHandleOnFabricObjectModified() {
    if (!fabricCanvas.current) {
      return;
    }
    fabricCanvas.current.on('object:modified', function(event: any) {
      if (event.target && event.target.fontFamily) {
        if (event.target.name) {
          props.onObjectModified('text', event.target.name);
        }
      } else if (event.target.name) {
        props.onObjectModified('logo', event.target.name);
      }
    });
  }
  function initHandleOnFabricObjectMoving() {
    if (!fabricCanvas.current) {
      return;
    }
    fabricCanvas.current.on('object:moving', function(e) {
      if (!fabricCanvas.current) {
        return;
      }

      const activeObject = e.target;
      if (!activeObject) {
        return;
      }

      if (!viewportTransform.current) {
        return;
      }

      if (viewportTransform.current.length === 0) {
        return;
      }

      const canvas = fabricCanvas.current as any;
      let canvasObjects = fabricCanvas.current.getObjects(),
        activeObjectCenter = activeObject.getCenterPoint(),
        activeObjectCenterLeft = activeObjectCenter.x,
        activeObjectCenterTop = activeObjectCenter.y,
        activeObjectBoundingRect = activeObject.getBoundingRect(),
        activeObjectHeight = activeObjectBoundingRect.height / viewportTransform.current[3],
        activeObjectWidth = activeObjectBoundingRect.width / viewportTransform.current[0],
        transform = canvas._currentTransform;
      if (!transform) return;
      if (designSvgPatternObject.current._objects.find((item: any) => item.id && item.id.includes('placket'))
        && designSvgPatternObject.current._objects.find((item: any) => item.id && item.id.includes('collar_under') && (activeObjectCenterTop >= 40 && activeObjectCenterTop <= 120))
      ) {
        activeObject.flipX = true;
      } else {
        activeObject.flipX = false;
      }

      const activeObjectPosition = getActiveObjectPosition(activeObject);
      handleMovingObjectOverflow(activeObject);

      let fabricFrontObjCenterX = 0;
      let fabricBackObjCenterX = 0;
      let fabricLeftObjCenterX = 0;
      let fabricRightObjCenterX = 0;
      let fabricFrontObjCenterY = 0;
      let fabricBackObjCenterY = 0;
      let fabricLeftObjCenterY = 0;
      let fabricRightObjCenterY = 0;

      if (props.modelStyle === ModelStyle.Short) {
        fabricLeftObjCenterX = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.x / 4) : Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.x);
        fabricRightObjCenterX = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.x / 4) : Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.x);

        fabricLeftObjCenterY = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.y / 4) : Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.y);
        fabricRightObjCenterY = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.y / 4) : Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.y);
      } else {
        if (ModelStyle.Shirt === props.modelStyle) {
          fabricFrontObjCenterX = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.front_main.pathOffset.x / 4) : Math.round(designSvgPatternObjectsBounding.current.front_main.pathOffset.x);
          fabricBackObjCenterX = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.back_main.pathOffset.x / 4) : Math.round(designSvgPatternObjectsBounding.current.back_main.pathOffset.x);
          fabricLeftObjCenterX = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.x / 4) : Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.x);
          fabricRightObjCenterX = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.x / 4) : Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.x);

          fabricFrontObjCenterY = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.front_main.pathOffset.y / 4) : Math.round(designSvgPatternObjectsBounding.current.front_main.pathOffset.y);
          fabricBackObjCenterY = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.back_main.pathOffset.y / 4) : Math.round(designSvgPatternObjectsBounding.current.back_main.pathOffset.y);
          fabricLeftObjCenterY = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.y / 4) : Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.y);
          fabricRightObjCenterY = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.y / 4) : Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.y);
        } else {
          fabricFrontObjCenterX = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.front_main.pathOffset.x / 4) : Math.round(designSvgPatternObjectsBounding.current.front_main.pathOffset.x);
          fabricBackObjCenterX = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.back_main.pathOffset.x / 4) : Math.round(designSvgPatternObjectsBounding.current.back_main.pathOffset.x);
          fabricFrontObjCenterY = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.front_main.pathOffset.y / 4) : Math.round(designSvgPatternObjectsBounding.current.front_main.pathOffset.y);
          fabricBackObjCenterY = props.isMobile ? Math.round(designSvgPatternObjectsBounding.current.back_main.pathOffset.y / 4) : Math.round(designSvgPatternObjectsBounding.current.back_main.pathOffset.y);
        }
      }

      // ---------- Center Alignment Guideline Handler ---------------
      let widthCenter = 0;
      let heightCenter = 0;

      if (ModelStyle.Shirt === props.modelStyle) {
        if (activeObjectPosition === 'front_main') {
          isInVerticalCenter.current = Math.round(activeObjectCenter.x) in frontWidthCenterMap.current;
          isInHorizontalCenter.current = Math.round(activeObjectCenter.y) in frontHeightCenterMap.current;
          widthCenter = fabricFrontObjCenterX;
          heightCenter = fabricFrontObjCenterY;

          const frontWidth = props.isMobile ? designSvgPatternObjectsBounding.current.front_main.width / 4 : designSvgPatternObjectsBounding.current.front_main.width;
          const frontHeight = props.isMobile ? designSvgPatternObjectsBounding.current.front_main.height / 4 : designSvgPatternObjectsBounding.current.front_main.height;
          const boundingTop = fabricFrontObjCenterY - (frontHeight / 2);
          centerVerticalLine.current = {
            startY: boundingTop,
            widthCenter,
            height: frontHeight,
            viewportTransform: viewportTransform.current,
          };
          const boundingLeft = fabricFrontObjCenterX - (frontWidth / 2);
          centerHorizontalLine.current = {
            startX: boundingLeft,
            heightCenter,
            width: frontWidth,
            viewportTransform: viewportTransform.current,
          };
        } else if (activeObjectPosition === 'back_main') {
          isInVerticalCenter.current = Math.round(activeObjectCenter.x) in backWidthCenterMap.current;
          isInHorizontalCenter.current = Math.round(activeObjectCenter.y) in backHeightCenterMap.current;
          widthCenter = fabricBackObjCenterX;
          heightCenter = fabricBackObjCenterY;
          const backWidth = props.isMobile ? designSvgPatternObjectsBounding.current.back_main.width / 4 : designSvgPatternObjectsBounding.current.back_main.width;
          const backHeight = props.isMobile ? designSvgPatternObjectsBounding.current.back_main.height / 4 : designSvgPatternObjectsBounding.current.back_main.height;

          const boundingTop = fabricBackObjCenterY - (backHeight / 2);
          centerVerticalLine.current = {
            startY: boundingTop,
            widthCenter,
            height: backHeight,
            viewportTransform: viewportTransform.current,
          };
          const boundingLeft = fabricBackObjCenterX - (backWidth / 2);
          centerHorizontalLine.current = {
            startX: boundingLeft,
            heightCenter,
            width: backWidth,
            viewportTransform: viewportTransform.current,
          };
        } else if (activeObjectPosition === 'left_main' && designSvgPatternObjectsBounding.current.left_main) {
          isInVerticalCenter.current = Math.round(activeObjectCenter.x) in leftWidthCenterMap.current;
          isInHorizontalCenter.current = Math.round(activeObjectCenter.y) in leftHeightCenterMap.current;
          widthCenter = fabricLeftObjCenterX;
          heightCenter = fabricLeftObjCenterY;
          const leftWidth = props.isMobile ? designSvgPatternObjectsBounding.current.left_main.width / 4 : designSvgPatternObjectsBounding.current.left_main.width;
          const leftHeight = props.isMobile ? designSvgPatternObjectsBounding.current.left_main.height / 4 : designSvgPatternObjectsBounding.current.left_main.height;

          const boundingTop = fabricLeftObjCenterY - (leftHeight / 2);
          centerVerticalLine.current = {
            startY: boundingTop,
            widthCenter,
            height: leftHeight,
            viewportTransform: viewportTransform.current,
          };
          const boundingLeft = fabricLeftObjCenterX - (leftWidth / 2);
          centerHorizontalLine.current = {
            startX: boundingLeft,
            heightCenter,
            width: leftWidth,
            viewportTransform: viewportTransform.current,
          };
        } else if (activeObjectPosition === 'right_main' && designSvgPatternObjectsBounding.current.right_main) {
          isInVerticalCenter.current = Math.round(activeObjectCenter.x) in rightWidthCenterMap.current;
          isInHorizontalCenter.current = Math.round(activeObjectCenter.y) in rightHeightCenterMap.current;
          widthCenter = fabricRightObjCenterX;
          heightCenter = fabricRightObjCenterY;
          const rightWidth = props.isMobile ? designSvgPatternObjectsBounding.current.right_main.width / 4 : designSvgPatternObjectsBounding.current.right_main.width;
          const rightHeight = props.isMobile ? designSvgPatternObjectsBounding.current.right_main.height / 4 : designSvgPatternObjectsBounding.current.right_main.height;

          const boundingTop = fabricRightObjCenterY - (rightHeight / 2);
          centerVerticalLine.current = {
            startY: boundingTop,
            widthCenter,
            height: rightHeight,
            viewportTransform: viewportTransform.current,
          };
          const boundingLeft = fabricRightObjCenterX - (rightWidth / 2);
          centerHorizontalLine.current = {
            startX: boundingLeft,
            heightCenter,
            width: rightWidth,
            viewportTransform: viewportTransform.current,
          };
        }
      } else {
        if (activeObjectPosition === 'front_main') {
          isInVerticalCenter.current = Math.round(activeObjectCenter.x) in frontWidthCenterMap.current;
          isInHorizontalCenter.current = Math.round(activeObjectCenter.y) in frontHeightCenterMap.current;
          widthCenter = fabricFrontObjCenterX;
          heightCenter = fabricFrontObjCenterY;

          const frontWidth = props.isMobile ? designSvgPatternObjectsBounding.current.front_main.width / 4 : designSvgPatternObjectsBounding.current.front_main.width;
          const frontHeight = props.isMobile ? designSvgPatternObjectsBounding.current.front_main.height / 4 : designSvgPatternObjectsBounding.current.front_main.height;
          const boundingTop = fabricFrontObjCenterY - (frontHeight / 2);
          centerVerticalLine.current = {
            startY: boundingTop,
            widthCenter,
            height: frontHeight,
            viewportTransform: viewportTransform.current,
          };
          const boundingLeft = fabricFrontObjCenterX - (frontWidth / 2);
          centerHorizontalLine.current = {
            startX: boundingLeft,
            heightCenter,
            width: frontWidth,
            viewportTransform: viewportTransform.current,
          };
        } else if (activeObjectPosition === 'back_main') {
          isInVerticalCenter.current = Math.round(activeObjectCenter.x) in backWidthCenterMap.current;
          isInHorizontalCenter.current = Math.round(activeObjectCenter.y) in backHeightCenterMap.current;
          widthCenter = fabricBackObjCenterX;
          heightCenter = fabricBackObjCenterY;
          const backWidth = props.isMobile ? designSvgPatternObjectsBounding.current.back_main.width / 4 : designSvgPatternObjectsBounding.current.back_main.width;
          const backHeight = props.isMobile ? designSvgPatternObjectsBounding.current.back_main.height / 4 : designSvgPatternObjectsBounding.current.back_main.height;

          const boundingTop = fabricBackObjCenterY - (backHeight / 2);
          centerVerticalLine.current = {
            startY: boundingTop,
            widthCenter,
            height: backHeight,
            viewportTransform: viewportTransform.current,
          };
          const boundingLeft = fabricBackObjCenterX - (backWidth / 2);
          centerHorizontalLine.current = {
            startX: boundingLeft,
            heightCenter,
            width: backWidth,
            viewportTransform: viewportTransform.current,
          };
        }
      }

      if (isInVerticalCenter.current || isInHorizontalCenter.current) {
        activeObject.setPositionByOrigin(
          new fabric.Point(
            isInVerticalCenter.current ? widthCenter : activeObjectCenterLeft,
            isInHorizontalCenter.current ? heightCenter : activeObjectCenterTop
          ),
          'center',
          'center'
        );
      }
      // ---------- End of Center Alignment Guideline Handler ---------------


      // ---------- Objects Alignment Guideline Handler ---------------
      /**
       * It should be trivial to DRY this up by encapsulating (repeating) creation of x1, x2, y1, and y2 into functions,
       * but we're not doing it here for perf. reasons -- as this a function that's invoked on every mouse move
       * */
      // const onlyAddedObjectsForObjectAlignments = canvasObjects.slice().filter((item: any) => item.id ? item.id.toString() === 'logo_front' : false);
      const onlyAddedObjectsForObjectAlignments = canvasObjects.slice().filter((item: any) => !item.id || item.id.toString() === 'logo_front');

      for (let i = onlyAddedObjectsForObjectAlignments.length; i--; ) {
        if (onlyAddedObjectsForObjectAlignments[i] === activeObject) continue;

        if (activeObjectPosition === 'back_main') {
          const fabricFrontObj = designSvgPatternObjectsBounding.current.front_main;
          const fabricLeftObj = designSvgPatternObjectsBounding.current.left_main;
          const fabricRightObj = designSvgPatternObjectsBounding.current.right_main;
          if (fabricFrontObj) {
            const frontSvgLayerPathOffset = fabricFrontObj.pathOffset;
            const boundingLeft = frontSvgLayerPathOffset.x - ((props.isMobile ? fabricFrontObj.width / 4 : fabricFrontObj.width) / 2);
            const boundingRight = frontSvgLayerPathOffset.x + ((props.isMobile ? fabricFrontObj.width / 4 : fabricFrontObj.width) / 2);
            const otherObjectCoordsLeft = onlyAddedObjectsForObjectAlignments[i].aCoords?.tl.x || 0;
            const otherObjectCoordsRight = onlyAddedObjectsForObjectAlignments[i].aCoords?.tr.x || 0;
            if (otherObjectCoordsLeft > boundingLeft && otherObjectCoordsRight < boundingRight) {
              continue;
            }
          }
          if (fabricLeftObj) {
            const leftSvgLayerPathOffset = fabricLeftObj.pathOffset;
            const boundingTop = leftSvgLayerPathOffset.y - ((props.isMobile ? fabricLeftObj.height / 4 : fabricLeftObj.height) / 2);
            const boundingBottom = leftSvgLayerPathOffset.y + ((props.isMobile ? fabricLeftObj.height / 4 : fabricLeftObj.height) / 2);
            const otherObjectCoordsTop = onlyAddedObjectsForObjectAlignments[i].aCoords?.tl.x || 0;
            const otherObjectCoordsBottom = onlyAddedObjectsForObjectAlignments[i].aCoords?.bl.y || 0;
            if (otherObjectCoordsTop > boundingTop && otherObjectCoordsBottom < boundingBottom) {
              continue;
            }
          }
          if (fabricRightObj) {
            const rightSvgLayerPathOffset = fabricRightObj.pathOffset;
            const boundingTop = rightSvgLayerPathOffset.y - ((props.isMobile ? fabricRightObj.height / 4 : fabricRightObj.height) / 2);
            const boundingBottom = rightSvgLayerPathOffset.y + ((props.isMobile ? fabricRightObj.height / 4 : fabricRightObj.height) / 2);
            const otherObjectCoordsTop = onlyAddedObjectsForObjectAlignments[i].aCoords?.tl.x || 0;
            const otherObjectCoordsBottom = onlyAddedObjectsForObjectAlignments[i].aCoords?.bl.y || 0;
            if (otherObjectCoordsTop > boundingTop && otherObjectCoordsBottom < boundingBottom) {
              continue;
            }
          }
        }

        if (activeObjectPosition === 'front_main') {
          const fabricBackObj = designSvgPatternObjectsBounding.current.back_main;
          const fabricLeftObj = designSvgPatternObjectsBounding.current.left_main;
          const fabricRightObj = designSvgPatternObjectsBounding.current.right_main;
          if (fabricBackObj) {
            const backSvgLayerPathOffset = fabricBackObj.pathOffset;
            const boundingLeft = backSvgLayerPathOffset.x - ((props.isMobile ? fabricBackObj.width / 4 : fabricBackObj.width) / 2);
            const boundingRight = backSvgLayerPathOffset.x + ((props.isMobile ? fabricBackObj.width / 4 : fabricBackObj.width) / 2);
            const otherObjectCoordsLeft = onlyAddedObjectsForObjectAlignments[i].aCoords?.tl.x || 0;
            const otherObjectCoordsRight = onlyAddedObjectsForObjectAlignments[i].aCoords?.tr.x || 0;
            if (otherObjectCoordsLeft > boundingLeft && otherObjectCoordsRight < boundingRight) {
              continue;
            }
          }
          if (fabricLeftObj) {
            const leftSvgLayerPathOffset = fabricLeftObj.pathOffset;
            const boundingTop = leftSvgLayerPathOffset.y - ((props.isMobile ? fabricLeftObj.height / 4 : fabricLeftObj.height) / 2);
            const boundingBottom = leftSvgLayerPathOffset.y + ((props.isMobile ? fabricLeftObj.height / 4 : fabricLeftObj.height) / 2);
            const otherObjectCoordsTop = onlyAddedObjectsForObjectAlignments[i].aCoords?.tl.x || 0;
            const otherObjectCoordsBottom = onlyAddedObjectsForObjectAlignments[i].aCoords?.bl.y || 0;
            if (otherObjectCoordsTop > boundingTop && otherObjectCoordsBottom < boundingBottom) {
              continue;
            }
          }
          if (fabricRightObj) {
            const rightSvgLayerPathOffset = fabricRightObj.pathOffset;
            const boundingTop = rightSvgLayerPathOffset.y - ((props.isMobile ? fabricRightObj.height / 4 : fabricRightObj.height) / 2);
            const boundingBottom = rightSvgLayerPathOffset.y + ((props.isMobile ? fabricRightObj.height / 4 : fabricRightObj.height) / 2);
            const otherObjectCoordsTop = onlyAddedObjectsForObjectAlignments[i].aCoords?.tl.x || 0;
            const otherObjectCoordsBottom = onlyAddedObjectsForObjectAlignments[i].aCoords?.bl.y || 0;
            if (otherObjectCoordsTop > boundingTop && otherObjectCoordsBottom < boundingBottom) {
              continue;
            }
          }
        }

        if (props.modelStyle === ModelStyle.Short && activeObjectPosition === 'right_main') {
        } else if (activeObjectPosition === 'right_main') {
          const fabricLeftObj = designSvgPatternObjectsBounding.current.left_main;
          const fabricFrontObj = designSvgPatternObjectsBounding.current.front_main;
          const fabricBackObj = designSvgPatternObjectsBounding.current.back_main;
          if (fabricLeftObj) {
            const leftSvgLayerPathOffset = fabricLeftObj.pathOffset;
            const boundingLeft = leftSvgLayerPathOffset.x - ((props.isMobile ? fabricLeftObj.width / 4 : fabricLeftObj.width) / 2);
            const boundingRight = leftSvgLayerPathOffset.x + ((props.isMobile ? fabricLeftObj.width / 4 : fabricLeftObj.width) / 2);
            const otherObjectCoordsLeft = onlyAddedObjectsForObjectAlignments[i].aCoords?.tl.x || 0;
            const otherObjectCoordsRight = onlyAddedObjectsForObjectAlignments[i].aCoords?.tr.x || 0;

            const frontSvgLayerPathOffset = fabricFrontObj.pathOffset;
            const frontBoundingTop = frontSvgLayerPathOffset.y - ((props.isMobile ? fabricFrontObj.height / 4 : fabricFrontObj.height) / 2);
            const frontBoundingBottom = frontSvgLayerPathOffset.y + ((props.isMobile ? fabricFrontObj.height / 4 : fabricFrontObj.height) / 2);

            const backSvgLayerPathOffset = fabricBackObj.pathOffset;
            const backBoundingTop = backSvgLayerPathOffset.y - ((props.isMobile ? fabricBackObj.height / 4 : fabricBackObj.height) / 2);
            const backBoundingBottom = backSvgLayerPathOffset.y + ((props.isMobile ? fabricBackObj.height / 4 : fabricBackObj.height) / 2);

            const otherObjectCoordsTop = onlyAddedObjectsForObjectAlignments[i].aCoords?.tl.x || 0;
            const otherObjectCoordsBottom = onlyAddedObjectsForObjectAlignments[i].aCoords?.bl.y || 0;

            if ((otherObjectCoordsLeft > boundingLeft && otherObjectCoordsRight < boundingRight)
              && (otherObjectCoordsTop > frontBoundingTop && otherObjectCoordsBottom < frontBoundingBottom)
              && (otherObjectCoordsTop > backBoundingTop && otherObjectCoordsBottom < backBoundingBottom)) {
              continue;
            }
          }
        }

        if (props.modelStyle === ModelStyle.Short && activeObjectPosition === 'left_main') {
        } else if (activeObjectPosition === 'left_main') {
          const fabricRightObj = designSvgPatternObjectsBounding.current.right_main;
          const fabricFrontObj = designSvgPatternObjectsBounding.current.front_main;
          const fabricBackObj = designSvgPatternObjectsBounding.current.back_main;
          if (fabricRightObj) {
            const rightSvgLayerPathOffset = fabricRightObj.pathOffset;
            const boundingLeft = rightSvgLayerPathOffset.x - ((props.isMobile ? fabricRightObj.width / 4 : fabricRightObj.width) / 2);
            const boundingRight = rightSvgLayerPathOffset.x + ((props.isMobile ? fabricRightObj.width / 4 : fabricRightObj.width) / 2);
            const otherObjectCoordsLeft = onlyAddedObjectsForObjectAlignments[i].aCoords?.tl.x || 0;
            const otherObjectCoordsRight = onlyAddedObjectsForObjectAlignments[i].aCoords?.tr.x || 0;

            const frontSvgLayerPathOffset = fabricFrontObj.pathOffset;
            const frontBoundingTop = frontSvgLayerPathOffset.y - ((props.isMobile ? fabricFrontObj.height / 4 : fabricFrontObj.height) / 2);
            const frontBoundingBottom = frontSvgLayerPathOffset.y + ((props.isMobile ? fabricFrontObj.height / 4 : fabricFrontObj.height) / 2);

            const backSvgLayerPathOffset = fabricBackObj.pathOffset;
            const backBoundingTop = backSvgLayerPathOffset.y - ((props.isMobile ? fabricBackObj.height / 4 : fabricBackObj.height) / 2);
            const backBoundingBottom = backSvgLayerPathOffset.y + ((props.isMobile ? fabricBackObj.height / 4 : fabricBackObj.height) / 2);

            const otherObjectCoordsTop = onlyAddedObjectsForObjectAlignments[i].aCoords?.tl.x || 0;
            const otherObjectCoordsBottom = onlyAddedObjectsForObjectAlignments[i].aCoords?.bl.y || 0;

            if ((otherObjectCoordsLeft > boundingLeft && otherObjectCoordsRight < boundingRight)
              && (otherObjectCoordsTop > frontBoundingTop && otherObjectCoordsBottom < frontBoundingBottom)
              && (otherObjectCoordsTop > backBoundingTop && otherObjectCoordsBottom < backBoundingBottom)) {
              continue;
            }
          }
        }

        let objectCenter = onlyAddedObjectsForObjectAlignments[i].getCenterPoint(),
          objectLeft = objectCenter.x,
          objectTop = objectCenter.y,
          objectBoundingRect = onlyAddedObjectsForObjectAlignments[i].getBoundingRect(),
          objectHeight = objectBoundingRect.height / viewportTransform.current[3],
          objectWidth = objectBoundingRect.width / viewportTransform.current[0];

        if (verticalLines.current) {
          // snap by the horizontal center line
          if (isInRange(objectLeft, activeObjectCenterLeft)) {
            verticalInTheRange.current = true;
            verticalLines.current.push({
              x: objectLeft,
              y1: (objectTop < activeObjectCenterTop)
                ? (objectTop - objectHeight / 2 - aligningLineOffset)
                : (objectTop + objectHeight / 2 + aligningLineOffset),
              y2: (activeObjectCenterTop > objectTop)
                ? (activeObjectCenterTop + activeObjectHeight / 2 + aligningLineOffset)
                : (activeObjectCenterTop - activeObjectHeight / 2 - aligningLineOffset)
            });
            activeObject.setPositionByOrigin(new fabric.Point(objectLeft, activeObjectCenterTop), 'center', 'center');
          }

          // snap by the left edge
          if (isInRange(objectLeft - objectWidth / 2, activeObjectCenterLeft - activeObjectWidth / 2)) {
            verticalInTheRange.current = true;
            verticalLines.current.push({
              x: objectLeft - objectWidth / 2,
              y1: (objectTop < activeObjectCenterTop)
                ? (objectTop - objectHeight / 2 - aligningLineOffset)
                : (objectTop + objectHeight / 2 + aligningLineOffset),
              y2: (activeObjectCenterTop > objectTop)
                ? (activeObjectCenterTop + activeObjectHeight / 2 + aligningLineOffset)
                : (activeObjectCenterTop - activeObjectHeight / 2 - aligningLineOffset)
            });
            activeObject.setPositionByOrigin(new fabric.Point(objectLeft - objectWidth / 2 + activeObjectWidth / 2, activeObjectCenterTop), 'center', 'center');
          }

          // snap by the right edge
          if (isInRange(objectLeft + objectWidth / 2, activeObjectCenterLeft + activeObjectWidth / 2)) {
            verticalInTheRange.current = true;
            verticalLines.current.push({
              x: objectLeft + objectWidth / 2,
              y1: (objectTop < activeObjectCenterTop)
                ? (objectTop - objectHeight / 2 - aligningLineOffset)
                : (objectTop + objectHeight / 2 + aligningLineOffset),
              y2: (activeObjectCenterTop > objectTop)
                ? (activeObjectCenterTop + activeObjectHeight / 2 + aligningLineOffset)
                : (activeObjectCenterTop - activeObjectHeight / 2 - aligningLineOffset)
            });
            activeObject.setPositionByOrigin(new fabric.Point(objectLeft + objectWidth / 2 - activeObjectWidth / 2, activeObjectCenterTop), 'center', 'center');
          }
        }

        if (horizontalLines.current) {
            // snap by the vertical center line
          if (isInRange(objectTop, activeObjectCenterTop)) {
            horizontalInTheRange.current = true;
            horizontalLines.current.push({
              y: objectTop,
              x1: (objectLeft < activeObjectCenterLeft)
                ? (objectLeft - objectWidth / 2 - aligningLineOffset)
                : (objectLeft + objectWidth / 2 + aligningLineOffset),
              x2: (activeObjectCenterLeft > objectLeft)
                ? (activeObjectCenterLeft + activeObjectWidth / 2 + aligningLineOffset)
                : (activeObjectCenterLeft - activeObjectWidth / 2 - aligningLineOffset)
            });
            activeObject.setPositionByOrigin(new fabric.Point(activeObjectCenterLeft, objectTop), 'center', 'center');
          }

          // snap by the top edge
          if (isInRange(objectTop - objectHeight / 2, activeObjectCenterTop - activeObjectHeight / 2)) {
            horizontalInTheRange.current = true;
            horizontalLines.current.push({
              y: objectTop - objectHeight / 2,
              x1: (objectLeft < activeObjectCenterLeft)
                ? (objectLeft - objectWidth / 2 - aligningLineOffset)
                : (objectLeft + objectWidth / 2 + aligningLineOffset),
              x2: (activeObjectCenterLeft > objectLeft)
                ? (activeObjectCenterLeft + activeObjectWidth / 2 + aligningLineOffset)
                : (activeObjectCenterLeft - activeObjectWidth / 2 - aligningLineOffset)
            });
            activeObject.setPositionByOrigin(new fabric.Point(activeObjectCenterLeft, objectTop - objectHeight / 2 + activeObjectHeight / 2), 'center', 'center');
          }

          // snap by the bottom edge
          if (isInRange(objectTop + objectHeight / 2, activeObjectCenterTop + activeObjectHeight / 2)) {
            horizontalInTheRange.current = true;
            horizontalLines.current.push({
              y: objectTop + objectHeight / 2,
              x1: (objectLeft < activeObjectCenterLeft)
                ? (objectLeft - objectWidth / 2 - aligningLineOffset)
                : (objectLeft + objectWidth / 2 + aligningLineOffset),
              x2: (activeObjectCenterLeft > objectLeft)
                ? (activeObjectCenterLeft + activeObjectWidth / 2 + aligningLineOffset)
                : (activeObjectCenterLeft - activeObjectWidth / 2 - aligningLineOffset)
            });
            activeObject.setPositionByOrigin(new fabric.Point(activeObjectCenterLeft, objectTop + objectHeight / 2 - activeObjectHeight / 2), 'center', 'center');
          }
        }
      }
      if (!horizontalInTheRange.current && horizontalLines.current) {
        horizontalLines.current.length = 0;
      }
      if (!verticalInTheRange.current && verticalLines.current) {
        verticalLines.current.length = 0;
      }
      // ---------- End of Objects Alignment Guideline Handler ---------------
    });
  }
  function initHandleOnFabricSelections() {
    if (!fabricCanvas.current) {
      return;
    }
    fabricCanvas.current.on('selection:created', function(event: any) {
      if (event.selected && event.selected[0] && event.selected[0].name) {
        props.onSelectObjectItem(event.selected[0].name);
      } else {
        props.onSelectObjectItem('');
      }
      const mouseCursorContainer = document.getElementsByTagName('web-configurator')[0] ? document.getElementsByTagName('web-configurator')[0].shadowRoot?.getElementById('configurator-container') : document.getElementById('configurator-container');
      if (mouseCursorContainer) {
        mouseCursorContainer.addEventListener("touchmove", onDocumentTouchMove, false);
      }

    });
    fabricCanvas.current.on('selection:updated', function(event: any) {
      const mouseCursorContainer = document.getElementsByTagName('web-configurator')[0] ? document.getElementsByTagName('web-configurator')[0].shadowRoot?.getElementById('configurator-container') : document.getElementById('configurator-container');
      if (mouseCursorContainer) {
        mouseCursorContainer.addEventListener("touchmove", onDocumentTouchMove, false);
      }
    });
    fabricCanvas.current.on('before:selection:cleared', function(event: any) {
      const mouseCursorContainer = document.getElementsByTagName('web-configurator')[0] ? document.getElementsByTagName('web-configurator')[0].shadowRoot?.getElementById('configurator-container') : document.getElementById('configurator-container');
      if (mouseCursorContainer) {
        mouseCursorContainer.removeEventListener("touchmove", onDocumentTouchMove, false);
      }
    });
    fabricCanvas.current.on('selection:cleared', function(event: any) {
      const mouseCursorContainer = document.getElementsByTagName('web-configurator')[0] ? document.getElementsByTagName('web-configurator')[0].shadowRoot?.getElementById('configurator-container') : document.getElementById('configurator-container');
      if (mouseCursorContainer) {
        mouseCursorContainer.removeEventListener("touchmove", onDocumentTouchMove, false);
      }
    });
  }
  function initHandleOnFabricRenders() {
    if (!fabricCanvas.current) {
      return;
    }
    fabricCanvas.current.on('before:render', function() {
      if (!fabricCanvas.current) {
        return;
      }
      updateCanvasTextureMap();
    });
    fabricCanvas.current.on("after:render", function(fabricObj) {
      if (isInVerticalCenter.current) {
        showVerticalCenterLine(centerVerticalLine.current);
      }
      if (isInHorizontalCenter.current) {
        showHorizontalCenterLine(centerHorizontalLine.current);
      }

      if (verticalLines.current) {
        for (let i = verticalLines.current.length; i--; ) {
          drawVerticalLine(verticalLines.current[i]);
        }
        verticalLines.current.length = 0;
      }
      if (horizontalLines.current) {
        for (let i = horizontalLines.current.length; i--; ) {
          drawHorizontalLine(horizontalLines.current[i]);
        }
        horizontalLines.current.length = 0;
      }
    });
  }

  function setSvgDesignToFabricAndModel(svgDesignString: string, reset?: boolean) {
    if (!fabricCanvas.current || !fabricInsideCanvas.current) {
      return;
    }
    fabric.loadSVGFromString(
      svgDesignString,
      async function (objects) {
        if (!fabricCanvas.current || !fabricInsideCanvas.current || !canvasRef.current || !canvasInsideRef.current) {
          return;
        }
        addFabricInside3DModel();
        await handleInitSvgDesignForFabric(objects, reset);
        // --------------- Fabric Event Listner ---------------------
        initHandleOnFabricMouseMove();
        initHandleOnFabricMouseDown();
        initHandleOnFabricMouseUp();
        initHandleOnFabricObjectScaling();
        initHandleOnFabricObjectModified();
        initHandleOnFabricSelections();
        initHandleOnFabricRenders();
        initHandleOnFabricObjectMoving();
        // --------------- End of Fabric Event Listner ---------------------
      }
    );
  }

  useEffect(() => {
    const shadowRootContainer = document.querySelector('jako-configurator');
    const shadowRoot = shadowRootContainer ? shadowRootContainer.shadowRoot : null;
    const mouseCursorContainer = shadowRoot ? shadowRoot.getElementById('configurator-container') : document.getElementById('configurator-container');
    if (!mouseCursorContainer) {
      return;
    }
    if (props.isMobile) {
      mouseCursorContainer.addEventListener("touchstart", onDocumentTouchDown, false);
      mouseCursorContainer.addEventListener('touchend', onDocumentTouchUp);
      mouseCursorContainer.removeEventListener('mousedown', onDocumentMouseDown);
      mouseCursorContainer.removeEventListener('mouseup', onDocumentMouseUp);
    } else {
      mouseCursorContainer.addEventListener('mousedown', onDocumentMouseDown);
      mouseCursorContainer.addEventListener('mouseup', onDocumentMouseUp);
    }
    return () => {
      mouseCursorContainer.removeEventListener('mousedown', onDocumentMouseDown);
      mouseCursorContainer.removeEventListener('mouseup', onDocumentMouseUp);
      mouseCursorContainer.removeEventListener("touchstart", onDocumentTouchDown, false);
      mouseCursorContainer.removeEventListener('touchend', onDocumentTouchUp);
      mouseCursorContainer.removeEventListener("touchmove", onDocumentTouchMove, false);
    };
    // eslint-disable-next-line
  }, [props.isMobile]);

  useEffect(() => {
    initNewConfigurator();
    props.onInit();
    // eslint-disable-next-line
  }, [nodes]);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    } else {
      if (nodes['outside'] && nodes['outside'].material && nodes['outside'].material.map) {
        nodes['outside'].material.map.dispose();
      }
    }
    // eslint-disable-next-line
  }, [props.currentCuttingFormCode]);

  useEffect(() => {
    if (props.colorLogoHex) {
      getJakoLogoColorSvg().then((res) => {
        setNewJakoLogoColor(res[0]);
      });
    }
    // eslint-disable-next-line
  }, [props.selectedJerseySectionColor])

  useEffect(() => {
    if (props.colorDotsLeftHex) {
      getJakoLogoColorSvg().then((res) => {
        setNewDotsLogoColor(res[1], 'left');
      });
    }
    // eslint-disable-next-line
  }, [props.colorDotsLeftHex])

  useEffect(() => {
    if (props.colorDotsRightHex) {
      getJakoLogoColorSvg().then((res) => {
        setNewDotsLogoColor(res[2], 'right');
      });
    }
    // eslint-disable-next-line
  }, [props.colorDotsRightHex])

  useEffect(() => {
    getJakoLogoColorSvg().then((res) => {
      setNewJakoLogoColor(res[0]);
    });
    // eslint-disable-next-line
  }, [props.jakoLogoPosition])

  useEffect(() => {
    if (!fabricCanvas.current) {
      return;
    }
    if (props.isAddingState === null) {
      return;
    }
    if (props.isAddingState === FunctionTab.Teamname) {
      addText({
        textString: props.text,
        addingState: props.isAddingState,
      });
    } else if (props.isAddingState === FunctionTab.Number) {
      addText({
        textString: props.text,
        addingState: props.isAddingState,
      });
    } else if (props.isAddingState === FunctionTab.Logo && props.logo) {
      addLogo({
        imageObj: props.logo,
      });
    } else if (props.isAddingState !== FunctionTab.ResetStart) {
      addText({
        textString: props.text,
        addingState: props.isAddingState,
      });
    }
    // eslint-disable-next-line
  }, [props.isAddingState]);

  // useEffect(() => {
  //   if (!fabricCanvas.current) {
  //     return;
  //   }
  //   const addedObjects = fabricCanvas.current._objects;
  //   const onlyLogoAndTextObjects = addedObjects.slice()
  //     .filter((obj) => obj.name && obj.name.includes('tab'));
  //   if (onlyLogoAndTextObjects.length === 0) {
  //     return;
  //   }
  //   // if (props.selectedObjectItemFromUI) {
  //   //   onlyLogoAndTextObjects.forEach((obj) => {
  //   //     if (props.selectedObjectItemFromUI.name === obj.name) {
  //   //       obj.selectable = true;
  //   //     } else {
  //   //       obj.selectable = false;
  //   //     }
  //   //   });
  //   // } else {
  //   onlyLogoAndTextObjects.forEach((obj) => {
  //     obj.selectable = true;
  //   });
  //   // }
  // }, [props.selectedObjectItemFromUI]);

  function updateCanvasTextureMap() {
    if (!fabricCanvas.current) {
      return;
    }
    if (!canvasRef.current || !canvasInsideRef.current) {
      return;
    }

    if (nodes['outside'] && nodes['outside'].material && nodes['outside'].material.map) {
      nodes['outside'].material.map.needsUpdate = true;
      const insideKeys = Object.keys(nodes).filter((key) => key.includes('inside'));
      if (insideKeys.length) {
        insideKeys.forEach((insideKey) => {
          nodes[insideKey].material.map.needsUpdate = true;
        });
      }
    }
  }

  function initNewConfigurator() {
    console.log('nodes', nodes);
    nodes['outside'].geometry.computeBoundsTree();
    const insideKeys = Object.keys(nodes).filter((key) => key.includes('inside'));
    if (insideKeys.length) {
      insideKeys.forEach((insideKey) => {
        nodes[insideKey].geometry.computeBoundsTree();
      });
    }

    if (fabricCanvas.current) {
      fabricCanvas.current.clear();
      fabricCanvas.current.loadFromJSON({}, () => {});
    }
    if (fabricInsideCanvas.current) {
      fabricInsideCanvas.current.clear();
      fabricInsideCanvas.current.loadFromJSON({}, () => {});
    }
    props.onSetLoadingInitial(true);
    const fabricSetting = {
      cornerSize: props.isMobile ? 11 : 35,
      borderColor: "#26282D",
      borderScaleFactor: props.isMobile ? 1.5 / 4 : 1.5,
      borderShadowColor: "#00000055",
      borderShadowBlur: 4,
      borderDashArray: [props.isMobile ? 10 / 4 : 10, props.isMobile ? 10 / 4 : 10],
      lockSkewingX: true,
      lockSkewingY: true,
      lockScalingFlip: true,
      padding: 0,
    } as any;
    fabric.Object.prototype.set(fabricSetting);
    const resizeIcon = "data:image/svg+xml,%3Csvg width='32px' height='32px' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' fill='%23000000' transform='rotate(90)'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Ctitle%3Eionicons-v5-c%3C/title%3E%3Cpolyline points='304 96 416 96 416 208' style='fill:none;stroke:%23000000;stroke-linecap:square;stroke-miterlimit:10;stroke-width:32px'%3E%3C/polyline%3E%3Cline x1='405.77' y1='106.2' x2='111.98' y2='400.02' style='fill:none;stroke:%23000000;stroke-linecap:square;stroke-miterlimit:10;stroke-width:32px'%3E%3C/line%3E%3Cpolyline points='208 416 96 416 96 304' style='fill:none;stroke:%23000000;stroke-linecap:square;stroke-miterlimit:10;stroke-width:32px'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E";
    const resizeImg = document.createElement('img');
    resizeImg.src = resizeIcon;

    const rotateIcon = "data:image/svg+xml,%3Csvg height='32px' width='32px' fill='%23000000' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='0 0 33.317 28' enableBackground='new 0 0 33.317 28' xml:space='preserve'%3E%3Cpath d='M16.659,24c-5.078,0-9.213-3.987-9.475-9h2.975l-4.5-5l-4.5,5h3.025c0.264,6.671,5.74,12,12.475,12c3.197,0,6.104-1.21,8.315-3.185l-2.122-2.122C21.188,23.127,19.027,24,16.659,24z'%3E%3C/path%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M29.133,14c-0.265-6.669-5.74-12-12.475-12c-3.197,0-6.104,1.21-8.315,3.185l2.122,2.122C12.129,5.873,14.29,5,16.659,5c5.077,0,9.213,3.987,9.475,9h-2.975l4.5,5l4.5-5H29.133z'%3E%3C/path%3E%3C/svg%3E";
    const rotateImg = document.createElement('img');
    rotateImg.src = rotateIcon;

    const deleteIcon = "data:image/svg+xml,%3Csvg height='32px' width='32px' fill='%23000000' xmlns='http://www.w3.org/2000/svg' data-name='Layer 1' viewBox='0 0 100 100' x='0px' y='0px'%3E%3Ctitle%3E06%3C/title%3E%3Cg data-name='Group'%3E%3Cpath data-name='Compound Path' d='M81.5,24.6H62.7V20.8a7,7,0,0,0-7-7H44.3a7,7,0,0,0-7,7v3.8H18.5a3,3,0,0,0,0,6h6.1V75.2a11,11,0,0,0,11,11H64.4a11,11,0,0,0,11-11V30.6h6.1a3,3,0,0,0,0-6ZM43.3,20.8a1,1,0,0,1,1-1H55.7a1,1,0,0,1,1,1v3.8H43.3Zm26,54.5a5,5,0,0,1-5,5H35.6a5,5,0,0,1-5-5V30.6H69.4Z'%3E%3C/path%3E%3Cpath data-name='Path' d='M57.5,73a3,3,0,0,0,3-3V40.1a3,3,0,1,0-6,0V70A3,3,0,0,0,57.5,73Z'%3E%3C/path%3E%3Cpath data-name='Path' d='M42.5,73a3,3,0,0,0,3-3V40.1a3,3,0,1,0-6,0V70A3,3,0,0,0,42.5,73Z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E";
    const deleteImg = document.createElement('img');
    deleteImg.src = deleteIcon;

    function renderDeleteIconControl(ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: fabric.Object) {
      const size = fabricSetting.cornerSize; // this.cornerSize
      ctx.save();
      ctx.beginPath();
      ctx.arc(left, top, (size / 2) + 3, 0, 2 * Math.PI, false);
      ctx.strokeStyle = "#000000";
      ctx.stroke();
      ctx.shadowColor = "#00000055";
      ctx.shadowBlur = 4;
      ctx.fillStyle = "#ffffff";
      ctx.fill();
      ctx.lineWidth = 2;
      ctx.restore();

      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle || 0));
      ctx.drawImage(deleteImg, -size/2, -size/2, size, size);
      ctx.restore();
    }

    function renderRotateIconControl(ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: fabric.Object) {
      const size = fabricSetting.cornerSize; // this.cornerSize
      ctx.save();
      ctx.beginPath();
      ctx.arc(left, top, (size / 2) + 3, 0, 2 * Math.PI, false);
      ctx.strokeStyle = "#000000";
      ctx.stroke();
      ctx.shadowColor = "#00000055";
      ctx.shadowBlur = 2;
      ctx.fillStyle = "#ffffff";
      ctx.fill();
      ctx.lineWidth = 2;
      ctx.restore();

      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle || 0));
      ctx.drawImage(rotateImg, -size/2, -size/2, size, size);
      ctx.restore();
    }

    function renderResizeIconControl(ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: fabric.Object) {
      const size = fabricSetting.cornerSize; // this.cornerSize
      ctx.save();
      ctx.beginPath();
      ctx.arc(left, top, (size / 2) + 3, 0, 2 * Math.PI, false);
      ctx.strokeStyle = "#000000";
      ctx.stroke();
      ctx.shadowColor = "#00000055";
      ctx.shadowBlur = 2;
      ctx.fillStyle = "#ffffff";
      ctx.fill();
      ctx.lineWidth = 2;
      ctx.restore();

      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle || 0));
      ctx.drawImage(resizeImg, -size/2, -size/2, size, size);
      ctx.restore();
    }

    function onClickDeleteIconControl(eventData: MouseEvent, transform: Transform) {
      const target = transform.target as any;
      props.onDeleteFromControl(target.name);
      fabricCanvas.current?.remove(target);
      fabricCanvas.current?.requestRenderAll();
      return true;
    }

    // fabric.
    const fb = fabric as any;
    fabric.Object.prototype.setControlsVisibility({
      tl: false, //top-left
      mt: false, // middle-top
      tr: false, //top-right
      ml: false, //middle-left
      mr: false, //middle-right
      bl: false, // bottom-left
      mb: false, //middle-bottom
      br: true, //bottom-right
      mtr: true // rotate icon
    });

    fabric.Object.prototype.controls.deleteControl = new fabric.Control({
      x: fabric.Object.prototype.controls.tr.x,
      y: fabric.Object.prototype.controls.tr.y,
      offsetX: fabricSetting.cornerSize / 2,
      offsetY: -fabricSetting.cornerSize / 2,
      cursorStyle: 'pointer',
      mouseUpHandler: onClickDeleteIconControl,
      render: renderDeleteIconControl,
    });

    const brControlAction = fabric.Object.prototype.controls.br.actionHandler;
    fabric.Object.prototype.controls.br = new fabric.Control({
      x: fabric.Object.prototype.controls.br.x,
      y: fabric.Object.prototype.controls.br.y,
      offsetX: fabricSetting.cornerSize / 2,
      offsetY: fabricSetting.cornerSize / 2,
      actionName: 'scale',
      cursorStyle: 'scale',
      render: renderResizeIconControl,
      actionHandler: brControlAction,
    });

    fabric.Object.prototype.controls.mtr = new fabric.Control({
      x: fabric.Object.prototype.controls.tl.x,
      y: fabric.Object.prototype.controls.tl.y,
      offsetX: -fabricSetting.cornerSize / 2,
      offsetY: -fabricSetting.cornerSize / 2,
      actionName: 'rotate',
      cursorStyle: 'pointer',
      render: renderRotateIconControl,
      actionHandler: fb.controlsUtils.rotationWithSnapping,
    });

    const infoIcon = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!--! Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cpath fill='%23FF6C00' d='M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM208 352c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c8.8 0 16-7.2 16-16s-7.2-16-16-16H272V240c0-8.8-7.2-16-16-16H216c-8.8 0-16 7.2-16 16s7.2 16 16 16h24v96H208zm48-168a24 24 0 1 0 0-48 24 24 0 1 0 0 48z'/%3E%3C/svg%3E";
    const infoImg = document.createElement('img');
    infoImg.src = infoIcon;
    function renderInfoIcon(ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: fabric.Object) {
      const size = fabricSetting.cornerSize; // this.cornerSize
      ctx.save();
      ctx.beginPath();
      ctx.arc(left, top, (size / 2) + 3, 0, 2 * Math.PI, false);
      ctx.fillStyle = "transparent";
      ctx.fill();
      ctx.lineWidth = 2;
      ctx.restore();

      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle || 0));
      ctx.drawImage(infoImg, -size/2, -size/2, size, size);
      ctx.restore();
    }
    fabric.Object.prototype.controls.bl = new fabric.Control({
      x: fabric.Object.prototype.controls.bl.x,
      y: fabric.Object.prototype.controls.bl.y,
      offsetX: -fabricSetting.cornerSize / 2,
      offsetY: fabricSetting.cornerSize / 2,
      actionName: undefined,
      cursorStyle: undefined,
      render: renderInfoIcon,
      actionHandler: undefined,
    });

    fabric.Object.prototype.drawBorders = function(ctx, styleOverride) {
      const vm = this as any;
      styleOverride = styleOverride || {};
      var wh = vm._calculateCurrentDimensions(),
          strokeWidth = vm.borderScaleFactor,
          width = wh.x + strokeWidth,
          height = wh.y + strokeWidth,
          hasControls = typeof styleOverride.hasControls !== 'undefined' ?
            styleOverride.hasControls : vm.hasControls,
          shouldStroke = false;

      ctx.save();
      ctx.strokeStyle = styleOverride.borderColor || vm.borderColor;
      ctx.shadowColor = styleOverride.shadowColor || vm.borderShadowColor;
      ctx.shadowBlur = styleOverride.shadowBlur || vm.borderShadowBlur;
      vm._setLineDash(ctx, styleOverride.borderDashArray || vm.borderDashArray, null);

      ctx.strokeRect(
        -width / 2,
        -height / 2,
        width,
        height
      );

      if (hasControls) {
        ctx.beginPath();
        vm.forEachControl(function(control: any, key: any, fabricObject: any) {
          // in this moment, the ctx is centered on the object.
          // width and height of the above function are the size of the bbox.
          if (control.withConnection && control.getVisibility(fabricObject, key)) {
            // reset movement for each control
            shouldStroke = true;
            ctx.moveTo(control.x * width, control.y * height);
            ctx.lineTo(
              control.x * width + control.offsetX,
              control.y * height + control.offsetY
            );
          }
        });
        if (shouldStroke) {
          ctx.stroke();
        }
      }
      ctx.restore();
      return vm;
    };
    canvasRef.current = document.createElement('canvas');
    // document.body.appendChild(canvasRef.current);
    canvasInsideRef.current = document.createElement('canvas');
    fabricCanvas.current = new fabric.Canvas(canvasRef.current, {
      preserveObjectStacking: true,
      allowTouchScrolling: false,
      renderOnAddRemove: false,
    });
    fabricCanvas.current.setWidth(SVG_SIZE);
    fabricCanvas.current.setHeight(SVG_SIZE);
    fabricCanvas.current.selection = false;

    fabricInsideCanvas.current = new fabric.Canvas(canvasInsideRef.current, {
      preserveObjectStacking: true,
      allowTouchScrolling: false,
      renderOnAddRemove: false,
      selection: false
    });
    fabricInsideCanvas.current.setWidth(SVG_SIZE);
    fabricInsideCanvas.current.setHeight(SVG_SIZE);
    fabricInsideCanvas.current.selection = false;

    fabric.Object.prototype.objectCaching = false;
    fabric.Object.prototype.cornerColor = 'blue';
    fabric.Object.prototype.cornerStyle = 'circle';

    fabric.Canvas.prototype.getPointer = function (e: any, ignoreZoom) {
      const vm = this as any;
      if (vm._absolutePointer && !ignoreZoom) {
        return vm._absolutePointer;
      }
      if (vm._pointer && ignoreZoom) {
        return vm._pointer;
      }

    /* BEGIN PATCH CODE */
      var positionOnScene = getMouseUvPosition();
      if (!positionOnScene) {
        return {
          x: 0,
          y: 0
        };
      }
      return {
        x: positionOnScene.x,
        y: positionOnScene.y
      };
    };
  }

  function handleUnprocessableIconInControl(name?: string) {
    if (!name) {
      removeIconLogoInfoToControl();
      return;
    }
    if (!fabricCanvas.current) {
      removeIconLogoInfoToControl();
      return;
    }
    const foundedSelectedLogoObj = fabricCanvas.current._objects.find((obj) => obj.name === name) as any;
    if (foundedSelectedLogoObj && foundedSelectedLogoObj.isUnprocessable) {
      addIconLogoInfoToControl();
    } else {
      removeIconLogoInfoToControl();
    }
    fabricCanvas.current?.requestRenderAll();
    updateCanvasTextureMap();
  }
  function addIconLogoInfoToControl() {
    fabric.Object.prototype.setControlsVisibility({
      tl: false, //top-left
      mt: false, // middle-top
      tr: false, //top-right
      ml: false, //middle-left
      mr: false, //middle-right
      bl: true, // bottom-left
      mb: false, //middle-bottom
      br: true, //bottom-right
      mtr: true // rotate icon
    });
  }
  function removeIconLogoInfoToControl() {
    fabric.Object.prototype.setControlsVisibility({
      tl: false, //top-left
      mt: false, // middle-top
      tr: false, //top-right
      ml: false, //middle-left
      mr: false, //middle-right
      bl: false, // bottom-left
      mb: false, //middle-bottom
      br: true, //bottom-right
      mtr: true // rotate icon
    });
  }
  function getJakoRightLeftString(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      fetch(props.jakoLogoRight)
        .then((res) => {
          const fileReader = new FileReader();
          res.blob().then((blobRes) => {
            fileReader.readAsText(blobRes);
            fileReader.onload = () => {
              const svgFileJakoLogoStringResult = fileReader.result as string;
              if (!svgFileJakoLogoStringResult) {
                reject();
                return;
              }
              if (!fabricCanvas.current) {
                reject();
                return;
              }
              const htmlJakoLogoRight = document.createElement('div');
              htmlJakoLogoRight.innerHTML = svgFileJakoLogoStringResult.toString();
              const jakoLogoRightSVG = htmlJakoLogoRight.children[0];

              jakoLogoRightSVG.setAttribute('width', SVG_SIZE.toString());
              jakoLogoRightSVG.setAttribute('height', SVG_SIZE.toString());
              jakoLogoRightSVG.setAttribute('fill', props.colorLogoHex);
              jakoLogoRightSVG.setAttribute('stroke', props.selectedJerseySectionColor[JerseySection.Front]);
              jakoLogoRightSVG.setAttribute('stroke-width', '0.4');

              const jakoLogoRightSvgNode = jakoLogoRightSVG.cloneNode(true) as any;
              const adjustedJakoLogoRightSvgStringHTML = jakoLogoRightSvgNode.outerHTML;
              const svgLogoRightColorChanged = adjustedJakoLogoRightSvgStringHTML;
              if (!props.jakoLogoLeft) {
                resolve([svgLogoRightColorChanged, '']);
                return;
              }
              fetch(props.jakoLogoLeft)
                .then((leftRes) => {
                  const fileReader = new FileReader();
                  leftRes.blob().then((blobLeftRes) => {
                    fileReader.readAsText(blobLeftRes);
                    fileReader.onload = () => {
                      const svgFileJakoLogoStringResult = fileReader.result as string;
                      if (!svgFileJakoLogoStringResult) {
                        reject();
                        return;
                      }
                      if (!fabricCanvas.current) {
                        reject();
                        return;
                      }
                      const htmlJakoLogoLeft = document.createElement('div');
                      htmlJakoLogoLeft.innerHTML = svgFileJakoLogoStringResult.toString();
                      const jakoLogoLeftSVG = htmlJakoLogoLeft.children[0];

                      jakoLogoLeftSVG.setAttribute('width', SVG_SIZE.toString());
                      jakoLogoLeftSVG.setAttribute('height', SVG_SIZE.toString());
                      jakoLogoLeftSVG.setAttribute('fill', props.colorLogoHex);
                      jakoLogoLeftSVG.setAttribute('stroke', props.selectedJerseySectionColor[JerseySection.Front]);
                      jakoLogoLeftSVG.setAttribute('stroke-width', '0.4');

                      const jakoLogoLeftSvgNode = jakoLogoLeftSVG.cloneNode(true) as any;
                      const adjustedJakoLogoLeftSvgStringHTML = jakoLogoLeftSvgNode.outerHTML;
                      const svgLogoLeftColorChanged = adjustedJakoLogoLeftSvgStringHTML;
                      resolve([svgLogoRightColorChanged, svgLogoLeftColorChanged]);
                    };
                  });
                });
            };
          });
        });
    });
  }

  function getJakoLogoColorSvg(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      fetch(jakoLogoPositionUv)
        .then((res) => {
          const fileReader = new FileReader();
          res.blob().then((blobRes) => {
            fileReader.readAsText(blobRes);
            fileReader.onload = () => {
              const svgFileJakoLogoStringResult = fileReader.result as string;
              if (!svgFileJakoLogoStringResult) {
                reject();
                return;
              }
              if (!fabricCanvas.current) {
                reject();
                return;
              }
              const htmlJakoLogo = document.createElement('div');
              htmlJakoLogo.innerHTML = svgFileJakoLogoStringResult.toString();
              const jakoLogoSVG = htmlJakoLogo.children[0];

              jakoLogoSVG.setAttribute('width', SVG_SIZE.toString());
              jakoLogoSVG.setAttribute('height', SVG_SIZE.toString());
              jakoLogoSVG.setAttribute('fill', props.colorLogoHex);
              jakoLogoSVG.setAttribute('stroke', props.selectedJerseySectionColor[JerseySection.Front]);
              jakoLogoSVG.setAttribute('stroke-width', '0.4');

              const jakoLogoSvgNode = jakoLogoSVG.cloneNode(true) as any;
              const adjustedJakoLogoSvgStringHTML = jakoLogoSvgNode.outerHTML;
              const svgLogoColorChanged = adjustedJakoLogoSvgStringHTML;
              if (!jakoDotsUvLeft) {
                resolve([svgLogoColorChanged, '', '']);
                return;
              }
              fetch(jakoDotsUvLeft)
                .then((dotsLeftRes) => {
                  const fileReader = new FileReader();
                  dotsLeftRes.blob().then((blobRes) => {
                    fileReader.readAsText(blobRes);
                    fileReader.onload = () => {
                      const svgFileJakoDotsLeftStringResult = fileReader.result as string;
                      if (!svgFileJakoDotsLeftStringResult) {
                        reject();
                        return;
                      }
                      if (!fabricCanvas.current) {
                        reject();
                        return;
                      }
                      fetch(jakoDotsUvRight)
                        .then((dotsRightRes) => {
                          const fileReader = new FileReader();
                          dotsRightRes.blob().then((blobRes) => {
                            fileReader.readAsText(blobRes);
                            fileReader.onload = () => {
                              const svgFileJakoDotsRightStringResult = fileReader.result as string;
                              if (!svgFileJakoDotsRightStringResult) {
                                reject();
                                return;
                              }
                              if (!fabricCanvas.current) {
                                reject();
                                return;
                              }
                              const htmlDotsLeft = document.createElement('div');
                              htmlDotsLeft.innerHTML = svgFileJakoDotsLeftStringResult.toString();
                              const dotsLeftSVG = htmlDotsLeft.children[0];
                              dotsLeftSVG.setAttribute('width', SVG_SIZE.toString());
                              dotsLeftSVG.setAttribute('height', SVG_SIZE.toString());
                              const dotsLeftSvgNode = dotsLeftSVG.cloneNode(true) as any;
                              const adjustedDotsLeftSvgStringHTML = dotsLeftSvgNode.outerHTML;

                              const htmlDotsRight = document.createElement('div');
                              htmlDotsRight.innerHTML = svgFileJakoDotsRightStringResult.toString();
                              const dotsRightSVG = htmlDotsRight.children[0];
                              dotsRightSVG.setAttribute('width', SVG_SIZE.toString());
                              dotsRightSVG.setAttribute('height', SVG_SIZE.toString());
                              const dotsRightSvgNode = dotsRightSVG.cloneNode(true) as any;
                              const adjustedDotsRightSvgStringHTML = dotsRightSvgNode.outerHTML;

                              const svgDotsColorLeftChanged = adjustedDotsLeftSvgStringHTML.replace('0 0 2048 2048"', `0 0 2048 2048" fill="${props.colorDotsLeftHex}" `);
                              const svgDotsColorRightChanged = adjustedDotsRightSvgStringHTML.replace('0 0 2048 2048"', `0 0 2048 2048" fill="${props.colorDotsRightHex}" `);
                              resolve([svgLogoColorChanged, svgDotsColorLeftChanged, svgDotsColorRightChanged]);
                            };
                          });
                        });
                    };
                  });
                });
            };
          });
        });
    });
  }

  function initAddTexts(textItems: AddedTextItems) {
    if (!Object.keys(textItems).length) {
      return Promise.resolve();
    }
    const addTextInitPromises = Object.keys(textItems).map((itemKey) => addTextInit(textItems[itemKey]));
    return Promise.all(addTextInitPromises);
  }

  function initAddLogos(logoItems: AddedLogoItems) {
    if (!Object.keys(logoItems).length) {
      return Promise.resolve([]);
    }
    const addLogoInitPromises = Object.keys(logoItems).map((itemKey) => addLogoInit(logoItems[itemKey]));
    return Promise.all(addLogoInitPromises);
  }

  function addLogo({ imageObj }: AddedImagePosition) {
    const tmpWidthHeightSVG = document.createElement('div');
    tmpWidthHeightSVG.innerHTML = imageObj.src.toString();
    const imageSvg = tmpWidthHeightSVG.children[0] as any;
    imageSvg.setAttribute('width', imageSvg.viewBox.baseVal.width.toString());
    imageSvg.setAttribute('height',  imageSvg.viewBox.baseVal.height.toString());
    const imageSvgNode = imageSvg.cloneNode(true) as any;
    const rectElementForEmptySpace = document.createElement('rect');
    rectElementForEmptySpace.setAttribute('width', imageSvg.viewBox.baseVal.width.toString());
    rectElementForEmptySpace.setAttribute('height', imageSvg.viewBox.baseVal.height.toString());
    rectElementForEmptySpace.setAttribute('x', '0');
    rectElementForEmptySpace.setAttribute('y', '0');
    rectElementForEmptySpace.setAttribute('fill', 'transparent');
    imageSvgNode.appendChild(rectElementForEmptySpace);
    const adjustedImageSvgStringHTML = imageSvgNode.outerHTML;

    const objId = 'tab' + FunctionTab.Logo + '_' + uuidv4();
    const onlySvgString = adjustedImageSvgStringHTML.substring(adjustedImageSvgStringHTML.indexOf('<svg'));
    const formattedSvgString = onlySvgString.includes('<image')
      ? onlySvgString.replaceAll(/[\u{0080}-\u{FFFF}\r\n]/gu, ' ')
      : onlySvgString.replaceAll(/[\u{0080}-\u{FFFF}\r\n]/gu, ' ');

    function setLogoObject(objects: any[]) {
      const svgImage = fabric.util.groupSVGElements(objects, {
        left: 0,
        top: 0,
        selectable: true,
        centeredScaling: true,
        cornerStyle: 'circle',
        transparentCorners: false,
        snapAngle: 90,
        snapThreshold: 5,
        editable: false,
        name: objId,
        noScaleCache: true,
        lockUniScaling: true,
        objectCaching: false,
        padding: 0,
        caching: false,
        originX: 'center',
        originY: 'center',
        width: imageSvg.viewBox.baseVal.width,
        height: imageSvg.viewBox.baseVal.height,
      }) as any;
      svgImage.name = objId;
      svgImage.left = 0;
      svgImage.top = 0;
      svgImage.originX = 'center';
      svgImage.originY = 'center';
      svgImage.centeredScaling = true;
      svgImage.lockUniScaling = true;
      svgImage.snapAngle = 90;
      svgImage.snapThreshold = 5;
      svgImage.noScaleCache = true;
      svgImage.objectCaching = false;
      svgImage.isUnprocessable = imageObj.isUnprocessable;
      svgImage.clipPath = new fabric.Rect({
        width: imageSvg.viewBox.baseVal.width,
        height: imageSvg.viewBox.baseVal.height,
        top: -imageSvg.viewBox.baseVal.height / 2,
        left: -imageSvg.viewBox.baseVal.width / 2
      });
      let initHeight = MAX_LOGO_SIZE_HEIGHT_CM;
      if ((svgImage.width && svgImage.height) && (svgImage.width < svgImage.height)) {
        initHeight = MAX_LOGO_SIZE_HEIGHT_CM;
      } else {
        initHeight = MAX_LOGO_SIZE_HEIGHT_CM / 2;
      }
      svgImage.scaleToHeight(initHeight * cmFactorAdjustedRef.current);
      svgImage.maxHeight = MAX_LOGO_SIZE_HEIGHT_CM;
      svgImage.scaledWidth = svgImage.getScaledWidth() / cmFactorAdjustedRef.current;
      const logoSize = {
        maxHeight: MAX_LOGO_SIZE_HEIGHT_CM,
        scaledWidth: svgImage.getScaledWidth() / cmFactorAdjustedRef.current,
        scaledHeight: svgImage.getScaledHeight() / cmFactorAdjustedRef.current
      };
      fabricCanvas.current?.add(svgImage);
      if (props.isAddingState !== null && props.isAddingState !== undefined) {
        onAddImageItemHandler(props.isAddingState, svgImage, imageObj, logoSize);
      }
    }
    fabric.loadSVGFromString(
      formattedSvgString,
      function (objects) {
        if (objects.length === 0) {
          fabric.loadSVGFromString(onlySvgString.replaceAll(/[\u{0080}-\u{FFFF}\r\n]/gu, ' '), function(tryAgainObjects) {
            setLogoObject(tryAgainObjects);
          });
        } else {
          setLogoObject(objects);
        }
      }
    );
  }

  function addLogoInit(logoItem: LogoEditorObject) {
    return new Promise((resolve, reject) => logoItem.reference
      ? JfnetServices.getLogoByReference(logoItem.reference)
        .then((logoImageByRefRes) => {
          let svgTextString = logoImageByRefRes.data;
          if (svgTextString.slice(0, 10).includes('<?xml')) {
            svgTextString = svgTextString.substring(svgTextString.indexOf('<svg')+1);
            svgTextString = '<' + svgTextString;
          }
          const tmpWidthHeightSVG = document.createElement('div');
          tmpWidthHeightSVG.innerHTML = svgTextString.toString();
          const imageSvg = tmpWidthHeightSVG.children[0] as any;
          imageSvg.setAttribute('width', imageSvg.viewBox.baseVal.width.toString());
          imageSvg.setAttribute('height',  imageSvg.viewBox.baseVal.height.toString());
          const imageSvgNode = imageSvg.cloneNode(true) as any;
          const rectElementForEmptySpace = document.createElement('rect');
          rectElementForEmptySpace.setAttribute('width', imageSvg.viewBox.baseVal.width.toString());
          rectElementForEmptySpace.setAttribute('height', imageSvg.viewBox.baseVal.height.toString());
          rectElementForEmptySpace.setAttribute('x', '0');
          rectElementForEmptySpace.setAttribute('y', '0');
          rectElementForEmptySpace.setAttribute('fill', 'transparent');
          imageSvgNode.appendChild(rectElementForEmptySpace);
          const adjustedImageSvgStringHTML = imageSvgNode.outerHTML;

          const onlySvgString = adjustedImageSvgStringHTML.substring(adjustedImageSvgStringHTML.indexOf('<svg'));
          const formattedSvgString = onlySvgString.includes('<image')
            ? onlySvgString.replaceAll(/[\u{0080}-\u{FFFF}\r\n]/gu, ' ')
            : onlySvgString.replaceAll(/[\u{0080}-\u{FFFF}\r\n]/gu, ' ');
          function setLogoObject(objects: any[]) {
            const svgImage = fabric.util.groupSVGElements(objects, {
              left: logoItem.x,
              top: logoItem.y,
              selectable: true,
              centeredScaling: true,
              cornerStyle: 'circle',
              transparentCorners: false,
              angle: 0,
              snapAngle: 90,
              snapThreshold: 5,
              editable: false,
              name: logoItem.name,
              noScaleCache: true,
              lockUniScaling: true,
              objectCaching: false,
              padding: 0,
              caching: false,
              originX: 'center',
              originY: 'center',
              width: imageSvg.viewBox.baseVal.width,
              height: imageSvg.viewBox.baseVal.height,
            }) as any;
            svgImage.name = logoItem.name;
            svgImage.left = props.isMobile ? logoItem.x / 4 : logoItem.x;
            svgImage.top = props.isMobile ? logoItem.y / 4 : logoItem.y;
            svgImage.originX = 'center';
            svgImage.originY = 'center';
            svgImage.centeredScaling = true;
            svgImage.lockUniScaling = true;
            svgImage.snapAngle = 90;
            svgImage.snapThreshold = 5;
            svgImage.noScaleCache = true;
            svgImage.objectCaching = false;
            svgImage.isUnprocessable = logoItem.isUnprocessable;
            svgImage.clipPath = new fabric.Rect({
              width: imageSvg.viewBox.baseVal.width,
              height: imageSvg.viewBox.baseVal.height,
              top: -imageSvg.viewBox.baseVal.height / 2,
              left: -imageSvg.viewBox.baseVal.width / 2
            });
            const onlySvgString = adjustedImageSvgStringHTML.substring(adjustedImageSvgStringHTML.indexOf('<svg'));
            const formattedSvgString = onlySvgString.includes('<image')
              ? onlySvgString.replaceAll(/[\u{0080}-\u{FFFF}\r\n]/gu, ' ')
              : onlySvgString.replaceAll(/[\u{0080}-\u{FFFF}\r\n]/gu, ' ');
            svgImage.src = formattedSvgString;
            if (logoItem.indexLayer) {
              svgImage.indexLayer = logoItem.indexLayer;
            }
            if (logoItem.scaledHeight) {
              svgImage.scaleToHeight(logoItem.scaledHeight * cmFactorAdjustedRef.current);
              svgImage.maxHeight = logoItem.maxHeight;
              svgImage.scaledWidth = svgImage.getScaledWidth() / cmFactorAdjustedRef.current;
            } else {
              svgImage.scaleToHeight(logoItem.maxHeight * cmFactorAdjustedRef.current);
              svgImage.maxHeight = MAX_LOGO_SIZE_HEIGHT_CM;
              svgImage.scaledWidth = svgImage.getScaledWidth() / cmFactorAdjustedRef.current;
            }
            fabricCanvas.current?.add(svgImage);
            resolve(svgImage);
            setTimeout(() => {
              if (logoItem.rotation) {
                svgImage.angle = logoItem.rotation;
              }
              setTimeout(() => {
                fabricCanvas.current?.requestRenderAll();
                updateCanvasTextureMap();
              }, 100);
            }, 100);
          }
          fabric.loadSVGFromString(
            formattedSvgString,
            function (objects) {
              if (objects.length === 0) {
                fabric.loadSVGFromString(onlySvgString.replaceAll(/[\u{0080}-\u{FFFF}\r\n]/gu, ' '), function(tryAgainObjects) {
                  setLogoObject(tryAgainObjects);
                });
              } else {
                setLogoObject(objects);
              }
            }
          );
        })
      : resolve('')
    );
  }

  function addText({
    textString,
    addingState,
  }: AddedTextPosition) {
    const idName = 'tab' + addingState + '__' + uuidv4();

    JfnetServices.fontToSvg({
      text: textString,
      font: props.selectedFontFamily
    }).then((res) => {
      let svgTextString = res.data;
      if (svgTextString.slice(0, 10).includes('<?xml')) {
        svgTextString = svgTextString.substring(svgTextString.indexOf('<svg')+1);
        svgTextString = '<' + svgTextString;
      }
      const onlySvgString = svgTextString.substring(svgTextString.indexOf('<svg'));
      const formattedSvgString = onlySvgString.includes('<image')
        ? onlySvgString.replaceAll(/[\u{0080}-\u{FFFF}\r\n]/gu, ' ')
        : onlySvgString.replaceAll(/[\u{0080}-\u{FFFF}\r\n]/gu, ' ');
      fabric.loadSVGFromString(
        formattedSvgString,
        function (objects) {
          const svgImage = fabric.util.groupSVGElements(objects, {
            left: 0,
            top: 0,
            selectable: true,
            centeredScaling: true,
            cornerStyle: 'circle',
            transparentCorners: false,
            snapAngle: 90,
            snapThreshold: 5,
            editable: false,
            name: idName,
            noScaleCache: true,
            lockUniScaling: true,
            objectCaching: false,
            padding: 0,
            caching: false,
            originX: 'center',
            originY: 'center',
          }) as any;

          svgImage.name = idName;
          svgImage.left = 0;
          svgImage.top = 0;
          svgImage.originX = 'center';
          svgImage.originY = 'center';
          svgImage.centeredScaling = true;
          svgImage.lockUniScaling = true;
          svgImage.snapAngle = 90;
          svgImage.snapThreshold = 5;
          svgImage.noScaleCache = true;
          svgImage.objectCaching = false;
          svgImage.fontFamily = props.selectedFontFamily;
          svgImage.fill = props.selectedTextFill
            ? props.selectedTextFill
            : props.colorInvertedBg ? props.colorInvertedBg : '#000000';
          svgImage.text = textString;

          svgImage.maxHeight = MAX_TEXT_SIZE_HEIGHT_CM;
          svgImage.scaleToHeight(1);

          const textSize = {
            maxHeight: MAX_TEXT_SIZE_HEIGHT_CM,
          };

          fabricCanvas.current?.add(svgImage);
          if (props.isAddingState) {
            const file = new File([svgTextString], 'test.svg', {
              type: 'image/svg+xml'
            });
            JfnetServices.checkLogoUpload(file)
              .then((uploadRes) => {
                if (props.isAddingState) {
                  onAddTextItemHandler(
                    props.isAddingState,
                    svgImage,
                    uploadRes.data.reference,
                    textSize
                  );
                }
              })
              .catch((err) => {
                confirmAlert({
                  customUI: ({ onClose }) => {
                    if (err.response && err.response.status === 413) {
                      return (
                        <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                          <h5 className="confirm-popup-container__title">
                            { t('configurator_3d_error_file_too_large') }
                          </h5>
                          <button className="button_primary" onClick={onClose}>
                            { t('configurator_3d_ok') }
                          </button>
                        </div>
                      );
                    }
                    return (
                      <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                        <h5 className="confirm-popup-container__title">
                          { t('configurator_3d_error_something_wrong') }
                        </h5>
                        <button className="button_primary" onClick={onClose}>
                          { t('configurator_3d_ok') }
                        </button>
                      </div>
                    );
                  }
                });
              });
          }
        }
      );
    });
  }

  function addTextInit(textItem: TextEditorObject) {
    return new Promise((resolve, reject) => JfnetServices.getLogoByReference(textItem.reference)
      .then((textImageByRefRes) => {
        let svgTextString = textImageByRefRes.data;
        if (svgTextString.slice(0, 10).includes('<?xml')) {
          svgTextString = svgTextString.substring(svgTextString.indexOf('<svg')+1);
          svgTextString = '<' + svgTextString;
        }
        const onlySvgString = svgTextString.substring(svgTextString.indexOf('<svg'));
        const formattedSvgString = onlySvgString.includes('<image')
          ? onlySvgString.replaceAll(/[\u{0080}-\u{FFFF}\r\n]/gu, ' ')
          : onlySvgString.replaceAll(/[\u{0080}-\u{FFFF}\r\n]/gu, ' ');
        fabric.loadSVGFromString(
          formattedSvgString,
          function (objects) {
            const svgImage = fabric.util.groupSVGElements(objects, {
              left: 0,
              top: 0,
              selectable: true,
              centeredScaling: true,
              cornerStyle: 'circle',
              transparentCorners: false,
              angle: 0,
              snapAngle: 90,
              snapThreshold: 5,
              editable: false,
              name: textItem.name,
              noScaleCache: true,
              lockUniScaling: true,
              objectCaching: false,
              padding: 0,
              caching: false,
              originX: 'center',
              originY: 'center',
            }) as any;

            svgImage.name = textItem.name;
            svgImage.originX = 'center';
            svgImage.originY = 'center';
            svgImage.centeredScaling = true;
            svgImage.lockUniScaling = true;
            svgImage.snapAngle = 90;
            svgImage.snapThreshold = 5;
            svgImage.noScaleCache = true;
            svgImage.objectCaching = false;
            svgImage.fontFamily = textItem.fontFamily || props.selectedFontFamily;
            svgImage.fill = textItem.fill;
            svgImage.text = textItem.text;
            svgImage.reference = textItem.reference;

            if (textItem.indexLayer) {
              svgImage.indexLayer = textItem.indexLayer;
            }

            if (textItem.scaledHeight) {
              svgImage.scaleToHeight(textItem.scaledHeight * cmFactorAdjustedRef.current);
              svgImage.maxHeight = textItem.maxHeight;
              svgImage.scaledWidth = svgImage.getScaledWidth() / cmFactorAdjustedRef.current;
            } else {
              svgImage.scaleToHeight(textItem.maxHeight * cmFactorAdjustedRef.current);
              svgImage.maxHeight = MAX_TEXT_SIZE_HEIGHT_CM;
              svgImage.scaledWidth = svgImage.getScaledWidth() / cmFactorAdjustedRef.current;
            }

            svgImage.left = props.isMobile ? textItem.x / 4 : textItem.x;
            svgImage.top = props.isMobile ? textItem.y / 4 : textItem.y;

            delete svgImage.controls.ml;
            delete svgImage.controls.mr;
            delete svgImage.controls.mb;
            delete svgImage.controls.mt;
            fabricCanvas.current?.add(svgImage);
            resolve(svgImage);
            setTimeout(() => {
              if (textItem.rotation) {
                svgImage.angle = textItem.rotation;
              }
              setTimeout(() => {
                fabricCanvas.current?.requestRenderAll();
                updateCanvasTextureMap();
              }, 100);
            }, 100);
          }
        );
      })
      .catch(() => {
        reject('');
      })
    );
  }

  function changeColor(selectedColor: any): Promise<void> {
    if (!Object.keys(selectedColor).length) {
      return Promise.resolve();
    }
    if (!fabricCanvas.current || !fabricInsideCanvas.current) {
      return Promise.resolve();
    }
    if (!designSvgPatternObject.current) {
      return Promise.resolve();
    }
    if (!nodes['outside'].material.map) {
      return Promise.resolve();
    }

    const defaultAreaKeys = Object.keys(selectedColor).filter((colorKey) =>
      !colorKey.includes('copy-')
      && !colorKey.includes('bg-')
    );

    function setInsideColorHandler(insidePartItem: any, partKey: string) {
      const onlyKNumber =  props.currentCuttingFormCode.slice(0, 5);
      const customEnableToFillInsideByCuttingCodeKeys: string[] | undefined = ENABLE_INSIDE_COLOR_PART_KEYS_BY_CUTTING_CODE[onlyKNumber];
      const isEnableToFillInsidePart = customEnableToFillInsideByCuttingCodeKeys
        ? customEnableToFillInsideByCuttingCodeKeys.some((key) => partKey.includes(key))
        : DEFAULT_ENABLE_INSIDE_COLOR_PART_KEYS.some((key) => partKey.includes(key));
      if (!isEnableToFillInsidePart) {
        return;
      }
      if (!insidePartItem) {
        return;
      }
      if (!customEnableToFillInsideByCuttingCodeKeys) {
        insidePartItem.fill = selectedColor[partKey];
        return;
      }
      const foundedCustomEnableFillInsideKey = customEnableToFillInsideByCuttingCodeKeys.find((key) => partKey.includes(key));
      if (foundedCustomEnableFillInsideKey) {
        insidePartItem.fill = selectedColor[`${foundedCustomEnableFillInsideKey}_main`] || selectedColor[partKey];
      }
    }

    defaultAreaKeys.forEach((partKey) => {
      const foundedPartItem = designSvgPatternObject.current._objects.find((obj: any) => obj.id === partKey);
      const foundedCopyPartItem = designSvgPatternObject.current._objects.find((obj: any) => obj.id === 'copy-' + partKey);
      const foundedCopyBgPartItem = designSvgPatternObject.current._objects.find((obj: any) => obj.id === 'bg-' + partKey);

      const foundedPartInsideItem = designSvgPatternInsideObject.current._objects.find((obj: any) => obj.id === partKey);
      const foundedCopyPartInsideItem = designSvgPatternInsideObject.current._objects.find((obj: any) => obj.id === 'copy-' + partKey);
      const foundedCopyBgPartInsideItem = designSvgPatternInsideObject.current._objects.find((obj: any) => obj.id === 'bg-' + partKey);

      if (props.selectedJerseySectionPattern[partKey] && props.selectedJerseySectionPattern[partKey].svg) {
        foundedPartItem.fill = selectedColor[partKey];
        setInsideColorHandler(foundedPartInsideItem, partKey);
      } else if (foundedPartItem) {
        foundedPartItem.fill = selectedColor[partKey];
        setInsideColorHandler(foundedPartInsideItem, partKey);
        if (foundedCopyPartItem) {
          foundedCopyPartItem.fill = selectedColor[partKey];
          setInsideColorHandler(foundedCopyPartInsideItem, partKey);
        }
      }
      if (foundedCopyBgPartItem) {
        foundedCopyBgPartItem.fill = selectedColor[partKey];
        setInsideColorHandler(foundedCopyBgPartInsideItem, partKey);
      }
    });

    return delay(100).then(() => {
      fabricCanvas.current?.requestRenderAll();
      fabricInsideCanvas.current?.requestRenderAll();
      updateCanvasTextureMap();
    });
  }

  function changePattern(selectedPatternObj: AddedPatternJerseySectionItems): Promise<void> {
    if (!fabricCanvas.current) {
      return Promise.resolve();
    }
    if (!designSvgPatternObject.current) {
      return Promise.resolve();
    }
    if (!nodes['outside'].material.map) {
      return Promise.resolve();
    }
    const filteredCopyObjects = designSvgPatternObject.current._objects.slice()
      .filter((jerseyPartObj: any) => jerseyPartObj.id.includes('copy-'));
    const getPossibleJerseySection = Object.keys(selectedPatternObj);
    if (getPossibleJerseySection.length === 0) {
      fabricCanvas.current.requestRenderAll();
      updateCanvasTextureMap();
      return Promise.resolve();
    }

    console.log('designSvgPatternObject.current._objects', designSvgPatternObject.current._objects);
    console.log('fabricCanvas.current._objects', fabricCanvas.current._objects);
    console.log('filteredCopyObjects', filteredCopyObjects);
    return new Promise((resolve, reject) => {
      filteredCopyObjects.forEach((jerseyPartObj: any) => {
        const jerseySectionId = jerseyPartObj.id.split('copy-')[1];
        if (selectedPatternObj[jerseySectionId]) {
          // --------- Adjust Pattern SVG ---------------
          const foundedPrintCutCoords = props.printcutCoords.find((section) => jerseySectionId.toString().toLowerCase().includes(section.name.split('_')[0].toLowerCase()));
          // backendHeight: 2241
          // backendWidth: 1635.1
          // coefficientX: 1.6894345681267016
          // coefficientY: 1.7131466841746645
          // name: "front_main"
          // x: 139.86928198232437
          // y: 51.30909151678812
          const printcutCoords = foundedPrintCutCoords
            ? foundedPrintCutCoords
            : props.printcutCoords[0]
              ? props.printcutCoords[0]
              : {
                name: 'front_main',
                x: 139.86928198232437,
                y: 51.30909151678812,
                coefficientX: 1.6894345681267016,
                coefficientY: 1.7131466841746645
              };
          const patternFill =
            selectedPatternObj[jerseySectionId].fill
              ? selectedPatternObj[jerseySectionId].fill
              : props.mainSchwarz
                ? '#' + props.mainSchwarz.colorHexCode
                : '#26282D';
          const patternScale = !selectedPatternObj[jerseySectionId].change_size ? 1 : (selectedPatternObj[jerseySectionId].scale || 1);
          const patternOffsetX = (selectedPatternObj[jerseySectionId].left || 0);
          const patternOffsetY = (selectedPatternObj[jerseySectionId].top || 0);
          const patternSvgString = selectedPatternObj[jerseySectionId].svg;
//           const patternSvgString = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Ebene_1" x="0px" y="0px" viewBox="0 0 2115.2 2584.3" style="enable-background:new 0 0 2115.2 2584.3;" xml:space="preserve">
// <style type="text/css">
// 	.st0{fill:#070706;}
// </style>
// <rect x="632.4" class="st0" width="850.4" height="2584.3"/>
// </svg>`;
          if (!patternSvgString) {
            jerseyPartObj.set('fill', props.selectedJerseySectionColor[jerseySectionId]);
            fabricCanvas.current?.requestRenderAll();
            updateCanvasTextureMap();
            setTimeout(() => {
              resolve();
            }, 200);
            return;
          }

          const onlySvgString = patternSvgString.substring(patternSvgString.indexOf('<svg'));
          const patternSvgFileTextResult = onlySvgString;
          if (!patternSvgFileTextResult) {
            fabricCanvas.current?.requestRenderAll();
            updateCanvasTextureMap();
            setTimeout(() => {
              reject('Missing patternSvgFileTextResult');
            }, 200);
            return;
          }
          const htmlObject = document.createElement('div');
          htmlObject.innerHTML = patternSvgFileTextResult.toString();
          const htmlObjectSvgAny = htmlObject.children[0] as any;
          const svgWidth = parseFloat(htmlObjectSvgAny.viewBox.baseVal.width);
          const svgHeight = parseFloat(htmlObjectSvgAny.viewBox.baseVal.height);
          htmlObjectSvgAny.setAttribute('viewBox', `0 0 ${svgWidth} ${svgHeight}`);
          htmlObjectSvgAny.setAttribute('width', svgWidth);
          htmlObjectSvgAny.setAttribute('height', svgHeight);
          htmlObjectSvgAny.setAttribute('style', '');

          const svgChildrenArray = Array.prototype.slice.call(htmlObjectSvgAny.children);
          const styleTag = svgChildrenArray.find((item) => item.tagName === 'style');
          if (styleTag) {
            styleTag.innerHTML = `.st0{fill:${patternFill};}.st1{fill:none;display:none;}`;
          } else {
            svgChildrenArray.slice().forEach((ch) => {
              ch.setAttribute('fill', patternFill);
            });
          }
          const adjustedPatternString = htmlObject.children[0].outerHTML;
          fabric.loadSVGFromString(adjustedPatternString, function(objs) {
            const svgImage = fabric.util.groupSVGElements(objs, {});
            svgImage.set({
              objectCaching: false,
              centeredScaling: false,
              selectable: false,
              name: uuidv4(),
              width: svgWidth,
              height: svgHeight,
            });
            if (selectedPatternObj[jerseySectionId].change_size) {
              svgImage.scaleToHeight((svgImage.getScaledHeight() / printcutCoords.coefficientY) * patternScale);
            } else {
              svgImage.scaleToHeight(svgImage.getScaledHeight() / printcutCoords.coefficientY);
            }
            const patternSourceCanvas = new fabric.StaticCanvas(null);
            patternSourceCanvas.skipOffscreen = false;
            patternSourceCanvas.svgViewportTransformation = true;
            patternSourceCanvas.enableRetinaScaling = false;
            patternSourceCanvas.imageSmoothingEnabled = false;
            patternSourceCanvas.setDimensions({
              width: svgImage.getScaledWidth(),
              height: svgImage.getScaledHeight(),
            });
            patternSourceCanvas.add(svgImage);
            patternSourceCanvas.calcOffset();

            const patternSrc = patternSourceCanvas.getElement() as any;
            const pattern = new fabric.Pattern({
              source: patternSrc,
              repeat: selectedPatternObj[jerseySectionId].tileable ? 'repeat' : 'no-repeat',
            });
            // 2116.40 x  2587
            let designOffsetX = 0;
            let designOffsetY = 0;
            const isDesignPart = jerseySectionId.toString().toLowerCase().includes('design');
            if (isDesignPart) {
              const foundedDesignPart = designSvgPatternObject.current._objects.find((item: any) => item.id.includes(jerseySectionId));
              if (foundedDesignPart) {
                const designTlx = foundedDesignPart.pathOffset.x - (foundedDesignPart.width / 2);
                const designTly = foundedDesignPart.pathOffset.y - (foundedDesignPart.height / 2);
                const foundedCurrentPart = designSvgPatternObject.current._objects.find((item: any) => item.id.includes(printcutCoords.name));
                if (foundedCurrentPart) {
                  const currentTlx = foundedCurrentPart.pathOffset.x - (foundedCurrentPart.width / 2);
                  const currentTly = foundedCurrentPart.pathOffset.y - (foundedCurrentPart.height / 2);
                  designOffsetX = designTlx - currentTlx;
                  designOffsetY = designTly - currentTly;
                }
              }
            }

            const correctOffsetWithBackendX = -(printcutCoords.x + designOffsetX);
            const correctOffsetWithBackendY = -(printcutCoords.y + designOffsetY);

            // Offset
            if (selectedPatternObj[jerseySectionId].move_horizontally && selectedPatternObj[jerseySectionId].move_vertically) {
              pattern.offsetX = correctOffsetWithBackendX + patternOffsetX;
              pattern.offsetY = correctOffsetWithBackendY + patternOffsetY;
            } else if (selectedPatternObj[jerseySectionId].move_horizontally) {
              pattern.offsetX = correctOffsetWithBackendX + patternOffsetX;
              pattern.offsetY = correctOffsetWithBackendY;
            } else if (selectedPatternObj[jerseySectionId].move_vertically) {
              pattern.offsetX = correctOffsetWithBackendX;
              pattern.offsetY = correctOffsetWithBackendY + patternOffsetY;
            } else {
              pattern.offsetX = correctOffsetWithBackendX;
              pattern.offsetY = correctOffsetWithBackendY;
            }
            console.log('jerseyPartObj', jerseyPartObj);
            jerseyPartObj.set('fill', pattern);
            setTimeout(() => {
              patternSourceCanvas.requestRenderAll();
              fabricCanvas.current?.requestRenderAll();
              updateCanvasTextureMap();
            }, 100);
          });
          // --------- End of Adjust Pattern SVG ---------------
        } else if (props.selectedJerseySectionColor[jerseySectionId]) {
          jerseyPartObj.set("fill", props.selectedJerseySectionColor[jerseySectionId]);
          fabricCanvas.current?.requestRenderAll();
          updateCanvasTextureMap();
          setTimeout(() => {
            resolve();
          }, 200);
        }
      });
    });

    // const params = new Proxy(new URLSearchParams(window.location.search), {
    //   get: (searchParams, prop) => searchParams.get(prop.toString()),
    // }) as any;
    // return new Promise((resolve, reject) => {
    //   filteredCopyObjects.forEach((jerseyPartObj: any) => {
    //     const jerseySectionId = jerseyPartObj.id.split('copy-')[1];
    //     if (selectedPatternObj[jerseySectionId]) {
    //       // --------- Adjust Pattern SVG ---------------
    //       const foundedPrintCutCoords = props.printcutCoords.find((section) => jerseySectionId.toString().toLowerCase().includes(section.name.split('_')[0].toLowerCase()));
    //       const printcutCoords = foundedPrintCutCoords
    //         ? foundedPrintCutCoords
    //         : props.printcutCoords[0]
    //           ? props.printcutCoords[0]
    //           : {
    //             name: '',
    //             x: 0,
    //             y: 0,
    //             coefficientX: 1,
    //             coefficientY: 1
    //           };
    //       const patternFill =
    //         selectedPatternObj[jerseySectionId].fill
    //           ? selectedPatternObj[jerseySectionId].fill
    //           : props.mainSchwarz
    //             ? '#' + props.mainSchwarz.colorHexCode
    //             : '#26282D';
    //       const patternScale = !selectedPatternObj[jerseySectionId].change_size ? 1 : (selectedPatternObj[jerseySectionId].scale || 1);
    //       const patternOffsetX = (selectedPatternObj[jerseySectionId].left || 0);
    //       const patternOffsetY = (selectedPatternObj[jerseySectionId].top || 0);
    //       const patternSvgString = selectedPatternObj[jerseySectionId].svg;
    //       if (!patternSvgString) {
    //         jerseyPartObj.set('fill', props.selectedJerseySectionColor[jerseySectionId]);
    //         fabricCanvas.current?.requestRenderAll();
    //         updateCanvasTextureMap();
    //         setTimeout(() => {
    //           resolve();
    //         }, 200);
    //         return;
    //       }

    //       const onlySvgString = patternSvgString.substring(patternSvgString.indexOf('<svg'));
    //       const patternSvgFileTextResult = onlySvgString;
    //       if (!patternSvgFileTextResult) {
    //         fabricCanvas.current?.requestRenderAll();
    //         updateCanvasTextureMap();
    //         setTimeout(() => {
    //           reject('Missing patternSvgFileTextResult');
    //         }, 200);
    //         return;
    //       }
    //       const htmlObject = document.createElement('div');
    //       htmlObject.innerHTML = patternSvgFileTextResult.toString();
    //       const htmlObjectSvgAny = htmlObject.children[0] as any;
    //       const svgWidth = parseFloat(htmlObjectSvgAny.viewBox.baseVal.width);
    //       const svgHeight = parseFloat(htmlObjectSvgAny.viewBox.baseVal.height);
    //       const initWidth = svgWidth / printcutCoords.coefficientX;
    //       const initHeight = svgHeight / printcutCoords.coefficientY;
    //       htmlObjectSvgAny.setAttribute('viewBox', `0 0 ${svgWidth} ${svgHeight}`);
    //       htmlObjectSvgAny.setAttribute('width', svgWidth);
    //       htmlObjectSvgAny.setAttribute('height', svgHeight);
    //       // htmlObjectSvgAny.setAttribute('style', '');
    //       const svgChildrenArray = Array.prototype.slice.call(htmlObjectSvgAny.children);
    //       const styleTag = svgChildrenArray.find((item) => item.tagName === 'style');
    //       if (styleTag) {
    //         styleTag.innerHTML = `.st0{fill:${patternFill};}.st1{fill:none;display:none;}`;
    //       } else {
    //         svgChildrenArray.slice().forEach((ch) => {
    //           ch.setAttribute('fill', patternFill);
    //         });
    //       }
    //       const adjustedPatternString = htmlObjectSvgAny.outerHTML;
    //       fabric.loadSVGFromString(adjustedPatternString, function(objs) {
    //         const svgImage = fabric.util.groupSVGElements(objs, {});
    //         svgImage.set({

    //           centeredScaling: false,
    //           selectable: false,
    //           name: uuidv4(),
    //           width: svgWidth,
    //           height: svgHeight
    //         });

    //         if (selectedPatternObj[jerseySectionId].change_size) {
    //           svgImage.scaleToWidth(svgImage.getScaledWidth() * patternScale);
    //         } else {
    //           svgImage.scaleToWidth(svgImage.getScaledWidth());
    //         }

    //         const patternSourceCanvas = new fabric.StaticCanvas(null);
    //         patternSourceCanvas.skipOffscreen = false;
    //         patternSourceCanvas.svgViewportTransformation = true;
    //         patternSourceCanvas.enableRetinaScaling = false;
    //         patternSourceCanvas.imageSmoothingEnabled = false;
    //         // patternSourceCanvas.s
    //         // patternSourceCanvas.setDimensions({
    //         //   width: svgWidth,
    //         //   height: svgHeight,
    //         // });
    //         patternSourceCanvas.setWidth(svgWidth);
    //         patternSourceCanvas.setHeight(svgHeight);
    //         patternSourceCanvas.add(svgImage);
    //         // patternSourceCanvas.centerObject(svgImage);
    //         patternSourceCanvas.calcOffset();

    //         const svgs = patternSourceCanvas.toSVG({
    //           width: svgImage.getScaledWidth() / printcutCoords.coefficientX,
    //           height: svgImage.getScaledHeight() / printcutCoords.coefficientY,
    //         });

    //         fabric.loadSVGFromString(svgs, function(objs) {
    //           const svgPattern = fabric.util.groupSVGElements(objs, {});
    //           svgPattern.scaleToWidth(svgPattern.getScaledWidth());
    //           svgPattern.scaleToHeight(svgPattern.getScaledHeight());
    //           const element = svgPattern.toCanvasElement()  as any;
    //           // svgImage.set({
    //           //   objectCaching: false,
    //           //   centeredScaling: false,
    //           //   selectable: false,
    //           //   name: uuidv4(),
    //           //   width: initWidth,
    //           //   height: initHeight
    //           // });
    //           const patternSrc = patternSourceCanvas.getElement() as any;
    //           const pattern = new fabric.Pattern({
    //             source: element,
    //             repeat: selectedPatternObj[jerseySectionId].tileable ? 'repeat' : 'no-repeat',
    //           });
    //           // 2116.40 x  2587
    //           let designOffsetX = 0;
    //           let designOffsetY = 0;
    //           const isDesignPart = jerseySectionId.toString().toLowerCase().includes('design');
    //           if (isDesignPart) {
    //             const foundedDesignPart = designSvgPatternObject.current._objects.find((item: any) => item.id.includes(jerseySectionId));
    //             if (foundedDesignPart) {
    //               const designTlx = foundedDesignPart.pathOffset.x - (foundedDesignPart.width / 2);
    //               const designTly = foundedDesignPart.pathOffset.y - (foundedDesignPart.height / 2);
    //               const foundedCurrentPart = designSvgPatternObject.current._objects.find((item: any) => item.id.includes(printcutCoords.name));
    //               if (foundedCurrentPart) {
    //                 const currentTlx = foundedCurrentPart.pathOffset.x - (foundedCurrentPart.width / 2);
    //                 const currentTly = foundedCurrentPart.pathOffset.y - (foundedCurrentPart.height / 2);
    //                 designOffsetX = designTlx - currentTlx;
    //                 designOffsetY = designTly - currentTly;
    //               }
    //             }
    //           }
  
    //           const correctOffsetWithBackendX = -(printcutCoords.x + designOffsetX);
    //           const correctOffsetWithBackendY = -(printcutCoords.y + designOffsetY);
  
    //           // Offset
    //           if (selectedPatternObj[jerseySectionId].move_horizontally && selectedPatternObj[jerseySectionId].move_vertically) {
    //             pattern.offsetX = correctOffsetWithBackendX + patternOffsetX;
    //             pattern.offsetY = correctOffsetWithBackendY + patternOffsetY;
    //           } else if (selectedPatternObj[jerseySectionId].move_horizontally) {
    //             pattern.offsetX = correctOffsetWithBackendX + patternOffsetX;
    //             pattern.offsetY = correctOffsetWithBackendY;
    //           } else if (selectedPatternObj[jerseySectionId].move_vertically) {
    //             pattern.offsetX = correctOffsetWithBackendX;
    //             pattern.offsetY = correctOffsetWithBackendY + patternOffsetY;
    //           } else {
    //             pattern.offsetX = correctOffsetWithBackendX;
    //             pattern.offsetY = correctOffsetWithBackendY;
    //           }
    //           jerseyPartObj.set('fill', pattern);
    //           setTimeout(() => {
    //             patternSourceCanvas.requestRenderAll();
    //             fabricCanvas.current?.requestRenderAll();
    //             updateCanvasTextureMap();
    //           }, 100);
    //         });

    //       });
    //       // --------- End of Adjust Pattern SVG ---------------
    //     } else if (props.selectedJerseySectionColor[jerseySectionId]) {
    //       jerseyPartObj.set("fill", props.selectedJerseySectionColor[jerseySectionId]);
    //       fabricCanvas.current?.requestRenderAll();
    //       updateCanvasTextureMap();
    //       setTimeout(() => {
    //         resolve();
    //       }, 200);
    //     }
    //   });
    // });
  }

  function getPatternConfigs(selectedPatternObj: AddedPatternJerseySectionItems): Promise<{
    [key: string]: {
      offsetX: number,
      offsetY: number,
      width: number,
      height: number,
    }
  } | null> {
    if (!fabricCanvas.current) {
      return Promise.resolve(null);
    }
    if (!designSvgPatternObject.current) {
      return Promise.resolve(null);
    }
    if (!nodes['outside'].material.map) {
      return Promise.resolve(null);
    }
    const filteredCopyObjects = designSvgPatternObject.current._objects.slice()
      .filter((jerseyPartObj: any) => jerseyPartObj.id.includes('copy-'));
    const getPossibleJerseySection = Object.keys(selectedPatternObj);
    if (getPossibleJerseySection.length === 0) {
      fabricCanvas.current.requestRenderAll();
      updateCanvasTextureMap();
      return Promise.resolve(null);
    }
    // const params = new Proxy(new URLSearchParams(window.location.search), {
    //   get: (searchParams, prop) => searchParams.get(prop.toString()),
    // }) as any;
    let results: {
      [key: string]: {
        offsetX: number,
        offsetY: number,
        width: number,
        height: number,
      }
    } = {};
    return new Promise((resolve, reject) => {
      const filteredCopyObjectsOnlyHasPattern = filteredCopyObjects.slice().filter((jerseyPartObj: any) => {
        const jerseySectionId = jerseyPartObj.id.split('copy-')[1];
        return selectedPatternObj[jerseySectionId];
      });

      filteredCopyObjectsOnlyHasPattern.forEach((jerseyPartObj: any, index: number) => {
        const jerseySectionId = jerseyPartObj.id.split('copy-')[1];
        if (selectedPatternObj[jerseySectionId]) {
          // --------- Adjust Pattern SVG ---------------
          const foundedPrintCutCoords = props.printcutCoords.find((section) => jerseySectionId.toString().toLowerCase().includes(section.name.split('_')[0].toLowerCase()));
          const printcutCoords = foundedPrintCutCoords
            ? foundedPrintCutCoords
            : props.printcutCoords[0];
          const patternFill =
            selectedPatternObj[jerseySectionId].fill
              ? selectedPatternObj[jerseySectionId].fill
              : props.mainSchwarz
                ? '#' + props.mainSchwarz.colorHexCode
                : '#26282D';
          const patternScale = !selectedPatternObj[jerseySectionId].change_size ? 1 : (selectedPatternObj[jerseySectionId].scale || 1);
          const patternOffsetX = (selectedPatternObj[jerseySectionId].left || 0);
          const patternOffsetY = (selectedPatternObj[jerseySectionId].top || 0);
          const patternSvgString = selectedPatternObj[jerseySectionId].svg;
          if (!patternSvgString) {
            fabricCanvas.current?.requestRenderAll();
            updateCanvasTextureMap();
            setTimeout(() => {
              resolve(null);
            }, 200);
            return;
          }

          const onlySvgString = patternSvgString.substring(patternSvgString.indexOf('<svg'));
          const patternSvgFileTextResult = onlySvgString;
          if (!patternSvgFileTextResult) {
            fabricCanvas.current?.requestRenderAll();
            updateCanvasTextureMap();
            setTimeout(() => {
              reject('Missing patternSvgFileTextResult');
            }, 200);
            return;
          }

          const htmlObject = document.createElement('div');
          htmlObject.innerHTML = patternSvgFileTextResult.toString();
          const htmlObjectSvgAny = htmlObject.children[0] as any;
          const svgWidth = parseFloat(htmlObjectSvgAny.viewBox.baseVal.width);
          const svgHeight = parseFloat(htmlObjectSvgAny.viewBox.baseVal.height);
          htmlObjectSvgAny.setAttribute('viewBox', `0 0 ${svgWidth} ${svgHeight}`);
          htmlObjectSvgAny.setAttribute('width', svgWidth);
          htmlObjectSvgAny.setAttribute('height', svgHeight);
          htmlObjectSvgAny.setAttribute('style', '');

          const svgChildrenArray = Array.prototype.slice.call(htmlObjectSvgAny.children);
          const styleTag = svgChildrenArray.find((item) => item.tagName === 'style');
          if (styleTag) {
            styleTag.innerHTML = `.st0{fill:${patternFill};}.st1{fill:none;}`;
          } else {
            svgChildrenArray.slice().forEach((ch) => {
              ch.setAttribute('fill', patternFill);
            });
          }
          const adjustedPatternString = htmlObject.children[0].outerHTML;
          fabric.loadSVGFromString(adjustedPatternString, function(objs) {
            const svgImage = fabric.util.groupSVGElements(objs, {});
            svgImage.set({
              objectCaching: false,
              centeredScaling: false,
              selectable: false,
              name: uuidv4(),
              width: svgWidth,
              height: svgHeight,
            });
            if (selectedPatternObj[jerseySectionId].change_size) {
              svgImage.scaleToHeight((svgImage.getScaledHeight() / printcutCoords.coefficientY) * patternScale);
            } else {
              svgImage.scaleToHeight(svgImage.getScaledHeight() / printcutCoords.coefficientY);
            }
            const patternSourceCanvas = new fabric.StaticCanvas(null);
            patternSourceCanvas.skipOffscreen = false;
            patternSourceCanvas.svgViewportTransformation = true;
            patternSourceCanvas.enableRetinaScaling = false;
            patternSourceCanvas.imageSmoothingEnabled = false;
            patternSourceCanvas.setDimensions({
              width: svgImage.getScaledWidth(),
              height: svgImage.getScaledHeight(),
            });
            patternSourceCanvas.add(svgImage);
            patternSourceCanvas.calcOffset();
            const patternSrc = patternSourceCanvas.getElement() as any;
            const pattern = new fabric.Pattern({
              source: patternSrc,
              repeat: selectedPatternObj[jerseySectionId].tileable ? 'repeat' : 'no-repeat',
            });
            // 2116.40 x  2587
            let designOffsetX = 0;
            let designOffsetY = 0;
            const isDesignPart = jerseySectionId.toString().toLowerCase().includes('design');
            if (isDesignPart) {
              const foundedDesignPart = designSvgPatternObject.current._objects.find((item: any) => item.id.includes(jerseySectionId));
              if (foundedDesignPart) {
                const designTlx = foundedDesignPart.pathOffset.x - (foundedDesignPart.width / 2);
                const designTly = foundedDesignPart.pathOffset.y - (foundedDesignPart.height / 2);
                const foundedCurrentPart = designSvgPatternObject.current._objects.find((item: any) => item.id.includes(printcutCoords.name));
                if (foundedCurrentPart) {
                  const currentTlx = foundedCurrentPart.pathOffset.x - (foundedCurrentPart.width / 2);
                  const currentTly = foundedCurrentPart.pathOffset.y - (foundedCurrentPart.height / 2);
                  designOffsetX = designTlx - currentTlx;
                  designOffsetY = designTly - currentTly;
                }
              }
            }

            const correctOffsetWithBackendX = -(printcutCoords.x + designOffsetX);
            const correctOffsetWithBackendY = -(printcutCoords.y + designOffsetY);

            // Offset
            if (selectedPatternObj[jerseySectionId].move_horizontally && selectedPatternObj[jerseySectionId].move_vertically) {
              pattern.offsetX = correctOffsetWithBackendX + patternOffsetX;
              pattern.offsetY = correctOffsetWithBackendY + patternOffsetY;
            } else if (selectedPatternObj[jerseySectionId].move_horizontally) {
              pattern.offsetX = correctOffsetWithBackendX + patternOffsetX;
              pattern.offsetY = correctOffsetWithBackendY;
            } else if (selectedPatternObj[jerseySectionId].move_vertically) {
              pattern.offsetX = correctOffsetWithBackendX;
              pattern.offsetY = correctOffsetWithBackendY + patternOffsetY;
            } else {
              pattern.offsetX = correctOffsetWithBackendX;
              pattern.offsetY = correctOffsetWithBackendY;
            }
            results = {
              ...results,
              [jerseySectionId]: {
                offsetX: pattern.offsetX || 0,
                offsetY: pattern.offsetY || 0,
                width: svgImage.getScaledWidth(),
                height: svgImage.getScaledHeight()
              }
            };
            if (filteredCopyObjectsOnlyHasPattern.length - 1 === index) {
              resolve(results);
            }
          });
        }
      });
    });
  }

  function setNewJakoLogoColor(svgString: string) {
    if (!fabricCanvas.current) {
      return;
    }
    const logoObj = fabricCanvas.current._objects.find((obj: any) => obj.id === 'logo_front') as any;
    if (!logoObj) {
      return;
    }

    const uvObj = fabricCanvas.current._objects.find((obj: any) => obj.id === 'mainUv') as fabric.Object;
    logoObj.set({
      id: 'removeLogo'
    });
    fabricCanvas.current.remove(logoObj);

    setTimeout(() => {
      fabric.loadSVGFromString(
        svgString,
        function (logoObjects) {
          const newLogoObj = fabric.util.groupSVGElements(logoObjects, {
            width: SVG_SIZE,
            height: SVG_SIZE,
            left: 0,
            top: 0,
            selectable: false,
            objectCaching: false,
          });
          if (!fabricCanvas.current || !canvasRef.current || !canvasInsideRef.current) {
            return;
          }
          const newLogoObjAny = newLogoObj as any;
          newLogoObjAny.set({
            id: 'logo_front',
            selectable: false,
          });
          fabricCanvas.current.add(newLogoObj);
          setTimeout(() => {
            if (fabricCanvas.current) {
              // fabricCanvas.current.sendToBack(newLogoObj);
              fabricCanvas.current.sendToBack(uvObj);
              fabricCanvas.current.requestRenderAll();
              setTimeout(() => {
                updateCanvasTextureMap();
              }, 400);
            }
          }, 100);
        }
      );
    }, 100);
  }
  function setNewDotsLogoColor(svgString: string, side: string) {
    if (!fabricCanvas.current) {
      return;
    }
    const dotsObj = fabricCanvas.current._objects.find((obj: any) => obj.id === 'dots_front' + side) as any;
    if (!dotsObj) {
      return;
    }

    const uvObj = fabricCanvas.current._objects.find((obj: any) => obj.id === 'mainUv') as fabric.Object;
    dotsObj.set({
      id: 'removeDots' + side
    });
    fabricCanvas.current.remove(dotsObj);

    setTimeout(() => {
      fabric.loadSVGFromString(
        svgString,
        function (dotsObjects) {
          const newDotsObj = fabric.util.groupSVGElements(dotsObjects, {
            width: SVG_SIZE,
            height: SVG_SIZE,
            left: 0,
            top: 0,
            selectable: false,
            objectCaching: false,
          });
          if (!fabricCanvas.current || !canvasRef.current || !canvasInsideRef.current) {
            return;
          }
          const newDotsObjAny = newDotsObj as any;
          newDotsObjAny.set({
            id: 'dots_front' + side,
            selectable: false,
          });
          fabricCanvas.current.add(newDotsObjAny);
          fabricCanvas.current.sendToBack(newDotsObjAny);
          fabricCanvas.current.sendToBack(uvObj);
          fabricCanvas.current.requestRenderAll();
          updateCanvasTextureMap();
        }
      );
    }, 100);
  }

  if (!props.currentCuttingFormCode) {
    return <mesh />;
  }

  return (
    <group>
      <primitive object={scene} />
    </group>
  );
});

import { JakoLogoPosition, ModelStyle } from './types';
import { useState, useRef, useEffect, useContext, forwardRef, useImperativeHandle, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation, Trans } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import { Base64 } from 'js-base64';

import { Scrollbar } from 'react-scrollbars-custom';

import {
  faAngleRight,
  faAngleLeft,
  faCircleInfo
} from '@fortawesome/pro-solid-svg-icons';
import {
  faMinus as faMinusRegular,
  faPlus as faPlusRegular,
} from '@fortawesome/pro-light-svg-icons';
import {
  faUpload,
  faTrash,
  faArrowsUpDownLeftRight,
  faArrowsMaximize,
  faBringForward,
  faSendBackward,
  faXmark,
  faLock,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import IconInfo from './icons/IconInfo';
import IconLock from './icons/IconLock';

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import type { LogoTabInterface , LogoAddPosition} from './types';

import jerseyFrontTopCenter from '../assets/jakoImages/position/jakoLogo/center.jpg';
import jerseyFrontTopLeft from '../assets/jakoImages/position/jakoLogo/left.jpg';
import jerseyFrontTopRight from '../assets/jakoImages/position/jakoLogo/right.jpg';
import { LOGO_ADD_POSITION } from './constants';
import { JfnetServices } from '../helpers/services';
import {BaseContext} from "../contexts/BaseContext";
import IconTrashCan from './icons/IconTrashCan';
import IconCross from './icons/IconCross';

enum LogoTabType {
  UploadLogo,
  LogoPosition
}

enum MobileLogoFunction {
  JakoLogo,
  Home,
  Position,
  Size,
}

export type JakoLogoTabHandler = {
  openLogoOptionByName: (objectName: string) => void,
};

const JakoLogoTab = forwardRef<JakoLogoTabHandler, LogoTabInterface>(({
  modelStyle,
  selectedAddedLogoKey,
  addedLogoItems,
  jakoLogoPosition,
  currentCuttingFormCode,
  colorList,
  cmFactor,
  onAddLogoInput,
  onClickLogoLock,
  onClickDeleteLogo,
  onSelectLogo,
  onChangeLogoPosition,
  onChangeWidthSize,
  onChangeHeightSize,
  onChangeLogoColor,
  onSelectAddLogoPosition,
  onSendLogoToFront,
  onSendLogoToBack,
  onSelectJakoLogoPosition,
  onSetLoading,
  setSelectTabBack,
  setSelectTabNext,
  isMobile,
  onCloseMobile,
  onScrollToSelectedItem
}, ref) => {
    const { isb2c } = useContext(BaseContext);
    useImperativeHandle(ref, () => ({
      openLogoOptionByName(logoName: string) {
        if (showMoreLogoOption[logoName]) {
          return;
        }
        let results = {};
        Object.keys(addedLogoItems).forEach((itemKey) => {
          results = {
            ...results,
            [itemKey]: logoName === itemKey
          };
        });
        setShowMoreLogoOption(results);
      },
    }));

    const [selectMobileFunction, setSelectMobileFunction] = useState<MobileLogoFunction | null>(MobileLogoFunction.Home);
    const [hasMoreScrollContainer, setHasMoreScrollContainer] = useState(false);
    const scrollBarRef = useRef(null as any);

    const [selectTab, setSelectTab] = useState<LogoTabType>(LogoTabType.UploadLogo);
    const [showAddPosition, setShowAddPosition] = useState(false);
    const [showJakoLogoPosition, setShowJakoLogoPosition] = useState(true);
    const [selectedPosition, setSelectedPosition] = useState<{[key: string]: LogoAddPosition | null}>({});

    const [showMoreLogoOption, setShowMoreLogoOption] = useState<any>({});

    const inputWidthRef = useRef(null as any);
    const inputHeightRef = useRef(null as any);
    const [inputSizeWidth, setInputSizeWidth] = useState({} as { [key: string]: number });
    const [inputSizeHeight, setInputSizeHeight] = useState({} as { [key: string]: number });
    const [isUploadError, setIsUploadError] = useState(false);

    const { t } = useTranslation('translations');

    const isPolo = useMemo(() => currentCuttingFormCode.includes('PW'), [currentCuttingFormCode]);
    const isNoPositionInput = useMemo(() =>
      selectedAddedLogoKey
        ? selectedPosition[selectedAddedLogoKey] === null || selectedPosition[selectedAddedLogoKey] === undefined
        : false
    , [selectedPosition, selectedAddedLogoKey]);

    const mobileTabTitle = useMemo(() => {
      if (!selectedAddedLogoKey && selectMobileFunction === MobileLogoFunction.JakoLogo) {
        return t('configurator_3d_edit_logo');
      }
      if (!selectedAddedLogoKey) {
        return t('configurator_3d_add_logo');
      }
      if (isNoPositionInput) {
        return t('configurator_3d_choose_position');
      }
      if (selectMobileFunction !== null && selectMobileFunction !== MobileLogoFunction.Home) {
        return '';
      }
      if (selectMobileFunction !== null) {
        return '';
      }
      return '';
    }, [selectedAddedLogoKey, selectMobileFunction, t, isNoPositionInput]);

    const isShowCloseButtonTopRight = useMemo(() => {
      return selectedAddedLogoKey !== null && selectMobileFunction !== MobileLogoFunction.Home;
    }, [selectedAddedLogoKey, selectMobileFunction]);

    const addPositionOptionKeys = useMemo(() => modelStyle === ModelStyle.Short
      ? Object.keys(LOGO_ADD_POSITION).filter((key) => key.includes('Short_'))
      : Object.keys(LOGO_ADD_POSITION).filter((key) => !key.includes('Short_'))
    , [modelStyle]);

    useEffect(() => {
      if (!scrollBarRef.current) {
        return;
      }

      onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
      scrollBarRef.current.getScrollElement()
        .addEventListener("scroll", function(e: any) { onScrollContainerUpdate(e.target); });
    }, []);

    useEffect(() => {
      setTimeout(() => {
        if (scrollBarRef.current) {
          onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
        }
      }, 100);
    }, [selectMobileFunction]);

    useEffect(() => {
      if (!selectedAddedLogoKey) {
        return;
      }
      if (isMobile) {
        return;
      }
      let results = {};
      Object.keys(addedLogoItems).forEach((itemKey) => {
        results = {
          ...results,
          [itemKey]: false
        };
      });
      results = {
        ...results,
        [selectedAddedLogoKey]: true
      };
      setShowMoreLogoOption(results);
      onScrollToSelectedItem('trigger'+selectedAddedLogoKey);
      // eslint-disable-next-line
    }, [selectedAddedLogoKey]);

    useEffect(() => {
      if (selectedAddedLogoKey && addedLogoItems[selectedAddedLogoKey]) {
        const shadowRootContainer = document.querySelector('jako-configurator');
        const shadowRoot = shadowRootContainer ? shadowRootContainer.shadowRoot : null;
        const widthCm = addedLogoItems[selectedAddedLogoKey].scaledWidth;
        const widthFloatToFixedOneBackToFloat = Math.round(widthCm * 10) / 10;
        if (inputWidthRef.current) {
          inputWidthRef.current.value = widthFloatToFixedOneBackToFloat;
        }
        const getWidthInput = shadowRoot ? shadowRoot.getElementById(`inputQtyWidth_${selectedAddedLogoKey}`) : document.getElementById(`inputQtyWidth_${selectedAddedLogoKey}`) as any;
        if (getWidthInput) {
          getWidthInput.value = widthFloatToFixedOneBackToFloat;
        }

        setInputSizeWidth((prevState) => ({
          ...prevState,
          [selectedAddedLogoKey]: widthFloatToFixedOneBackToFloat
        }));

        const heightCm = addedLogoItems[selectedAddedLogoKey].scaledHeight;
        const heightFloatToFixedOneBackToFloat = Math.round(heightCm * 10) / 10;
        if (inputHeightRef.current) {
          inputHeightRef.current.value = heightFloatToFixedOneBackToFloat;
        }
        const getHeightInput = shadowRoot ? shadowRoot.getElementById(`inputQtyHeight_${selectedAddedLogoKey}`) : document.getElementById(`inputQtyHeight_${selectedAddedLogoKey}`) as any;
        if (getHeightInput) {
          getHeightInput.value = heightFloatToFixedOneBackToFloat;
        }

        setInputSizeHeight((prevState) => ({
          ...prevState,
          [selectedAddedLogoKey]: heightFloatToFixedOneBackToFloat
        }));
        setSelectedPosition({
          ...selectedPosition,
          [selectedAddedLogoKey]:
            selectedPosition[selectedAddedLogoKey]
              ? selectedPosition[selectedAddedLogoKey]
              : addedLogoItems[selectedAddedLogoKey] ? addedLogoItems[selectedAddedLogoKey].position : null
        });
      } else {
        let results = {};
        let positionResults = {...selectedPosition};
        Object.keys(addedLogoItems).forEach((itemKey) => {
          results = {
            ...results,
            [itemKey]: false
          };
          if (addedLogoItems[itemKey].position) {
            positionResults = {
              ...positionResults,
              [itemKey]: addedLogoItems[itemKey].position
            };
          }
        });
        setSelectedPosition(positionResults);
        setShowMoreLogoOption(results);
      }
      // eslint-disable-next-line
    }, [addedLogoItems, selectedAddedLogoKey]);

    const { getRootProps, getInputProps } = useDropzone({
      // accept: {
      //   'image/*': ['.png', '.svg', '.pdf', '.ai', '.eps'],
      // },
      onDrop: onDropFile
    });

    function onDropFile(e: any, rejects: any) {
      setIsUploadError(false);

      if (rejects.length) {
        setIsUploadError(true);
        return;
      }
      setTimeout(() => {
        uploadChecking(e);
      }, 100);
    }

    function showSelectAddPositionHandler(data: {
      fileName: string,
      src: string,
      isUnprocessable: boolean,
      reference: string
    }) {
      if (!data.reference) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                <h5 className="confirm-popup-container__title">
                  { t('configurator_3d_error_something_wrong') }
                </h5>
                <button className="button_primary" onClick={onClose}>
                  { t('configurator_3d_ok') }
                </button>
              </div>
            );
          }
        });
        return;
      }
      // testing get image by reference purpose
      setTimeout(() => {
        JfnetServices.getLogoByReference(data.reference)
          .then((res) => {
            onAddLogoInput({
              ...data,
              src: res.data
            });
          })
          .catch(() => {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div id="jako-configurator-3d-wc" className="confirm-popup-container">
                    <h5 className="confirm-popup-container__title">
                      { t('configurator_3d_error_something_wrong') }
                    </h5>
                    <button className="button_primary" onClick={onClose}>
                      { t('configurator_3d_ok') }
                    </button>
                  </div>
                );
              }
            });
          });
        }, 500);
    }

    function toBase64(file: File): Promise<string | ArrayBuffer | null> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }

    function uploadChecking(files: any) {
      const file = files[0];
      onSetLoading(true);

      function onConfirmAlertClose(closeFn: () => void, imageBase64String: string, file: any, reference: string) {
        onSetLoading(true);
        if (imageBase64String) {
          let svgString = Base64.decode(imageBase64String);
          if (svgString.slice(0, 10).includes('<?xml')) {
            svgString = svgString.substring(svgString.indexOf('<svg')+1);
            svgString = '<' + svgString;
          }
          showSelectAddPositionHandler({
            fileName: file.name,
            src: svgString,
            isUnprocessable: true,
            reference: reference || ''
          });
          closeFn();
        } else if (file && file.type && (file.type.toString().includes('png') || file.type.toString().includes('jp'))) {
          toBase64(file)
            .then((resBase64) => {
              if (!resBase64) {
                return;
              }
              showSelectAddPositionHandler({
                fileName: file.name,
                src: resBase64.toString(),
                isUnprocessable: true,
                reference: reference || ''
              });
              closeFn();
            }).catch(() => {
              onSetLoading(false);
              closeFn();
            });
        } else {
          onSetLoading(false);
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div id="jako-configurator-3d-wc" className="jako w-full w-[200px] mx-auto p-0 bg-white md:w-full md:w-[400px]">
                  <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                    {
                      isb2c
                        ? <Trans>
                          { t('configurator_3d_invalid_filetype_b2c') }
                        </Trans>
                        : <Trans>
                          { t('configurator_3d_invalid_filetype') }
                        </Trans>
                    }
                  </h5>
                  <button className="button_primary !bg-theme-primary" onClick={onClose}>
                    { t('configurator_3d_ok') }
                  </button>
                </div>
              );
            }
          });
          closeFn();
        }
      }

      JfnetServices.checkLogoUpload(file)
        .then((res) => {
          if (res.data.reference === null) {
            onSetLoading(false);
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div id="jako-configurator-3d-wc" className="jako w-full w-[200px] mx-auto p-0 bg-white md:w-full md:w-[400px]">
                    <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                      <Trans>
                        { t('configurator_3d_file_has_problem') }
                      </Trans>
                    </h5>
                    <button className="button_primary !bg-theme-primary" onClick={onClose}>
                      { t('configurator_3d_ok') }
                    </button>
                  </div>
                );
              }
            });
            return;
          }
          if (!res.data.canBeUsed) {
            onSetLoading(false);
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div id="jako-configurator-3d-wc" className="jako w-full w-[200px] mx-auto p-0 bg-white md:w-full md:w-[400px]">
                    <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                      {
                        isb2c
                          ? <Trans>
                            { t('configurator_3d_invalid_filetype_b2c') }
                          </Trans>
                          : <Trans>
                            { t('configurator_3d_invalid_filetype') }
                          </Trans>
                      }
                    </h5>
                    <button className="button_primary !bg-theme-primary" onClick={() => onConfirmAlertClose(onClose, res.data.image, file, res.data.reference)}>
                      { t('configurator_3d_ok') }
                    </button>
                  </div>
                );
              }
            });
            return;
          }
          if (res.data) {
            let svgString = Base64.decode(res.data.image);
            if (svgString.slice(0, 10).includes('<?xml')) {
              svgString = svgString.substring(svgString.indexOf('<svg')+1);
              svgString = '<' + svgString;
            }
            showSelectAddPositionHandler({
              fileName: file.name,
              src: svgString,
              isUnprocessable: false,
              reference: res.data.reference || ''
            });
          } else {
            onSetLoading(false);
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div id="jako-configurator-3d-wc" className="jako w-full w-[200px] mx-auto p-0 bg-white md:w-full md:w-[400px]">
                    <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                      {
                        isb2c
                          ? <Trans>
                            { t('configurator_3d_invalid_filetype_b2c') }
                          </Trans>
                          : <Trans>
                            { t('configurator_3d_invalid_filetype') }
                          </Trans>
                      }
                    </h5>
                    <button className="button_primary !bg-theme-primary" onClick={onClose}>
                      { t('configurator_3d_ok') }
                    </button>
                  </div>
                );
              }
            });
          }
        })
        .catch((err) => {
          onSetLoading(false);
          confirmAlert({
            customUI: ({ onClose }) => {
              if (err.response && err.response.status === 413) {
                return (
                  <div id="jako-configurator-3d-wc" className="jako w-full w-[200px] mx-auto p-0 bg-white md:w-full md:w-[400px]">
                    <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                      { t('configurator_3d_error_file_too_large') }
                    </h5>
                    <button className="button_primary !bg-theme-primary" onClick={onClose}>
                      { t('configurator_3d_ok') }
                    </button>
                  </div>
                );
              }
              return (
                <div id="jako-configurator-3d-wc" className="jako w-full w-[200px] mx-auto p-0 bg-white md:w-full md:w-[400px]">
                  <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                    { t('configurator_3d_error_something_wrong') }
                  </h5>
                  <button className="button_primary !bg-theme-primary" onClick={onClose}>
                    { t('configurator_3d_ok') }
                  </button>
                </div>
              );
            }
          });
        });
    }

    function handleOnDecreaseSize(dimension: string) {
      if (!selectedAddedLogoKey) {
        return;
      }
      if (dimension === 'width') {
        const currentValue = inputSizeWidth[selectedAddedLogoKey];
        if (currentValue - 0.1 < 1) {
          return;
        }
        const widthCmToPixel = (currentValue - 0.1) * cmFactor;
        onChangeWidthSize(selectedAddedLogoKey, widthCmToPixel);
      } else {
        const currentValue = inputSizeHeight[selectedAddedLogoKey];
        if (currentValue - 0.1 < 1) {
          return;
        }
        const heightCmToPixel = (currentValue - 0.1) * cmFactor;
        onChangeHeightSize(selectedAddedLogoKey, heightCmToPixel);
      }
  }
  function handleOnIncreaseSize(dimension: string) {
    if (!selectedAddedLogoKey) {
      return;
    }
    if (dimension === 'width') {
      const currentValue = inputSizeWidth[selectedAddedLogoKey];
      const widthCmToPixel = (currentValue + 0.1) * cmFactor;
      onChangeWidthSize(selectedAddedLogoKey, widthCmToPixel);
    } else {
      const currentValue = inputSizeHeight[selectedAddedLogoKey];
      const heightCmToPixel = (currentValue + 0.1) * cmFactor;
      onChangeHeightSize(selectedAddedLogoKey, heightCmToPixel);
    }
  }
  function handleOnChangeWidthSize(event: any) {
    if (!selectedAddedLogoKey) {
      return;
    }
    const shadowRootContainer = document.querySelector('jako-configurator');
    const shadowRoot = shadowRootContainer ? shadowRootContainer.shadowRoot : null;
    if (!event.target.value) {
      if (parseInt(event.target.value) < 1) {
        if (inputWidthRef.current) {
          inputWidthRef.current.value = 1;
          const widthCmToPixel = 1 * cmFactor;
          onChangeWidthSize(selectedAddedLogoKey, widthCmToPixel);
        }
        const getWidthInput = shadowRoot ? shadowRoot.getElementById(`inputQtyWidth_${selectedAddedLogoKey}`) : document.getElementById(`inputQtyWidth_${selectedAddedLogoKey}`) as any;
        if (getWidthInput) {
          getWidthInput.value = 1;
          const widthCmToPixel = 1 * cmFactor;
          onChangeWidthSize(selectedAddedLogoKey, widthCmToPixel);
        }
      } else {
        if (inputWidthRef.current) {
          inputWidthRef.current.value = inputSizeWidth[selectedAddedLogoKey];
          const widthCmToPixel = inputSizeWidth[selectedAddedLogoKey] * cmFactor;
          onChangeWidthSize(selectedAddedLogoKey, widthCmToPixel);
        }
        const getWidthInput = shadowRoot ? shadowRoot.getElementById(`inputQtyWidth_${selectedAddedLogoKey}`) : document.getElementById(`inputQtyWidth_${selectedAddedLogoKey}`) as any;
        if (getWidthInput) {
          getWidthInput.value = inputSizeWidth[selectedAddedLogoKey];
          const widthCmToPixel = inputSizeWidth[selectedAddedLogoKey] * cmFactor;
          onChangeWidthSize(selectedAddedLogoKey, widthCmToPixel);
        }
      }
      return;
    }
    const floatToFixedOneBackToFloat = Math.round(parseFloat(event.target.value) * 10) / 10;
    if (inputWidthRef.current) {
      inputWidthRef.current.value = floatToFixedOneBackToFloat;
    }
    const getWidthInput = shadowRoot ? shadowRoot.getElementById(`inputQtyWidth_${selectedAddedLogoKey}`) : document.getElementById(`inputQtyWidth_${selectedAddedLogoKey}`) as any;
    if (getWidthInput) {
      getWidthInput.value = floatToFixedOneBackToFloat;
    }
    setInputSizeWidth((prevState) => ({
      ...prevState,
      [selectedAddedLogoKey]: floatToFixedOneBackToFloat
    }));

    const widthCmToPixel = parseFloat(event.target.value) * cmFactor;
    onChangeWidthSize(selectedAddedLogoKey, widthCmToPixel);
  }
  function handleOnChangeHeightSize(event: any) {
    if (!selectedAddedLogoKey) {
      return;
    }
    const shadowRootContainer = document.querySelector('jako-configurator');
    const shadowRoot = shadowRootContainer ? shadowRootContainer.shadowRoot : null;
    if (!event.target.value) {
      if (parseInt(event.target.value) < 1) {
        if (inputHeightRef.current) {
          inputHeightRef.current.value = 1;
          const heightCmToPixel = 1 * cmFactor;
          onChangeHeightSize(selectedAddedLogoKey, heightCmToPixel);
        }
        const getHeightInput = shadowRoot ? shadowRoot.getElementById(`inputQtyHeight_${selectedAddedLogoKey}`) : document.getElementById(`inputQtyHeight_${selectedAddedLogoKey}`) as any;
        if (getHeightInput) {
          getHeightInput.value = 1;
          const heightCmToPixel = 1 * cmFactor;
          onChangeHeightSize(selectedAddedLogoKey, heightCmToPixel);
        }
      } else {
        if (inputHeightRef.current) {
          inputHeightRef.current.value = inputSizeHeight[selectedAddedLogoKey];
          const heightCmToPixel = inputSizeHeight[selectedAddedLogoKey] * cmFactor;
          onChangeHeightSize(selectedAddedLogoKey, heightCmToPixel);
        }
        const getHeightInput = shadowRoot ? shadowRoot.getElementById(`inputQtyHeight_${selectedAddedLogoKey}`) : document.getElementById(`inputQtyHeight_${selectedAddedLogoKey}`) as any;
        if (getHeightInput) {
          getHeightInput.value = inputSizeHeight[selectedAddedLogoKey];
          const heightCmToPixel = inputSizeHeight[selectedAddedLogoKey] * cmFactor;
          onChangeHeightSize(selectedAddedLogoKey, heightCmToPixel);
        }
      }
      return;
    }
    const floatToFixedOneBackToFloat = Math.round(parseFloat(event.target.value) * 10) / 10;
    if (inputHeightRef.current) {
      inputHeightRef.current.value = floatToFixedOneBackToFloat;
    }
    const getHeightInput = shadowRoot ? shadowRoot.getElementById(`inputQtyHeight_${selectedAddedLogoKey}`) : document.getElementById(`inputQtyHeight_${selectedAddedLogoKey}`) as any;
    if (getHeightInput) {
      getHeightInput.value = floatToFixedOneBackToFloat;
    }
    setInputSizeHeight((prevState) => ({
      ...prevState,
      [selectedAddedLogoKey]: floatToFixedOneBackToFloat
    }));

    const heightCmToPixel = parseFloat(event.target.value) * cmFactor;
    onChangeHeightSize(selectedAddedLogoKey, heightCmToPixel);
  }

  function handleOnSelectLogo(logoName: string) {
    setSelectMobileFunction(MobileLogoFunction.Home);
    if (logoName === selectedAddedLogoKey) {
      handleSetShowMoreLogoOption(logoName);

      if (showMoreLogoOption[logoName]) {
        onSelectLogo(null);
      } else {
        onSelectLogo(logoName);
      }
      return;
    }
    onSelectLogo(logoName);
  }

  function handleSetShowMoreLogoOption(logoName: string) {
    let results = {};
    Object.keys(addedLogoItems).forEach((itemKey) => {
      results = {
        ...results,
        [itemKey]: false
      };
    });
    results = {
      ...results,
      [logoName]: !showMoreLogoOption[logoName],
    };
    setShowMoreLogoOption(results);
  }

  function handleOnChangePosition(name: string, positionKey: string, isInit?: boolean) {
    setSelectedPosition({
      ...selectedPosition,
      [name]: LOGO_ADD_POSITION[positionKey].position
    });
    onSelectAddLogoPosition(LOGO_ADD_POSITION[positionKey].jerseySection, LOGO_ADD_POSITION[positionKey].position, true);
    onChangeLogoPosition(name, positionKey, isInit);
    onCloseMobile();

    setTimeout(() => {
      if (scrollBarRef.current) {
        onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
      }
    }, 1000);
  }

  function onScrollContainerUpdate(el: any) {
    setTimeout(() => {
      if (el.clientHeight + el.scrollTop >= el.scrollHeight) {
        setHasMoreScrollContainer(false);
      } else {
        setHasMoreScrollContainer(true);
      }
    }, 1000);
  }

  function handleOnClickCloseMobile() {
    if (scrollBarRef.current) {
      onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
    }

    if (!selectedAddedLogoKey && selectMobileFunction === MobileLogoFunction.JakoLogo) {
      setSelectMobileFunction(null);
      return;
    }

    if (!selectedAddedLogoKey) {
      onCloseMobile();
      return;
    }
    if (isNoPositionInput) {
      onSelectLogo(null);
      return;
    }
    if (selectMobileFunction !== null && selectMobileFunction !== MobileLogoFunction.Home) {
      setSelectMobileFunction(MobileLogoFunction.Home);
      return;
    }
    if (selectMobileFunction !== null) {
      onSelectLogo(null);
      return;
    }
    onCloseMobile();
  }

  function handleOnSelectJakoLogoMobile() {
    setSelectMobileFunction(MobileLogoFunction.JakoLogo);
    onSelectLogo(null);
  }

  return isMobile
    ? <div
      className={
        "relative w-full px-6 pb-6"
        + (isShowCloseButtonTopRight ? " pt-20" : " pt-10")
      }
    >
      {
        isShowCloseButtonTopRight
          ? <button
            className="absolute top-[18px] right-[12px] z-10 text-[#141414]"
            onClick={handleOnClickCloseMobile}
          >
            <IconCross className="h-[20px]" />
          </button>
          : <></>
      }
      {
        mobileTabTitle
          ? <p className="text-[#141414] text-[14px] font-semibold text-center min-h-[21px] pb-5">
            { mobileTabTitle }
          </p>
          : <></>
      }
      <SimpleBar
        ref={scrollBarRef}
        style={{
          width: '100%',
          height: selectedAddedLogoKey
            ? isNoPositionInput || selectMobileFunction === MobileLogoFunction.Position
              ? '221px'
              : 'auto'
            : Object.keys(addedLogoItems).length ? '205px' : '214px',
          overflowX: 'hidden'
        }}
        autoHide={false}
        onScroll={onScrollContainerUpdate}
      >
        {
          selectedAddedLogoKey
            ? isNoPositionInput
              ? <div className="grid grid-cols-2 items-center justify-items-center gap-x-5 gap-y-4 mt-10">
                {
                  addPositionOptionKeys.map((addPositionKey, index) => {
                    const position = addPositionKey as LogoAddPosition;
                    return <button
                      key={'OPEN_LOGO_ADD_POSITION' + selectedAddedLogoKey + index}
                      className="bg-white group flex flex-col items-center w-[125px]"
                      onClick={() => handleOnChangePosition(selectedAddedLogoKey, addPositionKey, true)}
                    >
                      <p className="font-semibold text-[#141414] mb-3 text-[12px] whitespace-nowrap">
                        { t(LOGO_ADD_POSITION[position].label) }
                      </p>
                      <div
                        className={
                          "relative w-full h-[150px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                          + (selectedPosition[selectedAddedLogoKey] === LOGO_ADD_POSITION[position].position ? ' !border-b-2 !border-b-[#008AC9]' : '')
                        }
                      >
                        <img
                          src={LOGO_ADD_POSITION[position].thumbnail}
                          alt=""
                          className="absolute !w-full h-[220px] object-cover"
                          style={{
                            objectPosition: 'center ' + LOGO_ADD_POSITION[position].thumbnailYPostionOption
                          }}
                        />
                      </div>
                    </button>;
                  })
                }
              </div>
              : <>
                {
                  selectMobileFunction === MobileLogoFunction.Home
                    ? <div className="mt-5">
                      <div className="flex items-center justify-between mb-5">
                        <div className="flex items-center gap-1 max-w-[60%]">
                          <p
                            className={
                              'font-bold text-[14px] truncate'
                              + (addedLogoItems[selectedAddedLogoKey].isUnprocessable ? ' text-[#FF6C00]' : '')
                            }
                            style={{
                              maxWidth: 'calc(100% - 16px)'
                            }}
                          >
                            { addedLogoItems[selectedAddedLogoKey].fileName }
                          </p>
                        </div>
                        <div className="flex gap-5">
                          <button
                            className="relative z-20"
                            style={{
                              color: addedLogoItems[selectedAddedLogoKey].isLock ? '#008AC9' : '#141414'
                            }}
                            onClick={() => onClickLogoLock(selectedAddedLogoKey)}
                          >
                            <i className="w-[22px] h-[22px]">
                              <IconLock className="w-[22px] h-[22px]" />
                            </i>
                          </button>
                          <button
                            className="relative z-20"
                            onClick={() => onClickDeleteLogo(selectedAddedLogoKey)}
                          >
                            <i className="w-[20px] h-[20px] text-[#141414]">
                              <IconTrashCan className="w-[20px] h-[20px]" />
                            </i>
                          </button>
                          <button
                            className="relative z-20 text-[#141414] ml-4"
                            onClick={handleOnClickCloseMobile}
                          >
                            <IconCross className="w-[24px] h-[24px]" />
                          </button>
                        </div>
                      </div>
                      {
                        addedLogoItems[selectedAddedLogoKey].isUnprocessable
                          ? <div className="w-full flex gap-4 mb-5">
                            <i className="relative top-px w-[14px] h-[14px] text-[#FF6C00]">
                              <IconInfo className="w-[14px] h-[14px]" />
                            </i>
                            <p className="relative z-20 text-[11px] text-[#FF6C00]">
                              { t('configurator_3d_warning_logo_type')}
                            </p>
                          </div>
                          : <></>
                      }
                      <div className="grid grid-cols-4 justify-center overflow-x-hidden">
                        <button className="flex flex-col items-center group" onClick={() => setSelectMobileFunction(MobileLogoFunction.Position)}>
                          <div className="p-2">
                            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} className="h-[25px] text-[#141414]" />
                          </div>
                          <span className="text-[12px] font-medium text-[#141414]">
                            { t('configurator_3d_position') }
                          </span>
                        </button>
                        <button className="flex flex-col items-center group" onClick={() => setSelectMobileFunction(MobileLogoFunction.Size)}>
                          <div className="p-2">
                            <FontAwesomeIcon icon={faArrowsMaximize} className="h-[25px] text-[#141414]" />
                          </div>
                          <span className="text-[12px] font-medium text-[#141414]">
                            { t('configurator_3d_size') }
                          </span>
                        </button>
                        <button className="flex flex-col items-center group" onClick={() => onSendLogoToFront(selectedAddedLogoKey)}>
                          <div className="p-2">
                            <FontAwesomeIcon icon={faBringForward} className="h-[25px] text-[#141414]" />
                          </div>
                          <span className="text-[12px] font-medium text-[#141414]">
                            { t('configurator_3d_foreground') }
                          </span>
                        </button>
                        <button className="flex flex-col items-center group" onClick={() => onSendLogoToBack(selectedAddedLogoKey)}>
                          <div className="p-2">
                            <FontAwesomeIcon icon={faSendBackward} className="h-[25px] text-[#141414]" />
                          </div>
                          <span className="text-[12px] font-medium text-[#141414]">
                            { t('configurator_3d_background') }
                          </span>
                        </button>
                      </div>
                      <div className="w-full flex justify-end gap-4 mt-5">
                        <i className="relative top-px w-[14px] h-[14px] text-[#FF6C00]">
                          <IconInfo className="w-[14px] h-[14px]" />
                        </i>
                        <p className="relative z-20 text-[11px] text-[#FF6C00]">
                          { t('configurator_3d_size_info_logo')}
                        </p>
                      </div>
                    </div>
                    : <></>
                }
                {
                  selectMobileFunction === MobileLogoFunction.Position
                    ? <div>
                      <p className="text-[#141414] text-[14px] font-semibold text-center">
                        { t('configurator_3d_choose_position') }
                      </p>
                      <div className="grid grid-cols-2 items-center justify-items-center gap-x-5 gap-y-4 mt-10">
                        {
                          addPositionOptionKeys.map((addPositionKey, index) => {
                            const position = addPositionKey as LogoAddPosition;
                            return <button
                              key={'OPEN_LOGO_ADD_POSITION' + selectedAddedLogoKey + index}
                              className="bg-white group flex flex-col items-center w-[125px]"
                              onClick={() => handleOnChangePosition(selectedAddedLogoKey, addPositionKey)}
                            >
                              <p className="font-semibold text-[#141414] mb-3 text-[12px] whitespace-nowrap">
                                { t(LOGO_ADD_POSITION[position].label) }
                              </p>
                              <div
                                className={
                                  "relative w-full h-[150px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                                  + (selectedPosition[selectedAddedLogoKey] === LOGO_ADD_POSITION[position].position ? ' !border-b-2 !border-b-[#008AC9]' : '')
                                }
                              >
                                <img
                                  src={LOGO_ADD_POSITION[position].thumbnail}
                                  alt=""
                                  className="absolute !w-full h-[220px] object-cover"
                                  style={{
                                    objectPosition: 'center ' + LOGO_ADD_POSITION[position].thumbnailYPostionOption
                                  }}
                                />
                              </div>
                            </button>;
                          })
                        }
                      </div>
                    </div>
                    : <></>
                }
                {
                  selectMobileFunction === MobileLogoFunction.Size
                    ? <div className="mt-10">
                      <div className="flex items-center justify-around">
                        <div className="flex flex-col items-center">
                          <p className="text-[14px] mb-5">
                            {t('configurator_3d_height_cm')}
                          </p>
                          <div className="quantity quantitySizes">
                            <div className="spinner_wrapper">
                              <div
                                className="spinner_down"
                                onClick={() => handleOnDecreaseSize('height')}
                              >
                                <FontAwesomeIcon icon={faMinusRegular} className="icon" />
                              </div>
                              {/* // 1cm = 37.79527559055118px */}
                              <input
                                ref={inputHeightRef}
                                defaultValue={Math.round((addedLogoItems[selectedAddedLogoKey].scaledHeight) * 10) / 10}
                                className="input_quantity_line"
                                name="input_item_quantity_sizes_"
                                id="input_item_quantity_sizes_"
                                type="number"
                                onFocus={(e) => e.target.select()}
                                onChange={(event) => handleOnChangeHeightSize(event)}
                              />
                              <div
                                className="spinner_up"
                                onClick={() => handleOnIncreaseSize('height')}
                              >
                                <FontAwesomeIcon icon={faPlusRegular} className="icon" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col items-center">
                          <p className="text-[14px] mb-5">
                            {t('configurator_3d_width_cm')}
                          </p>
                          <div className="quantity quantitySizes">
                            <div className="spinner_wrapper">
                              <div
                                className="spinner_down"
                                onClick={() => handleOnDecreaseSize('width')}
                              >
                                <FontAwesomeIcon icon={faMinusRegular} className="icon" />
                              </div>
                              {/* // 1cm = 37.79527559055118px */}
                              <input
                                ref={inputWidthRef}
                                defaultValue={Math.round((addedLogoItems[selectedAddedLogoKey].scaledWidth) * 10) / 10}
                                className="input_quantity_line"
                                name="input_item_quantity_sizes_"
                                id="input_item_quantity_sizes_"
                                type="number"
                                onFocus={(e) => e.target.select()}
                                onChange={(e) => handleOnChangeWidthSize(e)}
                              />
                              <div
                                className="spinner_up"
                                onClick={() => handleOnIncreaseSize('width')}
                              >
                                <FontAwesomeIcon icon={faPlusRegular} className="icon" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    : <></>
                }
              </>
            : selectMobileFunction === MobileLogoFunction.JakoLogo
              ? <div className="grid grid-cols-2 gap-x-5 gap-y-4 mt-5 pr-4 justify-items-center">
                  {
                    ModelStyle.Short === modelStyle
                      ? <>
                        <button
                          className="bg-white group flex flex-col items-center w-[125px]"
                          onClick={() => onSelectJakoLogoPosition(JakoLogoPosition.Right)}
                        >
                          <p
                            className={
                              "font-semibold  mb-3 text-[12px] whitespace-nowrap"
                              + (jakoLogoPosition === JakoLogoPosition.Right ? '  text-[#008AC9]' : ' text-[#141414]')
                            }
                          >
                            { t('configurator_3d_short_right') }
                          </p>
                          <div
                            className={
                              "relative w-full h-[150px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                              + (jakoLogoPosition === JakoLogoPosition.Right ? ' !border-b-2 !border-b-[#008AC9]' : '')
                            }
                          >
                            <img
                              src={jerseyFrontTopLeft}
                              alt=""
                              className="absolute !w-full h-[220px] object-cover"
                              style={{
                                objectPosition: 'center -25px'
                              }}
                            />
                          </div>
                        </button>

                        <button
                          className="bg-white group flex flex-col items-center w-[125px]"
                          onClick={() => onSelectJakoLogoPosition(JakoLogoPosition.Left)}
                        >
                          <p
                            className={
                              "font-semibold  mb-3 text-[12px] whitespace-nowrap"
                              + (jakoLogoPosition === JakoLogoPosition.Left ? '  text-[#008AC9]' : ' text-[#141414]')
                            }
                          >
                            { t('configurator_3d_short_left') }
                          </p>
                          <div
                            className={
                              "relative w-full h-[150px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                              + (jakoLogoPosition === JakoLogoPosition.Left ? ' !border-b-2 !border-b-[#008AC9]' : '')
                            }
                          >
                            <img
                              src={jerseyFrontTopRight}
                              alt=""
                              className="absolute !w-full h-[220px] object-cover"
                              style={{
                                objectPosition: 'center -25px'
                              }}
                            />
                          </div>
                        </button>
                      </>
                      : <>
                        <button
                          className="bg-white group flex flex-col items-center w-[125px]"
                          onClick={() => onSelectJakoLogoPosition(JakoLogoPosition.Right)}
                        >
                          <p
                            className={
                              "font-semibold  mb-3 text-[12px] whitespace-nowrap"
                              + (jakoLogoPosition === JakoLogoPosition.Right ? '  text-[#008AC9]' : ' text-[#141414]')
                            }
                          >
                            { t('configurator_3d_front_top_right') }
                          </p>
                          <div
                            className={
                              "relative w-full h-[150px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                              + (jakoLogoPosition === JakoLogoPosition.Right ? ' !border-b-2 !border-b-[#008AC9]' : '')
                            }
                          >
                            <img
                              src={jerseyFrontTopRight}
                              alt=""
                              className="absolute !w-full h-[220px] object-cover"
                              style={{
                                objectPosition: 'center -25px'
                              }}
                            />
                          </div>
                        </button>

                        <button
                          className="bg-white group flex flex-col items-center w-[125px]"
                          onClick={() => onSelectJakoLogoPosition(JakoLogoPosition.Left)}
                        >
                          <p
                            className={
                              "font-semibold  mb-3 text-[12px] whitespace-nowrap"
                              + (jakoLogoPosition === JakoLogoPosition.Left ? '  text-[#008AC9]' : ' text-[#141414]')
                            }
                          >
                            { t('configurator_3d_front_top_left') }
                          </p>
                          <div
                            className={
                              "relative w-full h-[150px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                              + (jakoLogoPosition === JakoLogoPosition.Left ? ' !border-b-2 !border-b-[#008AC9]' : '')
                            }
                          >
                            <img
                              src={jerseyFrontTopLeft}
                              alt=""
                              className="absolute !w-full h-[220px] object-cover"
                              style={{
                                objectPosition: 'center -25px'
                              }}
                            />
                          </div>
                        </button>
                      </>
                  }
                  {
                    !isPolo
                      ? <button
                        className="bg-white group flex flex-col items-center w-[125px]"
                        onClick={() => onSelectJakoLogoPosition(JakoLogoPosition.Center)}
                      >
                        <p
                          className={
                            "font-semibold  mb-3 text-[12px] whitespace-nowrap"
                            + (jakoLogoPosition === JakoLogoPosition.Center ? '  text-[#008AC9]' : ' text-[#141414]')
                          }
                        >
                          { t('configurator_3d_front_top_center') }
                        </p>
                        <div
                          className={
                            "relative w-full h-[150px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                            + (jakoLogoPosition === JakoLogoPosition.Center ? ' !border-b-2 !border-b-[#008AC9]' : '')
                          }
                        >
                          <img
                            src={jerseyFrontTopCenter}
                            alt=""
                            className="absolute !w-full h-[220px] object-cover"
                            style={{
                              objectPosition: 'center -25px'
                            }}
                          />
                        </div>
                      </button>
                      : <></>
                  }
              </div>
              : Object.keys(addedLogoItems).length
                ? <>
                  <div className="w-full flex justify-center gap-4 mb-5">
                    <i className="relative top-px w-[14px] h-[14px] text-[#FF6C00]">
                      <IconInfo className="w-[14px] h-[14px]" />
                    </i>
                    <p className="relative z-20 text-[14px] text-[#FF6C00] leading-6">
                      { t('configurator_3d_logo_upload_confirmation')}
                    </p>
                  </div>
                  <div className="grid grid-cols-2 gap-x-5 gap-y-4 mt-5 pr-4 pb-10">
                    <div className="form-group logoUpload h-[100px]">
                      <div
                        {
                          ...getRootProps(
                            {
                              id: 'dropArea',
                              className: 'dropzone h-[100px]'
                            }
                          )
                        }
                        >
                        <input {...getInputProps()} />
                        <form className="flex flex-col items-center justify-center">
                          <FontAwesomeIcon icon={faUpload} className="!mb-0" />
                          <div className="text-[14px] text-center">{t('configurator_3d_drop_file_here')}</div>
                        </form>
                        {
                          isUploadError && <p className="text-[#FF6C00]">{ t('configurator_3d_file_type_error')}</p>
                        }
                      </div>
                    </div>
                    <button
                      className="w-full h-[100px] flex items-center justify-center bg-[#F0F0F0]"
                      onClick={handleOnSelectJakoLogoMobile}
                    >
                      { t('configurator_3d_edit_logo') }
                    </button>
                    {
                      Object.keys(addedLogoItems).map((itemKey, index: number) =>
                        <button
                          key={'addedLogoItems' + itemKey + index}
                          className="relative group w-full flex flex-col items-center"
                          onClick={() => handleOnSelectLogo(itemKey)}
                        >
                          {
                            addedLogoItems[itemKey].isUnprocessable
                              ? <i className="absolute -top-px right-1 w-[16px] h-[16px] text-[#FF6C00]">
                                <IconInfo className="w-[16px] h-[16px]" />
                              </i>
                              : <></>
                          }
                          <p
                            className={
                              'font-semibold text-[12px] mb-3 truncate w-[80%]'
                              + (
                                showMoreLogoOption[itemKey]
                                  ? addedLogoItems[itemKey].isUnprocessable
                                    ? ' text-[#FF6C00]' : ' text-[#008AC9]'
                                  : addedLogoItems[itemKey].isUnprocessable ? ' text-[#FF6C00]' : ''
                              )
                            }
                          >
                            { addedLogoItems[itemKey].fileName }
                          </p>
                          <div className="relative w-full h-[100px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]">
                            {
                              addedLogoItems[itemKey].src.includes('<svg')
                                ? <img src={`data:image/svg+xml;utf8,${encodeURIComponent(addedLogoItems[itemKey].src)}`} alt="" className="absolute !w-full h-[100px] object-contain"/>
                                : <img src={addedLogoItems[itemKey].src} alt="" className="absolute !w-full h-[100px] object-contain"/>
                            }
                          </div>
                        </button>
                      )
                    }
                  </div>
                </>
                : <>
                  <div className="w-full flex justify-center gap-4 mb-5">
                    <i className="relative top-px w-[14px] h-[14px] text-[#FF6C00]">
                      <IconInfo className="w-[14px] h-[14px]" />
                    </i>
                    <p className="relative z-20 text-[14px] text-[#FF6C00] leading-6">
                      { t('configurator_3d_logo_upload_confirmation')}
                    </p>
                  </div>
                  <div className="form-group logoUpload mt-5 mb-2">
                    <div
                      {
                        ...getRootProps(
                          {
                            id: 'dropArea',
                            className: 'marginBottom--small dropzone h-[90px]'
                          }
                        )
                      }
                      >
                      <input {...getInputProps()} />
                      <form className="flex flex-col items-center justify-center">
                        <FontAwesomeIcon icon={faUpload} className="!mb-0" />
                        <div className="text-[14px] text-center">{t('configurator_3d_drop_file_here')} ({t('configurator_3d_logo_upload_maximum')})</div>
                        <div className="text-[12px] text-[#C3C3C3] text-center">{t('configurator_3d_accept_only_file')}</div>
                      </form>
                      {
                        isUploadError && <p className="text-[#FF6C00]">{ t('configurator_3d_file_type_error')}</p>
                      }
                    </div>
                  </div>
                  <button
                    className="w-full h-[90px] flex items-center justify-center bg-[#F0F0F0]"
                    onClick={handleOnSelectJakoLogoMobile}
                  >
                    { t('configurator_3d_edit_logo') }
                  </button>
                </>
        }
      </SimpleBar>
      {
        hasMoreScrollContainer
          ? <div
            className={
              "absolute w-full h-[40px] bottom-0 left-0 bg-gradient-to-b from-transparent to-white transition-all duration-200"
            }
          />
          : <></>
      }
    </div>
    : <>
      <div className="tabs">
        <ul className="tabs__list">
          <li
            className={"tabs__li" + (selectTab === LogoTabType.UploadLogo ? ' active' : '')}
            onClick={() => setSelectTab(LogoTabType.UploadLogo)}
          >
            { t('configurator_3d_add_logo') }
          </li>
          <li
            className={"tabs__li " + (selectTab === LogoTabType.LogoPosition ? ' active' : '')}
            onClick={() => setSelectTab(LogoTabType.LogoPosition)}
          >
            { t('configurator_3d_edit_logo') }
          </li>
        </ul>
        <div
          className={
            "tabs__content mt-10 "
            + (selectTab === LogoTabType.UploadLogo ? ' active' : '')
          }
        >
          <div className="h1 text-center">{ t('configurator_3d_add_logo') }</div>
          <div className="text-center marginBottom--small">{ t('configurator_3d_add_logo_text') }</div>
          <div className="form-group logoUpload mb-10">
            <div className="form-group--headline">
              { t('configurator_3d_add_logo') }
            </div>
            <div
              {
                ...getRootProps(
                  {
                    id: 'dropArea',
                    className: 'marginBottom--small dropzone'
                  }
                )
              }
              >
              <input {...getInputProps()} />
              <form className="flex flex-col items-center justify-center">
                <FontAwesomeIcon icon={faUpload} className="!mb-0" />
                <div>{t('configurator_3d_drop_file_here')}</div>
                <div>{t('configurator_3d_or')}</div>
                <label className="link">{t('configurator_3d_browse_your_folder')}</label>
                <div>{t('configurator_3d_accept_only_file')}</div>
                <div>({t('configurator_3d_logo_upload_maximum')})</div>
              </form>
              {
                isUploadError && <p className="text-[#FF6C00]">{ t('configurator_3d_file_type_error')}</p>
              }
            </div>
          </div>

          {
            Object.keys(addedLogoItems).length === 0
            ? <div className="w-full flex gap-4 mb-10">
              <i className="relative top-px w-[16px] h-[16px] text-[#FF6C00]">
                <IconInfo className="w-[16px] h-[16px]" />
              </i>
              <p className="relative z-20 text-[#FF6C00] leading-8">
                { t('configurator_3d_logo_upload_confirmation')}
              </p>
            </div>
            : <></>
          }

          <div className="slideContent slideContent__borderTop">
            {
              Object.keys(addedLogoItems).length
                ? <div className="relative py-4">
                    <div className="slideContainer__headline">
                      { t('configurator_3d_added_logos') }
                    </div>

                    <div className="flex flex-col">
                      {
                        Object.keys(addedLogoItems).map((itemKey, index: number) =>
                          <div
                            id={'trigger' + itemKey}
                            key={'addedLogoObj' + itemKey + index}
                          >
                            <div
                              key={addedLogoItems[itemKey].fileName + '_' + index}
                              className={
                                "relative w-full flex items-center justify-between py-8 px-4 border-b"
                                + (showMoreLogoOption[itemKey] ? ' border-transparent' : ' border-[#F0F0F0]')
                              }
                            >
                              <button
                                className="absolute w-full h-full inset-0 z-10"
                                onClick={() => handleOnSelectLogo(itemKey)}
                              />
                              <div
                                className="flex items-center gap-1"
                                style={{
                                  maxWidth: 'calc(100% - 70px)'
                                }}
                              >
                                <p
                                  className={
                                    'font-semibold text-[16px] truncate'
                                    + (
                                      showMoreLogoOption[itemKey]
                                        ? addedLogoItems[itemKey].isUnprocessable
                                          ? ' text-[#FF6C00]' : ' text-[#008AC9]'
                                        : addedLogoItems[itemKey].isUnprocessable ? ' text-[#FF6C00]' : ''
                                    )
                                  }
                                  style={{
                                    maxWidth: 'calc(100% - 16px)'
                                  }}
                                >
                                  { index + 1 }. { addedLogoItems[itemKey].fileName }
                                </p>
                                {
                                  addedLogoItems[itemKey].isUnprocessable
                                    ? <i className="w-[16px] h-[16px] text-[#FF6C00] ml-4">
                                      <IconInfo className="w-[16px] h-[16px]" />
                                    </i>
                                    : <></>
                                }
                              </div>
                              <div className="relative flex items-center gap-4 z-20">
                                <button
                                  className="relative iconButton delete z-20"
                                  onClick={() => onClickDeleteLogo(itemKey)}
                                >
                                  <FontAwesomeIcon icon={faTrash} className="icon h-[22px]" />
                                </button>
                                <button onClick={() => handleOnSelectLogo(itemKey)}>
                                  <FontAwesomeIcon
                                    icon={faAngleRight}
                                    className={
                                      "icon transform transition-all duration-500"
                                      + (showMoreLogoOption[itemKey] ? ' rotate-90' : ' rotate-0')
                                    }
                                  />
                                </button>
                              </div>
                            </div>
                            {
                              showMoreLogoOption[itemKey] && addedLogoItems[itemKey].isUnprocessable
                                ? <div className="flex gap-4 mb-10">
                                  <i className="relative top-px w-[16px] h-[16px] text-[#FF6C00]">
                                    <IconInfo className="w-[16px] h-[16px]" />
                                  </i>
                                  <p className="relative z-20 text-[#FF6C00] leading-8">
                                    { t('configurator_3d_warning_logo_type')}
                                  </p>
                                </div>
                                : <></>
                            }

                            {
                              selectedPosition[itemKey] === null || selectedPosition[itemKey] === undefined
                                ? <div className={showMoreLogoOption[itemKey] ? ' block' : ' hidden'}>
                                  {/* Add Position */}
                                  <div className="slideContainer">
                                    <div className="flex justify-between items-center">
                                      <p className="text-[#141414] pr-4">
                                        <span className="font-semibold">{ t('configurator_3d_choose_position') }</span>
                                      </p>
                                      <button
                                        className="flex items-center gap-4"
                                        onClick={() => setShowAddPosition(!showAddPosition)}
                                      >
                                        <div className="relative w-[44px] h-[30px] border border-[#D4D4D4] overflow-hidden z-10">
                                          <div className="absolute top-0 left-0 w-[100px] h-[115px] object-cover bg-white" />
                                        </div>
                                        <FontAwesomeIcon
                                          icon={faAngleRight}
                                          className={
                                            "icon transform rotate-90"
                                          }
                                        />
                                      </button>
                                    </div>

                                    <div
                                      className={
                                        "slideContent__show active"
                                      }
                                    />
                                    <div className="slideContent__contentContainer mt-4">
                                      <div
                                        className={
                                          "relative border-t-2 border-[#f0f0f0]"
                                          + " before:bottom-0 before:left-0 before:w-full before:h-6 before:absolute before:z-10 before:bg-gradient-to-t before:from-white before:to-transparent"
                                        }
                                      >
                                        <Scrollbar
                                          disableTrackYWidthCompensation
                                          trackYProps={{ style: { width: '12px', background: 'none' } }}
                                          className='!h-[370px]'
                                        >
                                          <div className="relative itemsWrapper--3cols gap-4 pt-6 pr-6 pb-8 transition-all">
                                          {
                                            addPositionOptionKeys.map((addPositionKey, index) => {
                                              const position = addPositionKey as LogoAddPosition;
                                              return <button
                                                key={'OPEN_LOGO_ADD_POSITION' + itemKey + index}
                                                className="bg-white group flex flex-col min-w-[123px]"
                                                onClick={() => handleOnChangePosition(itemKey, addPositionKey, true)}
                                              >
                                                <div
                                                  className={
                                                    "relative w-full h-[110px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                                                    + (selectedPosition[itemKey] === LOGO_ADD_POSITION[position].position ? ' !border-b-2 !border-b-[#008AC9]' : '')
                                                  }
                                                >
                                                  <img
                                                    src={LOGO_ADD_POSITION[position].thumbnail}
                                                    alt=""
                                                    className="absolute !w-full h-auto object-cover"
                                                    style={{
                                                      objectPosition: 'center ' + LOGO_ADD_POSITION[position].thumbnailYPostionOption
                                                    }}
                                                  />
                                                </div>
                                                <div className="font-semibold text-[#141414] mt-3 text-[12px]">
                                                  { t(LOGO_ADD_POSITION[position].label) }
                                                </div>
                                              </button>;
                                            })
                                          }
                                          </div>
                                        </Scrollbar>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                : <div className={showMoreLogoOption[itemKey] ? ' block' : ' hidden'}>
                                  <div className="controlBarWrapper borderBottom">
                                    <div className="controlBar--left">
                                      <button
                                        className="icon p-3"
                                        style={{
                                            color: addedLogoItems[itemKey].isLock ? '#008AC9' : 'inherit'
                                        }}
                                        onClick={() => onClickLogoLock(itemKey)}
                                      >
                                        <FontAwesomeIcon icon={faLock} className="h-[25px]" />
                                      </button>
                                    </div>
                                  </div>
                                  {/* Add Position */}
                                  <div className="slideContainer">
                                    <div className="flex justify-between items-center">
                                      <p className="text-[#141414] pr-4">
                                        <span className="font-semibold">{ t('configurator_3d_choose_position') }</span> / { t(LOGO_ADD_POSITION[selectedPosition[itemKey] || addedLogoItems[itemKey].position].label) }
                                      </p>
                                      <button
                                        className="flex items-center gap-4"
                                        onClick={() => setShowAddPosition(!showAddPosition)}
                                      >
                                        <div className="relative w-[44px] h-[30px] border border-[#D4D4D4] overflow-hidden z-10">
                                          <img
                                            src={LOGO_ADD_POSITION[selectedPosition[itemKey] || addedLogoItems[itemKey].position].thumbnail}
                                            alt=''
                                            className="absolute top-0 left-0 w-[100px] h-[115px] object-cover"
                                            style={{
                                              objectPosition: 'center ' + LOGO_ADD_POSITION[selectedPosition[itemKey] || addedLogoItems[itemKey].position].thumbnailYPostionOption
                                            }}
                                          />
                                        </div>
                                        <FontAwesomeIcon
                                          icon={faAngleRight}
                                          className={
                                            "icon transform transition-all duration-500"
                                            + (showAddPosition ? ' rotate-90' : ' rotate-0')
                                          }
                                        />
                                      </button>
                                    </div>

                                    <div
                                      className={
                                        "slideContent__show"
                                        + (showAddPosition ? ' active' : '')
                                      }
                                    />
                                    <div className="slideContent__contentContainer mt-4">
                                      <div
                                        className={
                                          "relative border-t-2 border-[#f0f0f0]"
                                          + " before:bottom-0 before:left-0 before:w-full before:h-6 before:absolute before:z-10 before:bg-gradient-to-t before:from-white before:to-transparent"
                                        }
                                      >
                                        <Scrollbar
                                          disableTrackYWidthCompensation
                                          trackYProps={{ style: { width: '12px', background: 'none' } }}
                                          className='!h-[370px]'
                                        >
                                          <div className="itemsWrapper--3cols gap-4 pt-6 pr-6 pb-8 transition-all">
                                            {
                                              addPositionOptionKeys.map((addPositionKey, index) => {
                                                const position = addPositionKey as LogoAddPosition;
                                                return <button
                                                  key={'OPEN_LOGO_ADD_POSITION' + itemKey + index}
                                                  className="bg-white group flex flex-col min-w-[123px]"
                                                  onClick={() => handleOnChangePosition(itemKey, addPositionKey)}
                                                >
                                                  <div
                                                    className={
                                                      "relative w-full h-[110px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                                                      + (selectedPosition[itemKey] === LOGO_ADD_POSITION[position].position ? ' !border-b-2 !border-b-[#008AC9]' : '')
                                                    }
                                                  >
                                                    <img
                                                      src={LOGO_ADD_POSITION[position].thumbnail}
                                                      alt=""
                                                      className="absolute !w-full h-auto object-cover"
                                                      style={{
                                                        objectPosition: 'center ' + LOGO_ADD_POSITION[position].thumbnailYPostionOption
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="font-semibold text-[#141414] mt-3 text-[12px]">
                                                    { t(LOGO_ADD_POSITION[position].label) }
                                                  </div>
                                                </button>;
                                              })
                                            }
                                          </div>
                                        </Scrollbar>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-group borderBottom fontSize space--between">
                                    <div className="form-group--headline">
                                      {t('configurator_3d_width_cm')}
                                    </div>
                                    <div className="quantity quantitySizes">
                                      <div className="spinner_wrapper">
                                        <div
                                          className="spinner_down"
                                          onClick={() => handleOnDecreaseSize('width')}
                                        >
                                          <FontAwesomeIcon icon={faMinusRegular} className="icon" />
                                        </div>
                                        {/* // 1cm = 37.79527559055118px */}
                                        <input
                                          id={`inputQtyWidth_${itemKey}`}
                                          defaultValue={Math.round((addedLogoItems[itemKey].scaledWidth) * 10) / 10}
                                          className="input_quantity_line"
                                          type="number"
                                          onFocus={(e) => e.target.select()}
                                          onChange={(e) => handleOnChangeWidthSize(e)}
                                        />
                                        <div
                                          className="spinner_up"
                                          onClick={() => handleOnIncreaseSize('width')}
                                        >
                                          <FontAwesomeIcon icon={faPlusRegular} className="icon" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group borderBottom fontSize space--between">
                                    <div className="form-group--headline">
                                      {t('configurator_3d_height_cm')}
                                    </div>
                                    <div className="quantity quantitySizes">
                                      <div className="spinner_wrapper">
                                        <div
                                          className="spinner_down"
                                          onClick={() => handleOnDecreaseSize('height')}
                                        >
                                          <FontAwesomeIcon icon={faMinusRegular} className="icon" />
                                        </div>
                                        {/* // 1cm = 37.79527559055118px */}
                                        <input
                                          id={`inputQtyHeight_${itemKey}`}
                                          defaultValue={Math.round((addedLogoItems[itemKey].scaledHeight) * 10) / 10}
                                          className="input_quantity_line"
                                          type="number"
                                          onFocus={(e) => e.target.select()}
                                          onChange={(event) => handleOnChangeHeightSize(event)}
                                        />
                                        <div
                                          className="spinner_up"
                                          onClick={() => handleOnIncreaseSize('height')}
                                        >
                                          <FontAwesomeIcon icon={faPlusRegular} className="icon" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="relative z-20 controlBarWrapper marginBottom--small">
                                    <div className="controlBar--left">
                                      <button
                                        className="icon text-[26px] p-[10px]"
                                        onClick={() => onSendLogoToFront(itemKey)}
                                      >
                                        <FontAwesomeIcon icon={faBringForward} />
                                      </button>
                                      <button
                                        className="icon text-[26px] p-[10px]"
                                        onClick={() => onSendLogoToBack(itemKey)}
                                      >
                                        <FontAwesomeIcon icon={faSendBackward} />
                                      </button>
                                    </div>
                                    <div className="controlBar--right">
                                      <button
                                        className="icon text-[26px] p-[10px]"
                                        onClick={() => onClickDeleteLogo(itemKey)}
                                      >
                                        <FontAwesomeIcon icon={faTrash} />
                                      </button>
                                    </div>
                                  </div>
                                  <div className="flex gap-4">
                                    <i className="relative top-px w-[16px] h-[16px] text-[#FF6C00]">
                                      <IconInfo className="w-[16px] h-[16px]" />
                                    </i>
                                    <p className="relative z-20 text-[#FF6C00] leading-8">
                                      { t('configurator_3d_size_info_logo')}
                                    </p>
                                  </div>
                                </div>
                            }
                          </div>
                        )
                      }
                    </div>
                  </div>
                : <></>
            }
          </div>
        </div>

        {/* JAKO Logo Position Tab */}
        <div
          className={
            "tabs__content mt-10 "
            + (selectTab === LogoTabType.LogoPosition ? ' active' : '')
          }
        >
          <div className="slideContent slideContent__borderTop">
            <div className="slideContainer">
              <div className="slideContainer__headline">
                { t('configurator_3d_edit_logo') }
              </div>
              <div
                className={
                  "slideContent__show slideContent__show--small "
                  + (showJakoLogoPosition ? 'active' : '')
                }
                onClick={() => setShowJakoLogoPosition(!showJakoLogoPosition)}
              >
                <div className="slideContent__plus">
                  <div className="horizontal" />
                  <div className="vertical" />
                </div>
              </div>
              <div className="slideContent__contentContainer">
                <div className="itemsWrapper--2cols">
                  {
                    ModelStyle.Short === modelStyle
                      ? <>
                        <div
                          className={
                            "itemTemplate "
                            + (jakoLogoPosition === JakoLogoPosition.Right ? 'active' : '')
                          }
                          onClick={() => onSelectJakoLogoPosition(JakoLogoPosition.Right)}
                        >
                          <div className="itemTemplate__image">
                            <img src={jerseyFrontTopLeft} alt="" className="w-full h-auto object-cover" />
                          </div>
                          <div className="itemTemplate__description">
                            { t('configurator_3d_short_right') }
                          </div>
                        </div>
                        <div
                          className={
                            "itemTemplate "
                            + (jakoLogoPosition === JakoLogoPosition.Left ? 'active' : '')
                          }
                          onClick={() => onSelectJakoLogoPosition(JakoLogoPosition.Left)}
                        >
                          <div className="itemTemplate__image">
                            <img src={jerseyFrontTopRight} alt="" className="w-full h-auto object-cover" />
                          </div>
                          <div className="itemTemplate__description">
                            { t('configurator_3d_short_left') }
                          </div>
                        </div>
                      </>
                      : <>
                        <div
                          className={
                            "itemTemplate "
                            + (jakoLogoPosition === JakoLogoPosition.Right ? 'active' : '')
                          }
                          onClick={() => onSelectJakoLogoPosition(JakoLogoPosition.Right)}
                        >
                          <div className="itemTemplate__image">
                            <img src={jerseyFrontTopRight} alt="" className="w-full h-auto object-cover" />
                          </div>
                          <div className="itemTemplate__description">
                            { t('configurator_3d_front_top_right') }
                          </div>
                        </div>
                        <div
                          className={
                            "itemTemplate "
                            + (jakoLogoPosition === JakoLogoPosition.Left ? 'active' : '')
                          }
                          onClick={() => onSelectJakoLogoPosition(JakoLogoPosition.Left)}
                        >
                          <div className="itemTemplate__image">
                            <img src={jerseyFrontTopLeft} alt="" className="w-full h-auto object-cover" />
                          </div>
                          <div className="itemTemplate__description">
                            { t('configurator_3d_front_top_left') }
                          </div>
                        </div>
                      </>
                  }
                  {
                    !isPolo
                      ? <div
                          className={
                            "itemTemplate "
                            + (jakoLogoPosition === JakoLogoPosition.Center ? 'active' : '')
                          }
                          onClick={() => onSelectJakoLogoPosition(JakoLogoPosition.Center)}
                        >
                          <div className="itemTemplate__image">
                            <img src={jerseyFrontTopCenter} alt="" className="w-full h-auto object-cover" />
                          </div>
                          <div className="itemTemplate__description">
                            { t('configurator_3d_front_top_center') }
                          </div>
                        </div>
                      : <></>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between mt-20">
        <button
          className="px-[27px] py-[12px] bg-[#FAFAFA] text-[#5B5B5B]"
          onClick={setSelectTabBack}
        >
          <div className="flex items-center">
            <FontAwesomeIcon
              className="h-[14px] mr-2"
              icon={faAngleLeft}
            />
            <span className='relative top-[-1px]'>{ t('configurator_3d_back') }</span>
          </div>
        </button>
        <button
          className="px-[27px] py-[12px] bg-[#008AC9] text-[#FFFFFF]"
          onClick={setSelectTabNext}
        >
          <div className="flex items-center">
            <span className='relative top-[-1px]'>{ t('configurator_3d_basket_next') }</span>
            <FontAwesomeIcon
              className="h-[14px] ml-2"
              icon={faAngleRight}
            />
          </div>
        </button>
      </div>
    </>;
});

export default JakoLogoTab;

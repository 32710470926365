import { apiClient, apiJfnet, newApiJfnet } from './axios';
import type { IndividualisationViewCode } from '../webcomponent/types';
import type { CuttingFileTemplateResponse, CuttingOptionResponseItem } from '../webcomponent/responses';
import type { AxiosResponse } from 'axios';

export const JakoServices = {
  getCuttings({
    correlationId,
    company,
    shopCode,
    languageCode,
    itemNo,
  }: {
    correlationId: string,
    company: string,
    shopCode: string,
    languageCode: string,
    itemNo?: number,
  }) {
    if (itemNo) {
      return apiClient.get(`/cuttingForms/correlationId/${correlationId}/company/${company}/shopCode/${shopCode}/languageCode/${languageCode.toUpperCase()}`, {
        params: {
          itemNo
        }
      });
    }
    return apiClient.get(`/cuttingForms/correlationId/${correlationId}/company/${company}/shopCode/${shopCode}/languageCode/${languageCode.toUpperCase()}`);
  },
  getCuttingsOptions({
    correlationId,
    company,
    shopCode,
    languageCode,
  }: {
    correlationId: string,
    company: string,
    shopCode: string,
    languageCode: string,
  }): Promise<AxiosResponse<CuttingOptionResponseItem[], any>> {
    return apiClient.get(`/cuttingFormLinks/correlationId/${correlationId}/company/${company}/shopCode/${shopCode}/languageCode/${languageCode.toUpperCase()}`);
  },
  getDesigns({
    correlationId,
    company,
    shopCode,
    languageCode,
  }: {
    correlationId: string,
    company: string,
    shopCode: string,
    languageCode: string,
  }) {
    return apiClient.get(`/designs/correlationId/${correlationId}/company/${company}/shopCode/${shopCode}/languageCode/${languageCode.toUpperCase()}`);
  },
  getTranslations({
    correlationId,
    company,
    selectLang,
    forParam,
  }: {
    correlationId: string,
    company: string,
    selectLang: string,
    forParam?: number,
  }) {
    return apiClient.get(`/textConstants/correlationId/${correlationId}/company/${company || 'JAKO%20AG'}/languageCode/${selectLang.toUpperCase() || 'DE'}/for/${forParam || 1}`);
  },
  getPatterns({
    correlationId,
    company,
    languageCode,
  }: {
    correlationId: string,
    company: string,
    languageCode: string,
  }) {
    return apiClient.get(`/pattern/correlationId/${correlationId}/company/${company}/languageCode/${languageCode.toUpperCase()}`);
  },
  getColors({
    correlationId,
    company,
    languageCode,
  }: {
    correlationId: string,
    company: string,
    languageCode: string,
  }) {
    return apiClient.get(`/color/correlationId/${correlationId}/company/${company}/languageCode/${languageCode.toUpperCase()}`);
  },
  getCustomFields({
    correlationId,
    company,
    shopCode,
    languageCode,
  }: {
    correlationId: string,
    company: string,
    shopCode: string,
    languageCode: string,
  }) {
    return apiClient.get(`/customFields/correlationId/${correlationId}/company/${company}/shopCode/${shopCode}/languageCode/${languageCode.toUpperCase()}`);
  },
  getPrices({
    correlationId,
    itemNo,
    cuttingFormCode,
  }: {
    correlationId: string,
    itemNo: string,
    cuttingFormCode: string,
  }) {
    return apiClient.get(`/prices/correlationId/${correlationId}/itemNo/${itemNo}/cuttingFormCode/${cuttingFormCode}`);
  },
  putIndividualisation(saveNewConfiguration: {
    correlationId: string,
    company: string,
    shopCode: string,
    languageCode: string,
    itemNo: string,
    cuttingFormCode: string,
    visitorId: number,
    configurationName: string,
    isPrintable: boolean,
    designLines:  {
      viewCode: IndividualisationViewCode,
      fieldCode: string,
      value: string,
      size: {
        width: number,
        height: number,
      }[],
      fontColorCode: string,
      fontFamily: string,
      imageColors: {
        colorHexCode: string,
        colorInfoText: string,
      }[]
    }[],
    previewImages: {
      viewCode: IndividualisationViewCode,
      value: string
    }[],
    cuttingFormElements: {
      viewCode: IndividualisationViewCode | string,
      colorCode: string
    }[]
  }) {
    return apiClient.put('/individualisation/', {
      ...saveNewConfiguration
    });
  },
  postIndividualisation(body: {
    correlationId: string,
    designHeaderId: number,
    cuttingFormCode: string,
    configurationName: string,
    isPrintable: boolean,
    itemNo: string,
    designLines:  {
      viewCode: IndividualisationViewCode,
      fieldCode: string,
      value: string,
      size: {
        width: number,
        height: number,
      }[],
      fontColorCode: string,
      fontFamily: string,
      imageColors: {
        colorHexCode: string,
        colorInfoText: string,
      }[]
    }[],
    previewImages: {
      viewCode: IndividualisationViewCode,
      value: string
    }[],
    cuttingFormElements: {
      viewCode: IndividualisationViewCode | string,
      colorCode: string
    }[]
  }) {
    return apiClient.post('/individualisation/', body);
  },
  basketAddItems(data: {
    correlationId: string,
    visitorId: number,
    basketLines:  {
      itemNo: string,
      quantity: number,
      designHeaderId: number
    }[],
  }) {
    return apiClient.put('/basket/addItems', data);
  }
};

export const JfnetServices = {
  getCuttingFiles(cuttingFormCode: string): Promise<AxiosResponse<CuttingFileTemplateResponse, any>> {
    return apiJfnet.get(`/files/static/${cuttingFormCode}`);
  },
  getConfigs(designHeaderId: string){
    return newApiJfnet.get(`/config/${designHeaderId}`);
  },
  getConfigsWithJwt(designHeaderId: string, designHeaderIdSignature: string){
    return newApiJfnet.get(`/config/${designHeaderId}`, {
      headers: {
        'Design-Header-Id-Signature': designHeaderIdSignature
      }
    });
  },
  saveConfigs(designHeaderId: string, data: string){
    return Promise.resolve();
  },
  getLogoByReference(reference: string) {
    return newApiJfnet.get(`/logos/${encodeURIComponent(reference)}`);
  },
  checkLogoUpload(data: any): Promise<AxiosResponse<{
    canBeUsed: boolean,
    reason: string,
    image: string,
    reference: string
  }, any>> {
    const formData = new FormData();
    formData.append('file', data);
    return newApiJfnet.post(`/logos/check`, formData);
  },
  fontToSvg({ text, font }: {
    text: string,
    font: string
  }) {
    return newApiJfnet.post('/fonts/path', {
      text,
      font: font.replace(/\s/g, '')
    });
  },
  savePrintFileMaster({
    designHeaderId,
    printMaster,
  }: {
    designHeaderId: string,
    printMaster: any
  }) {
    const formData = new FormData();
    formData.append('designHeaderId', designHeaderId);
    formData.append('printMaster', printMaster);
    return newApiJfnet.post('/files/master', formData);
  },
  printFileMaster({
    designHeaderId,
    printData,
  }: {
    designHeaderId: string,
    printData: {
      printId: string,
      size: string,
      customFields: {
        [key: string]: string
      }
    }[]
  }) {
    return newApiJfnet.post('/files/print', {
      designHeaderId,
      printData
    });
  },
  getPrintcutCoords(templateCode: string) {
    return newApiJfnet.get('/print_cut/coords/by_template_code/' + templateCode);
  }
};
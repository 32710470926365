import React, { useState, useEffect, useContext } from 'react';
import { JakoServices } from '../helpers/services';
import type { CustomFieldsResponseItem } from '../webcomponent/responses';
import { BaseContext } from './BaseContext';

type Props = {
  children: string | JSX.Element | JSX.Element[]
}

export default function BaseStore({ children }: Props) {
  const [isLoadingCustomFields, setIsLoadingCustomFields] = useState(false);
  const [customFields, setCustomFields] = useState<CustomFieldsResponseItem[]>([]);
  const {
    correlationId,
    company,
    shopCode,
    visitorId,
    cuttingFormCode,
    basketUrl,
    languagecode,
    designheaderid,
    baseapiurl,
    uiapiurl,
    itemno,
    onclicksubmit,
    onclicksave,
    isb2c,
    isloggedin
  } = useContext(BaseContext);

  useEffect(() => {
    if (customFields.length) {
      return;
    }

    setIsLoadingCustomFields(true);
    const payloadCorrelationId = correlationId || '111';
    const payloadCompany = company || 'JAKO AG';
    const payloadShopCode = shopCode || 'B2C';
    JakoServices.getCustomFields({
      correlationId: payloadCorrelationId,
      company: payloadCompany,
      shopCode: payloadShopCode,
      languageCode: languagecode
    }).then((res) => {
      setCustomFields(res.data);
    }).finally(() => {
      setIsLoadingCustomFields(false);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <BaseContext.Provider value={{
      isLoadingCustomFields,
      customFields,
      correlationId,
      company,
      shopCode,
      visitorId,
      cuttingFormCode,
      basketUrl,
      languagecode,
      designheaderid,
      baseapiurl,
      uiapiurl,
      itemno,
      onclicksubmit,
      onclicksave,
      isb2c,
      isloggedin
    }}>
      { children }
    </BaseContext.Provider>
  );
}